import React from 'react';
import Dialog from '../../shared/dialog/dialog';
import { bindActionCreators } from 'redux';
import * as WindowManagementActions from '../../../stores/window-management/actions';
import { connect } from 'react-redux';
import ViewForm from '../../shared/view-form/view-form';
import { Button, Form, Select } from 'antd';

class FlowSheetConfigDialog extends React.Component {
  constructor(props) {
    super(props);
    this.menuLibs = [
      { nodeType: "d2d", odmId: 21681, title: 'Flowsheet Editor Object Panel Configuration - 0 [EPC.FS_OBJ_PANE_CFG0]' }
    ]
    this.dpis = [
      { value: 300, text: '300 dpi'},
      { value: 180, text: '180 dpi'},
      { value: 120, text: '120 dpi'},
      { value: 90, text: '90 dpi'},
      { value: 60, text: '60 dpi'},
    ]
    // todo: get the config from dataset
    console.log(this.props.dataset)
    this.state = {
      form: {
        menuId: '',
        paperSize: '',
        dpi: ''
      }
    }
  }

  handleClose = () => {
    this.props.actions.removeWindow(this.props.windowId)
  }

  handleSubmit() {
    this.props.vfODM.pushSubToParentODM()
    this.props.actions.removeWindow(this.props.windowId)
  }

  handleMinimize () {
    this.props.actions.setWindowVisible(this.props.windowId, false)
  }

  onFinish = (val) => {
    console.log(val)
    this.handleSubmit()
  }

  render() {
    const menuOptions = this.menuLibs.map(item => {
      return (<Select.Option value={item.odmId}>{item.title}</Select.Option>)
    })
    const dpiOptions = this.dpis.map(item => {
      return (<Select.Option value={item.value}>{item.text}</Select.Option>)
    })
    const validateMessages = {
      required: '${label} is required!',
    }
    const vfODM = this.props.vfODM
    const vfOE = this.props.vfOE
    const fsDataset = this.props.dataset
    
    return (
      <Dialog
        title={ this.props.title }
        windowId={this.props.windowId}
        visible={ this.props.visible }
        onClose={ this.handleClose.bind(this) }
        onMinimize={ this.handleMinimize.bind(this) }
        defaultWidth={500}
        defaultHeight={400}
      >
        <ViewForm vFObject={fsDataset} vFCode='CFG' odm={vfODM} oe={vfOE} key={fsDataset.id}></ViewForm>
        <div className="btn-container mt-20">
          <Button type="default" htmlType="button" onClick={() => {this.handleClose()}}>
            Cancel
          </Button>
          <Button type="primary" htmlType="submit" className="ml-20" onClick={() => {this.handleSubmit()}}>
            Apply
          </Button>
        </div>
      </Dialog>
    )
  }
}

const mapDispatchToProps = dispatch => ({
  actions: Object.assign(
    {},
    bindActionCreators(WindowManagementActions, dispatch)
  )
})

export default connect(
  null,
  mapDispatchToProps
)(FlowSheetConfigDialog)
