import React from 'react'
import { Input } from 'antd';

import ABase from './a-base'

class AInput extends ABase {
  constructor(props) {
    super(props);
  }

  static getDerivedStateFromProps(props, state) {
    if (props.editMode !== state.editMode) {
      return {
        editMode: props.editMode
      };
    }

    return {}
  }

  onChange = (event) => {
    this.onAttChange(event.target.value)
  }

  render() {
    return this.state.editMode ? <Input value={this.state.value} onChange={this.onChange} id={this.props.id}/> :
        <div>{this.state.value}</div>
  }
}

export default AInput
