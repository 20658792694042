import * as React from 'react';
import renderSwitcherIcon from "./icon-util";
import classNames from 'classnames';
import collapseMotion from "./motion";
import OTree from './o-tree';


const OAntDTree = React.forwardRef((props, ref) => {
    const {
        prefixCls,
        className,
        showIcon,
        showLine,
        switcherIcon,
        blockNode,
        children,
        checkable,
    } = props;
    const newProps = {
        ...props,
        showLine: Boolean(showLine),
    };

    return (
        <OTree
            itemHeight={20}
            ref={ref}
            {...newProps}
            className={classNames(
                {
                    [`${prefixCls}-icon-hide`]: !showIcon,
                    [`${prefixCls}-block-node`]: blockNode,
                },
                className,
            )}
            checkable={checkable ? <span className={`${prefixCls}-checkbox-inner`} /> : checkable}
            switcherIcon={(nodeProps) =>
                renderSwitcherIcon(prefixCls, switcherIcon, showLine, nodeProps)
            }
        >
            {children}
        </OTree>
    );
});

OAntDTree.defaultProps = {
    prefixCls: 'ant-tree',
    checkable: false,
    showIcon: false,
    motion: {
        ...collapseMotion,
        motionAppear: false,
    },
    blockNode: false,
};

export default OAntDTree
