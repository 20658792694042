import {
  REFRESH_ROOT_PROJECTS
} from '../constants/root-projects-types'

const initialState = []

export default function rootProjects (state = initialState, action) {
  switch (action.type) {
    case REFRESH_ROOT_PROJECTS:
      return [
        ...action.rootProjects
      ]

    default:
      return state
  }
}
