import {
  startCase
} from 'lodash'
import React from 'react'

import Input from '../common/input/input'
import Select from '../common/select/select'
import InputWithUnit from '../common/input-with-unit/input-with-unit'
import InputColorPicker from '../common/input-color-picker/input-color-picker'
import BorderSelect from '../common/border-select/border-select'
import {
  CELL_ATTRIBUTES_CAN_ADJUST_LIST,
  CELL_STYLES_CAN_ADJUST_LIST, COLUMN_STYLES_CAN_ADJUST_LIST, DEFAULT_COLUMN_WIDTH, ROW_STYLES_CAN_ADJUST_LIST,
  TABLE_STYLES_CAN_ADJUST_LIST,
} from '../../core/constant';
import classNames from 'classnames';

export const PROPERTIES_NEED_COLOR_PICKER = [
  'borderColor',
  'borderTopColor',
  'borderRightColor',
  'borderBottomColor',
  'borderLeftColor',
  'backgroundColor',
  'color'
]

export const PROPERTIES_NEED_SELECT = [
  'fontFamily',
  'fontWeight',
  'textDecoration',
  'textAlign'
]

export const getOptionsByKey = key => {
  switch (key) {
    case 'fontFamily':
      return ['Calibri', 'Arial', 'Times New Roman']
    case 'fontWeight':
      return ['lighter', 'normal', 'bold', 'bolder']
    case 'textDecoration':
      return ['none','underline', 'overline', 'line-through', 'blink']
    case 'textAlign':
      return ['left', 'center', 'right']
    default:
      return []
  }
}

export const PROPERTIES_NEED_INPUT_UNIT = [
  'width',
  'minWidth',
  'height',
  'borderWidth',
  'borderTopWidth',
  'borderRightWidth',
  'borderBottomWidth',
  'borderLeftWidth',
  'padding',
  'paddingTop',
  'paddingBottom',
  'paddingLeft',
  'paddingRight',
  'fontSize'
]

export const PROPERTIES_NEED_BORDER_SELECT = [
  'borderStyle',
  'borderTopStyle',
  'borderRightStyle',
  'borderBottomStyle',
  'borderLeftStyle',
]

// the order of this array will affect the render order
export const PROPERTIES_CATEGORIES = [
  'attribute',
  'general',
  'font',
  'border',
  'topBorder',
  'rightBorder',
  'bottomBorder',
  'leftBorder',
  'padding',
]

export const GENERAL_PROPERTIES_KEYS = [
  'width',
  'minWidth',
  'height',
  'backgroundColor'
]

export const FONT_PROPERTIES_KEYS = [
  'color',
  'fontSize',
  'fontFamily',
  'fontWeight',
  'textDecoration',
  'textAlign'
]

export const BORDERS_PROPERTIES_KEYS = [
  'borderWidth',
  'borderStyle',
  'borderColor'
]

export const TOP_BORDERS_PROPERTIES_KEYS = [
  'borderTopWidth',
  'borderTopStyle',
  'borderTopColor'
]

export const RIGHT_BORDERS_PROPERTIES_KEYS = [
  'borderRightWidth',
  'borderRightStyle',
  'borderRightColor'
]

export const BOTTOM_BORDERS_PROPERTIES_KEYS = [
  'borderBottomWidth',
  'borderBottomStyle',
  'borderBottomColor'
]

export const LEFT_BORDERS_PROPERTIES_KEYS = [
  'borderLeftWidth',
  'borderLeftStyle',
  'borderLeftColor'
]

export const PADDING_PROPERTIES_KEYS = [
  'paddingTop',
  'paddingBottom',
  'paddingLeft',
  'paddingRight'
]

export const ATTRIBUTES_KEYS = [
  'colspan',
  'autoColspan',
  'rowspan'
]

export const whetherToUseColorPicker = (key) => PROPERTIES_NEED_COLOR_PICKER.indexOf(key) >= 0
export const whetherToUseSelect = (key) => PROPERTIES_NEED_SELECT.indexOf(key) >= 0
export const whetherToUseInputWithUnit = (key) => PROPERTIES_NEED_INPUT_UNIT.indexOf(key) >= 0
export const whetherToUseBorderSelect = (key) => PROPERTIES_NEED_BORDER_SELECT.indexOf(key) >= 0

export const formattedLabel = str => {
  switch (str) {
    case 'color':
      str = 'fontColor'
      break
    case 'colspan':
      str = 'Manually-set colspan'
      break
    case 'autoColspan':
      str = 'colspan'
      break
  }

  return startCase(str)
}

export const groupedProperties = category => {
  switch (category) {
    case 'attribute':
      return ATTRIBUTES_KEYS
    case 'general':
      return GENERAL_PROPERTIES_KEYS
    case 'font':
      return FONT_PROPERTIES_KEYS
    case 'border':
      return BORDERS_PROPERTIES_KEYS
    case 'topBorder':
      return TOP_BORDERS_PROPERTIES_KEYS
    case 'rightBorder':
      return RIGHT_BORDERS_PROPERTIES_KEYS
    case 'bottomBorder':
      return BOTTOM_BORDERS_PROPERTIES_KEYS
    case 'leftBorder':
      return LEFT_BORDERS_PROPERTIES_KEYS
    case 'padding':
      return PADDING_PROPERTIES_KEYS
    default:
      return []
  }
}

export const groupedPropertiesAndFilter = (values) => {
  return PROPERTIES_CATEGORIES.map(category => {
    const keys = groupedProperties(category)
    const cateKeys = keys.filter(item => values.includes(item))

    return {category, keys: cateKeys}
  }).filter(item => item.keys.length > 0)
}


export const getInputComponentViaProperty = key => {
  if (whetherToUseColorPicker(key)) {
    return InputColorPicker
  } else if (whetherToUseSelect(key)) {
    return Select
  } else if (whetherToUseInputWithUnit(key)) {
    return InputWithUnit
  } else if (whetherToUseBorderSelect(key)) {
    return BorderSelect
  } else if (key === 'autoColspan') {
    return (props) => {
      const colspan = props.styles.colspan
      return (<div>{props.value || colspan || '1'}</div>)
    }
  } else {
    return Input
  }
}

export function getViewFormTypeTitle(vFObject) {

  let dialogTitle = ''

  switch(vFObject.typ.toLowerCase()) {
    case "d":
      dialogTitle = `Dataset [ID: ${vFObject.id}]`;
      break;
    case "rd":
      dialogTitle = `Root Dataset [ID: ${vFObject.id}]`;
      break;
    case "ra":
      dialogTitle = `Root Attribute [ID: ${vFObject.id}]`;
      break;
    case "a":
      dialogTitle = `Attribute [ID: ${vFObject.id}]`;
      break;
    case "rd2rd":
      dialogTitle = `RD2RD Relation [ID: ${vFObject.id}]`;
      break;
    case "d2d":
      dialogTitle = `D2D Relation [ID: ${vFObject.id}]`;
      break;
    case "rd2d":
      dialogTitle = `RD2D Relation [ID: ${vFObject.id}]`;
      break;
  }

  return dialogTitle
}

export const genHash = () => {
  const id = Math.random()
    .toString(36)
    .slice(2, 8)
  return id
}

export const getTableStyles = (table) => {
  if (table && table.styles) {
    const tableStyles = TABLE_STYLES_CAN_ADJUST_LIST.reduce((obj, key) => {
      obj[key] = table.styles[key]
      return obj
    }, {})
    // compute the width of table via columns
    // const tableWidth = table.properties['width']
    //   ? table.properties['width']
    //   : table.columns.reduce((sum, column) => {
    //       let columnWidth = parseInt(column.properties['width'], 10) || DEFAULT_COLUMN_WIDTH
    //       sum = sum + columnWidth
    //       return sum
    //     }, 0) + 'px'

    const tableWidth = table.styles['width']
      ? table.styles['width']
      : '100%'
    tableStyles['width'] = `${tableWidth}`
    return tableStyles
  } else {
    return {}
  }
}

export const getColumnStylesWithDefaultWidth = (col) => {
  const oStyles = getColumnStyles(col)
  oStyles['width'] = oStyles['width'] || `${DEFAULT_COLUMN_WIDTH}px`
  return oStyles
}

export const getColumnStyles = column => {
  if (column && column.styles) {
    return COLUMN_STYLES_CAN_ADJUST_LIST.reduce((obj, key) => {
      obj[key] = column.styles[key]
      return obj
    }, {})
  } else {
    return {}
  }
}

export const getRowStyles = (row) => {
  if (row && row.styles) {
    return ROW_STYLES_CAN_ADJUST_LIST.reduce((obj, key) => {
      obj[key] = row.styles[key]
      return obj
    }, {})
  } else {
    return {}
  }
}

export const getCellStyles = (cell) => {
  if (cell && cell.styles) {
    return CELL_STYLES_CAN_ADJUST_LIST.reduce((obj, key) => {
      obj[key] = cell.styles[key]
      return obj
    }, {})
  } else {
    return {}
  }
}

export const getCellRowspan = (cell) => {
  return (cell && cell.styles?.rowspan) || 1
}

export const getCellColspan = (cell) => {
  return (cell && (cell.styles?.autoColspan || cell.autoColspan || cell.styles?.colspan)) || 1
}

export const getDialogTitle = (icon, title) => {
  return <div className="common-dialog-title">
    <div className="common-dialog-title-icon">{icon}</div>
    <div className="ml-5 common-dialog-title-text" title={title}>{title}</div>
  </div>;
}
