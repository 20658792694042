import React from 'react'
import styles from './bottom-pane.module.less'
import ODM from 'odm'
import OE from 'oe'

class BottomPane extends React.Component {
  handleSave () {
    ODM.saveData('pend.save.click')
  }

  handleDiscard () {
    ODM.discard()
  }

  handleUndelete () {
    ODM.undelete()
  }

  handleShowObjects (objectState) {
    let contentDivId = OE.getNextHTMLId();
    this.props.actions.addWindow({
      type: 'ODMReport',
      props: {
        title: 'Loaded Objects',
        objectState: objectState,
        visible: true,
        contentId: contentDivId,
        addWindow: this.props.actions.addWindow,
        onOpen: function() {
            // Call load
        }
      }
    });
  }

  render () {
    return (
      <div id='oe-mui-bottom'
        className={ styles.bottomPane }
      >
        <div id='footer_left'
          className={ styles.bottomPane_left }
        >Object count: <a id='dz_nobjload' className={ styles.bottomPane_link }
            onClick={ () => { this.handleShowObjects('loaded') } }
            onKeyDown={ () => { this.handleShowObjects('loaded') } }
        ><span id='nloadeditems'>0</span> Loaded</a>, <a id='dz_nobjnosave' className={ styles.bottomPane_link }
            onClick={ () => { this.handleShowObjects('nosave') } }
            onKeyDown={ () => { this.handleShowObjects('nosave') } }
        ><span id='nnosaveitems'>0</span> No-Save</a>, <a id='dz_nobjmod' className={ styles.bottomPane_link }
            onClick={ () => { this.handleShowObjects('modified') } }
            onKeyDown={ () => { this.handleShowObjects('modified') } }
        ><span id='nmodifieditems'>0</span> Modified</a>, <a id='dz_nobjnew' className={ styles.bottomPane_link }
            onClick={ () => { this.handleShowObjects('new') } }
            onKeyDown={ () => { this.handleShowObjects('new') } }
        ><span id='nnewitems'>0</span> New</a>, <a id='dz_nobjdel' className={ styles.bottomPane_link }
            onClick={ () => { this.handleShowObjects('deleted') } }
            onKeyDown={ () => { this.handleShowObjects('deleted') } }
        ><span id='ndelitems'>0</span> Deleted</a>, <a id='dz_nobjloc' className={ styles.bottomPane_link }
            onClick={ () => { this.handleShowObjects('local') } }
            onKeyDown={ () => { this.handleShowObjects('local') } }
        ><span id='nlocitems'>0</span> Local</a></div>
        <div id='footer_center'
          className={ styles.bottomPane_sep }
        ></div>
        <div id='footer_right'>
          {/*<a onclick='OE.testFunction()'>Test Function</a>*/}
          <a id='save_link' className={ styles.bottomPane_plink }
            onClick={ () => { this.handleSave() } }
            onKeyDown={ () => { this.handleSave() } }
          >Save</a>
          |
          <a id='discard_link' className={ styles.bottomPane_plink }
            onClick={ () => { this.handleDiscard() } }
            onKeyDown={ () => { this.handleDiscard() } }
          >Discard</a>
          |
          <a id='undelete_link' className={ styles.bottomPane_plink }
            onClick={ () => { this.handleUndelete() } }
            onKeyDown={ () => { this.handleUndelete() } }
          >Undelete</a>
        </div>
      </div>
    )
  }
}

export default BottomPane
