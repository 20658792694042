import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import BottomPane from '../components/shared/bottom-pane/bottom-pane'

import * as WindowManagementActions from '../stores/window-management/actions'

const mapStateToProps = state => ({
  windows: state.windowState.windows
})

const mapDispatchToProps = dispatch => ({
  actions: Object.assign(
    {},
    bindActionCreators(WindowManagementActions, dispatch)
  )
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BottomPane)
