import React from 'react'
import SlateEditor from '../../slate-editor/slate-editor';
import ABase from './a-base';


class ASlateEditor extends ABase {
  constructor(props) {
    super(props);
  }

  static getDerivedStateFromProps(props, state) {
    if (props.editMode !== state.editMode) {
      return {
        editMode: props.editMode
      };
    }

    return {}
  }

  onChange = (value) => {
    value = JSON.stringify(value)
    this.onAttChange(value)
  }

  render() {
    return (
      <SlateEditor value={this.state.value} onChange={this.onChange} readOnly={!this.state.editMode} />
    )
  }
}

export default ASlateEditor
