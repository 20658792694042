import OE from 'oe'
import ODM from 'odm'
import { Select } from 'antd';
import React from 'react';


export function callJSC(jsStr, argsString) {
  try {
    return Function(`'use strict'; return function(${argsString}) {${jsStr}}`)()
  } catch (e) {
    console.error(e.message)
  }
}

// for test

// const result = callJSC('var a = 1;var b = 20; return a + b + arg1 + arg2', 'arg1, arg2')(10, 20)
// console.log(result) // will return 51


export function getDefaultVFCodeFormRD(rd) {
  const vfs = OE.getVFs(rd);
  const vfCodes = Object.keys(vfs)

  return vfCodes.includes('DEFAULT') ? 'DEFAULT' : vfCodes[0]
}

export function getEquipIconByTreeNode(nodeData, fOdm = undefined) {
  const odm = fOdm || ODM
  const oe = (fOdm && OE.createSubOE(fOdm)) || OE
  const d2d = nodeData.nodeType === 'd2d' && odm.getD2DRelNoLoad(nodeData.odmId)
  const dataset = (d2d && odm.getDNoLoadNoExtd(d2d.tid)) || (nodeData.nodeType === 'd' && odm.getDNoLoadNoExtd(nodeData.odmId))
  if (!dataset) {
    return
  }
  const eq = oe.getEqFSData(dataset, undefined, 'ICON')
  const { svgNode} = eq
  const svgStr = createSvgStr(svgNode, undefined, 32)
  return svgStr
}

export function getEquipTitle(nodeData, fOdm = undefined) {
  const odm = fOdm || ODM
  const oe = (fOdm && OE.createSubOE(fOdm)) || OE
  const d2d = nodeData.nodeType === 'd2d' && odm.getD2DRelNoLoad(nodeData.odmId)
  const dataset = (d2d && odm.getDNoLoadNoExtd(d2d.tid)) || (nodeData.nodeType === 'd' && odm.getDNoLoadNoExtd(nodeData.odmId))
  if (!dataset) {
    return
  }

  return oe.getDescription(dataset)
}

export function getEquipSvgByTreeNode(nodeData, fOdm = undefined) {
  const odm = fOdm || ODM
  const oe = (fOdm && OE.createSubOE(fOdm)) || OE
  const d2d = nodeData.nodeType === 'd2d' && odm.getD2DRelNoLoad(nodeData.odmId)
  const dataset = (d2d && odm.getDNoLoadNoExtd(d2d.tid)) || (nodeData.nodeType === 'd' && odm.getDNoLoadNoExtd(nodeData.odmId))
  if (!dataset) {
    return
  }

  return getEquipSvgFSEqD(dataset, odm)
}

export function getEquipSvgFSEqD(fSEqD, fOdm = undefined) {
  const odm = fOdm || ODM
  const oe = (fOdm && OE.createSubOE(fOdm)) || OE
  const eq = fSEqD && oe.getEqFSData(fSEqD, undefined, 'FS_P&ID')
  const { svgNode, width, height, flowNozzles = [] } = eq
  const svgWidth = resolveEquipSize(width)
  const svgHeight = resolveEquipSize(height)
  const svgStr = createSvgStr(svgNode, svgWidth, svgHeight)

  return {
    did: fSEqD.id,
    svgStr,
    flowNozzles: flowNozzles.map(item => {
      const { angle, draggable, fDir, xPos, yPos, did } = item

      return { angle, draggable, fDir, x: xPos, y: yPos, did }
    })
  }
}

function resolveEquipSize(strVal = '') {
  const reg = /^(\d+|\d+.\d+) ?([a-z]+)$/i
  const matches = strVal.match(reg)
  if (!matches) {
    return 25.4
  }
  const value = matches[1]
  const unit = matches[2]
  const helper = new UnitConversion()

  switch (unit) {
    case 'mm': {
      return helper.mmConversionPx(value)
    }
    case 'cm': {
      return helper.mmConversionPx(value * 10)
    }
    case 'm': {
      return helper.mmConversionPx(value * 1000)
    }
    case 'in': {
      return helper.mmConversionPx(value * 25.4)
    }
    case 'ft': {
      return helper.mmConversionPx(value * 304.8)
    }
    default: {
      return parseInt(value)
    }
  }
}

export function dimUnitToIn(val, unit, fixed = 5) {
  val = Number(val)
  switch (unit) {
    case 'mm': {
      return Number((val / 25.4).toFixed(fixed))
    }
    case 'cm': {
      return Number((val / 2.54).toFixed(fixed))
    }
    case 'm': {
      return Number((val / 0.0254).toFixed(fixed))
    }
    case 'pt': {
      return Number((val / 72).toFixed(fixed))
    }
    case 'ft': {
      return Number((val * 12).toFixed(fixed))
    }
    default: {
      console.warn('[Unit] is not valid')
      return val
    }
  }
}

export function inToDistUnit(val, unit, fixed) {
  val = Number(val)
  switch (unit) {
    case 'mm': {
      return Number((val * 25.4).toFixed(fixed))
    }
    case 'cm': {
      return Number((val * 2.54).toFixed(fixed))
    }
    case 'm': {
      return Number((val * 0.0254).toFixed(fixed))
    }
    case 'pt': {
      return Number((val * 72).toFixed(fixed))
    }
    case 'ft': {
      return Number((val / 12).toFixed(fixed))
    }
    default: {
      console.warn('[Unit] is not valid')
      return val
    }
  }
}

function createSvgStr(svgNode, width, height) {
  const { atts = {}, children } = svgNode
  const attrsStr = Object.keys(atts).map(key => {
    return `${key}="${atts[key]}"`
  }).join(' ')

  const childrenStr = children.map(item => {
    const {children} = item

    const itemAttrs = Object.keys(item.atts || {}).map(key => {
      return `${key}="${item.atts[key]}"`
    }).join(' ')

    if (children) {
      return `<${item.type} ${itemAttrs}>${children}</${item.type}>`
    } else {
      return `<${item.type} ${itemAttrs} />`
    }
  })
  let widthStr = width ? `width="${width}"` : ''
  let heightStr = height ? `height="${height}"` : ''

  return `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" ${widthStr} ${heightStr} ${attrsStr}>${childrenStr}</svg>`
}

export function UnitConversion() {
  /**
   * get DPI
   * @returns {Array}
   */
  this.conversion_getDPI =function () {
    const arrDPI = new Array
    if (window.screen.deviceXDPI) {
      arrDPI[0] = window.screen.deviceXDPI
      arrDPI[1] = window.screen.deviceYDPI
    } else {
      const tmpNode = document.createElement('DIV')
      tmpNode.style.cssText = `width:1in;height:1in;position:absolute;left:0px;top:0px;z-index:99;visibility:hidden`
      document.body.appendChild(tmpNode)
      arrDPI[0] = parseInt(tmpNode.offsetWidth)
      arrDPI[1] = parseInt(tmpNode.offsetHeight)
      tmpNode.parentNode.removeChild(tmpNode)
    }
    return arrDPI
  }
  /**
   * px to mm
   * @param value
   * @returns {number}
   */
  this.pxConversionMm = function (value) {
    const inch = value/this.conversion_getDPI()[0]
    const c_value = inch * 25.4

    return c_value
  }
  /**
   * mm to px
   * @param value
   * @returns {number}
   */
  this.mmConversionPx = function (value) {
    const inch = value / 25.4
    const c_value = inch*this.conversion_getDPI()[0]

    return c_value
  }
}

export function mmToPxByDPI(value, dpi) {
  const inch = value / 25.4

  return inch * dpi
}

export function inToPxByDPI(value, dpi) {
  return value * dpi
}

export function pxToIn(val, dpi) {
  return (val / dpi).toFixed(5)
}

