import React from 'react'
import { Button, Form, Input, Modal, Select } from 'antd';
import OE from 'oe'
import ODM from 'odm'
import { CELL_DATASET_CODES } from '../../../../core/constant';

class CreateConfigDataset extends React.PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      isOpen: false,
      error: false
    }
  }

  handleSubmit = (value) => {
    OE.createDSByRDCode(value.code, [], [], (success, createdDataset) => {
      const state = {
        error: !success,
        isOpen: !success
      }
      if (success === true) {
        this.props.onCreated(createdDataset)
      }
      this.setState(state)
    });
  }

  handleCancel = () => {
    this.setState({
      isOpen: false
    })
  }

  openModal = () => {
    this.setState({
      isOpen: true
    })
  }


  render() {

    const codeEntry = CELL_DATASET_CODES.filter(entry => entry.rADTP === this.props.rADTP)
    const rdCodes = (codeEntry && codeEntry.length === 1) && codeEntry[0].cfgDRDCode
    let options = []
    if(rdCodes && rdCodes.length) {
      rdCodes.forEach((item, index) => {
        const rD = ODM.getRDNoLoadByCode(item)
        if (rD) {
          options.push(
            <Select.Option value={item} key={index}>{OE.getDescription(rD)}</Select.Option>
          )
        }
      })
    }
    return (
      <React.Fragment>
        <a onClick={this.openModal} onKeyDown={this.openModal}>{this.props.children}</a>
        <Modal title="Create Config Dataset" visible={this.state.isOpen} footer={null} onCancel={() => this.handleCancel()}>
          <Form
            layout={'vertical'}
            name="newForm"
            initialValues={{code: ''}}
            onFinish={(value) => this.handleSubmit(value)}
            onFinishFailed={this.onFinishFailed}
          >
            <p className="error-tips">
              {this.state.error ? 'Create data entry configuration': ''}
            </p>
            <Form.Item
              label="Type of Data Entry"
              name="code"
              rules={[
                { required: true, message: 'Please input the code!' },
              ]}
            >
              <Select
                placeholder="Select a code"
              >
                {options}
              </Select>
            </Form.Item>
            <div className="btn-container">
              <Button type="default" htmlType="button" onClick={() => this.handleCancel()}>
                Cancel
              </Button>
              <Button type="primary" htmlType="submit" className="ml-20">
                Submit
              </Button>
            </div>
          </Form>
        </Modal>
      </React.Fragment>
    )
  }
}

export default CreateConfigDataset
