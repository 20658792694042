import React from 'react';
import { cloneDeep } from 'lodash';
import { Dropdown, Menu } from 'antd';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import styles from './row-design.module.less';
import {
  CELL_ATTRIBUTES_CAN_ADJUST_LIST,
  CELL_STYLES_CAN_ADJUST_LIST,
  ROW_STYLES_CAN_ADJUST_LIST,
} from '../../../../core/constant';
import {
  genHash,
  getCellColspan,
  getCellRowspan,
  getCellStyles,
  getColumnStyles,
  getRowStyles,
  getTableStyles,
} from '../../utils';

const getRowMergedDraggingStyles = (row, provided) => {
  const userStyles = getRowStyles(row)
  return Object.assign(
    {},
    provided.draggableProps.style,
    userStyles
  )
}

class RowDesignTable extends React.PureComponent {
  constructor (props) {
    super(props);

    this.state = {
      sourceView: props.view,
      view: cloneDeep(props.view),
      activeRowIndex: props.activeRowIndex,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { view, activeRowIndex, activeCellIndex } = nextProps
    const changes = { activeRowIndex, activeCellIndex }
    if (view !== prevState.sourceView) {
      // formData changed
      changes.view = cloneDeep(view)
      changes.sourceView = view
    }

    return changes
  }

  onDragContextStart(result) {
    const { source } = result;
    this.perSetState({
      activeRowIndex: source.index
    })
  }

  onDragContextEnd(result) {
    const { source, destination } = result;

    // dropped outside the list
    if (!destination) {
      return;
    }

    const [removedRow] = this.state.view.rows.splice(source.index, 1);
    this.state.view.rows.splice(destination.index, 0, removedRow);

    this.perSetState({
      view: { ...this.state.view },
      activeRowIndex: destination.index
    });
  }

  dropdownShowHandler (visible, rowIndex) {
    if (visible) {
      this.perSetState({
        activeRowIndex: rowIndex
      })
    }
  }

  dropdownClickHandler ({ key }) {
    switch(key) {
      case 'insertAbove':
        this.insertNewRow(this.state.activeRowIndex, true)
        break
      case 'insertBelow':
        this.insertNewRow(this.state.activeRowIndex, false)
        break
      case 'delete':
        this.deleteRow(this.state.activeRowIndex)
    }
  }

  deleteRow = (rowIndex) => {
    if (this.state.view.rows.length === 1) {
      return;
    }

    this.state.view.rows.splice(rowIndex, 1)
    if (rowIndex === this.state.view.rows.length) {
      this.setState({
        activeRowIndex: this.state.activeRowIndex - 1,
      }, () => {
        this.perSetState({
          view: { ...this.state.view },
          activeRowIndex: this.state.activeRowIndex
        })
      })
    } else {
      this.perSetState({
        view: { ...this.state.view }
      })
    }
  }

  insertNewRow = (rowIndex, isAbove) => {
    const refRow = this.state.view.rows[rowIndex]
    const rowId = this.state.view.rows.length
    const newRow = {
      id: `row-${genHash()}`,
      cells: refRow.cells.reduce((newContent, item, index) => {
        newContent.push({
          id: `cell-${rowId}-${genHash()}`,
          styles: CELL_STYLES_CAN_ADJUST_LIST
            .concat(CELL_ATTRIBUTES_CAN_ADJUST_LIST)
            .reduce((obj, key) => {
              obj[key] = ''
              return obj
            }, {})
        })
        return newContent
      }, []),
      styles: ROW_STYLES_CAN_ADJUST_LIST.reduce((obj, key) => {
        obj[key] = ''
        return obj
      }, {})
    }

    if (isAbove) {
      this.state.view.rows.splice(rowIndex, 0, newRow)
    } else {
      this.state.view.rows.splice(rowIndex + 1, 0, newRow)
    }

    this.perSetState({
      view: { ...this.state.view },
      activeRowIndex: isAbove ? this.state.activeRowIndex + 1 : this.state.activeRowIndex
    })
  }

  perSetState(newState) {
    this.setState(newState)
    this.props.handleStateChange(newState)
  }



  render() {

    const rowCount = this.state.view.rows.length
    const deleteOption = rowCount !== 1 && (
      <React.Fragment>
        <Menu.Divider />
        <Menu.Item key="delete">Delete Row</Menu.Item>
      </React.Fragment>
    )
    const rowContextMenu = (
      <Menu onClick={ this.dropdownClickHandler.bind(this) }>
        <Menu.Item key="insertAbove">
          Insert Row Above
        </Menu.Item>
        <Menu.Item key="insertBelow">
          Insert Row Below
        </Menu.Item>
        {deleteOption}
      </Menu>
    )
    const rowDesignTable = (
      <DragDropContext
        onDragStart={this.onDragContextStart.bind(this)}
        onDragEnd={this.onDragContextEnd.bind(this)}
      >
        <Droppable droppableId="table" direction="vertical" type="row">
          {(provided, snapshot) => (
            <table className={ styles.ViewFormTableView }
                   style={ getTableStyles(this.state.view) }
                   ref={provided.innerRef}
            >
              <colgroup>
                {
                  this.state.view.columns.map(col => (
                    <col
                      key={ col.id }
                      style={ getColumnStyles(col) }
                    />
                  ))
                }
              </colgroup>
              <tbody>
              {
                this.state.view.rows.map((row, idx) => (
                  <Draggable key={row.id} draggableId={row.id} index={idx}>
                    {(provided, snapshot) => (
                      <Dropdown
                        overlay={ rowContextMenu }
                        trigger={['contextMenu']}
                        overlayClassName={styles.RowContextOverlay}
                        onVisibleChange={ visible => { this.dropdownShowHandler.bind(this)(visible, idx) } }
                      >
                        <tr
                          key={ row.id }
                          className={ `${idx === this.state.activeRowIndex ? styles.ActiveRow : ''}` }
                          onClick={ () => this.perSetState({ activeRowIndex: idx }) }
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={ getRowMergedDraggingStyles(row, provided) }
                        >
                          {
                            row.cells.map((cell, cellIndex) => (
                              <td
                                key={ cell.id }
                                style={ getCellStyles(cell) }
                                colSpan={ getCellColspan(cell) }
                                rowSpan={ getCellRowspan(cell) }
                              >{ cell.content || `cell-${idx}-${cellIndex}` }</td>
                            ))
                          }
                        </tr>
                      </Dropdown>
                    )}
                  </Draggable>
                ))
              }
              {provided.placeholder}
              </tbody>
            </table>
          )}
        </Droppable>
      </DragDropContext>
    )

    return rowDesignTable
  }
}

export default RowDesignTable;
