import React from 'react'
import { Dropdown, Menu } from 'antd';
import ODM from 'odm'
import OE from 'oe'
const { SubMenu } = Menu;

export function isFlowSheet(dataset) {
  const rootDataset = ODM.getRDNoLoadByCode('EPC.TOOLS.FS')
  return OE.isInstanceOfByRD(dataset, rootDataset)
//  return (rootDataset && rootDataset === 'EPC.TOOLS.FS') || /^FS\d+/.test(dataset.cod)
}

class D2DMenu extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { treeData, onVisibleChange, onClickMenuItem } = this.props
        const d2d = ODM.getD2DRelNoLoad(treeData.odmId)
        const dataset = ODM.getDNoLoad(d2d.tid)
        const rd = ODM.getRDNoLoad(dataset.rid)
        const isEquation = rd && (rd.cod === 'EPC.PML.GEQ')
        const d2dMenu = (
            <Menu forceSubMenuRender={false}>
              <Menu.Item key="open" onClick ={(e) => {e.domEvent.nativeEvent.noTreeSelect = true; onClickMenuItem(treeData,'open', e);}} >
                  Open
              </Menu.Item>
              {isEquation && <Menu.Item key="openEquationEditor" onClick ={(e) => {e.domEvent.nativeEvent.noTreeSelect = true; onClickMenuItem(treeData,'openEquationEditor', e);}} >
                Open Equation Editor
              </Menu.Item>}
              {dataset && isFlowSheet(dataset) && <Menu.Item key="configure" onClick ={(e) => {e.domEvent.nativeEvent.noTreeSelect = true; onClickMenuItem(treeData,'configure', e);}} >
                  Configure
                </Menu.Item>}
                <Menu.Item key="refresh" onClick ={(e) => {e.domEvent.nativeEvent.noTreeSelect = true; onClickMenuItem(treeData,'refresh', e);}} >
                    Refresh
                </Menu.Item>
                <SubMenu
                    key="d2d-d"
                    title="Dataset"
                >
                    <Menu.Item key="rename" onClick ={(e) => {e.domEvent.nativeEvent.noTreeSelect = true; onClickMenuItem(treeData,'rename', e);}} >
                        Rename
                    </Menu.Item>
                    <Menu.Item key="d-delete" onClick ={(e) => {e.domEvent.nativeEvent.noTreeSelect = true; onClickMenuItem(treeData,'d-delete', e);}} >
                        Delete
                    </Menu.Item>
                    <Menu.Item key="d-json" onClick ={(e) => {e.domEvent.nativeEvent.noTreeSelect = true; onClickMenuItem(treeData,'d-json', e);}} >
                        View JSON
                    </Menu.Item>
                </SubMenu>
                <SubMenu
                    key="d2d-rel"
                    title="Relation"
                >
                    <Menu.Item key="rel-delete" onClick ={(e) => {e.domEvent.nativeEvent.noTreeSelect = true; onClickMenuItem(treeData,'rel-delete', e);}} >
                        Delete
                    </Menu.Item>
                    <Menu.Item key="rel-json" onClick ={(e) => {e.domEvent.nativeEvent.noTreeSelect = true; onClickMenuItem(treeData,'rel-json', e);}} >
                        View JSON
                    </Menu.Item>
                </SubMenu>
            </Menu>
        );
        return (<Dropdown overlay={d2dMenu} onVisibleChange={onVisibleChange} >
            <a className="ant-dropdown-link">
                {treeData.title}
            </a>
        </Dropdown>);
    }
}

export default D2DMenu
