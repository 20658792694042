import React, { useState, memo, useMemo, createContext, useReducer, useEffect, useCallback } from 'react';
import { useTable, useFilters, useSortBy, useBlockLayout, useResizeColumns } from 'react-table';
import { Button, List, Popover, Select } from 'antd';
import { ColumnEditor } from './column-editor';
import { ColumnEditorsMap } from './index';
import { ColumnSettingDropdown } from './column-action-dropdown';
import * as matchSorter from 'match-sorter'
import DefaultColumnFilter from './default-column-filter';
import classnames from 'classnames'
import './style.less'
import OE from 'oe';
import ODM from 'odm';
import { copyTextToClipboard } from '../../../../../utils/utils';
import { RowSetting } from './row-setting';
import { dimUnitToIn } from '../../../../../core/utils';

const initState = {editMode: false, rowSettingAble: false, onSelect: false}
export const SpreadsheetContext = createContext({})

export const SET_EDIT_MODE = 'Set spreadsheet editMode'
export function setEditMode(payload) {
  return {
    type: SET_EDIT_MODE,
    payload
  }
}

export const TABLE_ROW_SETTING = 'Table row setting'
export function setTableRowSettingAble(payload) {
  return {
    type: TABLE_ROW_SETTING,
    payload
  }
}

export const SET_TABLE_SELECT_CELLS = 'Table select cells'
export function setTableSelectMode(payload) {
  return {
    type: SET_TABLE_SELECT_CELLS,
    payload
  }
}

export const SET_EDITING_CELL = 'Table set editing cell'
export function setTableEditingCell(payload) {
  return {
    type: SET_EDITING_CELL,
    payload
  }
}

const SpreadsheetEditorReducer = (state = initState, action) => {
  switch (action.type) {
    case SET_EDIT_MODE: {
      return {...state, editMode: action.payload}
    }
    case TABLE_ROW_SETTING: {
      return {...state, rowSettingAble: action.payload}
    }
    case SET_TABLE_SELECT_CELLS: {
      return {...state, onSelect: action.payload}
    }
    case SET_EDITING_CELL: {
      // {rowIndex, colId}
      return {...state, editCell: action.payload}
    }
    default: return state;
  }
}

function fuzzyTextFilterFn(rows, id, filterValue) {
  return matchSorter(rows, filterValue, { keys: [row => row.values[id]] })
}
// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = val => !val


// Set our editable cell renderer as the default Cell renderer
const defaultColumn = {
  Cell: ColumnEditor,
  Filter: DefaultColumnFilter,
}
export const TableSpreadsheet = memo(({data, columns, onDataChange, onAddRow, editMode, rowSetting, onSetRows, onRefresh, d, odm, oe}) => {
  const [activeCol, setActiveCol] = useState(null)
  const [tableColumns, setTableColumns] = useState(columns)
  const [showSearch, setShowSearch] = useState(false)
  const [selectState, setSelectState] = useState(null)
  const [ctxMenuStyle, setCtxMenuStyle] = useState(null)
  const [state, dispatch] = useReducer(SpreadsheetEditorReducer, {editMode})
  const [activeCell, setActiveCell] = useState(null)
  const oE = oe || OE
  const oDM = odm || ODM
  const filterTypes = useMemo(
    () => ({
      // Add a new fuzzyTextFilterFn filter type.
      fuzzyText: fuzzyTextFilterFn,
      // Or, override the default text filter to use
      // "startWith"
      text: (rows, id, filterValue) => {
        return rows.filter(row => {
          const rowValue = row.values[id]
          return rowValue !== undefined
            ? String(rowValue)
              .toLowerCase()
              .startsWith(String(filterValue).toLowerCase())
            : true
        })
      },
    }),
    []
  )

  useEffect(() => {
    if (state.editMode !== editMode) {
      dispatch(setEditMode(editMode))
    }
  }, [editMode])

  useEffect(() => {
    if (state.rowSettingAble !== rowSetting) {
      dispatch(setTableRowSettingAble(rowSetting))
      setTableColumns([...tableColumns])
    }
  }, [rowSetting])



  useEffect(() => {
    const onHideCtxMenu = (e) => {
      setCtxMenuStyle(null)
    }
    document.addEventListener('click', onHideCtxMenu, false)

    return () => {
      document.removeEventListener('click', onHideCtxMenu)
    }
  }, [])

  useEffect(() => {
    const clearSelect = (e) => {
      if (selectState && !state.onSelect) {
        setSelectState(null)
      }
    }
    document.addEventListener('click', clearSelect)

    return () => {
      document.removeEventListener('click', clearSelect)
    }
  }, [selectState, state.onSelect])

  const openContextMenu = (e) => {
    const {pageX, pageY} = e
    const style = {
      left: pageX,
      top: pageY,
    }
    setCtxMenuStyle(style)
    e.preventDefault()
  }
  /**
   *
   * @param index
   * @param type "above" | "below"
   */
  const addRow = (type) => {
    if (!activeCell) {
      return
    }
    onAddRow(activeCell.rowIndex, type)
  }
  const tableInstance = useTable(
    { columns: tableColumns, data, defaultColumn, onDataChange, filterTypes },
    useFilters, useSortBy, useBlockLayout, useResizeColumns, hooks => {

  })

  const {
    getTableProps,
      getTableBodyProps,
      headerGroups,
      rows,
      prepareRow,
      allColumns,
  } = tableInstance


  const isActiveColumn = (column) => {
    return column.id !== 'index' && column.id === activeCol
  }

  const setColumnEditor = (editorKey, column) => {
    const colCfgD = (column.colCfg.cfgDId || column.colCfg.cfgDId === 0) && oDM.getDNoLoadNoExtd(column.colCfg.cfgDId)
    const editorCfgAtt = colCfgD && oE.getAttForD(colCfgD, 'EDTCFG_D', undefined)
    const oldEditorCfgD = editorCfgAtt && oDM.getDNoLoadNoExtd(oE.getAttValue(editorCfgAtt))
    const oldEditorCfgRD = oldEditorCfgD && oDM.getRDNoLoadNoExtd(oldEditorCfgD.rid)
    const editorRA = oDM.getRAttNoLoadNoExtd(column.colCfg.deepRAId)
    const dataType = editorRA && editorRA.dtp.toLowerCase().endsWith("_ar") && editorRA.dtp.substring(0, editorRA.dtp.length - 3)
    const filteredEditorMapEntry = dataType && ColumnEditorsMap[dataType] && ColumnEditorsMap[dataType].filter(entry => entry.key === editorKey)
    const rDCod = filteredEditorMapEntry && filteredEditorMapEntry.length === 1 && "SYS.AECFG.SPDSHT.COL.EDTCFG." + filteredEditorMapEntry[0].rDCode
    const newEditorCfgRD = rDCod && oDM.getRDNoLoadByCode(rDCod)
    if (newEditorCfgRD) {
      const newEditorCfgD = (!oldEditorCfgD || oldEditorCfgRD.id !== newEditorCfgRD.id) && oE.createDSByRD(newEditorCfgRD, rDCod, [])
      if (newEditorCfgD) {
        const newEditorCfgAtt = editorCfgAtt || oE.createAttByCode(colCfgD, 'EDTCFG_D')
        oE.setAttValue(newEditorCfgAtt, newEditorCfgD.id)
        oDM.updateAtt(newEditorCfgAtt)
        column.colCfg.edtCfgDId = newEditorCfgD.id
        const newColumns = [...columns]
        const index = newColumns.findIndex(col => col.accessor === column.id)
        newColumns[index] = {...column}
        setTableColumns(newColumns)
      }
    }
  }

  const showColumn = (column) => {
    const columnHiddenProps = column.getToggleHiddenProps()
    columnHiddenProps.onChange({target: {checked: true}})
  }

  const activeColumn = (column) => {
    setActiveCol(column.id)
    if (column.id !== activeCol && showSearch) {
      setShowSearch(false)
    }
  }

  const isDimensionalCol = (column) => {
    if (column.id === 'index') return false

    const { colCfg } = column
    const rAtt = colCfg && !isNaN(colCfg.deepRAId) && oDM.getRAttNoLoadNoExtd(colCfg.deepRAId)

    return rAtt && rAtt.dtp === 'DIMVAR_AR'
  }

  const setColumnUnit = (value, column) => {
    const newColumns = [...columns]
    const {colCfg} = column
    const deepRA = colCfg && !isNaN(colCfg.deepRAId) && oDM.getRAttNoLoadNoExtd(colCfg.deepRAId)
    if (d && deepRA) {
      // Todo: add default unit to RA and later default unit sets.  This is a quick fix for now
      const dim = oDM.getDimensionById(deepRA.ddim || 2);
      const unitValue = (colCfg && colCfg.unit)
      const oldUnit = dim.units.filter(unit => unit.id === unitValue);
      const newUnit = dim.units.filter(unit => unit.id === value);
      const dataAtt = oE.getAttForD(d, deepRA.cod, undefined)
      const dataValues = dataAtt && oE.getAttValue(dataAtt)
      if(dataValues && oldUnit && (oldUnit.length || !unitValue) && newUnit && newUnit.length) {
        let newVals
        if (unitValue) {
          const oUPreOffset = oldUnit[0].os1;
          const oUPostOffset = oldUnit[0].os2;
          const oUFactor = oldUnit[0].fac;
          const nUPreOffset = newUnit[0].os1;
          const nUPostOffset = newUnit[0].os2;
          const nUFactor = newUnit[0].fac;
          newVals = dataValues.val.map((oldVal) => ((oldVal-oUPostOffset) / oUFactor - oUPostOffset + nUPreOffset) * nUFactor + nUPostOffset)
        } else {
          newVals = dataValues.val.map((oldVal) => oldVal)
        }
        dataAtt.data.uid = newUnit[0].id
        dataAtt.data.val = newVals
        oDM.updateAtt(dataAtt, dataAtt.dtp, dataAtt.id)
        colCfg.unit = value
      }
      const index = newColumns.findIndex(col => col.accessor === column.id)
      newColumns[index] = {...column}
      setTableColumns(newColumns)
      if (onRefresh) {
        onRefresh()
      }
    }
//    this.saveChange()

  }

  const onCellClick = (cell, e) => {
    e.nativeEvent.stopImmediatePropagation()
    if (activeCol) {
      setActiveCol(null)
      setShowSearch(false)
    }
    setActiveCell({
      rowIndex: cell.row.index,
      colId: cell.column.id
    })
  }

  const onDragSelectStart = (e, cell) => {
    dispatch(setTableSelectMode(true))
    const columnIndex = cell.row.cells.findIndex(item => item.column.id === cell.column.id)
    const initState = {
      initRow: cell.row.index,
      initColIndex: columnIndex,
      lastRow: cell.row.index,
      lastColIndex: columnIndex
    }

    setSelectState(initState)
  }

  const onDragSelectEnter = (e, cell) => {
    if (!state.onSelect) {
      return
    }

    const columnIndex = cell.row.cells.findIndex(item => item.column.id === cell.column.id)
    const newState = {
      lastRow: cell.row.index,
      lastColIndex: columnIndex
    }

    setSelectState({...selectState, ...newState})
  }

  const dragSelectEnd = (e) => {
    e.nativeEvent.stopImmediatePropagation()
    if (state.onSelect) {
      dispatch(setTableSelectMode(false))
    }
  }

  const calcSelectBounds = () => {
    let minRow, maxRow, minCol, maxCol
    if (selectState.initRow > selectState.lastRow) {
      minRow = selectState.lastRow
      maxRow = selectState.initRow
    } else {
      minRow = selectState.initRow
      maxRow = selectState.lastRow
    }

    if (selectState.initColIndex > selectState.lastColIndex) {
      minCol = selectState.lastColIndex
      maxCol = selectState.initColIndex
    } else {
      minCol = selectState.initColIndex
      maxCol = selectState.lastColIndex
    }

    return {minRow, maxRow, minCol, maxCol}
  }

  const onKeyUp = (e) => {
    if (!(e.keyCode === 67 && e.ctrlKey)) {
      return
    }
    if (!selectState) {
      return
    }
    const {minRow, maxRow, minCol, maxCol} = calcSelectBounds()
    const selectRows = data.slice(minRow, maxRow + 1)
    const selectColumnIds = allColumns.slice(minCol, maxCol + 1).map(item => item.id)

    const copiedStr = selectRows.map(row => {
      const columnStr = selectColumnIds.map(colId => row[colId]).join('\t')

      return columnStr
    }).join('\r\n')

    copyTextToClipboard(copiedStr)
  }

  const onSetEditCell = (cell) => {
    if (state.editMode && !cell.column.colCfg.readOnly) {
      dispatch({type: SET_EDITING_CELL, payload: {rowIndex: cell.row.index, colId: cell.column.id}})
    }
  }

  useEffect(() => {
    window.addEventListener('keyup', onKeyUp)

    return () => {
      window.removeEventListener('keyup', onKeyUp)
    }
  }, [state, selectState])

  const isCellActive = useCallback((cell) => {
    if (!selectState || !activeCell) {
      return false
    }

    const {minRow, maxRow, minCol, maxCol} = calcSelectBounds()
    if (cell.row.index >= minRow && cell.row.index <= maxRow) {
      const colIndex = cell.row.cells.findIndex(item => item.column.id === cell.column.id)
      if (minCol <= colIndex && colIndex <= maxCol) {
        return true
      }
    }

    return false

    // return activeCell && activeCell.rowIndex === cell.row.index && cell.column.id === activeCell.colId
  }, [selectState, activeCell])

  return (
    <SpreadsheetContext.Provider value={{state, dispatch}}>
      <div {...getTableProps()} className="spreadsheet table" onContextMenu={openContextMenu}>
      <div>
      {headerGroups.map(headerGroup => (
        <>
          <div {...headerGroup.getHeaderGroupProps()} className="tr">
            {headerGroup.headers.map((column, index) => {
              const nextColumn = allColumns[index + 1]
              let hideTrigger
              if (nextColumn && nextColumn.isVisible === false) {
                hideTrigger = <div
                  onClick={() => showColumn(nextColumn)}
                  onKeyDown={null}
                  className="show-col-trigger"></div>
              }
              return (
                <div
                  className={classnames('th', {'active-col': isActiveColumn(column)})}
                  {...column.getHeaderProps()}
                  onClick={() => activeColumn(column)}
                  onKeyDown={null}
                >
                  <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                    <span style={{whiteSpace: 'nowrap'}}>{column.render('Header')}</span>
                    { isDimensionalCol(column) && (() => {
                        const { colCfg } = column
                        const deepRA = colCfg && !isNaN(colCfg.deepRAId) && oDM.getRAttNoLoadNoExtd(colCfg.deepRAId)
                        const dim = oDM.getDimensionById((deepRA && deepRA.ddim) || 2);
                        const units = dim.units.map(item => ({label: item.cod, value: item.id}))
                        return (
                          <Select value={colCfg.unit} options={units}
                                  onChange={(value) => setColumnUnit(value, column)}
                                  style={{ width: 90 }} bordered={false} className="ml-10 select-no-arrow" />
                        )
                      })()
                    }
                  </div>

                    {isActiveColumn(column) && (<div style={{position: 'absolute', right: '2px', top: '2px'}}>
                      <ColumnSettingDropdown
                        column={column}
                        onSubmitEditor={(key) => setColumnEditor(key, column)}
                        onFilter={() => {setShowSearch(true)}}
                      />
                    </div>)}
                    <div {...column.getResizerProps()} className={classnames('resizer', {isResizing: column.isResizing})}/>
                    {hideTrigger}
                  </div>
                )
              })}
            </div>
            {showSearch && (<div key="searchRow" {...headerGroup.getHeaderGroupProps()} className="tr" key="searchRow">
              {headerGroup.headers.map((column, index) => {
                return (
                  <div className="th search-th" {...column.getHeaderProps()} key={`searchTh${index}`}>
                    {isActiveColumn(column) && <div className="search-input">{column.canFilter ? column.render('Filter') : null}</div>}
                  </div>
                )
              })}
            </div>)}
          </>
        ))}
        </div>
        <div {...getTableBodyProps()} onMouseUp={dragSelectEnd}>
        {rows.map((row, index) => {
          prepareRow(row)
          return (
            <div {...row.getRowProps()} key={index} className="tr">
              {row.cells.map(cell => {
                return (
                  <div
                    {...cell.getCellProps()}
                    onClick={(e) => onCellClick(cell, e)}
                    onDoubleClick={() => onSetEditCell(cell)}
                    onKeyDown={null}
                    className={classnames('td', {'active-col': isActiveColumn(cell.column), 'active-cell': isCellActive(cell)})}
                    onMouseDown={e => onDragSelectStart(e, cell)}
                    onMouseEnter={e => onDragSelectEnter(e, cell)}
                  >
                    {cell.render('Cell')}
                  </div>
                )
              })}
            </div>
          )
        })}
        </div>
        {ctxMenuStyle && <List className="context-menu" bordered size="small" style={ctxMenuStyle}>
          <List.Item>
            <RowSetting value={data.length} onChange={(e) => {
              const value = e.target.value
              if (onSetRows) {
                onSetRows(value)
                setCtxMenuStyle(null)
              }
            }}/>
          </List.Item>
          {activeCell && <>
            <List.Item onClick={() => addRow('above')}>
              Insert a row above.
            </List.Item>
            <List.Item onClick={() => addRow('below')}>
              Insert a row below.
            </List.Item>
            <List.Item onClick={() => addRow('remove')}>
              Remove this row.
            </List.Item>
          </>}

        </List>}
      </div>
    </SpreadsheetContext.Provider>
  )
})
