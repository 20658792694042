import React from 'react'
import ABase from './a-base'
import { treeNodeIcon } from '../../odata-tree/tree-node-icon'
import { ReactComponent as DefaultTreeIcon } from '../../../../assets/icons/tree-default.svg'
import styles from './editors.module.less'
import classNames from 'classnames'
import DropEditor from './drop-editor';
import { ReactComponent as AttributeIcon } from '../../../../assets/icons/attribute.svg';


class RaEditor extends ABase {
  constructor(props) {
    super(props);
  }

  static getDerivedStateFromProps(props, state) {
    if (props.editMode !== state.editMode) {
      return {
        editMode: props.editMode
      };
    }

    return {}
  }

  doCheck(node) {
    const nodeData = node.dataRef
    if (nodeData.nodeType === 'ra') {
      return true
    }

    return false
  }

  onDrop(e) {
    const { dragNode } = e
    const nodeData = dragNode.dataRef
    if (this.doCheck(dragNode)) {
      const value = nodeData && nodeData.odmId;
      if (value) {
        this.onAttChange(value)
      }
    }
  }

  onRemove() {
    this.onAttChange(undefined)
  }

  renderRa() {
    const ra = (this.state.value || this.state.value === 0) && this.odm.getRAttNoLoadNoExtd(this.state.value)
    if (!ra) {
      return ''
    }

    const iconElem = <AttributeIcon style={{verticalAlign: '-.125em', height:'1.1em', width:'1.1em', color: '#7f0000'}}/>;

    return <div className={classNames(styles.editorContent)}>
      {iconElem}{ra.cod}
    </div>

  }


  renderEditor() {
    return <DropEditor
      onDrop={(e) => this.onDrop(e)}
      doCheck={(node) => this.doCheck(node)}
      onRemove={() => this.onRemove()}
      isEmpty={!(this.state.value || this.state.value === 0)}
    >
      {this.renderRa()}
    </DropEditor>
  }

  render() {

    return this.state.editMode ? this.renderEditor() : this.renderRa()
  }
}

export default RaEditor
