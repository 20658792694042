import React from 'react'
import Dialog from '../../shared/dialog/dialog';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as WindowManagementActions from '../../../stores/window-management/actions';
import { TABLE_STYLES_CAN_ADJUST_LIST } from '../../shared/view-form/view-form';
import TableWithTabsDesign from "./tabs-design/tabs-design";
import ViewFormInlineDesign from './inline-design/inline-design';
import { getDialogTitle } from '../utils';
import { svgStrToImg } from '../../../utils/utils';

class ViewFormTableDesignDialog extends React.Component {
  constructor(props) {
    super(props);

    this.designRef = React.createRef()
    const view = props.view
    const dialogTitle = getDialogTitle(
      svgStrToImg(view.icon),
      `Design ${view.title}`
    )
    this.props.actions.setWindowTitle(this.props.windowId, dialogTitle);
  }

  handleMinimize () {
    this.props.actions.setWindowVisible(this.props.windowId, false);
  }

  handleSetTitle (newTitle) {
    this.props.actions.setWindowTitle(this.props.windowId, newTitle);
  }

  handleSubmit () {
    const view = this.designRef.current.getFormData()
    // form data with tabs is a array
    // inline form is a object
    this.props.updateViewForm(
      view
    )
    this.handleClose()
  }

  handleClose() {
    this.props.actions.removeWindow(this.props.windowId);
  }

  renderFormWithTabs() {
    const tableView = this.props.view.data
    return (
      <TableWithTabsDesign view={tableView} ref={this.designRef}></TableWithTabsDesign>
    )
  }

  render() {
    const tableView = this.props.view.data
    console.log(tableView)
    return <Dialog
      title={ this.props.title }
      submitButton={ true }
      cancelButton={ true }
      onClose={ this.handleClose.bind(this) }
      onCancel={ this.handleClose.bind(this) }
      onSubmit={ this.handleSubmit.bind(this) }
      windowId={ this.props.windowId }
    >
      {Array.isArray(tableView) ? this.renderFormWithTabs() : <ViewFormInlineDesign view={tableView} ref={this.designRef} />}
    </Dialog>
  }
}

const mapStateToProps = state => ({
});

const mapDispatchToProps = dispatch => ({
  actions: Object.assign(
    {},
    bindActionCreators(WindowManagementActions, dispatch)
  )
});


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ViewFormTableDesignDialog)
