import React from 'react'
import Dialog from '../../shared/dialog/dialog';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as WindowManagementActions from '../../../stores/window-management/actions';
import InlineDragDesign from './inline-drag-design/inline-drag-design';
import TabDragDesign from './tab-drag-design/tab-drag-design';
import { getDialogTitle } from '../utils';
import { svgStrToImg } from '../../../utils/utils';
import OE from 'oe'


class ViewFormDesignDialog extends React.Component {
  constructor(props) {
    super(props);
    this.designRef = React.createRef()
    const rdTitle = OE.getDescription(this.props.rDataset)
    const dialogTitle = getDialogTitle(
      svgStrToImg(OE.getSVGIconCodeForRD(this.props.rDataset, false)),
      `Design ${rdTitle}`
    )

    this.props.actions.setWindowTitle(this.props.windowId, dialogTitle);
  }

  handleClose () {
    this.props.actions.removeWindow(this.props.windowId)
  }

  handleSubmit () {
    const formData = this.designRef.current.getFormData()
    // form data with tabs is a array
    // inline form is a object
    this.props.updateTableDesign(
      formData
    )
    this.handleClose()
  }

  renderFormWithTabs() {
    return (
      <TabDragDesign {...this.props} view={this.props.view.data} ref={this.designRef}></TabDragDesign>
    )
  }

  render() {
    const tableView = this.props.view.data
    return <Dialog
      title={ this.props.title }
      visible={ this.props.visible }
      submitButton={ true }
      cancelButton={ true }
      onClose={ this.handleClose.bind(this) }
      onCancel={ this.handleClose.bind(this) }
      onSubmit={ this.handleSubmit.bind(this) }
      windowId={ this.props.windowId }
    >
      {Array.isArray(tableView) ?
        this.renderFormWithTabs() : <InlineDragDesign {...this.props} view={tableView} ref={this.designRef}/>}
    </Dialog>
  }
}

const mapStateToProps = state => ({
});

const mapDispatchToProps = dispatch => ({
  actions: Object.assign(
    {},
    bindActionCreators(WindowManagementActions, dispatch)
  )
});


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ViewFormDesignDialog)
