import React, { useState, memo, useEffect, useContext } from 'react';
import AInput from '../a-input';
import ACheckboxGroup from '../a-checkbox-group';
import ASelect from '../a-select';
import ADimvarInput from '../a-dimvar-input';
import DEditor from '../d-editor';
import RdEditor from '../rd-editor';
import AttrEditor from '../attr-editor';
import RaEditor from '../ra-editor';
import AAntdFileDnd from '../a-antd-filednd';
import ODM from 'odm'
import { ColumnEditorsMap } from './index';
import { DefaultColumnEditors } from './index';
import { SET_EDITING_CELL, SpreadsheetContext } from './table-spreadsheet';
import { TableCheckboxGroup } from './editors/checkbox-group';
import { TableInput } from './editors/input';

export const ColumnEditor = memo((cellData) => {
  const initialValue = cellData.value
  const {row, onDataChange, column} = cellData
  // We need to keep and update the state of the cell normally
  const [value, setValue] = useState(initialValue)
  const { state, dispatch } = useContext(SpreadsheetContext)

  const onChange = e => {
    setValue(e.target.value)
  }

  // We'll only update the external data when the input is blurred
  const onBlur = (e) => {
    if (initialValue !== value) {
      onDataChange(row.index, column.id, value)
    }
  }

  const onEditorValueChange = (newVal) => {
    onDataChange(row.index, column.id, newVal)
  }

  const onEditorPaste = (cells) => {
//    const columnIndex = row.cells.findIndex(item => item.column.id === column.id)
    const rowIndex = row.index
    onDataChange(rowIndex, column.id, cells)
  }

  // If the initialValue is changed external, sync it up with our state
  React.useEffect(() => {
    setValue(initialValue)
  }, [initialValue])
  const colConfig = column.colCfg

  const isEditingCell = () => {
    return state.editCell && state.editCell.rowIndex === cellData.row.index && state.editCell.colId === cellData.column.id
  }

  // view mode
  if (!state.editMode || colConfig.readOnly || !isEditingCell()) {
    return <span>{value}</span>
  }

  let editorElem = <TableInput initialValue={value} onChange={onEditorValueChange} editMode={state.editMode} onEditorPaste={onEditorPaste}/>
  // deepRAId: 29815
  // headerTxt: "Maximum"
  // rAId: 29815

  // Lyon: see below code to retrive the editor type, via a configuration dataset.  The reason I would like it this way, is that you can set attributes for each dataset that are specific to the type of editor (such as for example acceptable text patterns for text elements, or acceptable values for select fields, etc.). Having a configuration-dataset instance for each column will allow users to customize the behavior of the editor to their needs.
  const rA = (column.colCfg.deepRAId || column.colCfg.deepRAId === 0) && ODM.getRAttNoLoadNoExtd(column.colCfg.deepRAId)
  const rAType = rA && rA.dtp.toLowerCase().endsWith("_ar") && rA.dtp.substring(0, rA.dtp.length - 3)
  if (rAType) {
    const editorCfgD = (column.colCfg.edtCfgDId || column.colCfg.edtCfgDId) && ODM.getDNoLoadNoExtd(column.colCfg.edtCfgDId)
    const editorCfgRD = editorCfgD && ODM.getRDNoLoadNoExtd(editorCfgD.rid)
    const edtRDCode = (editorCfgRD && editorCfgRD.cod && editorCfgRD.cod.startsWith("SYS.AECFG.SPDSHT.COL.EDTCFG.") && editorCfgRD.cod.substr(28))
    const filteredEditorMapEntry = rAType && ColumnEditorsMap[rAType] && ColumnEditorsMap[rAType].filter(entry => entry.rDCode === edtRDCode)
    if (edtRDCode && (!filteredEditorMapEntry || filteredEditorMapEntry.length !== 1)) {
      console.log("Improper editor \"" + edtRDCode + "\" defined for attribute of type \"" + rAType + "\"")
    }
    const columnEditorKey = (filteredEditorMapEntry && filteredEditorMapEntry.length === 1 && filteredEditorMapEntry[0].key)  || DefaultColumnEditors[rAType]
    switch (columnEditorKey) {
      // Lyon: you will need to update all the inputs in order to be able to support view-form-cell inputs as well as spreadsheet-table inputs, so that you can uncomment the code below.
      case 'TEXT_INPUT': {
        editorElem = <TableInput initialValue={value} onChange={onEditorValueChange} editMode={state.editMode} onEditorPaste={onEditorPaste}/>
        break
      }

      case 'CHECKBOX': {

        editorElem = <TableCheckboxGroup values={value} options={[]} onChange={onEditorValueChange} editMode={state.editMode}/>
        break
      }
      /*

case 'SELECT': {
  editorElem = <ASelect editMode={false}/>
  break
}
case 'DIMENSIONAL_EDITOR': {
  editorElem = <ADimvarInput editMode={false}/>
  break
}
case 'TIMESTAMP_INPUT': {
  // editorElem = ... need to be added later
  break
}
case 'DATASET_EDITOR': {
  editorElem = <DEditor editMode={false}/>
  break
}
case 'ROOTDATASET_EDITOR': {
  editorElem = <RdEditor editMode={false}/>
  break
}
case 'ATTRIBUTE_EDITOR': {
  editorElem = <AttrEditor editMode={false}/>
  break
}
case 'ROOTATTRIBUTE_EDITOR': {
  editorElem = <RaEditor editMode={false}/>
  break
}
case 'FILE_EDITOR': {
  editorElem = <AAntdFileDnd editMode={false}/>
  break
}
 */
      default: {
      }
    }
  }

  return editorElem
})
