import React from 'react';
import { cloneDeep } from 'lodash';
import styles from './tabs-design.module.less';
import {Input, Tabs, Modal, Form, Button, Dropdown, Menu} from "antd";
import ViewFormInlineDesign from '../inline-design/inline-design';
import DraggableTabs from '../../../shared/dragable-tabs/dragable-tabs';
import { bindActionCreators } from 'redux';
import * as WindowManagementActions from '../../../../stores/window-management/actions';
import { connect } from 'react-redux';
import { getItems, getNewTable } from '../../../shared/view-form/view-form';
import { DownOutlined } from '@ant-design/icons';
const TabPane = Tabs.TabPane

class TableWithTabsDesign extends React.PureComponent {

  constructor(props) {
    super(props);

    const tabs = props.view

    this.state = {
      tabs: tabs,
      activeTabIndex: '0',
      isAddModalShow: false,
    };

    this.initTabValue = { cod: '', dsc: ''}
    this.mode = 'create'
    this.inlineDesignRef = React.createRef()
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { view, tableProperties } = nextProps
    const changes = { tableProperties }
    if (view !== prevState.sourceView) {
      // formData changed
      changes.formData = cloneDeep(view)
      changes.sourceView = view
    }

    return changes
  }

  componentDidMount() {
    this.tabChange('0')
  }

  onInlineFormChange = (view) => {
    const index = parseInt(this.state.activeTabIndex, 10)
    const newTab = {...this.state.tabs[index], ...view}
    this.state.tabs[index] = newTab
    this.setState({
      view: view
    })
  }

  /**
   * when table design dialog submit, dialog will get the submit value from this function.
   * there are muti-forms, so will return a array of formData
   * @returns formData[]
   */
  getFormData() {
    return this.state.tabs
  }

  perSetState(newState) {
    this.setState(newState)
  }

  tabChange(index) {
    index = parseInt(index, 10) // index is string, need a number
    const activeTab = this.state.tabs[index]
    this.setState({
      view: activeTab,
      activeTabIndex: index,
    })
  }

  onDeleteConfirm = (index) => {
    const tabs = this.state.tabs
    tabs.splice(index, 1)
    const newTabs = [...this.state.tabs]
    this.setState({
      tabs: newTabs
    })
  }

  onEdit(targetKey, action) {
    const index = parseInt(targetKey, 10)
    if (action === 'remove') {
      const title = 'Delete Confirm'
      const tabs = this.state.tabs

      const content = `Will you delete tab ${tabs[index].name}?`
      this.props.actions.addWindow({
        type: 'DeleteDialog',
        props: {
          title: title,
          content: content,
          visible: true,
          onSubmit: () => this.onDeleteConfirm(index)
        }
      });
    } else if (action === 'add') {
      this.initTabValue = { cod: '', dsc: ''}
      this.mode = 'create'
      this.setState({
        isAddModalShow: true
      })
    }
  }

  save(value) {
    if (this.mode === 'edit') {
      const { tabs, activeTabIndex } = this.state
      const activeTab = tabs[activeTabIndex]
      const {dsc, cod} = value
      tabs[activeTabIndex] = {...activeTab, name: value.dsc, dsc, code: cod}
      this.setState({
        tabs: [...tabs],
        isAddModalShow: false
      })
      return
    }
    // do create
    this.setState({
      tabs: [
        ...this.state.tabs,
        {
          ...value,
          name: value.dsc,
          code: value.cod,
          newCreate: true,
          ...getNewTable(4, 2)
        }
      ],
      isAddModalShow: false
    })
  }

  olderChange(older) {
    const newTabs = []
    const oldTabs = this.state.tabs
    const olders = older.map(item => parseInt(item, 10))
    olders.forEach((item, index) => {
      newTabs[index] = oldTabs[item]
    })

    this.setState({
      tabs: newTabs
    }, () => {
      this.tabChange('0')
    })
  }

  onModalClose() {
    this.setState({
      isAddModalShow: false
    })
  }

  renderFormSetting(view) {
    if (!view.rows) {
      return <h1>new tab</h1>
    }

    return (
      <ViewFormInlineDesign view={view} ref={this.inlineDesignRef} formDataChange={this.onInlineFormChange}></ViewFormInlineDesign>
    )
  }

  showEdit(tab) {
    this.initTabValue ={cod: tab.code, dsc: tab.name}
    this.mode = 'edit'
    this.setState({
      isAddModalShow: true
    })
  }

  render() {
    const tabs = this.state.tabs.map((item, index) => {
      // const tab = (<div onDoubleClick={() => this.showEdit(item)}>{item.name}</div>)
      const menu = <Menu>
        <Menu.Item>
          <div onClick={() => this.showEdit(item)}>Rename tab</div>
        </Menu.Item>
      </Menu>
      const tab = <Dropdown.Button overlay={menu} icon={<DownOutlined />}>
        {item.name}
      </Dropdown.Button>

      return (
        <TabPane tab={tab} key={index}>
          {index === this.state.activeTabIndex ? this.renderFormSetting(item) : ''}
        </TabPane>
      )
    })
    let modalTitle = 'Add tab'
    let submitBtnText = 'Create'
    if (this.mode === 'edit') {
      modalTitle = 'Rename tab'
      submitBtnText = 'Confirm'
    }

    return (
      <React.Fragment>
        <DraggableTabs
          defaultActiveKey="0"
          type="editable-card"
          onEdit={this.onEdit.bind(this)}
          onChange={this.tabChange.bind(this)}
          orderChange={order => this.olderChange(order)}
        >
          {tabs}
        </DraggableTabs>
        <Modal title={modalTitle}
               zIndex={9999999}
               destroyOnClose={true}
               maskClosable={false}
               visible={this.state.isAddModalShow}
               onCancel={() => this.onModalClose()}
               footer={null}>
          <Form
            layout={'vertical'}
            initialValues={this.initTabValue}
            onFinish={(value) => this.save(value)}
          >
            <Form.Item
              label="Code"
              name="cod"
              rules={[
                { required: true, message: 'Please input the code!' },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Description"
              name="dsc"
              rules={[
                { required: true, message: 'Please input the description!' },
              ]}
            >
              <Input />
            </Form.Item>
            <div className="btn-container">
              <Button type="default" htmlType="button" onClick={() => this.onModalClose()}>
                Cancel
              </Button>
              <Button type="primary" htmlType="submit" className="ml-20">
                {submitBtnText}
              </Button>
            </div>
          </Form>
        </Modal>
      </React.Fragment>

    )
  }
}

const mapDispatchToProps = dispatch => ({
  actions: Object.assign(
    {},
    bindActionCreators(WindowManagementActions, dispatch)
  )
});


export default connect(
  null,
  mapDispatchToProps,
  null,
  {forwardRef: true}
)(TableWithTabsDesign)

