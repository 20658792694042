import {
  TOGGLE_ROOT_ONLINE,
  SET_SIDEBAR_WIDTH,
} from '../constants/root-states-types'

const initialState = {
  online: false,
  sidebarWidth: 250,
}

export default function rootStates (state = initialState, action) {
  switch (action.type) {
    case TOGGLE_ROOT_ONLINE:
      state.online = !state.online
      return Object.assign(
        {},
        state
      )
    
    case SET_SIDEBAR_WIDTH:
      console.log(action.newVal);
      state.sidebarWidth = action.newVal
      return Object.assign(
        {},
        state
      )

    default:
      return state
  }
}
