import React from 'react'
import {Dropdown, Menu} from "antd";
import ODM from 'odm'
import OE from 'oe'
import {bindActionCreators} from "redux";
import * as WindowManagementActions from "../../../stores/window-management/actions";
import {connect} from "react-redux";
import * as OdataTreeActions from '../../../stores/odata-tree/odata-tree-actions';

class RvwsMenu extends React.Component {
  constructor(props) {
    super(props);
  }

  onClickMenuItem(treeNodeData, type, e) {
    if (type === 'new') {
      const odmObject = ODM.getRDNoLoadNoExtd(treeNodeData.odmId);
      this.props.actions.addWindow({
        type: 'NewViewDialog',
        props: {
          title: 'ViewForm',
          visible: true,
          odmObject: odmObject,
          newType: 'vf',
          onSubmit: this.onNew,
        }
      });
    }

    if (type === 'refresh') {
      this.props.actions.updateODataTreeAction()
    }
  }

  onNew = (odmObject, value) => {

    if(value.code && value.description && value.formType) {
      const rootDataset = ODM.getRDNoLoad(odmObject.id);
      OE.createEmptyRDV(value.code, value.description, (value.formType === "INLINE"), rootDataset, undefined);
      this.props.actions.updateODataTreeAction()
    }
  }

  render() {
    const { treeData, onVisibleChange } = this.props
    const rvwsMenu = (
      <Menu forceSubMenuRender={false}>
        <Menu.Item onClick ={(e) => {e.domEvent.nativeEvent.noTreeSelect = true; this.onClickMenuItem(treeData,'refresh', e);}} >
            Refresh
        </Menu.Item>
        <Menu.Item onClick ={(e) => {e.domEvent.nativeEvent.noTreeSelect = true; this.onClickMenuItem(treeData,'new', e);}} >
            New
        </Menu.Item>
      </Menu>
    );
    return (
      <Dropdown overlay={rvwsMenu} onVisibleChange={onVisibleChange} >
      <a className="ant-dropdown-link">
        {treeData.title}
      </a>
      </Dropdown>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  actions: Object.assign(
    {},
    bindActionCreators(WindowManagementActions, dispatch),
    bindActionCreators(OdataTreeActions, dispatch),
  )
});

export default connect(
  null,
  mapDispatchToProps
)(RvwsMenu)
