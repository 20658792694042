import React from 'react'
import styles from './input.module.less'
import { Input } from 'antd'

class InputCon extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      value: ''
    }
  }

  handleValueChange (e) {
    this.setState({ value: e.target.value }, () => {
      this.props.handleChange(this.state.value)
    })
  }

  componentDidMount () {
    this.setState({ value: this.props.value })
  }

  render () {
    return (
      <Input
        className={ styles.Input }
        value={this.state.value}
        allowClear
        onChange={ e => this.handleValueChange.bind(this)(e) }
      />
    )
  }
}

export default InputCon
