import React from 'react'
import styles from './view-form.module.less'
import ViewFormCell from './view-form-cell.js'
import { ReactComponent as VersionIcon } from '../../../assets/icons/version.svg'
import { ReactComponent as TableDesignIcon } from '../../../assets/icons/table-setting.svg'
import { ReactComponent as DrawIcon } from '../../../assets/icons/design.svg'
import { ReactComponent as TableEditIcon } from '../../../assets/icons/table-edit.svg'
import { ReactComponent as CloseSquareOutinedIcon } from '../../../assets/icons/close-square-outline.svg'
import { ReactComponent as CheckSquareOutinedIcon } from '../../../assets/icons/check-square-outline.svg'
import { ReactComponent as DeleteIcon } from '../../../assets/icons/delete.svg'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { cloneDeep } from 'lodash'
import * as WindowManagementActions from '../../../stores/window-management/actions'
import OE from 'oe'
import ODM from 'odm'
import { EditOutlined, SettingFilled, SyncOutlined } from '@ant-design/icons';
import { Button, Dropdown, Menu, Popconfirm, Select, Tabs, message } from 'antd';
import { format } from 'date-fns'
import CreateChild from './create-child/create-child';
import classNames from 'classnames';
import {
  CELL_ATTRIBUTES_CAN_ADJUST_LIST,
  CELL_STYLES_CAN_ADJUST_LIST, COLUMN_STYLES_CAN_ADJUST_LIST, DEFAULT_COLUMN_WIDTH, ROW_STYLES_CAN_ADJUST_LIST,
  TABLE_STYLES_CAN_ADJUST_LIST,
} from '../../../core/constant';
import {
  genHash,
  getCellColspan, getCellRowspan,
  getCellStyles,
  getColumnStyles,
  getRowStyles,
  getTableStyles,
} from '../../window-management/utils';
import {svgStrToImg} from "../../../utils/utils";
import ReactToPrint from 'react-to-print';
import { guiDataToViewForm, viewFormsDataToGuiData } from './utils';
import ODrag from '../o-drag/o-drag'
const { TabPane } = Tabs;

export const getNewTable = (rowCount, colCount) => {
  return {
    styles: {width: '100%'},
    columns: new Array(colCount).fill({}),
    rows: new Array(rowCount).fill({
      styles: {},
      cells: new Array(colCount).fill({
        styles: {}
      })
    })
  }
}
// fake data generator
export const getItems = (rowCount, colCount) => {
  return {
    id: `table-${genHash()}`,
    properties: TABLE_STYLES_CAN_ADJUST_LIST
      .reduce((obj, key) => {
        obj[key] = ''
        return obj
      }, {}),
    content: Array.from({ length: rowCount}, (v1, k1) => k1).map(k1 => ({
      id: `row-${genHash()}`,
      content: Array.from({ length: colCount}, (v, k) => k).map(k => ({
        id: `cell-${k1}-${genHash()}`,
        content: `cell ${k1}-${k}`,
        cellCfg: {},
        properties: CELL_STYLES_CAN_ADJUST_LIST
          .concat(CELL_ATTRIBUTES_CAN_ADJUST_LIST)
          .reduce((obj, key) => {
            obj[key] = ''
            return obj
          }, {})
      })),
      properties: ROW_STYLES_CAN_ADJUST_LIST.reduce((obj, key) => {
        obj[key] = ''
        return obj
      }, {})
    })),
    columns: Array.from({ length: colCount }, (v, k) => k).map(k => ({
      id: `column-${genHash()}`,
      content: '',
      properties: COLUMN_STYLES_CAN_ADJUST_LIST.reduce((obj, key) => {
        obj[key] = ''
        return obj
      }, {})
    }))
  }
}

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};




class ViewForm extends React.Component {

  constructor(props) {
    super(props);
    this.odm = props.odm || ODM
    this.oe = props.oe || OE
    this.state = {
      rows: 4,
      cols: 6,
      editedTableFormData: {
        id: '',
        properties: {},
        content: [],
        columns: []
      },
      tableFormData: {
        id: '',
        properties: {},
        content: [],
        columns: []
      },
      versionThreadLoading: false,
      versionData: null,
      viewsMenuVisible: false,
      versionMenuShow: false,
      tableEditingConfirm: false,
      activeTabIndex: 0,
      noSetting: this.props.noSetting || false,
      editMode: props.editMode || false,
      guiData: null,
      viewFormCode: props.vFCode || 'DEFAULT',
      childEditMode: props.childEditMode || {}
    };
    this.onDragEnd = this.onDragEnd.bind(this);
    if (this.props.vFObject && this.props.vFObject.typ) {
      let vFObject = this.props.vFObject;
      this.vFObjTyp = vFObject.typ.toLowerCase()
      switch (this.vFObjTyp) {
        case 'd2d':
          this.d2dRel = vFObject;
          this.dataset = this.odm.getDNoLoad(vFObject.tid);
          this.rd2rdRel = (this.d2dRel.rid || this.d2dRel.rid === 0) && this.odm.getRD2RDRelNoLoad(this.d2dRel.rid)
          this.rDataset = this.odm.getRDNoLoad(this.dataset.rid);
          break
        case 'rd':
          this.rDataset = vFObject;
          break;
        case 'rd2rd':
          this.rd2rdRel = vFObject;
          this.rDataset = this.odm.getRDNoLoad(this.rd2rdRel.tid);
          break;
        case 'd':
          this.dataset = vFObject;
          this.rDataset = this.odm.getRDNoLoad(this.dataset.rid);
          this.state.noSetting = vFObject.srl.length > 0
          break;
      }
      this.rDDefaultD = this.rDataset && this.oe.getDefaultD(this.rDataset)
      this.rd2rdRelDefaultD = this.rd2rdRel && (this.rd2rdRel.rtp && this.rd2rdRel.rtp.toLowerCase() === 'rdsub') && this.oe.getDefaultD(this.rd2rdRel)
    }
    const guiData = this.getGuiData(this.state.viewFormCode)
    if (guiData) {
      this.state.guiData = guiData
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (props.editMode !== undefined && props.editMode !== state.editMode) {
      return {
        editMode: props.editMode
      };
    }

    if (props.childEditMode !== undefined && props.childEditMode !== state.childEditMode) {
      return {
        childEditMode: props.childEditMode
      };
    }

    return {}
  }

  componentDidMount() {
    this.initView()
  }

  initView() {
    if(this.rDataset) {
      const versionData = ODM.rDVTs[this.rDataset.vtid]
      if (versionData) {
        this.setState({
          versionData
        })
      }
      if (this.state.guiData) {
        if (this.state.guiData.inline) {
          this.initInlineTableFormData()
        } else {
          this.initFormDataWithTab()
        }
      }
    } else {
      this.initInlineTableFormData()
    }
  }

  getFormDataFromGuiData(index) {
    if (!this.state.guiData) {
      return getItems(4, 6)
    }
    const guiData = this.state.guiData
    const baseCellConfig = {
      rDataset: this.rDataset,
      dataset: this.dataset,
      rd2rdRel: this.rd2rdRel,
      d2dRel: this.d2dRel,
    }

    return guiDataToViewForm(guiData, index, baseCellConfig)
  }


  /**
   * Set up data copy, it is used for editing
   */
  initInlineTableFormData() {
    const data = this.getFormDataFromGuiData(0)
    this.setState({
      editedTableFormData: cloneDeep(data),
      tableFormData: cloneDeep(data)
    })
  }

  initFormDataWithTab() {
    this.tabChange(0)
  }

  getGuiData(vfCode) {
    if (!this.rDataset) {
      return
    }

    return this.oe.getGUI(this.rDataset, (vfCode || 'DEFAULT')) || this.oe.getGUI(this.rDataset, 'DEFAULT_INLINE')
  }

  refresh() {
    this.initView()
    this.forceUpdate()
  }

  onDragEnd(result) {
    const { source, destination } = result;

    // dropped outside the list
    if (!destination) {
        return;
    }

    switch(result.type) {
      case "row":
        const [removedRow] = this.state.editedTableFormData.content.splice(source.index, 1);
        this.state.editedTableFormData.content.splice(destination.index, 0, removedRow);
        break;
      case "cell":
        let sourceRow = this.state.editedTableFormData.content.filter(item => item.id ===  source.droppableId)[0];
        let destinationRow = this.state.editedTableFormData.content.filter(item => item.id ===  destination.droppableId)[0];

        const [removedCell] = sourceRow.content.splice(source.index, 1);
        destinationRow.content.splice(destination.index, 0, removedCell);
        break;
      default:
    }

    this.setState({
      editedTableFormData: this.state.editedTableFormData
    });
  }

  submitChange() {
    this.setState({
      tableFormData: cloneDeep(this.state.editedTableFormData),
      tableEditingConfirm: false,
    }, () => {
      // Store this.state.tableFormData via ODM
      let rDataset = this.odm.getRDNoLoad(this.rDataset.id);
      let guiObject = rDataset.gui[this.state.viewFormCode || 'DEFAULT'];
      if (!guiObject) {
        return message.warn(`Root Dataset ${rDataset.cod} don't have View, Please create a view first.`)
      }
      guiObject.data = cloneDeep(this.state.guiData.data);
      this.odm.updateRD(rDataset, rDataset.id);

      if (typeof this.props.onAfterSubmit === 'function') {
        this.props.onAfterSubmit(this.state.tableFormData)
      }
    })
  }

  dismissChange() {
    this.setState({
      editedTableFormData: cloneDeep(this.state.tableFormData),
      tableEditingConfirm: false,
    })
  }


  updateViewForm = (newVal) => {
    const newGuiData = viewFormsDataToGuiData(newVal, this.state.guiData)

    if (this.state.guiData.inline) {
      this.setState({
        tableEditingConfirm: true,
        guiData: {...this.state.guiData, data: newGuiData}
      }, () => {
        this.tabChange(0)
      })
    } else {
      this.setState({
        tableEditingConfirm: true,
        guiData: {...this.state.guiData, data: newGuiData}
      }, () => {
        this.tabChange(this.state.activeTabIndex)
      })
    }
  }

  updateTableDesign = (designData) => {

    this.updateViewForm(designData)
  }

  openDragDesignDialog = () => {
    let tabs = null
    if (!this.state.guiData) {
      return message.warning('create a View first')
    }
    if (!this.state.guiData.inline) {
      // view form with tabs
      tabs = [...this.state.guiData.data.descs]
      tabs = tabs.map((item, index) => {
        return {
          name: item,
          dsc: item,
          cod: this.state.guiData.data.codes[index],
          formData: this.getFormDataFromGuiData(index)
        }
      })
    }
    let vFDesignProps = {
      title: 'Temporary Dialog Title',
      visible: true,
      tabs: tabs,
      updateTableDesign: this.updateTableDesign.bind(this),
      formData: this.state.editedTableFormData,
      ownerRDId: this.rDataset.id,
      rDataset: this.rDataset,
      vfCode: this.state.viewFormCode || 'DEFAULT',
    }
    if (this.rd2rdRel) {
      vFDesignProps.rd2rdRel = this.rd2rdRel
      vFDesignProps.owherRd2rdRelId = this.rd2rdRel.id
    }
    this.props.actions.addWindow({
      type: 'ViewFormDesign',
      props: vFDesignProps
    });
  }


  openTableDesignDialog = () => {
    let tabs = null
    if (!this.state.guiData) {
      return message.warning('create a View first')
    }
    if (!this.state.guiData.inline) {
      // view form with tabs
      tabs = [...this.state.guiData.data.descs]
      tabs = tabs.map((item, index) => {
        const { raid, gcod, cfgdid, rd2rdrelid} = this.state.guiData.data
        const activeRaid = raid[index]
        const activeGcod = gcod[index]
        const activeCfgdid = cfgdid[index]
        const activeRd2rdrelid = rd2rdrelid[index]
        const props = {
          gcod: activeGcod,
          cfgdid: activeCfgdid,
          rd2rdrelid: activeRd2rdrelid,
          raid: activeRaid
        }
        return {
          name: item,
          dsc: item,
          cod: this.state.guiData.data.codes[index],
          props,
          formData: this.getFormDataFromGuiData(index)
        }
      })
      if (tabs.length === 0) {
        tabs.push({
          name: 'TBD',
          dsc: 'TBD',
          cod: 'TBD',
          formData: this.getFormDataFromGuiData(0)
        })
      }
    }
    this.props.actions.addWindow({
      type: 'ViewFormTableDesign',
      props: {
        title: 'Temporary Dialog Title',
        visible: true,
        tabs: tabs,
        ownerRDId: this.rDataset.id,
        rDataset: this.rDataset,
        columns: this.state.editedTableFormData.columns,
        formData: this.state.editedTableFormData,
        tableProperties: this.state.editedTableFormData.properties,
        updateViewForm: this.updateViewForm.bind(this),
      }
    })
  }

  loadVersionThread = () => {
    let versionThread = ODM.rDVTs[this.rDataset.vtid];
    if(!versionThread) {
      this.setState({
        versionThreadLoading: true
      })
      this.getVersionThreadIn(this.rDataset.vtid).then(() => {
        this.setState({
          versionThreadLoading: false,
          versionData: ODM.rDVTs[this.rDataset.vtid],
          versionMenuShow: true,
        })
      })
    } else {
      if (this.state.versionData !== versionThread) {
        this.setState({
          versionData: versionThread
        })
      }
    }
  }

  getVersionThreadIn(vtid) {
    return new Promise((resolve, reject) => {
//      const interval = setInterval(() => {
        ODM.callCallbackForVersionThreadId(vtid, () => {
          if (ODM.rDVTs[vtid]) {
//            clearInterval(interval)
            resolve()
          }
        });
//      }, 400)
    })
  }

  onVersionItemClick(versionObj) {
    console.log(versionObj)
  }

  onVisibleChange(isShow) {
    this.setState({
      versionMenuShow: isShow
    })
  }

  onViewsMenuVisibleChange(isShow) {
    this.setState({
      viewsMenuVisible: isShow
    })
  }

  changeView(vfCode) {
    if (vfCode === this.state.viewFormCode) {
      return
    }
    const guiData = this.getGuiData(vfCode)
    const baseCellConfig = {
      rDataset: this.rDataset,
      dataset: this.dataset,
      rd2rdRel: this.rd2rdRel,
      d2dRel: this.d2dRel,
    }

    const data =  guiDataToViewForm(guiData, 0, baseCellConfig)
    this.setState({
      viewFormCode: vfCode,
      guiData,
      editedTableFormData: cloneDeep(data),
      tableFormData: cloneDeep(data),
    }, () => {
      this.initView()
    })
  }

  renderViews() {
    const curVFs = this.oe.getVFs(this.rDataset);
    const menuItems = Object.keys(curVFs).map((vfCode, index) => {
      const vForm = this.rDataset.gui[vfCode]
      return (
        <Menu.Item key={index} onClick={() => this.changeView(vfCode)}
                   className={classNames({'active-item': this.state.viewFormCode === vfCode})}
        >{vfCode}</Menu.Item>
      )
    })
    const menu = (
      <Menu>
        {menuItems}
      </Menu>
    );


    return (
      <Dropdown overlay={menu}
                visible={this.state.viewsMenuVisible}
                onVisibleChange={(isShow) => this.onViewsMenuVisibleChange(isShow)}
                placement="bottomCenter" arrow overlayClassName="version-thread-menu">
        <div className={styles.viewFormViews}>
          <i className="iconfont icon-view"></i>
        </div>

      </Dropdown>
    )

  }

  renderVersionThread() {
    const versionData = this.state.versionData;
    const objVersion = (this.rDataset && this.rDataset.ver) || "-";
    if (versionData) {
      const versionDataSorted = [...this.state.versionData].sort((prev, current) => current.ver - prev.ver);
      const menu = (
        <Menu>
          {versionDataSorted.map((item, index) => {
            const date = new Date();
            const dateFormat = format(date, 'MM/dd HH:mm')
            return (
              <Menu.Item key={index} onClick={() => this.onVersionItemClick(item)}>
                {`${dateFormat} ${item.ver}`}
              </Menu.Item>
            )
          })}
        </Menu>
      );

      return (
        <Dropdown overlay={menu}
                  visible={this.state.versionMenuShow}
                  onVisibleChange={(isShow) => this.onVisibleChange(isShow)}
                  placement="bottomCenter" arrow overlayClassName="version-thread-menu">
          <div className={styles.TopRowVersion}>
            <span>
              <VersionIcon/>
              <div>{objVersion}</div>
            </span>
          </div>

        </Dropdown>
      )
    } else {
      let objVersion = (this.rDataset && this.rDataset.ver) || "-";
      const versionIcons = this.state.versionThreadLoading ? <SyncOutlined spin /> : <VersionIcon/>
      return (
        <div className={styles.TopRowVersion} onClick={() => this.loadVersionThread()} onKeyDown={() => this.loadVersionThread()}>
          <span>
            {versionIcons}
            <div>{objVersion}</div>
          </span>
        </div>
      )
    }
  }

  tabChange = (index) => {
    const formData = this.getFormDataFromGuiData(index)
    this.setState({
      editedTableFormData: cloneDeep(formData),
      tableFormData: cloneDeep(formData),
      activeTabIndex: +index, // index is string, need a number
    })
  }

  renderFormWithTabs() {
    const tabs = this.state.guiData.data.descs.map((item, index) => {
      return (
        <TabPane tab={item} key={index}>
          {index === this.state.activeTabIndex ? this.renderInlineForm() : ''}
        </TabPane>
      )
    })

    return (
      <Tabs defaultActiveKey="0" onChange={this.tabChange}>
        {tabs}
      </Tabs>
    )
  }

  onInnerViewFormSetting() {
    console.log('inner view form setting')
  }

  onDeleteChild(child) {
    if (child && child.typ === "d2d") {
      const tD = this.odm.getDNoLoadNoExtd(child.tid)
      this.odm.deleteD2DRel(child, child.id)
      this.forceUpdate()
    }
  }

  toggleEditMode = () => {
    this.setState({
      editMode: !this.state.editMode
    })
  }

  getChildViewEditModeKey(vFObject, vFCode) {
    return `${vFObject.id}_${vFCode}`
  }

  toggleChildEditMode = (vFObject, vFCode) => {
    const key = this.getChildViewEditModeKey(vFObject, vFCode)
    const newChildEditModeMap = {...this.state.childEditMode}
    newChildEditModeMap[key] = !newChildEditModeMap[key]
    this.setState({
      childEditMode: newChildEditModeMap
    })
  }

  onD2DDNDDrop(e, parentD, relData) {
    const node = e.dragNode
    const cfgD = relData && this.odm.getDNoLoadNoExtd(relData.cfg)
    if (cfgD) {
      const codeRD = this.odm.getRDNoLoadByCode("SYS.REL.CFG.SYS_CODE");
      const codeDs =  codeRD && this.oe.getSubDsWithRD(cfgD, codeRD);
      const  filteredCodeDs = codeDs && codeDs.filter(codeD => {
        const nameA = this.oe.getAttForD(codeD, "NAME", undefined);
        const tmpVar = nameA && this.oe.getAttValue(nameA);
        return (tmpVar && tmpVar.toLowerCase() === 'on-drop');
      });
      if (filteredCodeDs && filteredCodeDs.length === 1) {
        const draggedD2dRel = node.dataRef.nodeType === 'd2d' && this.odm.getD2DRelNoLoadNoExtd(node.dataRef.odmId)
        const draggedD = draggedD2dRel ? this.odm.getDNoLoadNoExtd(draggedD2dRel.tid) : (node.dataRef.nodeType === 'd' ? this.odm.getDNoLoadNoExtd(node.dataRef.odmId) : null)
        Promise.resolve(this.oe.callFunction(relData, 'on-drop', {ojsDraggedD: draggedD, ojsParentD: parentD, ojsRRel: relData}, true)).then(pVal => {
          this.refresh()
        })
        return
      }
    }
    const draggedD2dRel = node.dataRef.nodeType === 'd2d' && this.odm.getD2DRelNoLoadNoExtd(node.dataRef.odmId)
    let draggedD = draggedD2dRel ? this.odm.getDNoLoad(draggedD2dRel.tid) : (node.dataRef.nodeType === 'd' ? this.odm.getDNoLoad(node.dataRef.odmId) : null)
    const createdDSub = this.oe.createDSubRelForRDSubRel(parentD.id, draggedD.id, relData, false)
    this.refresh()
  }

  doD2DDNDCheck(node, parentD, relData) {
    const cfgD = relData && this.odm.getDNoLoadNoExtd(relData.cfg)
    if (cfgD) {
      const codeRD = this.odm.getRDNoLoadByCode("SYS.REL.CFG.SYS_CODE");
      const codeDs =  codeRD && this.oe.getSubDsWithRD(cfgD, codeRD);
      const  filteredCodeDs = codeDs && codeDs.filter(codeD => {
        const nameA = this.oe.getAttForD(codeD, "NAME", undefined);
        const tmpVar = nameA && this.oe.getAttValue(nameA);
        return (tmpVar && tmpVar.toLowerCase() === 'check-drop');
      });
      if (filteredCodeDs && filteredCodeDs.length === 1) {
        const draggedD2dRel = node.dataRef.nodeType === 'd2d' && this.odm.getD2DRelNoLoadNoExtd(node.dataRef.odmId)
        const draggedD = draggedD2dRel ? this.odm.getDNoLoadNoExtd(draggedD2dRel.tid) : (node.dataRef.nodeType === 'd' ? this.odm.getDNoLoadNoExtd(node.dataRef.odmId) : null)
        let returnVal
        return this.oe.callFunction(relData, 'check-drop', {ojsDraggedD: draggedD, ojsParentD: parentD, ojsRRel: relData}, true)
      }
    }
    // todo: add checks whether adding a sub is allowed, depending on cfgD attributes
    const singOrMultAtt = this.oe.getAttForD(cfgD, 'SING_OR_MULT', undefined)
    const singOrMultAttVal = this.oe.getAttValue(singOrMultAtt)
    const maxSubAtt = (singOrMultAttVal === 'multiple' && this.oe.getAttForD(cfgD, 'SUBMAX', undefined)) || undefined
    let maxSubNumber = singOrMultAttVal === 'multiple' ? Number.parseFloat(this.oe.getAttValue(maxSubAtt)) : 1
    maxSubNumber = Number.isNaN(maxSubNumber) ? 10000 : maxSubNumber
    const existingSubRels = this.oe.getDSubRelsForRDSubRel(parentD, relData)
    const draggedD2dRel = node.dataRef.nodeType === 'd2d' && this.odm.getD2DRelNoLoadNoExtd(node.dataRef.odmId)
    const draggedD = draggedD2dRel ? this.odm.getDNoLoadNoExtd(draggedD2dRel.tid) : (node.dataRef.nodeType === 'd' ? this.odm.getDNoLoadNoExtd(node.dataRef.odmId) : null)
    const reqSubRD = relData && this.odm.getRDNoLoadNoExtd(relData.tid)
    return !!(reqSubRD && draggedD && this.oe.isInstanceOfByRD(draggedD, reqSubRD) && existingSubRels.length < maxSubNumber)
  }

  renderCell(cell, row, col) {
    if (this.rDataset && this.state.guiData) {
      const index = this.state.guiData.inline ? 0 : this.state.activeTabIndex
      const tableRd2rdrelid = this.state.guiData.data.rd2rdrelid[index]
      const rd2rdrelid = tableRd2rdrelid[row] && tableRd2rdrelid[row][col] || null
      const tableRAId = this.state.guiData.data.raid[index]
      const rAId = tableRAId[row] && tableRAId[row][col] || null
      if (!rAId) {
        const subVFCode = this.state.guiData.data.gcod[index][row][col]
        if (subVFCode) {
          let isEdit = this.state.editMode
          const relData = (rd2rdrelid || rd2rdrelid===0) && this.odm.getRD2RDRelNoLoadNoExtd(rd2rdrelid)
          if (relData) {
            const tmp = this.oe.getRDSubRels(this.rDataset, {});
            const childRels = Object.keys(tmp).map((relId) => tmp[relId]);
            const filteredChildRels = childRels.filter(rel => rel.id === rd2rdrelid)
            if (filteredChildRels && filteredChildRels.length) {
              if (this.props.vFObject.typ === 'd' || this.props.vFObject.typ === 'd2d') {
                const parentD = this.props.vFObject.typ === 'd' ? this.props.vFObject : this.odm.getDNoLoad(this.props.vFObject.tid)
                if (parentD) {
                  const rels = this.oe.getDSubRelsForRDSubRel(parentD, relData)
                  //const childrenData = (rels && rels.length && rels.map(item => this.odm.getD2DRelNoLoad(item.id))) || []
                  const key = this.getChildViewEditModeKey(parentD, subVFCode)
                  isEdit = isEdit || !!this.state.childEditMode[key]
                  const children = rels.map((subRel, index) => {
                    return (
                      <tr key={index}>
                        <td style={{padding: '4px'}}>
                          <ViewForm
                            vFObject={subRel}
                            key={subRel.id}
                            vFCode={subVFCode}
                            displayObjectSize={false}
                            enableClipboard={false}
                            displayDataTypes={false}
                            innerViewForm={true}
                            editMode={isEdit}
                            noTop={true}
                            onAfterSubmit={ () => { console.log('after submit') } }
                            odm={this.odm}
                            oe={this.oe}
                          />
                        </td>
                        {isEdit && (<td>
                          <Popconfirm
                            title="Are you sure to delete this node?"
                            onConfirm={() => this.onDeleteChild(subRel)}
                            okText="Confirm"
                            cancelText="Cancel"
                            overlayStyle={{zIndex: 9999}}
                          >
                            <DeleteIcon className={styles.deleteIcon} />
                          </Popconfirm>

                        </td>)}
                      </tr>
                    )
                  })
                  return (
                    <div>
                      <ODrag
                        onDrop={(e) => this.onD2DDNDDrop(e, parentD, relData)}
                        doCheck={(node) => this.doD2DDNDCheck(node, parentD, relData)}
                        >{this.renderChildrenTopRow(parentD, subVFCode, relData, true)}</ODrag>
                      {children  && children.length ? <table>
                        <tbody>
                        {children}
                        </tbody>
                      </table>
                      : ""}
                    </div>
                  )
                }
              } else if (this.props.vFObject.typ === 'rd' || this.props.vFObject.typ === 'rd2rd') {
                return (
                  <ViewForm
                    vFObject={relData}
                    vFCode={subVFCode}
                    key={relData.id}
                    displayObjectSize={false}
                    enableClipboard={false}
                    displayDataTypes={false}
                    innerViewForm={true}
                    editMode={this.state.editMode}
                    noTop={false}
                    onAfterSubmit={ () => { console.log('after submit') } }
                    odm={this.odm}
                    oe={this.oe}
                  />
                )
              } else {
                return (
                  <div>Cannot render view-form object of incorrect type</div>
                )
              }
            } else {
              return (
                <div>rd2rd-relation cannot be found</div>
              )
            }
          } else {
            if (this.props.vFObject.typ === 'd' || this.props.vFObject.typ === 'd2d') {
//              const parentD = this.props.vFObject.typ === 'd' ? this.props.vFObject : this.odm.getDNoLoad(this.props.vFObject.tid)
//              if (parentD) {
                return <ViewForm
                  vFObject={this.props.vFObject}
                  key={this.props.vFObject.id}
                  vFCode={subVFCode}
                  displayObjectSize={false}
                  enableClipboard={false}
                  displayDataTypes={false}
                  innerViewForm={true}
                  editMode={isEdit}
                  noTop={true}
                  onAfterSubmit={ () => { console.log('after submit') } }
                  odm={this.odm}
                  oe={this.oe}
                />
//              } else {
//                return (
//                  <div>Could not find dataset</div>
//                )
//              }
            } else if (this.props.vFObject.typ === 'rd' || this.props.vFObject.typ === 'rd2rd') {
                return <ViewForm
                  vFObject={this.props.vFObject}
                  vFCode={subVFCode}
                  displayObjectSize={false}
                  enableClipboard={false}
                  displayDataTypes={false}
                  innerViewForm={true}
                  editMode={isEdit}
                  noTop={true}
                  onAfterSubmit={ () => { console.log('after submit') } }
                  odm={this.odm}
                  oe={this.oe}
                />
            }
          }
        } else {
//            return (<div>VFCode Missing</div>)
            return (<div>...</div>)
        }
      }
    }

    let vFData = {
      vFObjTyp:  this.vFObjTyp,
      dataset: this.dataset,
      rDataset: this.rDataset,
      d2dRel: this.d2dRel,
      rd2rdRel: this.rd2rdRel,
      rDDefaultD: this.rDDefaultD,
      rd2rdRelDefaultD: this.rd2rdRelDefaultD
    }

    return (
      <React.Fragment>
        <ViewFormCell cell={cell} vFData={vFData} formEditMode={this.state.editMode} odm={this.odm} oe={this.oe} readonly={this.props.readonly}></ViewFormCell>
      </React.Fragment>
    )
  }

  renderInlineForm() {

    return (
      <div className={styles.ViewFormTableView_wrapper}>
        <table className={styles.ViewFormTableView}
               style={getTableStyles(this.state.editedTableFormData)}
        >
          <colgroup>
            {
              this.state.editedTableFormData.columns.map(col => (
                <col
                  key={col.id}
                  style={getColumnStyles(col)}
                ></col>
              ))
            }
          </colgroup>
          <tbody>
          {
            this.state.editedTableFormData.content.map((row, rowIndex) => (
              <tr
                key={row.id}
                style={getRowStyles(row)}
              >
                {
                  row.content.map((cell, colIndex) => (
                    <td
                      key={cell.id}
                      style={getCellStyles(cell)}
                      colSpan={getCellColspan(cell)}
                      rowSpan={getCellRowspan(cell)}
                      id={cell.id}
                      className={styles.tableCell}
                    >
                      {this.renderCell(cell, rowIndex, colIndex)}
                    </td>
                  ))
                }
              </tr>
            ))
          }
          </tbody>
        </table>
      </div>
    )
  }

  renderChildrenTopRow(vFObject, vFCode, relData, showEdit = true, showCreateChild = true) {
    const topRowObj = relData && relData.tid ? this.odm.getRDNoLoadNoExtd(relData.tid) : this.rDataset
    const key = this.getChildViewEditModeKey(vFObject, vFCode)
    const isEdit = !!this.state.childEditMode[key]
    return (
      <div className={styles.TopRow} >
          <div className={styles.vfIconAndTitle}>
            {svgStrToImg(this.oe.getSVGIconCodeForRD(this.rDataset, false))}
            <span className="ml-5">{(relData && this.oe.getDescription(relData)) || this.oe.getDescription(topRowObj)}</span>
          </div>
        <div className={styles.TopRowRightContainer}>
          {showEdit && <div
            className={classNames({[styles.activeIcon]: isEdit})}
            onClick={() => this.toggleChildEditMode(vFObject, vFCode)}
            onKeyDown={() => this.toggleChildEditMode(vFObject, vFCode)}
          ><EditOutlined/></div>}
          {showCreateChild && <CreateChild className={styles.tableDesignIcon} vFObject={vFObject}
                       refreshParent={this.forceUpdate.bind(this)} vFCode={vFCode} relData={relData} odm={this.odm} oe={this.oe}></CreateChild>
          }
        </div>
      </div>);
  }

  renderTopActions() {
    const activeIconStyle = {
      color: "darkblue"
    };
    const dismissIconStyle = { color: "red" };
    const submitIconStyle = { color: "green" };
    const version = this.renderVersionThread();
    let topRowActionIcons
    let formSettingActions = ''
    if (this.props.noActions) {
      return ''
    }
    if (!this.state.noSetting) {
      formSettingActions = (
        <React.Fragment>
          <div onClick={() => this.openDragDesignDialog()} onKeyDown={() => this.openDragDesignDialog()}>
            <TableDesignIcon className={styles.tableDesignIcon}/>
          </div>

          <div onClick={() => this.openTableDesignDialog()} onKeyDown={() => this.openTableDesignDialog()}>
            <TableEditIcon className={styles.tableDesignIcon} />
          </div>
        </React.Fragment>
      )
    }


    if (this.props.innerViewForm) {
      topRowActionIcons = (
        <div className={styles.TopRowRightContainer}>
          <div onClick={() => this.onInnerViewFormSetting()} onKeyDown={() => this.onInnerViewFormSetting()}>
            <DrawIcon className={styles.tableDesignIcon} />
          </div>
        </div>
      )
    } else {
      topRowActionIcons = (
        <div className={styles.TopRowRightContainer}>
          <ReactToPrint
            trigger={() => {
              // NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
              // to the root node of the returned component as it will be overwritten.
              return <div className={styles.iconWrapper}><i className="iconfont icon-print" title="Print"></i></div>
            }}
            content={() => this.componentRef}
          />
          <div className={styles.iconWrapper} onClick={() => this.refresh()} onKeyDown={() => this.refresh()}>
            <i className="iconfont icon-refresh" title="Refresh"></i>
          </div>

          {this.renderViews()}
          {version}
          <div
            className={classNames({[styles.activeIcon]: this.state.editMode})}
            onClick={() => this.toggleEditMode()}
            onKeyDown={() => this.toggleEditMode()}
          ><EditOutlined/></div>
          {formSettingActions}
          {this.state.tableEditingConfirm ? (
            <React.Fragment>
              <div className={styles.changeConfirm}><CloseSquareOutinedIcon onClick={() => this.dismissChange()} style={dismissIconStyle} /></div>
              <div className={styles.changeConfirm}><CheckSquareOutinedIcon onClick={() => this.submitChange()} style={submitIconStyle} /></div>
            </React.Fragment>
          ) : ''}
        </div>
      )
    }

    return topRowActionIcons
  }

  renderTopRow() {
    let topRow;
    const vFormType = this.props.vFObject.typ
    const titleObj = (vFormType === 'd2d' || vFormType === 'd') ? this.dataset : this.rDataset
    if (this.rDataset) {
      topRow = (
        <div className={styles.TopRow} >
          <div className={styles.vfIconAndTitle}>
            {svgStrToImg(this.oe.getSVGIconCodeForRD(this.rDataset, false))}
            <span className="ml-5">{this.oe.getDescription(this.rDataset)}&nbsp;[{titleObj.cod} - {titleObj.id}]</span>
          </div>
          {this.renderTopActions()}
        </div>);
    } else {
      topRow = (
        <div className={styles.TopRow} >
          <span >Test Form</span>
          {this.renderTopActions()}
        </div>);
    }

    return topRow
  }

  render () {
    if (!this.props.vFObject) {
      return ''
    }
   let topRow = ''
    if (!this.props.noTop) {
      if (this.props.innerViewForm && this.props.vFObject.typ === 'rd2rd') {
        topRow = this.renderChildrenTopRow(this.odm.getRDNoLoad(this.props.vFObject.sid), this.state.guiData.path, this.props.vFObject, true, false)
      } else {
        topRow = this.renderTopRow()
      }
    }


    let viewFormContent = ''
    if (this.state.guiData) {
      viewFormContent = this.state.guiData.inline ? this.renderInlineForm() : this.renderFormWithTabs()
    }

    return (
      <div>
        {topRow}
        <div className={ styles.Mainpane } ref={el => (this.componentRef = el)}>
          {viewFormContent}
        </div>
      </div>
    );
  }
};

const mapDispatchToProps = dispatch => ({
  actions: Object.assign(
    {},
    bindActionCreators(WindowManagementActions, dispatch)
  )
});

export default connect(
  null,
  mapDispatchToProps
)(ViewForm)
