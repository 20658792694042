import ODM from 'odm'
import OE from 'oe'

const loop = (data, key, callback) => {
    data.forEach((item, index, arr) => {
        if (item.key === key) {
            return callback(item, index, arr);
        }
        if (item.children) {
            return loop(item.children, key, callback);
        }
    });
};

function findNodeParents(treeData, node) {
    let nodeParents = null
    const nodeKeys = node.key.split('-')
    nodeKeys.reduce((prev, current) => {
        if (!prev) return null

        const nodes = prev.data
        const prevKey = prev.key
        const currentKey = `${prevKey}-${current}`.replace(/^-/, '')
        const target = nodes.find(item => item.key === currentKey)
        if (target) {
            nodeParents = nodeParents || []
            nodeParents.push(target)

            return {data: target.children, key: currentKey}
        } else {
            return null
        }
    }, {data: treeData, key: ''})

    return nodeParents
}

function findSameItem(arr1, arr2) {
    return arr1.filter(item => {
        return arr2.some(item2 => item2 === item)
    })
}



function checkAllowDrop(dragNode, dragOverNode, treeData) {
    if (!dragNode || !dragOverNode) {
        return false
    }
    const sourceType = dragNode.nodeType
    const distType = dragOverNode.nodeType
    switch(sourceType) {
        case 'rd':
            // rd => xnds, rdsubs
            return ['xnds', 'rdsubs'].includes(distType)
        case 'rd2rd':
            return ['xnds', 'rdsubs'].includes(distType)
        case 'd':
        case 'd2d':
            console.log(dragNode.nodeType)
            if (['dsubs'].includes(distType)) {
                const distParents = findNodeParents(treeData, dragOverNode)
                const sourceParents = findNodeParents(treeData, dragNode)
                const distD = ODM.getDNoLoadNoExtd(dragOverNode.odmId)
                const distRD = ODM.getRDNoLoadNoExtd(distD.rid)
                const distDParents = distParents.filter(item => item.nodeType === 'd')
                const sourceD2DRel = ODM.getD2DRelNoLoadNoExtd(dragNode.odmId)
                const sourceD = ODM.getDNoLoadNoExtd(sourceD2DRel.tid)
                const sourceDParents = sourceParents.filter(item => item.nodeType === 'd')
                const sourceRD = ODM.getRDNoLoadNoExtd(sourceD.rid)
                const distRDSubRels = OE.getRDSubRels(distRD,{})
                const rDSubArray = Object.keys(distRDSubRels).map(key => distRDSubRels[key] && distRDSubRels[key].tid && ODM.getRDNoLoadNoExtd(distRDSubRels[key].tid)).filter(item => OE.isExtensionOfByRD(sourceRD, item))

                return rDSubArray && rDSubArray.length && rDSubArray.length === 1
            }

            return false
            break

        case 'ra':
            return ['res'].includes(distType)

        case 'rvw':
            return ['rvws'].includes(distType)
        case 'rdsubs':
            return false
        case 'xnds':
            return false
        case 'dsubs':
            return false
        case 'rtests':
            return false
        case 'ras':
            return false
        case 'rvws':
            return false
        case 'vw':
            return false
        case 'vws':
            return false
        case 'rreps':
            return false
        case 'reps':
            return false
        default:
            return false
    }
}

export default checkAllowDrop
