import React from 'react'
import styles from './inline-design.module.less'
import { cloneDeep } from 'lodash';

import { ReactComponent as FormDesignCellIcon } from '../../../../assets/icons/form-design-cell.svg';
import { ReactComponent as FormDesignRowIcon } from '../../../../assets/icons/form-design-row.svg';
import { ReactComponent as FormDesignColumnIcon } from '../../../../assets/icons/form-design-column.svg';
import { ReactComponent as FormStyleTableIcon } from '../../../../assets/icons/form-design-table.svg';
import classNames from 'classnames';
import CellDesign from '../cell-design/cell-design';
import RowDesign from '../row-design/row-design';
import RowDesignTable from '../row-design/row-design-table';
import CellDesignTable from '../cell-design/cell-design-table';
import ColDesignTable from '../col-design/col-design-table';
import ColDesign from '../col-design/col-design';
import TableDesign from '../table-design/table-design';
import TableDesignTable from '../table-design/table-design-table';
import { TABLE_STYLES_CAN_ADJUST_LIST } from '../../../../core/constant';

class ViewFormInlineDesign extends React.Component {
  constructor(props) {
    super(props);
    const view = props.view

    this.state = {
      view: view,
      activeRowIndex: 0,
      activeCellIndex: 0,
      dragging: false,
      draggingPos: {
        y0: 0,
        height: 0
      },
      previewHeight: '100px',
      designMode: 'cell',
      editingColumnIndex: 0,
    };

    this.previewRef = React.createRef()
    this.containerRef = React.createRef()
    this.dragMoveHandler = this.handleDragMove.bind(this)
    this.dragEndHandler = this.handleDragEnd.bind(this)
  }

  handleDragStart (e) {
    e.preventDefault()
    e.stopPropagation()
    const previewBounds = this.previewRef.current.getBoundingClientRect()
    this.setState({
      dragging: true,
      draggingPos: {
        ...this.state.draggingPos,
        y0: e.clientY,
        height: previewBounds.height
      }
    }, () => {
      this.containerRef.current.addEventListener('mousemove', this.dragMoveHandler, false)
      this.containerRef.current.addEventListener('mouseup', this.dragEndHandler, false)
    })
  }

  handleDragMove (e) {
    e.preventDefault()
    e.stopPropagation()
    if (this.state.dragging) {
      const deltaY = e.clientY - this.state.draggingPos.y0
      this.setState({
        previewHeight: `${this.state.draggingPos.height + deltaY}px`
      })
    }
  }

  handleDragEnd (e) {
    e.preventDefault()
    e.stopPropagation()
    this.setState({
      dragging: false,
      draggingPos: {
        y0: 0,
        height: 0
      }
    })
    this.containerRef.current.removeEventListener('mousemove', this.dragMoveHandler, false)
    this.containerRef.current.removeEventListener('mouseup', this.dragEndHandler, false)
  }

  onDragContextStart(result) {
    const { source } = result;
    this.setState({
      activeRowIndex: source.index
    })
  }

  onDragContextEnd(result) {
    const { source, destination } = result;

    // dropped outside the list
    if (!destination) {
      return;
    }
    const rows = this.state.view.rows

    const [removedRow] = rows.splice(source.index, 1);
    rows.splice(destination.index, 0, removedRow);

    this.setState({
      view: { ...this.state.view },
      activeRowIndex: destination.index
    });
  }

  /**
   * when table design dialog submit, dialog will get the submit value from this function.
   * @returns formData
   */
  getFormData() {
    const newTableProperties = this.state.view.styles
    Object.keys(newTableProperties).forEach(key => {
      if (TABLE_STYLES_CAN_ADJUST_LIST.indexOf(key) >= 0) {
        this.state.view.styles[key] = newTableProperties[key]
      }
    })

    return this.state.view
  }


  handleStateChange = (state, type) => {
    this.setState(state, () => {
      // for tabs-design
      if (this.props.formDataChange) {
        this.props.formDataChange(this.state.view)
      }
    })
  }

  setDesignMode(mode) {
    this.setState({
      designMode: mode
    })
  }

  renderDesignComponent(mode) {
    if (mode === 'cell') {
      return this.designCell()
    }

    if (mode === 'row') {
      return this.designRow()
    }

    if (mode === 'col') {
      return this.designCol()
    }

    if (mode === 'table') {
      return this.designTable()
    }
  }

  designCell() {
    const props = {
      view: this.state.view,
      activeCellIndex: this.state.activeCellIndex,
      activeRowIndex: this.state.activeRowIndex,
      handleStateChange: this.handleStateChange
    }
    return (
      <CellDesign
        {...props}
      />
    )
  }

  designRow() {
    const props = {
      view: this.state.view,
      activeRowIndex: this.state.activeRowIndex,
      handleStateChange: this.handleStateChange
    }

    return (
      <RowDesign
        {...props}
      />
    )
  }

  designCol() {
    const props = {
      view: this.state.view,
      handleStateChange: this.handleStateChange,
      editingColumnIndex: this.state.editingColumnIndex
    }
    return <ColDesign
      {...props}
    />
  }

  designTable() {
    const props = {
      view: this.state.view,
      handleStateChange: this.handleStateChange,
      tableProperties: this.state.tableProperties
    }
    return <TableDesign
      {...props}
    />
  }

  isCellActive(row, col) {
    if (this.state.designMode === 'cell') {
      return row === this.state.activeRowIndex && col === this.state.activeCellIndex
    } else {
      return false
    }
  }

  isRowActive(row) {
    if (this.state.designMode === 'row') {
      return row === this.state.activeRowIndex
    } else {
      return false
    }
  }

  onCellClick(row, col) {
    this.setState({ activeRowIndex: row, activeCellIndex: col })
  }

  renderCellTable() {
    const props = {
      view: this.state.view,
      activeRowIndex: this.state.activeRowIndex,
      activeCellIndex: this.state.activeCellIndex,
      handleStateChange: this.handleStateChange
    }
    return <CellDesignTable
      {...props}
    />
  }

  renderRowTable() {
    const props = {
      view: this.state.view,
      activeRowIndex: this.state.activeRowIndex,
      handleStateChange: this.handleStateChange
    }
    return <RowDesignTable
      {...props}
    />
  }

  renderColTable() {
    const props = {
      view: this.state.view,
      handleStateChange: this.handleStateChange,
      editingColumnIndex: this.state.editingColumnIndex
    }
    return <ColDesignTable
      {...props}
    />
  }

  renderTableDesignTable() {
    const props = {
      view: this.state.view,
      handleStateChange: this.handleStateChange,
      tableProperties: this.state.tableProperties
    }
    return <TableDesignTable
      {...props}
    />
  }

  renderDesignTable(mode) {
    if (mode === 'cell') {
      return this.renderCellTable()
    }

    if (mode === 'row') {
      return this.renderRowTable()
    }

    if (mode === 'col') {
      return this.renderColTable()
    }

    if (mode === 'table') {
      return this.renderTableDesignTable()
    }
  }

  renderModeActions() {
    return (
      <div className={styles.actionsContainer}>
        <div
          className={classNames({
            [styles.activeAction]: this.state.designMode === 'cell',
          }, styles.actionIcon)}
          onClick={() => this.setDesignMode('cell')} onKeyDown={() => this.setDesignMode('cell')}>
          <FormDesignCellIcon/>
        </div>
        <div
          className={classNames({
            [styles.activeAction]: this.state.designMode === 'row'
          }, styles.actionIcon)}
          onClick={() => this.setDesignMode('row')} onKeyDown={() => this.setDesignMode('row')}>
          <FormDesignRowIcon/>
        </div>
        <div
          className={classNames({
            [styles.activeAction]: this.state.designMode === 'col'
          }, styles.actionIcon)}
          onClick={() => this.setDesignMode('col')} onKeyDown={() => this.setDesignMode('col')}>
          <FormDesignColumnIcon/>
        </div>
        <div
          className={classNames({
            [styles.activeAction]: this.state.designMode === 'table'
          }, styles.actionIcon)}
          onClick={() => this.setDesignMode('table')} onKeyDown={() => this.setDesignMode('table')}>
          <FormStyleTableIcon/>
        </div>
      </div>
    )
  }

  render() {
    const designComponent = this.renderDesignComponent(this.state.designMode)
    const designTable = this.renderDesignTable(this.state.designMode)
    return (
      <div className={ styles.viewForm_container }
           ref={ this.containerRef }
      >


        <div className={ styles.viewForm_preview }
             ref={ this.previewRef }
             style={{ height: this.state.previewHeight }}
        >
          <div className={ styles.ViewFormTableView_wrapper }
               ref={ this.tableViewWrapper }
          >
            {designTable}
          </div>
        </div>

        <div className={ styles.dragHandle }>
          <div className={ styles.dragHandle_anchor }
               onMouseDown={ this.handleDragStart.bind(this) }
          ></div>
        </div>
        {this.renderModeActions()}

        <div className={styles.designContainer}>

          <div className={styles.designContent}>
            {designComponent}
          </div>
        </div>

      </div>
    )
  }
}



export default ViewFormInlineDesign
