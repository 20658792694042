import React from 'react'
import styles from './input-with-unit.module.less'
import {
  endsWith
} from 'lodash'

import {
  Input,
  AutoComplete,
} from 'antd'
import Icon from "@ant-design/icons";

const { Option } = AutoComplete;

const DEFAULT_SUPPORTED_UNITS = [
  'px',
  'pt',
  '%'
]

const getSupportedUnits = units => {
  return units
    ? units
    : DEFAULT_SUPPORTED_UNITS
}

class InputWithUnit extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      value: '',
      unit: '',
      query: '',
      options: [],
      open: false
    }
  }

  componentDidMount () {
    this.initialValue()
  }

  initialValue () {
    const initialValue = this.props.value
    const unit = getSupportedUnits(this.props.supportedUnits)
      .find(item => endsWith(initialValue, item))  || DEFAULT_SUPPORTED_UNITS[0]

    if (unit) {
      this.setState({
        value: initialValue.substring(0, initialValue.length - unit.length),
        unit: unit,
        query: unit
      })
    } else {
      this.setState({
        value: initialValue,
        unit: DEFAULT_SUPPORTED_UNITS[0],
        query: DEFAULT_SUPPORTED_UNITS[0],
      })
    }
  }

  handleValueChange (e) {
    this.setState({ value: e.target.value }, () => {
      let modeValue = `${this.state.value}${this.state.unit}`
      if (!this.state.value) {
        modeValue = undefined
      }
      this.props.handleChange(modeValue)
    })
  }

  handleUnitSelect (e) {
    this.setState({
      unit: e,
      query: e,
      open: false
    }, () => {
      this.props.handleChange(`${this.state.value}${this.state.unit}`)
    })
  }

  handleUnitChange (e) {
    this.setState({ query: e })
  }

  handleUnitSearch (e) {
    const dataSources = getSupportedUnits(this.props.supportedUnits)
    this.setState({
      options: dataSources.filter(item => item.indexOf(e) >= 0)
    })
  }

  handleUnitFocus (e) {
    this.handleUnitSearch(this.state.query)
    this.setState({
      open: true
    })
  }

  handleUnitBlur (e) {
    this.setState({
      open: false
    })
  }

  clear (e) {
    this.setState({
      value: '',
      unit: '',
      query: ''
    }, () => {
      this.props.handleChange('')
    })
  }

  render () {
    return (
      <div className={ styles.InputWithUnit }>
        <Input.Group compact>
          <Input
            style={{ width: 115 }}
            className={ styles.InputWithUnit_input }
            value={this.state.value}
            onChange={ this.handleValueChange.bind(this) }
          />
          <AutoComplete defaultValue=""
            style={{ width: 87 }}
            dropdownStyle={{ zIndex: 1000000000 }}
            open={ this.state.open }
            value={ this.state.query }
            optionLabelProp="text"
            onSelect={ this.handleUnitSelect.bind(this) }
            onChange={ this.handleUnitChange.bind(this) }
            onSearch={ this.handleUnitSearch.bind(this) }
            onFocus={ this.handleUnitFocus.bind(this) }
            onBlur={ this.handleUnitBlur.bind(this) }
          >
            {
              this.state.options.map(option => (
                <Option key={option} text={option}>
                  {
                    option.split(this.state.query)
                    .reduce((acc, frag) => acc === null ? frag : <>{acc}<span className={ styles.highlight }>{this.state.query}</span>{frag}</>, null)
                  }
                </Option>
              ))
            }
          </AutoComplete>
        </Input.Group>

        <button
          className={ styles.clearInput }
          onClick={() => this.clear()}
        >
          <Icon type="close-circle" theme={ 'filled' } />
        </button>
      </div>
    )
  }
}

export default InputWithUnit
