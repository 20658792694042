import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as WindowManagementActions from '../../../stores/window-management/actions'
import Dialog from '../../shared/dialog/dialog';
import { Button, Upload } from 'antd';
import styles from './set-icon-dialog.module.less'
import ODM from 'odm'
import OE from 'oe'
import { FormatPainterOutlined, InboxOutlined } from '@ant-design/icons';
import { getDialogTitle } from '../utils';


class SetIconDialog extends React.Component {
  constructor(props) {
    super(props);
    const icon = OE.getSVGIconCodeForRD(this.props.rdObject)
    this.state = {
      name: '',
      content: icon || '',
      tips: 'Please select a SVG file',
      error: false
    }

    const dialogTitle = getDialogTitle(
      <FormatPainterOutlined />,
      this.props.title
    )

    this.props.actions.setWindowTitle(this.props.windowId, dialogTitle);
  }


  handleSubmit() {
    // do set icon
    if (!this.state.content) {
      this.setState({
        error: true
      })
      return
    }
    // save icon to rd
    const rdObject = ODM.getRDNoLoad(this.props.rdObject.id)
    rdObject.svgIcon = this.state.content || ""
    ODM.updateRD(rdObject, rdObject.id)

    this.handleClose()
  }

  handleClose() {
    this.props.actions.removeWindow(this.props.windowId);
  }

  handleMinimize() {
    this.props.actions.setWindowVisible(this.props.windowId, false);
  }

  onSelectFile(e) {
    const file = e.file

    const reader = new FileReader()

    reader.onload = (res) => {
      // Lyon: I added some code to manipulate the input in order to remove useless leading code and comments.  You can improve this a little if you want.
      const content = res.target.result.substring(res.target.result.toLowerCase().indexOf("<svg")).replace(/<!--.*?-->/g, "")
      this.setState({
        name: file.name,
        content,
        error: false
      })
    }

    reader.readAsText(file)

  }

  render() {
    let image = ''

    if (this.state.content) {
      const imageContent = encodeURIComponent(this.state.content)
      const imageSrc = `data:image/svg+xml;utf8,${imageContent}`
      image = (<img src={imageSrc} alt="icon-preview"/>)
    }

    return (
      <Dialog
        title={ this.props.title }
        visible={ this.props.visible }
        submitButton={ true }
        cancelButton={ true }
        hasBazel={ false }
        onClose={ this.handleClose.bind(this) }
        onMinimize={ this.handleMinimize.bind(this) }
        onSubmit={this.handleSubmit.bind(this)}
        windowId={ this.props.windowId }
      >
        {this.state.error && (<div className="error-tips">{this.state.tips}</div>)}
        <Upload.Dragger
          accept=".svg"
          showUploadList={false}
          className={styles.dragUpload}
          customRequest={(e) => this.onSelectFile(e)}
        >
          <p className="ant-upload-drag-icon" style={{marginBottom: '0px'}}>
            <InboxOutlined />
          </p>
          <p className={styles.fileName}>{this.state.name ? this.state.name : 'Click or drag a SVG file to set the icon.'}</p>
        </Upload.Dragger>

        { image && (<div className={styles.preview}>{image}</div>)}

      </Dialog>
    )
  }
}

const mapDispatchToProps = dispatch => ({
  actions: Object.assign(
    {},
    bindActionCreators(WindowManagementActions, dispatch)
  )
});

export default connect(
  null,
  mapDispatchToProps
)(SetIconDialog)
