import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import LeftPane from '../components/shared/left-pane/left-pane'

import * as WindowManagementActions from '../stores/window-management/actions'

const mapStateToProps = state => ({
  rootProjects: state.rootProjects,
  projects: state.projects,
  rootLibraries: state.rootLibraries,
  libraries: state.libraries,
  windowState: state.windowState
})

const mapDispatchToProps = dispatch => ({
  actions: Object.assign(
    {},
    bindActionCreators(WindowManagementActions, dispatch)
  )
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LeftPane)
