import { useSlate } from 'slate-react';
import React from 'react';
import { Editor } from 'slate';
import classNames from 'classnames';



const isMarkActive = (editor, format) => {
  const marks = Editor.marks(editor)
  return marks ? marks[format] === true : false
}

export const toggleMark = (editor, format) => {
  const isActive = isMarkActive(editor, format)

  if (isActive) {
    Editor.removeMark(editor, format)
  } else {
    Editor.addMark(editor, format, true)
  }
}

const MarkButton = ({ format, icon }) => {
  const editor = useSlate()
  return (
    <button
      className={classNames('icon-btn', {'icon-btn-active': isMarkActive(editor, format)})}
      onMouseDown={event => {
        event.preventDefault()
        toggleMark(editor, format)
      }}
    >
      <i className={classNames('iconfont', icon)}></i>
    </button>
  )
}

export default MarkButton
