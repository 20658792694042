import React  from 'react';
import { TableSpreadsheet } from './table-spreadsheet';
import ABase from '../a-base';
import { Button, InputNumber } from 'antd';

export const DefaultColumnEditors = {
  TEXT: 'TEXT_INPUT',
  LONG: 'TEXT_INPUT',
  INTEGER: 'TEXT_INPUT',
  FLOAT: 'TEXT_INPUT',
  BOOLEAN: 'CHECKBOX',
  DIMVAR: 'DIMENSIONAL_EDITOR',
  TIMESTAMP: 'TIMESTAMP_INPUT',
  DATASET: 'DATASET_EDITOR',
  ROOTDATASET: 'ROOTDATASET_EDITOR',
  ATTRIBUTE: 'ATTRIBUTE_EDITOR',
  ROOTATTRIBUTE: 'ROOTATTRIBUTE_EDITOR',
  FILE: 'FILE_EDITOR'
}

export const ColumnEditorsMap = {
  TEXT: [
    {text: 'Text input', key: 'TEXT_INPUT', rDCode: 'TXT'},
    {text: 'Select', key: 'SELECT', rDCode: 'SEL'},
    {text: 'Checkbox', key: 'CHECKBOX', rDCode: 'CB'}
  ],
  LONG: [
    {text: 'Text input', key: 'TEXT_INPUT', rDCode: 'TXT'},
    {text: 'Select', key: 'SELECT', rDCode: 'SEL'},
    {text: 'Checkbox', key: 'CHECKBOX', rDCode: 'CB'}
  ],
  INTEGER: [
    {text: 'Text input', key: 'TEXT_INPUT', rDCode: 'TXT'},
    {text: 'Select', key: 'SELECT', rDCode: 'SEL'},
    {text: 'Checkbox', key: 'CHECKBOX', rDCode: 'CB'}
  ],
  FLOAT: [
    {text: 'Text input', key: 'TEXT_INPUT', rDCode: 'TXT'},
    {text: 'Select', key: 'SELECT', rDCode: 'SEL'},
    {text: 'Checkbox', key: 'CHECKBOX', rDCode: 'CB'}
  ],
  BOOLEAN: [
    {text: 'Select', key: 'SELECT', rDCode: 'SEL'},
    {text: 'Checkbox', key: 'CHECKBOX', rDCode: 'CB'}
  ],
  DIMVAR: [
    {text: 'Dimensional editor', key: 'DIMENSIONAL_EDITOR', rDCode: 'DIMVAR'}
  ],
  TIMESTAMP: [
    {text: 'Text input', key: 'TEXT_INPUT', rDCode: 'TXT'},
    {text: 'Timestamp input', key: 'TIMESTAMP_INPUT', rDCode: 'TS'},
    {text: 'Select', key: 'SELECT', rDCode: 'SEL'},
    {text: 'Checkbox', key: 'CHECKBOX', rDCode: 'CB'}
  ],
  DATASET: [
    {text: 'Dataset editor', key: 'DATASET_EDITOR', rDCode: 'D'}
  ],
  ROOTDATASET: [
    {text: 'Root-Dataset editor', key: 'ROOTDATASET_EDITOR', rDCode: 'RD'}
  ],
  ATTRIBUTE: [
    {text: 'Attribute editor', key: 'DATASET_EDITOR', rDCode: 'A'}
  ],
  ROOTATTRIBUTE: [
    {text: 'Root-Attribute editor', key: 'ROOTATTRIBUTE_EDITOR', rDCode: 'RA'}
  ],
  FILE: [
    {text: 'File editor', key: 'FILE_EDITOR', rDCode: 'FILE'}
  ]
}

export default class TableSpreadsheetEditor extends ABase {
  constructor(props) {
    super(props);

    const data = this.getData()

    this.state = {data: data, rowSetting: false}
  }

  getData() {
    const vFData = this.props.vFData;
    const d = this.d  || (vFData.vFObjTyp === 'rd2rd' && this.oe.getDefaultD(vFData.rd2rdRel)) || (vFData.vFObjTyp === 'rd' && this.oe.getDefaultD(vFData.rDataset))

    const cellCfg = this.props.cellCfg;
    const spdshtCfgD = this.odm.getDNoLoadNoExtd(cellCfg.cfgdid)
    const cfgRD = spdshtCfgD && this.odm.getRDNoLoadNoExtd(spdshtCfgD.rid)
    const tmp = this.oe.getSubRDAndRD2RDWithCode(cfgRD, "SYS.AECFG.SPDSHT.COL")
    const colCfgDRels = this.oe.getDSubRelsForRDSubRel(spdshtCfgD, tmp.rD2RD)
    this.colCfgData = []
    this.attValues = []
    colCfgDRels.map((colCfgDRel, index) => {
      const colCfgD = this.odm.getDNoLoadNoExtd(colCfgDRel.tid)
      // console.log(colCfgD)
      const rAAtt = this.oe.getAttForD(colCfgD, "RA", undefined)
      const rA = rAAtt && this.odm.getRAttNoLoadNoExtd(this.oe.getAttValue(rAAtt))
      const deepRA = rA && this.oe.getDeepRAtt(rA)
      const headerAtt = this.oe.getAttForD(colCfgD, "HEADER", undefined)
      const headerTxt = headerAtt ? this.oe.getAttValue(headerAtt) : (deepRA ? this.oe.getDescription(deepRA) : "")
      const accessor = 'c'+(index+1)
      let unitValue
      if (deepRA) {
        if (d) {
          const dataAtt = this.oe.getAttForD(d, deepRA.cod, undefined)
          const dataValues = dataAtt && this.oe.getAttValue(dataAtt)
          if (deepRA.dtp === 'DIMVAR_AR') {
            unitValue = dataValues && dataValues.uid
            this.attValues.push(dataValues && dataValues.val)
          } else {
            this.attValues.push(dataValues)
          }
        } else {
          this.attValues.push(undefined)
        }
      }

      const editorCfgAtt = colCfgD && this.oe.getAttForD(colCfgD, 'EDTCFG_D', undefined)
      const readOnlyCfgAtt = colCfgD && this.oe.getAttForD(colCfgD, 'READONLY', undefined)
      const editorCfgD = editorCfgAtt && this.odm.getDNoLoadNoExtd(this.oe.getAttValue(editorCfgAtt))
      const colCfgDataEl = {
        headerTxt: headerTxt,
        attCode: rA && rA.cod,
        rAId: rA && rA.id,
        dId: d && d.id,
        deepRAId: deepRA && deepRA.id,
        accessor: accessor,
        cfgDId: colCfgD && colCfgD.id,
        edtCfgDId: editorCfgD && editorCfgD.id,
        readOnly: readOnlyCfgAtt ? readOnlyCfgAtt.data.val === 'true' : false
      }
      if (unitValue) {
        colCfgDataEl.unit = unitValue
      }
      this.colCfgData.push(colCfgDataEl)
    })

    let nRow = this.attValues.reduce((acc, attVals) => Math.max(acc, (attVals && attVals.length) || 0), 0)

//    nRow = 6 // Lyon: the number of rows should either be set by the user via the configuration dataset, or automatically be computed via the maximum length of each array-attribute.  For now, I simply used 6 as a placeholder.
    let data = [];
    for (var rowI = 0; rowI < nRow; rowI++) {
      let newRow = {}
      for (var colI = 0; colI < this.colCfgData.length; colI++) {
        newRow[this.colCfgData[colI].accessor] = (this.attValues[colI] && this.attValues[colI].length && this.attValues[colI].length > rowI && this.attValues[colI][rowI]) || ""
      }
      data.push(newRow)
    }

    return data
  }

  static getDerivedStateFromProps(props, state) {
    if (props.editMode !== state.editMode) {
      return {
        editMode: props.editMode
      };
    }

    return {}
  }

  setODMValue(deepRAtt, rowIndex, colAccessor, value) {
    if (deepRAtt && deepRAtt.dtp.toLowerCase().endsWith("_ar") ) {
      const vFData = this.props.vFData;
      const d = this.d  || (vFData.vFObjTyp === 'rd2rd' && this.oe.getOrCreateDefaultD(vFData.rd2rdRel)) || (vFData.vFObjTyp === 'rd' && this.oe.getOrCreateDefaultD(vFData.rDataset))
      if(!d) {
        // RJZ comment: Will need to add proper logging later
        console.log("table-spreadsheet error occured setting attribute - no dataset for which to set attribute")
        return;
      }
      if (!this.d) {
        this.d = d
      }
      const dataType = deepRAtt.dtp.substring(0, deepRAtt.dtp.length - 3)
      let newTableColData = this.state.data.map(row => row[colAccessor])
      if (Array.isArray(value)) {
        value.forEach((element, index) => {newTableColData[rowIndex+index] = element})
      } else {
        newTableColData[rowIndex] = value
      }
      const castVal = this.oe.castToType(newTableColData, dataType, true)
      if (deepRAtt.loc) {
        this.oe.setLocalAttValueForD(d, deepRAtt, castVal)
      } else {
        let att = this.oe.getAttForD(d, deepRAtt.cod, undefined)
        if (!att || (att.did !== d.id)) {
          att = this.oe.createAtt(d, deepRAtt)
        }
        att.data.val = castVal
        this.odm.updateAtt(att)
        return att
      }
    } {
      // RJZ comment: Will need to add proper logging later
      console.log("table-spreadsheet error occured setting attribute - trying to set array for non-array attribute")
      return;
    }
  }

  onChange = (rowIndex, col, value, updateCb) => {
    const colAccessorArray = this.colCfgData.map(colCfgEl1 => colCfgEl1.accessor)
    const colIndex = colAccessorArray && colAccessorArray.indexOf(col)

    if (colAccessorArray && colIndex > -1) {
      if (Array.isArray(value)) { //  User pasted an array from a spreadsheet into the cell.
        if (value.length && (value.length + rowIndex) < (this.state.data.length +1)) {
          const pastedColCount = (Array.isArray(value[0]) && value[0].length) || 1
          if ((colIndex+pastedColCount) < (this.colCfgData.length +1)) {
            let dataCopy = [...this.state.data];
            let rowCopy = []
            for (let pastedRowIndex = 0; pastedRowIndex < value.length; pastedRowIndex ++) {
              rowCopy.push({...this.state.data[rowIndex+pastedRowIndex]});
            }
            for (let pastedColIndex = 0; pastedColIndex < pastedColCount; pastedColIndex++) {
              const colCfgEl = this.colCfgData[colIndex + pastedColIndex]
              const deepRAtt = colCfgEl && this.odm.getRAttNoLoadNoExtd(colCfgEl.deepRAId)
              const pastedColValues = value.map(valueRow => valueRow[pastedColIndex])
              const updatedAtt = this.setODMValue(deepRAtt, rowIndex, colCfgEl.accessor, pastedColValues)
              if (updatedAtt) {
                pastedColValues.forEach((cellValue, pasteRowIndex) => {
                  rowCopy[pasteRowIndex][colCfgEl.accessor] = cellValue
                })
              }
            }
            for (let pastedRowIndex = 0; pastedRowIndex < value.length; pastedRowIndex ++) {
              dataCopy[rowIndex+pastedRowIndex] =  rowCopy[pastedRowIndex]
            }
            this.setState({data: dataCopy});
          }
        }
      } else { // user changes a single cell of the spreadsheet
        const colCfgEl = this.colCfgData[colIndex]
        const deepRAtt = colCfgEl && this.odm.getRAttNoLoadNoExtd(colCfgEl.deepRAId)
        const updatedAtt = this.setODMValue(deepRAtt, rowIndex, col, value)
        if (updatedAtt) {
          let dataCopy = [...this.state.data];
          let rowCopy = {...this.state.data[rowIndex]};
          rowCopy[col] = value;
          dataCopy[rowIndex] = rowCopy;
          this.setState({data: dataCopy});
          if (updateCb) {
            return updateCb(updatedAtt)
          }
        }
      }
    } else {
      // RJZ comment: Will need to add proper logging later
      console.log("table-spreadsheet error occured setting attribute - could not identify modified column")
      return;
    }
  }

  /**
   *
   * @param index
   * @param type "above" | "below"
   */
  onAddRow = (index, type) =>{
    const data = [...this.state.data]
    if (type === 'remove') {
      data.splice(index, 1)
      this.setState({data})
      return
    }
    let insertIndex
    if (type === 'above') {
      insertIndex = index
    } else {
      insertIndex = index + 1
    }
    const newRow = {}
    Object.keys(data[0]).forEach(key => {
      newRow[key] = ''
    })
    data.splice(insertIndex, 0, newRow)
    this.setState({data})
  }

  buildEmptyDataItem(counts) {
    return new Array(counts).fill(1).map(() => {
      const newRow = {}
      this.colCfgData.forEach(col=>{
        newRow[col.accessor] = ''
      })

      return newRow
    })
  }


  onSetRows = (val) => {
    const data = this.state.data
    let newData = []
    if (data.length < val) {
      const diff = val - data.length
      const items = this.buildEmptyDataItem(diff)

      newData = [...data, ...items]
    } else {
      newData = data.slice(0, val)
    }

    this.setState({
      data: newData
    })
  }

  toggleRowSetting = () => {
    this.setState({
      rowSetting: !this.state.rowSetting
    })
  }

  onRefresh = () => {
    const data = this.getData()
    this.setState({data})
  }

  render() {

    const columns = this.colCfgData.map((colCfgEl, colIndex) => {
      return {Header: colCfgEl.headerTxt, accessor: colCfgEl.accessor, colCfg: colCfgEl}
    })

    return (
      <div className="spreadsheet-editor">
        {/*<div className="table-header">*/}
        {/*  <Button onClick={this.toggleRowSetting} className="mr-10">{this.state.rowSetting ? 'Close' : 'Open'} Row Setting</Button>*/}
        {/*  {this.state.rowSetting && <div className="info-item">*/}
        {/*    <label htmlFor="rowCount" className="mr-10">Set rows:</label>*/}
        {/*    <InputNumber min={1} value={this.state.data.length} onChange={this.onSetRows} id="rowCount"/>*/}
        {/*  </div>}*/}
        {/*</div>*/}
        <div className="table-container">
          <TableSpreadsheet
            data={this.state.data}
            columns={columns}
            onDataChange={this.onChange}
            onAddRow={this.onAddRow}
            onSetRows={this.onSetRows}
            onRefresh = {this.onRefresh}
            rowSetting={this.state.rowSetting}
            editMode={this.state.editMode}
            d = {this.d}
            odm = {this.odm}
            oe = {this.oe}/>
        </div>
      </div>

    )
  }
}







