import React from 'react'
import ReactDOM from 'react-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as WindowManagementActions from '../../../stores/window-management/actions'
import OE from 'oe'
import ODM from 'odm'
import { EditOutlined} from '@ant-design/icons';
import AInput from './attribute-editors/a-input';
import ARadioGroup from './attribute-editors/a-radio-group';
import ASelect from './attribute-editors/a-select';
import ACheckboxGroup from './attribute-editors/a-checkbox-group';
import AMonacoEditor from './attribute-editors/a-monaco-editor';
import AAntdUpload from './attribute-editors/a-antd-upload';
import AAntdFileDnd from './attribute-editors/a-antd-filednd';
import ADimvarInput from './attribute-editors/a-dimvar-input';
import ASlateEditor from './attribute-editors/a-slate-editor';
import styles from './view-form.module.less'
import classNames from 'classnames';
import RdEditor from './attribute-editors/rd-editor';
import DEditor from './attribute-editors/d-editor';
import RaEditor from './attribute-editors/ra-editor';
import AttrEditor from './attribute-editors/attr-editor';
import ASpreadsheetEditor from './attribute-editors/a-spreadsheet-editor';
import ASvgDndEditor from './attribute-editors/a-svg-dnd-editor';
import TableSpreadsheetEditor from './attribute-editors/table-spreadsheet-editor/index';
import { idGenerator } from '../../../libs/flow-editor/utils';

class ViewFormCell extends React.Component {
  id = idGenerator()
  constructor(props) {
    super(props);
    this.odm = props.odm || ODM
    this.oe = props.oe || OE
  }

  state = {
    lastFormEditMode: this.props.editMode
  };

  static getDerivedStateFromProps(props, state) {
    if (props.formEditMode !== state.lastFormEditMode) {
      return {
        lastFormEditMode: props.formEditMode,
        editMode: props.formEditMode
      };
    }

    return {}
  }

  toggleEditMode = () => {
    this.setState({
      editMode: !this.state.editMode
    })
  }

  render() {
    const cellCfg = this.props.cell.cellCfg
    const vFData = this.props.vFData

    if (cellCfg) {
      const rAtt = this.props.cell.cellCfg.raid && this.odm.getRAttNoLoadNoExtd(this.props.cell.cellCfg.raid);
      const cfgD = this.odm.getDNoLoadNoExtd(this.props.cell.cellCfg.cfgdid);
      const cfgRD = cfgD && this.odm.getRDNoLoadNoExtd(cfgD.rid);
      const cfgRDCod = cfgRD && cfgRD.cod;
      const cfgDCod = cfgD && cfgD.cod;

      this.callOERenderAtt = false;
      let attrElem
      if (cfgRDCod) {
        switch (cfgRDCod) {
          case 'SYS.ATT.H5.INP.TXT':
          case 'SYS.AECFG.ANTD.INP': {
            attrElem = (<AInput cellCfg={cellCfg} vFData={vFData} editMode={this.state.editMode} odm={this.odm} oe={this.oe} id={this.id}/>)
            break
          }
          case 'SYS.ATT.H5.SEL':
          case 'SYS.AECFG.ANTD.SEL': {
            attrElem = <ASelect cellCfg={cellCfg} vFData={vFData} editMode={this.state.editMode} odm={this.odm} oe={this.oe} />
            break
          }
          case "SYS.ATT.H5.CBDIV": // Checkbox-type input.  Lyon, please complete this section in a similar way as for the select element.
          case "SYS.AECFG.ANTD.CB": // Lyon, we will need to create this root-dataset, allowing to set the props for the antd Checkbox and Checkbox Group. We will do this later.  Please create an issue in github.
            attrElem = <ACheckboxGroup cellCfg={cellCfg} vFData={vFData} editMode={this.state.editMode} odm={this.odm} oe={this.oe} />
            break;
          case "SYS.ATT.H5.RADIV": // Radio-type input.  Lyon, please complete this section in a similar way as for the select element.
          case "SYS.AECFG.ANTD.RA": // Lyon, we will need to create this root-dataset, allowing to set the props for the antd Checkbox and Checkbox Group. We will do this later.  Please create an issue in github.
            attrElem = (<ARadioGroup cellCfg={cellCfg} vFData={vFData} editMode={this.state.editMode} odm={this.odm} oe={this.oe} />)
            break;
          case "SYS.ATT.H5.INP.FLE":  // File input.  Lyon please see if you can complete this section.
          case "SYS.AECFG.ANTD.ULD": // Lyon, we will need to create this root-dataset, allowing to set the props for the antd Checkbox and Checkbox Group. We will do this later.  Please create an issue in github.
            attrElem = <AAntdUpload cellCfg={cellCfg} vFData={vFData} editMode={this.state.editMode} odm={this.odm} oe={this.oe} />
            break;

          case 'SYS.ATT.SVGCODE': {
            attrElem = <ASvgDndEditor cellCfg={cellCfg} vFData={vFData} editMode={this.state.editMode} odm={this.odm} oe={this.oe} />
            break;
          }
          case 'SYS.ATT.ACE':
          case 'SYS.AECFG.ME': // Monaco Editor Root-Dataset
            attrElem = (<AMonacoEditor cellCfg={cellCfg} vFData={vFData} editMode={this.state.editMode} odm={this.odm} oe={this.oe} />)
            break;
          case 'SYS.ATT.SLATE':
            attrElem = (<ASlateEditor cellCfg={cellCfg} vFData={vFData} editMode={this.state.editMode} odm={this.odm} oe={this.oe} />)
            break
          case 'SYS.ATT.HOT':
          case 'SYS.AECFG.TBL': // Lyon, I am thinking using React-Table.  Please let me know if you think React-Table is a good/solid tool.
            this.callOERenderAtt = true;
            break;

          // Lyon: see below code to determine what to do when the root-attribute is of type "SYS.ATT".  This is only temporary,
          // until all cfgD datasets are switched out by the new datasets with corresponding root-datasets
          case 'SYS.ATT.FILEDND': {
            attrElem = <AAntdFileDnd cellCfg={cellCfg} vFData={vFData} editMode={this.state.editMode} odm={this.odm} oe={this.oe} />
            break;
          }
          case 'SYS.ATT.SPREADSHEET': {
            attrElem = <ASpreadsheetEditor cellCfg={cellCfg} vFData={vFData} editMode={this.state.editMode} odm={this.odm} oe={this.oe} />
            break;
          }
          case 'SYS.AECFG.SPDSHT': {
            attrElem = <TableSpreadsheetEditor cellCfg={cellCfg} vFData={vFData} editMode={this.state.editMode} odm={this.odm} oe={this.oe} />
            break;
          }
          case 'SYS.ATT':
            switch (cfgDCod) {
              case "OE.FLEDND": // Lyon: These will all be migrated to the "SYS.AECFG.ANTD.ULD" root-dataset type, as the antd Upload component supports drag and drop.
                attrElem = <AAntdFileDnd cellCfg={cellCfg} vFData={vFData} editMode={this.state.editMode} odm={this.odm} oe={this.oe} />
                break;
              case "QIL": // Lyon: This is the QUILL editor.  Please see if you can hook-up react-quill.
                attrElem = (<ASlateEditor cellCfg={cellCfg} vFData={vFData} editMode={this.state.editMode} odm={this.odm} oe={this.oe} />) // Lyon, uncomment this, once you have the AQuillEditor hooked-up
                break;
            }
            break;

          case 'SYS.ATT.OE.DIMVAR':
            attrElem = <ADimvarInput cellCfg={cellCfg} vFData={vFData} editMode={this.state.editMode} odm={this.odm} oe={this.oe} />
            break;

          case 'SYS.AECFG.ODT.RD':
            {
              let editorOptions
              const optionCodeAtt = cfgD && this.oe.getAttForD(cfgD, "OPT_CODE", undefined)
              const optionCode = optionCodeAtt && this.oe.getAttValue(optionCodeAtt)
              const jSCFunction = optionCode && Function(
              `
                'use strict';

                return function(ojsODM, ojsOE, ojsCellCfg, vFData) {
                   let ojsReturn;
                   ${optionCode}
                   return ojsReturn;
                }
              `
              )();
              try {
                const isSync = true
                editorOptions = isSync ? jSCFunction(this.odm, this.oe, cellCfg, vFData) : new Promise( (resolve,reject) => {resolve(jSCFunction(this.odm, this.oe, cellCfg, vFData))});
              } catch (error) {
                // todo: log error
              }
              attrElem = <RdEditor cellCfg={cellCfg} options={editorOptions} vFData={vFData} editMode={this.state.editMode} odm={this.odm} oe={this.oe} />
            }
            break
          case 'SYS.AECFG.ODT.D':
            {
              let editorOptions
              const optionCodeAtt = cfgD && this.oe.getAttForD(cfgD, "OPT_CODE", undefined)
              const optionCode = optionCodeAtt && this.oe.getAttValue(optionCodeAtt)
              const jSCFunction = optionCode && Function(
              `
                'use strict';

                return function(ojsODM, ojsOE, ojsCellCfg, vFData) {
                   let ojsReturn;
                   ${optionCode}
                   return ojsReturn;
                }
              `
              )();
              try {
                const isSync = true
                editorOptions = isSync ? jSCFunction(this.odm, this.oe, cellCfg, vFData) : new Promise( (resolve,reject) => {resolve(jSCFunction(this.odm, this.oe, cellCfg, vFData))});
              } catch (error) {
                // todo: log error
              }
              attrElem = <DEditor cellCfg={cellCfg} options={editorOptions} vFData={vFData} editMode={this.state.editMode} odm={this.odm} oe={this.oe} />
            }
            break
          case 'SYS.AECFG.ODT.RA':
            attrElem = <RaEditor cellCfg={cellCfg} vFData={vFData} editMode={this.state.editMode} odm={this.odm} oe={this.oe} />
            break
          case 'SYS.AECFG.ODT.A':
            attrElem = <AttrEditor cellCfg={cellCfg} vFData={vFData} editMode={this.state.editMode} odm={this.odm} oe={this.oe} />
            break
        }
      }

      if (!this.callOERenderAtt) {
        const label = (rAtt && this.oe.getDescription(rAtt)) || "...";
        return (<React.Fragment>
          <label htmlFor={this.id}>{label}</label>
          <div>
            {attrElem}
            {!this.props.readonly && !this.state.lastFormEditMode && <div
              className={classNames(styles.editAction, {[styles.activeIcon]: this.state.editMode})}
              onClick={() => this.toggleEditMode()}
              onKeyDown={() => this.toggleEditMode()}
            ><EditOutlined /></div>}
          </div>
        </React.Fragment>);
      }
    }
    return <div></div>;
  }
};

const mapDispatchToProps = dispatch => ({
  actions: Object.assign(
    {},
    bindActionCreators(WindowManagementActions, dispatch)
  )
});

export default connect(
  null,
  mapDispatchToProps
)(ViewFormCell)
