import React, { useEffect, useRef, useState } from 'react';
import { Input } from 'antd';

export const TableInput = React.memo(({initialValue, onChange, editMode, onEditorPaste}) => {

  const [value, setValue] = useState(initialValue)
  const ref = useRef()

  const onInputChange = e => {
    setValue(e.target.value)
  }

  useEffect(() => {
    if (initialValue !== value) {
      setValue(initialValue)
    }
    ref.current.focus()
  }, [initialValue])

  // We'll only update the external data when the input is blurred
  const onBlur = (e) => {
    if (initialValue !== value) {
      onChange(value)
    }
  }

  const onPaste = (e) => {
    if ( !(e.clipboardData && e.clipboardData.items) ) {
      return
    }

    const values = Array.from(e.clipboardData.items).filter(item => {
      return item.type === 'text/plain'
    })
    const dataItem = values[0]
    if (!dataItem) {
      return
    }

    dataItem.getAsString((str) => {
      const cells = str.split('\r\n').map((rowStr) => rowStr.split('\t'))
      // setValue(value) // paste data should be handle by paste function
      setTimeout(() => {
        onEditorPaste(cells)
      })
    })

  }

  if (editMode) {
    return (
      <input ref={ref} value={value !== undefined ? value : ''} onChange={onInputChange} onBlur={onBlur} onPaste={onPaste} className="table-cell-edit"/>
    )
  }

  return (<div>{value}</div>)
})
