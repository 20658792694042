import React from 'react'
import styles from './left-pane.module.less'
import OE from 'oe'
import { ReactComponent as IconClose } from '../../../assets/icons/close.svg'

import { Menu, Tabs } from 'antd';
import classNames from 'classnames'
import {
  AreaChartOutlined, ClusterOutlined, CodeOutlined,
  EllipsisOutlined, FileAddOutlined, FolderOpenOutlined,
  SisternodeOutlined, SubnodeOutlined,
} from '@ant-design/icons';

const MIN_MENU_WIDTH = 150
const MAX_MENU_WIDTH = 500

let draggingHandler = () => {}
let dragEndHandler = () => {}

class AppSideMenu extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      resizing: false,
      menuWidth: 250,
      positions: {
        x0: 0,
        delta: 0
      },
      windows: props.windowState.windows || [],
      openedKeys: {}
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (props.windowState.windows !== state.windows) {
      return {
        windows: props.windowState.windows || [],
      };
    }

    return {}
  }

  componentDidMount () {
    draggingHandler = this.handleResizerDragging.bind(this)
    dragEndHandler = this.handleResizerDragend.bind(this)

    document.body.addEventListener('mousemove', draggingHandler, false)
    document.body.addEventListener('mouseup', dragEndHandler, false)
  }

  componentWillUnmount () {
    document.body.removeEventListener('mousemove', draggingHandler, false)
    document.body.removeEventListener('mouseup', dragEndHandler, false)
  }

  handleResizerDragstart = (e) => {
    e.preventDefault()
    e.persist()
    this.setState({ resizing: true })
    this.setState(state => {
      return {
        positions: {
          x0: e.clientX,
          delta: 0
        }
      }
    })
  }

  handleResizerDragging = (e) => {
    if (this.state.resizing) {
      e.preventDefault()
      this.setState(state => {
        const deltaX = e.clientX - this.state.positions.x0
        let newDelta

        if (state.menuWidth + deltaX <= MIN_MENU_WIDTH) {
          newDelta = MIN_MENU_WIDTH - state.menuWidth
        } else if (state.menuWidth + deltaX >= MAX_MENU_WIDTH) {
          newDelta = MAX_MENU_WIDTH - state.menuWidth
        } else {
          newDelta = deltaX
        }

        return {
          positions: {
            x0: state.positions.x0,
            delta: newDelta
          }
        }
      })
    }
  }

  handleResizerDragend = (e) => {
    if (this.state.resizing) {
      e.preventDefault()
      const deltaX = e.clientX - this.state.positions.x0
      this.setState(state => {
        let newWidth
        if (state.menuWidth + deltaX <= MIN_MENU_WIDTH) {
          newWidth = MIN_MENU_WIDTH
        } else if (state.menuWidth + deltaX >= MAX_MENU_WIDTH) {
          newWidth = MAX_MENU_WIDTH
        } else {
          newWidth = state.menuWidth + deltaX
        }

        this.props.setSidebarWidth(newWidth)
        return { menuWidth: newWidth }
      })
      this.setState({ resizing: false })
    }
  }

  handleTabChange = (key) => {
    console.log(key)
  }

  handleListItemClick = (oEObject) => {
    this.props.actions.addWindow({
      type: 'ObjectBrowser',
      props: {
        title: 'Object Browser - ' + OE.getDescription(oEObject) + ' ['+oEObject.cod+']',
        visible: true,
        odmObject: oEObject
      }
    });
  }

  handleViewItemClick (windowId) {
    this.props.actions.setWindowVisible(windowId, true)
  }

  handleViewItemCloseClick (ev, windowId) {
    ev.stopPropagation()
    this.props.actions.removeWindow(windowId)
  }

  openUserForm (libCode, formCode, windowTitle) {
    this.props.actions.addWindow({
      type: 'UserFormDialog',
      props: {
        title: windowTitle,
        visible: true,
        libCode: libCode,
        formCode: formCode
      }
    });
  }

  onNewClick(e, container) {
    e.stopPropagation()
    e.preventDefault()
    switch(container) {
        case 'rootProjectsContainer':
          this.openUserForm('SYS_FORMS', 'INPUT_FORM1', 'New Root Project')
          break
        case 'rootLibrariesContainer':
          this.openUserForm('SYS_FORMS', 'INPUT_FORM0', 'New Root Library')
          break
        case 'baseLibrariesContainer':
          this.openUserForm('SYS_FORMS', 'INPUT_FORM2', 'New Library')
          break
        case 'baseProjectsContainer':
          this.openUserForm('SYS_FORMS', 'INPUT_FORM3', 'New Project')
          break
    }
  }

  onOpenClick(e) {
    e.stopPropagation()
    e.preventDefault()
    console.log('on Open click')
  }

  getSubmenuActions = (key) => {
    return (
      <div className={styles.rightActions}>
        <span><FileAddOutlined onClick={(e) => this.onNewClick(e, key)} title="New"/></span>
        <span><FolderOpenOutlined onClick={(e) => this.onOpenClick(e)} title="Open"/></span>
        <span className="anticon"><i className={classNames('ant-menu-submenu-arrow', styles.submenuArrow)}></i></span>
      </div>
    )
  }

  onOpenChange(keys) {
    const newOpenedKeysMap = {}
    keys.forEach(item => newOpenedKeysMap[item] = true)
    this.setState({
      openedKeys: newOpenedKeysMap
    })
  }


  render () {
    const windows = this.state.windows
    const objectBrowserWindows = []
    const viewJsonWindows = []
    const ODMReportWindows = []
    const rootDatasetWindows = []
    const datasetWindows = []
    const othersWindows = []
    windows.forEach(item => {
      if (item.type === 'ObjectBrowser') {
        return objectBrowserWindows.push(item)
      }
      if (item.type === 'ViewJson') {
        return viewJsonWindows.push(item)
      }
      if (item.type === 'ODMReport') {
       return  ODMReportWindows.push(item)
      }

      if (item.type === 'ViewForm') {
        const vfObject = item.props.vFObject
        if (vfObject.typ.endsWith('rd')) {
         return rootDatasetWindows.push(item)
        }

        if (vfObject.typ.endsWith('d')) {
          return datasetWindows.push(item)
        }
      }

      return othersWindows.push(item)
    })


    const categoryWindows = [
      {data: objectBrowserWindows, title: 'Object Explorer', icon: (<ClusterOutlined />)},
      {data: viewJsonWindows, title: 'JSON Code', icon: (<CodeOutlined />)},
      {data: ODMReportWindows, title: 'Object Report', icon: (<AreaChartOutlined />)},
      {data: rootDatasetWindows, title: 'Root Datasets', icon: (<SubnodeOutlined />)},
      {data: datasetWindows, title: 'Datasets', icon: (<SisternodeOutlined />)},
      {data: othersWindows, title: 'Others', icon: (<EllipsisOutlined />)},
    ].filter((item) => {
      return item.data.length > 0
    })

    return <div className={ `${ styles.appSideMenu } ${ !this.props.showLeftPane ? styles.appSideMenuHide : '' }` }
      style={{ width: this.state.menuWidth }}
    >
      <div className={ `app-side-menu ${styles.appSideMenu_body}` }>
        <Menu
          defaultSelectedKeys={['1']}
          mode="inline"
          theme="dark"
          selectable={ false }
          onOpenChange={(keys) => this.onOpenChange(keys)}
        >
          <Menu.SubMenu key="projectsContainer" icon={<span className="anticon"><i className="iconfont icon-cube"></i></span>} title="Projects"
            className={ `appSideMenu_submenu-root` }
            data-role="app-menu-submenu"
          >
            <Menu.SubMenu title="Root Projects" key="rootProjectsContainer"
                          className="custom-submenu-title"
                          expandIcon={this.getSubmenuActions('rootProjectsContainer')}
            >
              {this.props.rootProjects.map(rootProject => {
                return (
                  <Menu.Item key={ 'rootProjects' + rootProject.id }
                             onClick={(e) => this.handleListItemClick(rootProject, e)}>
                    { rootProject.cod }
                  </Menu.Item>
                )
              })}
            </Menu.SubMenu>
            <Menu.SubMenu title="Projects" key="baseProjectsContainer"
                          expandIcon={this.getSubmenuActions('baseProjectsContainer')}
            >
              {this.props.projects.map(prj => {
                return (
                  <Menu.Item key={ 'project' + prj.id } onClick={(e) => this.handleListItemClick(prj, e)}>
                    { prj.cod || prj.id}
                  </Menu.Item>
                )
              })}
            </Menu.SubMenu>
          </Menu.SubMenu>
          <Menu.SubMenu key="librariesContainer" icon={<span className="anticon"><i className="iconfont icon-library"></i></span>} title="Libraries"
            className={ `appSideMenu_submenu-root` }
          >
            <Menu.SubMenu title="Root Libraries" key="rootLibrariesContainer"
                          expandIcon={this.getSubmenuActions('rootLibrariesContainer')}
            >
              {this.props.rootLibraries.map(lib => {
                return (
                  <Menu.Item key={ 'rootLibraries' + lib.id }  onClick={(e) => this.handleListItemClick(lib, e)}>
                    { lib.cod || lib.id }
                  </Menu.Item>
                )
              })}
            </Menu.SubMenu>
            <Menu.SubMenu title="Libraries" key="baseLibrariesContainer"
                          expandIcon={this.getSubmenuActions('baseLibrariesContainer')}
            >
              {this.props.libraries.map(lib => {
                return (
                  <Menu.Item key={ 'libraries' + lib.id } onClick={(e) => this.handleListItemClick(lib, e)}>
                    { lib.cod || lib.id}
                  </Menu.Item>
                )
              })}
            </Menu.SubMenu>
          </Menu.SubMenu>
          <Menu.SubMenu key="windows" icon={<span className="anticon"><i className="iconfont icon-windows"></i></span>} title="Windows"
            className={ `appSideMenu_submenu-root` }
          >
            {categoryWindows.map(item => {
              return (
                <Menu.SubMenu title={item.title} key={window + item.title} icon={item.icon}>
                    {item.data.map(win => {
                      return (
                        <Menu.Item key={ win.windowId } onClick={ ev => { this.handleViewItemClick(win.windowId) } } title={win.props.title}>
                          <div className={styles.windowItem}>
                            <div className={styles.windowName}>{ win.props.title }</div>
                            <span className={ styles.appSideMenu_viewItem_inner_close }
                                  onClick={ ev => { this.handleViewItemCloseClick(ev, win.windowId) } }
                                  onKeyDown={ ev => { this.handleViewItemCloseClick(ev, win.windowId) } }
                            >
                              <IconClose></IconClose>
                            </span>
                          </div>
                        </Menu.Item>
                      )
                    })}
                </Menu.SubMenu>
              )
            })}
          </Menu.SubMenu>
        </Menu>
      </div>

      <div className={ styles.appSideMenu_resizer }
        onMouseDown={ this.handleResizerDragstart }
      >
        <div className={ styles.appSideMenu_resizerFake }
          style={{
            left: this.state.positions.delta,
            display: this.state.resizing ? 'block' : 'none'
          }}
        ></div>
      </div>
    </div>
  }
}

export default AppSideMenu
