import React from 'react'
import styles from './border-select.module.less'
import { Select } from 'antd'

const Option = Select.Option

const SUPPORTED_BORDER_STYLES = [
  'solid',
  'dashed',
  'dotted',
  'double',
  'groove',
  'ridge',
  'inset',
  'outset'
]

class BorderSelect extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      value: ''
    }
  }

  handleValueChange (payload) {
    this.setState({ value: payload }, () => {
      this.props.handleChange(this.state.value)
    })
  }

  componentDidMount () {
    this.setState({ value: this.props.value })
  }

  render () {
    return (
      <Select defaultValue=""
        className={ styles.BorderSelect }
        style={{ width: 200 }}
        dropdownStyle={{ zIndex: 1000000000 }}
        value={this.state.value}
        onChange={ this.handleValueChange.bind(this) }
      >
        {
          SUPPORTED_BORDER_STYLES.map(style => (
            <Option value={ style }
              key={ style }
            >
              <div className={ styles.BorderSelect_option }
                style={{  borderStyle: style  }}
              ></div>
            </Option>
          ))
        }
      </Select>
    )
  }
}

export default BorderSelect
