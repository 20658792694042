import React from 'react'
import {Dropdown, Menu} from "antd";
class AsMenu extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { treeData, onVisibleChange, onClickMenuItem } = this.props
        const asMenu = (
            <Menu forceSubMenuRender={false}>
                <Menu.Item onClick ={(e) => {e.domEvent.nativeEvent.noTreeSelect = true;onClickMenuItem(treeData,'refresh', e);}} >
                    Refresh
                </Menu.Item>
                <Menu.Item onClick ={(e) => {e.domEvent.nativeEvent.noTreeSelect = true;onClickMenuItem(treeData,'new', e);}} >
                    New - Todo: Sub-menus
                </Menu.Item>
            </Menu>
        );
        return (<Dropdown overlay={asMenu} onVisibleChange={onVisibleChange} >
            <a className="ant-dropdown-link">
                {treeData.title}
            </a>
        </Dropdown>);
    }
}

export default AsMenu;
