import React from 'react'
import { Checkbox } from 'antd';
import ABase from './a-base';

class ACheckboxGroup extends ABase {
  constructor(props) {
    super(props);
    this.setOptions('SYS.ATT.H5.INP.CBX', 'H5.value', 'INP_LBL')

    const value = this.state.value
    const groupVal = (value && !Array.isArray(value)) ? [value.toString()] : value
    this.state = {
      value: groupVal,
      editMode: props.editMode || false
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (props.editMode !== state.editMode) {
      return {
        editMode: props.editMode
      };
    }

    return {}
  }

  onChange = (value) => {
    this.onAttChange(value)
  }

  render() {
    const values = this.oe.castToType(this.state.value, "TEXT", true);
    if (this.state.editMode) {
      return (
        <Checkbox.Group
          value={values}
          options={this.options}
          onChange={this.onChange}
        />
      )
    } else {
      const label = typeof this.state.value === "boolean" ?  (this.state.value? "True" : "False") :values.map(item => {
          const target = this.options.find(o => o.value === item)
          return target ? target.label : ''
        }).join(',')
      return (<div>{label}</div>)
    }

  }
}

export default ACheckboxGroup
