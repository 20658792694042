import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as WindowManagementActions from '../../../stores/window-management/actions'
import Dialog from '../../shared/dialog/dialog';
import { getDialogTitle } from '../utils';
import { SearchOutlined, SwapOutlined } from '@ant-design/icons';

class SearchDialog extends React.Component {
  constructor(props) {
    super(props);
    const dialogTitle = getDialogTitle(
      <SearchOutlined />,
      this.props.title
    )
    this.props.actions.setWindowTitle(this.props.windowId, dialogTitle);
  }

  handleClose() {
    this.props.actions.removeWindow(this.props.windowId);
  }

  handleMinimize() {
    this.props.actions.setWindowVisible(this.props.windowId, false);
  }

  render() {

    return (
      <Dialog
        title={ this.props.title }
        windowId={this.props.windowId}
        visible={ this.props.visible }
        submitButton={ false }
        cancelButton={ false }
        hasBazel={ false }
        onClose={ this.handleClose.bind(this) }
        onMinimize={ this.handleMinimize.bind(this) }
        windowId={ this.props.windowId }
      >
        <h1>Search</h1>
      </Dialog>
    )
  }
}

const mapDispatchToProps = dispatch => ({
  actions: Object.assign(
    {},
    bindActionCreators(WindowManagementActions, dispatch)
  )
});

export default connect(
  null,
  mapDispatchToProps
)(SearchDialog)
