import React from 'react';
import { cloneDeep, startCase } from 'lodash';
import styles from './table-design.module.less';
import {
  formattedLabel,
  getInputComponentViaProperty,
  getOptionsByKey,
  groupedProperties, groupedPropertiesAndFilter,
  PROPERTIES_CATEGORIES,
} from '../../utils';
import { getSupportedUnits } from '../utils';
import { TABLE_STYLES_CAN_ADJUST_LIST } from '../../../../core/constant';

class TableDesign extends React.PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      sourceView: props.view,
      view: cloneDeep(props.view),
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { view, activeRowIndex, activeCellIndex } = nextProps
    const changes = { activeRowIndex, activeCellIndex }
    if (view !== prevState.sourceView) {
      // formData changed
      changes.view = cloneDeep(view)
      changes.sourceView = view
    }

    return changes
  }

  setTableProperties (key, value) {
    let changes = { [key]: value }
    if (key === 'width') {
      changes[key] = value ? value : '100%'
    }
    this.state.view.styles = {...this.state.view.styles, ...changes}
    this.perSetState({
      view: {...this.state.view}
    })
  }

  perSetState(newState) {
    this.setState(newState)
    this.props.handleStateChange(newState, 'table')
  }

  render() {
    const items = groupedPropertiesAndFilter(TABLE_STYLES_CAN_ADJUST_LIST)
      .map(item => {
        const {category, keys} = item

        return (
          <div className={styles.formCategoryWrapper}
               key={category}
          >
            <div className={styles.formSubHeader}>{startCase(category)}</div>
            {
              keys.map(key => {
                const InputComponent = getInputComponentViaProperty(key)
                return (
                  <div className={styles.formRow}
                       key={key}
                  >
                    <label className={ styles.formLabel }>{formattedLabel(key)}</label>
                    <InputComponent
                      className={ styles.formControl }
                      value={this.state.view.styles[key] || ''}
                      handleChange={ payload => this.setTableProperties.bind(this)(key, payload) }
                      supportedUnits={ getSupportedUnits(key) }
                      options={ getOptionsByKey(key) }
                    />
                  </div>
                )
              })
            }
          </div>
        )
      })

    return (
      <div className={styles.viewForm}>
        {items}
      </div>
    )
  }
}

export default TableDesign
