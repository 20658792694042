import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as WindowManagementActions from '../../../stores/window-management/actions'

import Dialog from '../../shared/dialog/dialog'
import { getDialogTitle, getViewFormTypeTitle } from '../utils';
import RootDatasetConfig from './root-dataset/root-dataset';
import ODM from 'odm'
import OE from 'oe'
import * as OdataTreeActions from '../../../stores/odata-tree/odata-tree-actions';
import { notification } from 'antd';
import { svgStrToImg } from '../../../utils/utils';

class RDConfigDialog extends React.Component {
  constructor (props) {
    super(props)
    this.isCreate = props.action === 'new'

    let dialogTitle;
    const rdObject = this.props.rdObject;

    if(this.isCreate) {
      if(rdObject) {
        const titlePrefix = "xnd" === this.props.relType.toLowerCase() ? 'Create Root-Dataset Extension' : 'Create Sub Root-Dataset';
        dialogTitle = titlePrefix + ' - ' + getViewFormTypeTitle(rdObject);
      }
    } else {
      dialogTitle = 'Modify Root Dataset - ' + getViewFormTypeTitle(rdObject);
    }
    dialogTitle = getDialogTitle(
      svgStrToImg(OE.getSVGIconCodeForRD(rdObject, false)),
      dialogTitle
    )
    this.props.actions.setWindowTitle(this.props.windowId, dialogTitle);
  }

  handleClose () {
    this.props.actions.removeWindow(this.props.windowId)
  }

  handleSubmit(value) {
    if (this.isCreate) {
      OE.createSubRD(value.cod, value.dsc, this.props.rdObject, this.props.relType, (fSuccess, fNewRel, fNewRD) => {
        if(fSuccess === true) {
          this.props.actions.updateODataTreeAction()
        } else {
          const titlePrefix = "xnd" === this.props.relType.toLowerCase() ? 'Create Root-Dataset Extension' : 'Create Sub Root-Dataset';
          notification.error({
            message: `${titlePrefix} failed`
          });
        }
      });
    } else {
      const rD  = ODM.getRDNoLoad(this.props.rdObject.id);
      OE.setDescription(rD, value.dsc);
      rD.cod = value.cod;
      ODM.updateRD(rD, rD.id);
      this.props.actions.updateODataTreeAction()
    }
    this.props.actions.removeWindow(this.props.windowId)
  }

  handleMinimize () {
    this.props.actions.setWindowVisible(this.props.windowId, false)
  }

  render () {


    return (
      <Dialog
        title={ this.props.title }
        windowId={this.props.windowId}
        visible={ this.props.visible }
        onClose={ this.handleClose.bind(this) }
        noContentPadding={ true }
        onOpen={ (this.props.onOpen && this.props.onOpen.bind(this))}
        onMinimize={ this.handleMinimize.bind(this) }
      >
        <RootDatasetConfig
          {...this.props}
          isCreate={this.isCreate}
          onCancel={ this.handleClose.bind(this) }
          onSubmit={ this.handleSubmit.bind(this) }
        ></RootDatasetConfig>
      </Dialog>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  actions: Object.assign(
    {},
    bindActionCreators(WindowManagementActions, dispatch),
    bindActionCreators(OdataTreeActions, dispatch),
  )
})

export default connect(
  null,
  mapDispatchToProps
)(RDConfigDialog)
