import React from 'react'
import {Dropdown, Menu} from "antd";
import ODM from 'odm'
import OE from 'oe'
import { bindActionCreators } from 'redux';
import * as OdataTreeActions from '../../../stores/odata-tree/odata-tree-actions';
import { connect } from 'react-redux';
const { SubMenu } = Menu;

class DSubsMenu extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          isRDSubsLoading: true
        }
        const d = (props.treeData.odmId || props.treeData.odmId === 0) && ODM.getDNoLoadNoExtd(props.treeData.odmId)
        const rD = d && ODM.getRDNoLoadNoExtd(d.rid)
        const tmp = OE.getRDSubRels(rD, {});
        const newRels = Object.keys(tmp).map((relId) => tmp[relId]);
        const unloadedRDIds = newRels
          .filter(item => item.tid && !ODM.getRDNoLoadNoExtd(item.tid))
          .map(item => item.tid)
        props.loadRD2RDList(unloadedRDIds).then(() => {
          const rels = Object.keys(tmp).map((relId) => tmp[relId]);
          const subRelMenuData = rels
            .map(item => item.tid && {rd: ODM.getRDNoLoadNoExtd(item.tid), rel: item})
            .filter(item => item.rd)
          if(!this.isUnmounted) {
            this.setState({
              isRDSubsLoading: false,
              subRelMenuData: subRelMenuData
            })
          }
        })
    }

    componentWillUnmount() {
      this.isUnmounted = true;
    }

    onMenuItemClick(e, item, d) {
      e.domEvent.nativeEvent.noTreeSelect = true;
      OE.createSubDForRdsubRel(d, item.rel, false);
      this.props.actions.updateODataTreeAction()
    }

    render() {
        const { treeData, onVisibleChange, onClickMenuItem } = this.props
        const d = ODM.getDNoLoadNoExtd(treeData.odmId)
        const rD = d && ODM.getRDNoLoadNoExtd(d.rid)
        const tmp = OE.getRDSubRels(rD, {});
        const newRels = Object.keys(tmp).map((relId) => tmp[relId]);
        const unloadedRDIds = newRels
          .filter(item => item.tid && !ODM.getRDNoLoadNoExtd(item.tid))
          .map(item => item.tid)

        const dsubsMenu = (
            <Menu forceSubMenuRender={false}>
                <Menu.Item onClick ={(e) => {e.domEvent.nativeEvent.noTreeSelect = true; onClickMenuItem(treeData,'refresh', e);}} >
                    Refresh
                </Menu.Item>
                { this.state.isRDSubsLoading ? <Menu.Item onClick ={(e) => {e.domEvent.nativeEvent.noTreeSelect = true; onClickMenuItem(treeData,'new', e);}} >
                      Loading sub-list...
                  </Menu.Item> :
                  <SubMenu
                    key="new-subds"
                    title="New Subset"
                  >
                  { this.state.subRelMenuData.map((item, index) =>
                    <Menu.Item key={"new-subd"+index} onClick ={(e) => this.onMenuItemClick(e, item, d)} >
                        {OE.getDescription(item.rd)}
                    </Menu.Item>
                  )}
                  </SubMenu>
                }
            </Menu>
        );
        return (<Dropdown overlay={dsubsMenu} onVisibleChange={onVisibleChange} >
            <a className="ant-dropdown-link">
                {treeData.title}
            </a>
        </Dropdown>);
    }
}


const mapDispatchToProps = dispatch => ({
  actions: Object.assign(
    {},
    bindActionCreators(OdataTreeActions, dispatch),
  )
});

export default connect(
  null,
  mapDispatchToProps
)(DSubsMenu)
