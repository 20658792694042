import React from 'react';
import MarkButton from './mark-button';
import BlockButton from './block-button';
import PopupActionButton from './popup-action-button';


const SlateToolbar = (props) => {

  return (
    <div className="editor-toolbar">
      <div className="icon-btn-group">
        <MarkButton format="bold" icon="icon-bold" />
        <MarkButton format="italic" icon="icon-italic" />
        <MarkButton format="strikethrough" icon="icon-strikethrough" />
        <MarkButton format="underline" icon="icon-underlined" />
      </div>

      <div className="icon-btn-group">
        <BlockButton format="heading-one" icon="icon-h1" />
        <BlockButton format="heading-two" icon="icon-h2" />
      </div>
      <MarkButton format="code" icon="icon-code" />

      <BlockButton format="block-quote" icon="icon-quote" />
      <BlockButton format="numbered-list" icon="icon-list-numbered" />
      <BlockButton format="bulleted-list" icon="icon-list-bulleted" />
      <PopupActionButton format="table" icon="icon-table"></PopupActionButton>
    </div>
  )
}

export default SlateToolbar
