import { DEFAULT_COLUMN_WIDTH } from '../../../core/constant';
import { getColumnStyles } from '../utils';

export function getSupportedUnits(key) {
  switch (key) {
    case 'borderWidth':
    case 'borderTopWidth':
    case 'borderRightWidth':
    case 'borderBottomWidth':
    case 'borderLeftWidth':
      return ['px', 'pt', '%', 'em', 'ex', 'cm', 'mm', 'in', 'pc', 'ch', 'rem', 'vh', 'vw', 'vmin', 'vmax']
    default:
      return undefined
  }
}

export const getColumnStylesWithDefaultWidth = (col) => {
  const oStyles = getColumnStyles(col)
  oStyles['width'] = oStyles['width'] || `${DEFAULT_COLUMN_WIDTH}px`
  return oStyles
}
