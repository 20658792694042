import React from 'react'
import styles from './view-form-preview.module.less'
import { getCellColspan, getCellRowspan, getCellStyles, getColumnStyles } from '../../window-management/utils';

export default class ViewFormPreview extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      view: props.view
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { view } = nextProps
    if (view !== prevState.view) {
      return { view: view }
    }

    return {}
  }

  render() {
    return (
      <div className={ styles.ViewFormPreview }>
        <table className={ styles.ViewFormPreview_table }
          style={this.state.view.styles}
        >
          <colgroup>
            {
              this.state.view.columns.map(col => (
                <col
                  key={ col.id }
                  style={ getColumnStyles(col) }
                />
              ))
            }
          </colgroup>
          <tbody>
            {
              this.state.view.rows.map((row, rowIndex) => (
                <tr
                  key={ row.id }
                  style={ row.styles }
                >
                  {
                    row.cells.map((cell, cellIndex) => (
                    <td
                      key={ cell.id }
                      style={ getCellStyles(cell) }
                      colSpan={ getCellColspan(cell) }
                      rowSpan={ getCellRowspan(cell) }
                    >{ cell.content || `cell-${rowIndex}-${cellIndex}` }</td>
                    ))
                  }
                </tr>
              ))
            }
          </tbody>
        </table>
      </div>
    )
  }
}
