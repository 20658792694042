import React from 'react';
import { bindActionCreators } from 'redux';
import * as WindowManagementActions from '../../stores/window-management/actions';
import { connect } from 'react-redux';
import { Button, Form, Input, Modal } from 'antd';
import styles from './auth-dialog.module.less';
import classNames from 'classnames';
import LoginForm from './login/login';
import ForgotPasswordForm from './forgot-password/forgot-password';

class AuthDialog extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isAuth: false,
      showLoginForm: false,
      mode: 'login'
    }

    this.dialogTitle = 'Auth'
  }

  showLogin() {
    this.setState({
      showLoginForm: true
    })
  }

  handleCancel = () => {
    this.setState({
      showLoginForm: false
    })
  }

  toForgot() {
    this.setState({
      mode: 'forgot'
    })
  }

  toLogin() {
    this.setState({
      mode: 'login'
    })
  }

  render() {

    const modeContent = this.state.mode === 'login' ? <LoginForm /> : <ForgotPasswordForm />

    return (
      <div className={classNames({
        [styles.loginAction]: true,
        [this.props.className]: true
      })}>
        <a className={styles.loginText} onClick={() => this.showLogin()} onKeyDown={() => this.showLogin()}>Login</a>
        <Modal
          visible={this.state.showLoginForm}
          title="Auth"
          onCancel={this.handleCancel}
          footer={null}
          width={480}
          bodyStyle={{padding: '20px 40px'}}
          maskClosable={false}
        >
          {modeContent}
          <div className={styles.bottomLinks}>
            {
              this.state.mode === 'login'
                ? (<a onClick={() => this.toForgot()} onKeyDown={() => this.toForgot()}>Forgot Password?</a>) :
                (<a onClick={() => this.toLogin()} onKeyDown={() => this.toLogin()}>or Login?</a>)
            }

          </div>
        </Modal>
      </div>
    )
  }
}

const mapDispatchToProps = dispatch => ({
  actions: Object.assign(
    {},
    bindActionCreators(WindowManagementActions, dispatch)
  )
});

export default connect(
  null,
  mapDispatchToProps
)(AuthDialog)
