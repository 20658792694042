import React from 'react'
import { bindActionCreators } from 'redux';
import * as OdataTreeActions from '../../../stores/odata-tree/odata-tree-actions';
import { connect } from 'react-redux';


class ODrag extends React.Component {
  constructor(props) {
    super(props);
  }

  onDrop = (e) => {
    const { onDrop } = this.props
    const dragNode = this.props.odataTree.dragNode
    if (!dragNode) {
      console.warn('this component can only accept odata-tree node')
      return
    }
    if (onDrop) {
      console.log(dragNode.dataRef)
      onDrop({event: e, dragNode: dragNode})
    }
    this.props.actions.nodeDragEndAction()
  }

  onDragOver = (e) => {
    if (this.props.doCheck) {
      e.persist()
      const dragNode = this.props.odataTree.dragNode
      const isPass = this.props.doCheck(dragNode)
      Promise.resolve(isPass).then(isPassValue => {
        if (!isPassValue) {
          e.dataTransfer.dropEffect = 'none'
        }
      })
    }
    e.dataTransfer.dropEffect = 'copy'
    e.preventDefault()
    e.stopPropagation()
  }

  render() {
    const { children } = this.props
    return <div
      className="o-drag-container"
      onDrop={this.onDrop}
      onDragOver={this.onDragOver}
    >
      {children}
    </div>
  }
}


const mapStateToProps = state => ({
  odataTree: state.odataTree
})

const mapDispatchToProps = dispatch => ({
  actions: Object.assign(
    {},
    bindActionCreators(OdataTreeActions, dispatch)
  )
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ODrag)
