import React, { useState } from 'react';
import styles from './table-builder.module.less'
import classNames from 'classnames';



const TableBuilder = (props) => {
  const [rowNum, setRowNum] = useState(4)
  const [colNum, setColNum] = useState(5)
  const [hoverSelector, setHoverSelector] = useState(true)

  function onContainerClick(e) {
    e.preventDefault()
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
  }

  function setActiveCell(e, rowIndex, colIndex) {
    if (!hoverSelector) {
      return
    }
    setRowNum(rowIndex + 1)
    setColNum(colIndex + 1)
  }

  function setSelect(e, rowIndex, colIndex) {
    setHoverSelector(false)
    setRowNum(rowIndex + 1)
    setColNum(colIndex + 1)
    if (props.onSelect) {
      props.onSelect({row: rowIndex + 1, col: colIndex + 1})
    }
  }


  const builderContent = new Array(10).fill(0).map((item, rowIndex) => {
    return (
      <div className={classNames(styles.builderRow)}>
        {
          new Array(10).fill(0).map((col, colIndex) => {
            return (
              <div
                className={classNames(styles.builderCell, {[styles.active]: colIndex < colNum && rowIndex < rowNum})}
                onKeyDown={(e) => setSelect(e, rowIndex, colIndex)}
                onClick={(e) => setSelect(e, rowIndex, colIndex)}
                onFocus={(e) => setActiveCell(e, rowIndex, colIndex)}
                onMouseOver={(e) => setActiveCell(e, rowIndex, colIndex)}
              ></div>
            )
          })
        }
      </div>
    )
  })

  return (
    <div
      className={styles.builderContainer}
      onClick={onContainerClick}
      onKeyDown={onContainerClick}
    >
      <div className={styles.header}>{rowNum} x {colNum} Table</div>
      {builderContent}
    </div>
  )
}

export default TableBuilder
