import React, { createRef } from 'react';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as WindowManagementActions from '../../../stores/window-management/actions'
import Dialog from '../../shared/dialog/dialog'
import {  getViewFormTypeTitle } from '../utils';
import { getViewTableByVFObj, ODMHelper, ViewForm } from 'orbit-components';
import OE from 'oe'
import ODM from 'odm'

class AddChildDialog extends React.Component {
  constructor (props) {
    super(props)
    const {parentD, rd2rd} = props
    let dialogTitle = 'Create child - ' + getViewFormTypeTitle(rd2rd);
    this.props.actions.setWindowTitle(this.props.windowId, dialogTitle);
    this.code = this.props.vfCode || 'DEFAULT'

    // if the d2d is single, this function may return undefined
    this.d2d = OE.newSubDForRdsubRel(parentD, rd2rd)
    if (this.d2d) {
      const d = ODM.getDNoLoad(this.d2d.tid)
      const view = getViewTableByVFObj(d, this.code)
      this.state = {view}
    }
    this.vfRef = createRef()

  }

  onRefresh = () => {
    const d = ODM.getDNoLoad(this.d2d.tid)
    const view = getViewTableByVFObj(d, this.code)
    this.setState({
      view
    })
  }

  createChild = (view, cell) => {
    const {parentD, rd2rd} = cell.tempView
    ODMHelper.createSubD(parentD, rd2rd)

    setTimeout(() => {
      this.onRefresh()
    })
  }

  onCancel () {
    if (this.d2d) {
      ODM.deleteD2DRel(this.d2d, this.d2d.id)
    }
    this.props.actions.removeWindow(this.props.windowId)
  }

  onFormSubmit = (changes) => {
    ODMHelper.vfChangesToOdm(changes, this.d2d)
  }

  handleSubmit() {
    if (this.vfRef.current) {
      this.vfRef.current.submitChanges()
    }
    const d = this.props.parentD
    d.trl.push(this.d2d.id)
    if(d.srl.indexOf(this.d2d.id) === -1) {
      d.srl.push(this.d2d.id);
      ODM.updateDNoSave(d, d.id)
    }
    if (this.props.onSubmit) {
      this.props.onSubmit()
    }
    this.props.actions.removeWindow(this.props.windowId)
  }

  handleMinimize () {
    this.props.actions.setWindowVisible(this.props.windowId, false)
  }


  render () {

    return (
      <Dialog
        submitButton={true}
        cancelButton={true}
        title={ this.props.title }
        windowId={this.props.windowId}
        onClose={ this.onCancel.bind(this) }
        onCancel={this.onCancel.bind(this)}
        onSubmit={this.handleSubmit.bind(this)}
        noContentPadding={ true }
        onMinimize={ this.handleMinimize.bind(this) }
      >
        {this.state?.view && <ViewForm
          view={this.state.view}
          formBridge={{
            onCreate: this.createChild,
            onSubmit: this.onFormSubmit
          }}
          ref={this.vfRef}
          isEdit={true}
          noActions={true}
        />}

      </Dialog>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  actions: Object.assign(
    {},
    bindActionCreators(WindowManagementActions, dispatch)
  )
})

export default connect(
  null,
  mapDispatchToProps
)(AddChildDialog)
