import Highlighter from "react-highlight-words";
import React from "react";


export function wrapperHighlighter(text, keyword) {
  return <Highlighter
    caseSensitive={false}
    highlightClassName="highlighter-words"
    searchWords={[keyword]}
    autoEscape={true}
    textToHighlight={text || ''}
  />
}

export function resolveTableData(type, data) {

  const tableData = []
  switch (type) {
    case 'rd':
    case 'd':
    case 'ra':
    case 'rd2rd':
    case 'rd2d':
    case 'd2d': {
      for(let fI in data) {
        if(data.hasOwnProperty(fI)) {
          if (!(fI.substring(0, 1) === 'n')) {
            const item = data[fI];
            tableData.push(item);
          }
        }
      }

      return tableData
    }
    case 'a': {
      for(let fI in data) {
        if(data.hasOwnProperty(fI)) {
          const atts1 = data[fI];
          for(let fI1 in atts1) {
            if(atts1.hasOwnProperty(fI1)) {
              if (!(fI1.substring(0, 1) === 'n')) {
                const att = atts1[fI1];
                tableData.push(att);
              }
            }
          }
        }
      }

      return tableData
    }
  }
}
