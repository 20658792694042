import React from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as WindowManagementActions from '../../../../stores/window-management/actions';
import styles from './inline-drag-design.module.less'
import {
  getCellColspan,
  getCellRowspan,
  getCellStyles, getColumnStyles,
  getRowStyles,
} from '../../utils';
import { cloneDeep } from 'lodash';
import CellDragDesign from "./cell-design";
import ODM from 'odm'
import { getDefaultVFCodeFormRD } from '../../../../core/utils';

class InlineDragDesign extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      view: props.view,
      dragging: false,
      draggingPos: {
        y0: 0,
        height: 0
      },
      previewHeight: '100px',
      editingColumnIndex: 0
    };

    this.containerRef = React.createRef()
  }

  handleClose () {
    this.props.actions.removeWindow(this.props.windowId)
  }

  localGetRowStyles(row) {
    let retVal = getRowStyles(row)
    retVal.height = '25px'
    return retVal
  }

  localGetCellStyles(cell) {
    let retVal = getCellStyles(cell)
    retVal.height = 'inherit'
    return retVal
  }

  /**
   * OwnerRDId: Id of the root-dataset to which the cell belongs to
   * OwnerVFCode: Code of the view-form to which this cell belong to
   * TabIndex: Set this to 1 for now.  I will explain the purpose of this in due time…
   * RowIndex: Row index of the cell (suggest that we start at 1, instead of 0)
   * CellIndex: Cell index of the cell (suggest that we start at 1, instead of 0)
   * RAId: Root attribute ID (when dragging a root-attribute into the cell, this attribute will be changed)
   * RADisplayCfgD: Leave this blank for now.  I will explain the purpose in due time…
   * Any other props which Wei thinks will be needed to properly display the form in configuration mode (such as rowspan, colspan, etc).
   * There will be more props that I will ask to add in due time…
   */
  buildDropChangeData(dragNode, cellPosition) {
    return {
      ownerRDId: this.props.ownerRDId,
      ownerVFCode: this.props.vfCode,
      tabIndex: 1,
      rowIndex: cellPosition.rowIndex + 1,
      cellIndex: cellPosition.colIndex + 1,
      rAID: dragNode.dataRef.odmId,
      rADisplayCfgD: null, // TODO: Leave this blank for now.  I will explain the purpose in due time…
    }
  }

  /**
   * when table design dialog submit, dialog will get the submit value from this function.
   * there are muti-forms, so will return a array of formData
   * @returns formData[]
   */
  getFormData() {
    // in case onFormDataChange exists, changes to the form are passed on to the parent component via onFormDataChange
    return this.props.onFormDataChange ? this.props.view : this.state.view
  }

  onRemoveCfgDId = (cell) => {
    this.getFormData().rows.map((row, rowIndex) => {
      row.cells.map((cell1, colIndex) => {
        if (cell1.id === cell.id) {
          let newView = cloneDeep(this.getFormData())
          newView.rows[rowIndex].cells[colIndex].cellCfg.d = undefined
          if(this.props.onFormDataChange) {
            this.props.onFormDataChange(newView)
          } else {
            const newState = {...this.state, view: newView}
            this.setState(newState)
          }
        }
      })
    })
  }

  render() {
    return (
      <div className={ styles.viewForm_container }
           ref={ this.containerRef }
      >
        <div className="table-container">
          <table className={ styles.ViewFormTableView }
                 style={ this.state.view.styles }
          >
            <colgroup>
              {
                this.state.view.columns.map(col => (
                  <col
                    key={ col.id }
                    style={ getColumnStyles(col) }
                  />
                ))
              }
            </colgroup>
            <tbody>
            {
              this.state.view.rows.map((row, rowIndex) => (
                <tr
                  key={ rowIndex }
                  style={ this.localGetRowStyles(row) }
                >
                  {
                    row.cells.map((cell, colIndex) => (
                      <td
                        key={ colIndex }
                        style={ this.localGetCellStyles(cell) }
                        colSpan={ getCellColspan(cell) }
                        rowSpan={ getCellRowspan(cell) }
                      >
                        <CellDragDesign cell={cell} onDrop={this.onCellDrop.bind(this)}
                                        rDataset={this.props.rDataset}
                                        onSetCfgDId={this.onSetCfgDId.bind(this)}
                                        onSetVFCode={this.onSetVFCode.bind(this)}
                                        onRemoveCfgDId={(cell) => this.onRemoveCfgDId(cell)}
                        ></CellDragDesign>
                      </td>
                    ))
                  }
                </tr>
              ))
            }
            </tbody>
          </table>
        </div>
      </div>
    )
  }

  onSetCfgDId = (cfgDId, cell) => {
    this.getFormData().rows.map((row, rowIndex) => {
      row.cells.map((cell1, colIndex) => {
        if (cell1.id === cell.id) {
          let view = cloneDeep(this.getFormData())
          view.rows[rowIndex].cells[colIndex].cellCfg.d = {id: cfgDId}
          if(this.props.onFormDataChange) {
            this.props.onFormDataChange(view)
          } else {
            const newState = {...this.state, view: view}
            this.setState(newState)
          }
        }
      })
    })
  }

  onSetVFCode = (vFCode, cell) => {
    this.getFormData().rows.map((row, rowIndex) => {
      row.cells.map((cell1, colIndex) => {
        if (cell1.id === cell.id) {
          let view = cloneDeep(this.getFormData())
          view.rows[rowIndex].cells[colIndex].tempObj.code = vFCode
          if(this.props.onFormDataChange) {
            this.props.onFormDataChange(view)
          } else {
            const newState = {...this.state, view: view}
            this.setState(newState)
          }
        }
      })
    })
  }

  onCellDrop = (dragNode, cell) => {
    this.getFormData().rows.map((row, rowIndex) => {
      row.cells.map((cell1, colIndex) => {
        if (cell1.id === cell.id) {
          const nodeData = dragNode.dataRef
          let view = cloneDeep(this.getFormData())
          const cell = view.rows[rowIndex].cells[colIndex]

          switch (nodeData && nodeData.nodeType) {
            case 'ra':
              cell.tempObj = {id: nodeData.odmId, type: 'ra'}
              cell.cellCfg = {}
              if(this.props.onFormDataChange) {
                this.props.onFormDataChange(view)
              } else {
                const newState = {...this.state, view: view}
                this.setState(newState)
              }
              break
            case 'rvw':
            case 'rd2rd':
              const rd2rdRel = nodeData.nodeType === 'rd2rd' && ODM.getRD2RDRelNoLoad(nodeData.odmId)
              const tRd = (rd2rdRel && ODM.getRDNoLoad(rd2rdRel.tid)) || (nodeData.nodeType === 'rd' && ODM.getRDNoLoad(nodeData.odmId))
              if (rd2rdRel && cell.tempObj?.id !== rd2rdRel.id) {
                cell.tempObj = {id: rd2rdRel.id, type: 'vf'}
              } else {
                cell.tempObj = {type: 'vf'}
              }
              const gcode = nodeData.nodeType === 'rvw' ? nodeData.formCode : getDefaultVFCodeFormRD(tRd)
              cell.tempObj.code = gcode
              if(this.props.onFormDataChange) {
                this.props.onFormDataChange(view)
              } else {
                const newState = {...this.state, view: view}
                this.setState(newState)
              }
              break
          }
        }
      })
    })
  }
}

const mapStateToProps = state => ({
});

const mapDispatchToProps = dispatch => ({
  actions: Object.assign(
    {},
    bindActionCreators(WindowManagementActions, dispatch)
  )
});


export default connect(
  mapStateToProps,
  mapDispatchToProps,
  null,
  {forwardRef: true}
)(InlineDragDesign)
