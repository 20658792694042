import React from 'react';
import { cloneDeep, startCase } from 'lodash';
import styles from './col-design.module.less';
import { Button } from 'antd';
import { formattedLabel, getInputComponentViaProperty, groupedProperties, PROPERTIES_CATEGORIES } from '../../utils';
import { getSupportedUnits } from '../utils';
import { COLUMN_STYLES_CAN_ADJUST_LIST } from '../../../../core/constant';

class ColDesign extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      sourceView: props.view,
      view: cloneDeep(props.view),
      editingColumnIndex: props.editingColumnIndex
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { view, activeRowIndex, activeCellIndex } = nextProps
    const changes = { activeRowIndex, activeCellIndex }
    if (view !== prevState.sourceView) {
      // formData changed
      changes.view = cloneDeep(view)
      changes.sourceView = view
    }

    return changes
  }

  setCurColumnProperties (key, value) {
    this.state.view.columns[this.state.editingColumnIndex].styles[key] = value
    this.perSetState({
      view: cloneDeep(this.state.view)
    })
  }

  handleColumnSelect (index) {
    this.perSetState({
      editingColumnIndex: index
    })
  }

  perSetState(newState) {
    this.setState(newState)
    this.props.handleStateChange(newState)
  }

  renderDesign() {
    const curColumn = this.state.view.columns[this.state.editingColumnIndex];
    const curColumnProps = curColumn && curColumn.styles || {}

    return PROPERTIES_CATEGORIES.map(category => {
      const keys = groupedProperties(category)
      const cateKeys = keys.filter(item => COLUMN_STYLES_CAN_ADJUST_LIST.includes(item))

      return {name: category, keys: cateKeys}
    }).filter((item) => item.keys.length > 0)
      .map(item => {
        const {name: category, keys} = item

        return (
          <div className={styles.formCategoryWrapper} key={category}>
            <div className={styles.formSubHeader}>{startCase(category)}</div>
            {
              keys.map(key => {
                const InputComponent = getInputComponentViaProperty(key)

                return (
                  <div className={styles.formRow} key={key + curColumn.id} >
                    <label className={ styles.formLabel }>{formattedLabel(key)}</label>
                    <InputComponent
                      className={ styles.formControl }
                      value={curColumnProps[key] || ''}
                      handleChange={ payload => this.setCurColumnProperties.bind(this)(key, payload) }
                      supportedUnits={ getSupportedUnits(key) }
                    />
                  </div>
                )
              })
            }
          </div>
        )
      })
  }

  render() {
    const viewForm = (
      <div className={styles.viewForm}>
        <div className={ styles.Columns_select_container }>
          {
            this.state.view.columns.map((col, idx) => (
              <Button
                className={ `${styles.Columns_select_item} ${ idx === this.state.editingColumnIndex ? styles.Columns_select_item_active : '' }` }
                key={ col.id }
                onClick={ () => { this.handleColumnSelect.bind(this)(idx) } }
                size="small"
              >{ `col ${idx + 1}` }</Button>
            ))
          }
        </div>
        <div className={ styles.Columns_design_container }>

        {this.renderDesign()}
        </div>
      </div>
    )

    return viewForm
  }
}

export default ColDesign
