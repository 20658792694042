import React from 'react';
import { Button, Form, Input } from 'antd';
import styles from './forgot-password.module.less';

class ForgotPasswordForm extends React.Component {
  constructor(props) {
    super(props);
  }

  onFinish = () => {

  }

  onFinishFailed = () => {

  }

  render() {
    const layout = {
      labelCol: { span: 5 },
      wrapperCol: { span: 24 },
    };

    const forgotForm = (
      <Form
        {...layout}
        layout={'vertical'}
        name="forgotForm"
        initialValues={{ remember: true }}
        onFinish={this.onFinish}
        onFinishFailed={this.onFinishFailed}
      >
        <Form.Item
          label="Email"
          name="email"
          rules={[
            { required: true, message: 'Please input your email!' },
            { type: 'email', message: 'Email is not a valid email!' }
          ]}
        >
          <Input />
        </Form.Item>


        <Form.Item wrapperCol= {{offset: 0, span: 24} } className={styles.forgotButtonWrapper}>
          <Button type="primary" htmlType="submit" className={styles.forgotButton}>
            Send me the link
          </Button>
        </Form.Item>
      </Form>
    )

    return forgotForm
  }
}

export default ForgotPasswordForm
