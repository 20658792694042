import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import App from '../App'

import * as RootProjectsActions from '../stores/actions/root-projects-actions'
import * as ProjectsActions from '../stores/actions/projects-actions'
import * as RootLibrariesActions from '../stores/actions/root-libraries-actions'
import * as LibrariesActions from '../stores/actions/libraries-actions'
import * as WindowManagementActions from '../stores/window-management/actions'
import * as RootStatesActions from '../stores/actions/root-states-actions'
import windowState from '../stores/window-management/reducer';

const mapStateToProps = state => ({
  windowState: state.windowState,
  rootStates: state.rootStates
})

const mapDispatchToProps = dispatch => ({
  actions: Object.assign(
    {},
    bindActionCreators(ProjectsActions, dispatch),
    bindActionCreators(RootProjectsActions, dispatch),
    bindActionCreators(RootLibrariesActions, dispatch),
    bindActionCreators(LibrariesActions, dispatch),
    bindActionCreators(WindowManagementActions, dispatch),
    bindActionCreators(RootStatesActions, dispatch)
  )
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(App)
