import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as WindowManagementActions from '../../../stores/window-management/actions'

import Dialog from '../../shared/dialog/dialog'
import { getDialogTitle, getViewFormTypeTitle } from '../utils';
import AttributeConfig from './attribute/attribute';
import ODM from 'odm'
import OE from 'oe'
import * as OdataTreeActions from '../../../stores/odata-tree/odata-tree-actions';
import { ReactComponent as AttributeIcon } from '../../../assets/icons/attribute.svg'

class AttributeConfigDialog extends React.Component {
  constructor (props) {
    super(props)
    this.isCreate = props.action === 'new'
    const raObject = this.props.raObject;
    const rdObject = this.props.rdObject;
    let dialogTitle;
    if(this.isCreate) {
      if(rdObject) {
        dialogTitle = 'Create Root Attribute - ' + getViewFormTypeTitle(rdObject);
      }
    } else {
      if(raObject) {
        dialogTitle = 'Modify - ' + getViewFormTypeTitle(raObject);
      }
    }
    this.title = getDialogTitle(
      <AttributeIcon style={{verticalAlign: '-.125em', height:'1.1em', width:'1.1em', color: '#7f0000'}}/>,
      dialogTitle
    )
  }

  componentDidMount () {
    this.props.actions.setWindowTitle(this.props.windowId, this.title);
  }

  handleClose () {
    this.props.actions.removeWindow(this.props.windowId)
  }

  handleSubmit(value) {
    const newDsc = value.dsc
    delete value.dsc
    const raObject = this.props.raObject
    const rdObject = this.props.rdObject
    if (this.isCreate) {
      // do create
      OE.createRAtt(rdObject.id, value.cod, value.dtp, newDsc, (...arg) => {
        if (value.dimension) {
          let createdRA = arg[1]
          const curDim = ODM.getDimensionByCode(value.dimension);
          if(curDim) {
            createdRA.ddim = curDim.id;
            ODM.updateRAtt(createdRA, createdRA.id);
          }
        }
        this.props.actions.updateODataTreeAction()
      })
    } else {
      const oldDsc = raObject.dsc[0]
      const newDscObj = {...oldDsc, txt: newDsc}
      const newRAObj = {...raObject, ...value, dsc: [newDscObj]}
      if (value.dimension) {
        const curDim = ODM.getDimensionByCode(value.dimension);
        if(curDim) {
          newRAObj.ddim = curDim.id;
        }
      }
      ODM.updateRAtt(newRAObj, raObject.id)
      this.props.actions.updateODataTreeAction()
    }
    this.handleClose()
  }

  handleMinimize () {
    this.props.actions.setWindowVisible(this.props.windowId, false)
  }

  render () {

    return (
      <Dialog
        title={ this.props.title }
        windowId={this.props.windowId}
        visible={ this.props.visible }
        submitButton={ null }
        cancelButton={ null }
        noContentPadding={ true }
        onClose={ this.handleClose.bind(this) }

        onMinimize={ this.handleMinimize.bind(this) }
      >
        <AttributeConfig {...this.props}
                         isCreate={this.isCreate}
                         onCancel={ this.handleClose.bind(this) }
                         onSubmit={ this.handleSubmit.bind(this) }
        ></AttributeConfig>
      </Dialog>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  actions: Object.assign(
    {},
    bindActionCreators(WindowManagementActions, dispatch),
    bindActionCreators(OdataTreeActions, dispatch),
  )
})

export default connect(
  null,
  mapDispatchToProps
)(AttributeConfigDialog)
