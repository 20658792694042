import React from 'react'
import ODrag from '../../o-drag/o-drag'
import styles from './editors.module.less'
import classNames from 'classnames'
import { CloseOutlined } from '@ant-design/icons';


class DropEditor extends React.Component {

  doCheck(node) {
    return this.props.doCheck(node)
  }

  onDrop(e) {
    this.props.onDrop(e)
  }

  onRemove() {
    this.props.onRemove()
  }

  render() {
    return <ODrag
      onDrop={(e) => this.onDrop(e)}
      doCheck={(node) => this.doCheck(node)}
    >
      <div className={styles.dropEditor}>
        <div className={classNames(styles.close, {[styles.hide]: this.props.isEmpty})}
             onClick={() => this.onRemove()} onKeyDown={() => this.onRemove()}><CloseOutlined/></div>
        {this.props.children}
      </div>

    </ODrag>
  }
}

export default DropEditor
