import React, { useEffect, useRef, useState } from 'react';
import { Table, Input, Button, Modal } from 'antd';
import styles from './editors.module.less'

import ABase from './a-base'
import { bindActionCreators } from 'redux';
import * as WindowManagementActions from '../../../../stores/window-management/actions';
import { connect } from 'react-redux';
import { ReactComponent as DeleteIcon } from '../../../../assets/icons/delete.svg'
import { EditOutlined } from '@ant-design/icons';
const EditableContext = React.createContext(null);
const EditableCell = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false)
  const [cellValue, setCellValue] = useState(record[dataIndex])
  const inputRef = useRef(null)

  const changeCellValue = (newValue) => {
    setCellValue(newValue)
  }

  useEffect(() => {
    if (editing) {
      inputRef.current.focus()
    }
  }, [editing])

  const toggleEdit = () => {
    setEditing(!editing)
  };

  const save = async () => {
    try {
      console.log(cellValue)
      toggleEdit()
      handleSave({ ...record, [dataIndex]: cellValue })
    } catch (errInfo) {
      console.log('Save failed:', errInfo)
    }
  };

  let childNode = children

  if (editable) {
    childNode = editing ? (
      <Input ref={inputRef}
             onPressEnter={save}
             onBlur={save}
             defaultValue={cellValue}
             onChange={(e) => changeCellValue(e.target.value)}
      />
    ) : (
      <div className="editable-cell-value-wrap" style={{ paddingRight: 24 }} onClick={toggleEdit} onKeyDown={toggleEdit}>
        {children}
      </div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};

class ASpreadsheetEditor extends ABase {
  constructor(props) {
    super(props);
    this.columns = [
      {
        title: (d) => {
          return (
            <div className={styles.editColTitle}>
              <span>Title</span>
              <div className={styles.titleActions}>
                <EditOutlined className={styles.action} onClick={() => this.toConfigColumn('name')}/>
                <DeleteIcon className={styles.action} onClick={() => this.toDelete('name')}/>
              </div>
            </div>
          )
        },
        titleText: 'Title',
        editable: true,
        dataIndex: 'name',
      },
    ]
    this.state.showConfigModal = false
    this.state.value = [
      {name: 'test'}
    ]
  }

  static getDerivedStateFromProps(props, state) {
    if (props.editMode !== state.editMode) {
      return {
        editMode: props.editMode
      };
    }

    return {}
  }

  onChange = (value) => {
    this.onAttChange(value)
  }

  handleSave = (...args) => {
    console.log(args)
  }

  onSaveColumn(colData) {
    this.handleCancel()
  }

  handleCancel = () => {

  }

  toAddColumn = () => {
    const { rDataset } = this.props.cellCfg
    this.props.actions.addWindow({
      type: 'AttributeConfigDialog',
      props: {
        title: 'ViewForm',
        visible: true,
        rdObject: rDataset,
        action: 'new',
        prefixCode: this.rAtt.cod
      }
    });
  }

  toConfigColumn = (dataIndex) => {
    const { rDataset } = this.props.cellCfg
    // todo: get raObject by dataIndex
    // this.props.actions.addWindow({
    //   type: 'AttributeConfigDialog',
    //   props: {
    //     title: 'ViewForm',
    //     visible: true,
    //     raObject: raObject,
    //     rdObject: rDataset,
    //     action: 'modify'
    //   }
    // });
  }

  toDelete = () => {

  }

  render() {
    const components = {
      body: {
        cell: EditableCell,
      },
    };
    const columns = this.columns.map(col => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: (record) => ({
          record,
          editable: col.editable && this.state.editMode,
          dataIndex: col.dataIndex,
          title: col.title,
          handleSave: this.handleSave,
        }),
        title: this.state.editMode ? col.title : col.titleText
      };
    });
    return (
      <React.Fragment>

        {this.state.editMode && <div className={styles.actionContainer}>
          <Button className={styles.action} onClick={this.toAddColumn}>Add Column</Button>
        </div>}
        <Table
          className="mt-5"
          size="small"
          columns={columns}
          pagination={false}
          components={components}
          dataSource={this.state.value}
        />
      </React.Fragment>
    )
  }
}


const mapDispatchToProps = dispatch => ({
  actions: Object.assign(
    {},
    bindActionCreators(WindowManagementActions, dispatch)
  )
});

export default connect(
  null,
  mapDispatchToProps
)(ASpreadsheetEditor)
