import React from 'react'
import { Input } from 'antd';

export default function DefaultColumnFilter({column: { filterValue, preFilteredRows, setFilter } }) {
  const count = preFilteredRows.length

  return (
    <Input
      value={filterValue || ''}
      className="search-input"
      onChange={e => {
        setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
      }}
      placeholder={`Search ${count} records...`}
    />
  )
}
