import React from 'react'
import {Dropdown, Menu} from "antd";
class VWMenu extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { treeData, onVisibleChange, onClickMenuItem } = this.props
        const vwMenu = (
            <Menu forceSubMenuRender={false}>
                <Menu.Item key="open" onClick ={(e) => {e.domEvent.nativeEvent.noTreeSelect = true; onClickMenuItem(treeData,'open', e);}} >
                    Open
                </Menu.Item>
                <Menu.Item key="refresh" onClick ={(e) => {e.domEvent.nativeEvent.noTreeSelect = true; onClickMenuItem(treeData,'refresh', e);}} >
                    Refresh
                </Menu.Item>
            </Menu>
        );
        return (<Dropdown overlay={vwMenu} onVisibleChange={onVisibleChange} >
            <a className="ant-dropdown-link">
                {treeData.title}
            </a>
        </Dropdown>);
    }
}

export default VWMenu;
