import React, {
  useState,
  useEffect,
  useLayoutEffect,
  useRef
} from 'react'
import styles from './dialog.module.less'

import { ReactComponent as IconClose } from '../../../assets/icons/close.svg'
import { ReactComponent as IconMaximize } from '../../../assets/icons/maximize.svg'
import { ReactComponent as IconNormalize } from '../../../assets/icons/normalize.svg'
import { ReactComponent as IconMinimize } from '../../../assets/icons/minimize.svg'

import { Button } from 'antd'
import { useSelector } from 'react-redux';


let curZIndex = 1000

const Dialog = (props) => {
  const {
    title,
    submitButton = false,
    submitText = 'submit',
    cancelButton = false,
    cancelText = 'cancel',
    hasBazel = false,
    clickBazelToClose = false,
    visible = true,
    defaultWidth = 400,
    defaultHeight = 300,
    onOpen = () => {},
    onClose = () => {},
    onMaximize = () => {},
    onMinimize = () => {},
    onNormalize = () => {},
    onSubmit = () => {},
    onCancel = () => {}
  } = props

  const randomX = Math.round(200 + 100 * Math.random())
  const randomY = Math.round(50 + 100 * Math.random())
  const [positionX, setPositionX] = useState(randomX)
  const [positionY, setPositionY] = useState(randomY)
  const [positionX0, setPositionX0] = useState(0)
  const [positionY0, setPositionY0] = useState(0)
  const [mouseX0, setMouseX0] = useState(0)
  const [mouseY0, setMouseY0] = useState(0)
  const [dragging, setDragging] = useState(false)
  const [resizing, setResizing] = useState(['', '']) // resizing side: ['top/bottom', 'left/right']
  const [width, setWidth] = useState(defaultWidth)
  const [height, setHeight] = useState(defaultHeight)
  const [width0, setWidth0] = useState(0)
  const [height0, setHeight0] = useState(0)
  const [cursor, setCursor] = useState('default')
  const [lastActiveWindowId, setLastActiveWindowId] = useState('') // only active once

  const [zIndex, setZIndex] = useState(curZIndex++)
  const [isMaximize, setIsMaximize] = useState(false)
  const windowState = useSelector(state => state.windowState)
  if (windowState.activeWindowId === props.windowId && lastActiveWindowId !== windowState.activeWindowId) {
    setZIndex(curZIndex++)
  }
  if (windowState.activeWindowId && windowState.activeWindowId !== lastActiveWindowId) {
    setLastActiveWindowId(windowState.activeWindowId)
  }
  useEffect(() => {
    document.body.addEventListener('mousemove', handleDragMove, false)
    document.body.addEventListener('mouseup', handleDragEnd, false)

    return () => {
      document.body.removeEventListener('mousemove', handleDragMove, false)
      document.body.removeEventListener('mouseup', handleDragEnd, false)
    }
  })

  useLayoutEffect(() => {
    handleOpen()
  }, [])

  useEffect(() => {
    if (visible) {
      setZIndex(curZIndex++)
    }
  }, [visible])

  function handleOpen () {
    onOpen()
  }

  function handleClose () {
    onClose()
  }

  function handleMinimizeClick () {
    onMinimize()
  }

  function handleDragStart (e) {
    e.persist()
    const newDirections = getResizingDirections(e)
    const isCursorInToolbar = getIsCursorInToolbar(e)
    if (newDirections.join('') !== '') {
      setPositionX0(positionX)
      setPositionY0(positionY)
      setWidth0(width)
      setHeight0(height)
      setMouseX0(e.clientX)
      setMouseY0(e.clientY)
      setResizing(newDirections)
    } else if (isCursorInToolbar) {
      setPositionX0(positionX)
      setPositionY0(positionY)
      setMouseX0(e.clientX)
      setMouseY0(e.clientY)
      setDragging(true)
      e.preventDefault()
      e.stopPropagation()
    }
    setZIndex(curZIndex++)
  }

  function handleDragMove (e) {
    if (resizing.join('') !== '') {
      let deltaX = e.clientX - mouseX0
      let deltaY = e.clientY - mouseY0
      if (resizing[0] === 'top') {
        setPositionY(positionY0 + deltaY)
        setHeight(height0 - deltaY)
      } else if (resizing[0] === 'bottom') {
        setHeight(height0 + deltaY)
      }

      if (resizing[1] === 'left') {
        setPositionX(positionX0 + deltaX)
        setWidth(width0 - deltaX)
      } else if (resizing[1] === 'right') {
        setWidth(width0 + deltaX)
      }
      e.preventDefault()
    } else if (dragging) {
      const deltaX = e.clientX - mouseX0
      const deltaY = e.clientY - mouseY0
      setPositionX(positionX0 + deltaX)
      setPositionY(positionY0 + deltaY)
      e.preventDefault()
    }
  }

  function handleDragEnd (e) {
    if (resizing.join('') !== '') {
      setPositionX0(0)
      setPositionY0(0)
      setWidth0(0)
      setHeight0(0)
      setMouseX0(0)
      setMouseY0(0)
      setResizing(['', ''])
      e.preventDefault()
    } else if (dragging) {
      setPositionX0(0)
      setPositionY0(0)
      setMouseX0(0)
      setMouseY0(0)
      setDragging(false)
      e.preventDefault()
    }
  }

  function handleDialogClick (e) {
    e.stopPropagation()
    setZIndex(curZIndex++)
  }

  function handleMaximizeClick () {
    setIsMaximize(true)
    onMaximize()
  }

  function handleNormalizeClick () {
    setIsMaximize(false)
    onNormalize()
  }

  function handleSubmit () {
    onSubmit()
  }

  function handleCancel () {
    onCancel()
  }

  function handleClickBazel () {
    if (clickBazelToClose) {
      onClose()
    }
  }

  function toggleWindowSize () {
    setIsMaximize(!isMaximize)
  }

  // window resize functionality
  const windowElement = useRef(null)
  const toolbarElement = useRef(null)
  const OFFSET = 6

  function getResizingDirections (e) {
    const windowRect = windowElement.current.getBoundingClientRect()
    const resizingDirections = ['', '']

    if (Math.abs(windowRect.top - e.clientY) <= OFFSET) {
      resizingDirections[0] = 'top'
    } else if (Math.abs(e.clientY - windowRect.bottom) <= OFFSET) {
      resizingDirections[0] = 'bottom'
    } else {
      resizingDirections[0] = ''
    }

    if (Math.abs(e.clientX - windowRect.left) <= OFFSET) {
      resizingDirections[1] = 'left'
    } else if (Math.abs(windowRect.right - e.clientX) <= OFFSET) {
      resizingDirections[1] = 'right'
    } else {
      resizingDirections[1] = ''
    }

    return resizingDirections
  }

  function getIsCursorInToolbar (e) {
    const toolbarRect = toolbarElement.current.getBoundingClientRect()
    return e.clientY > toolbarRect.top &&
    e.clientY < toolbarRect.bottom &&
    e.clientX > toolbarRect.left &&
    e.clientX < toolbarRect.right
  }

  function handleMouseMoveOverWindow (e) {
    const isCursorInToolbar = getIsCursorInToolbar(e)
    const resizingDirections = getResizingDirections(e).join('')
    if (['topleft', 'bottomright'].indexOf(resizingDirections) >= 0) {
      setCursor('nwse-resize')
    } else if (['topright', 'bottomleft'].indexOf(resizingDirections) >= 0) {
      setCursor('nesw-resize')
    } else if (['left', 'right'].indexOf(resizingDirections) >= 0) {
      setCursor('ew-resize')
    } else if (['top', 'bottom'].indexOf(resizingDirections) >= 0) {
      setCursor('ns-resize')
    } else if (isCursorInToolbar) {
      setCursor('grab')
    } else {
      setCursor('default')
    }
  }

  if (hasBazel) {
    return (
      <div className={ styles.OrbitWindow_wrapper }
        style={{
          zIndex
        }}
        onClick={ handleClickBazel }
        onKeyDown={ handleClickBazel }
      >
        <div className={ `${styles.OrbitWindow} ${isMaximize ? styles.OrbitWindow_maximize : '' } ${visible ? '' : styles.OrbitWindow_hide }` }
          style={{
            left: positionX,
            top: positionY,
            width: width,
            height: height,
            cursor
          }}
          onClick={ handleDialogClick }
          onKeyDown={ handleDialogClick }
          onMouseMove={ handleMouseMoveOverWindow }
          onMouseDown={ handleDragStart }
          ref={ windowElement }
        >
          <div className={ styles.OrbitWindow_toolbar }
            onMouseDown={ handleDragStart }
            ref={ toolbarElement }
          >
            <span className={ styles.OrbitWindow_title }>
              { title }
            </span>
            <span className={ styles.OrbitWindow_space }
              onDoubleClick={ toggleWindowSize }
            ></span>
            <span className={ styles.OrbitWindow_toolbar_buttons }>
              <button className={ styles.OrbitWindow_toolbar_button }
                onClick={ handleMinimizeClick }
              >
                <IconMinimize></IconMinimize>
              </button>

              {
                isMaximize ? (
                  <button className={ styles.OrbitWindow_toolbar_button }
                    onClick={ handleNormalizeClick }
                  >
                    <IconNormalize></IconNormalize>
                  </button>
                ) : (
                  <button className={ styles.OrbitWindow_toolbar_button }
                    onClick={ handleMaximizeClick }
                  >
                    <IconMaximize></IconMaximize>
                  </button>
                )
              }

              <button className={ styles.OrbitWindow_toolbar_button }
                onClick={ handleClose }
              >
                <IconClose></IconClose>
              </button>
            </span>
          </div>

          <div className={ props.noContentPadding ? styles.OrbitWindow_contentNoPadding : styles.OrbitWindow_content }>
            { props.children }
          </div>

          <div className={ styles.OrbitWindow_footer }>
            <span className={ styles.OrbitWindow_footer_space }></span>
            <span className={ styles.OrbitWindow_footer_buttons }>
              { cancelButton &&
                <Button type="default"
                  className={ styles.OrbitWindow_footer_cancel_button  }
                  onClick={ handleCancel }
                >{ cancelText }</Button>
              }

              { submitButton &&
                <Button type="primary"
                  className={ styles.OrbitWindow_footer_submit_button  }
                  onClick={ handleSubmit }
                >{ submitText }</Button>
              }
            </span>
          </div>
        </div>
      </div>
    )
  } else {
    return (
      <div className={ `${styles.OrbitWindow} ${isMaximize ? styles.OrbitWindow_maximize : '' } ${visible ? '' : styles.OrbitWindow_hide }` }
        style={{
          left: positionX,
          top: positionY,
          width: width,
          height: height,
          zIndex,
          cursor
        }}
        onClick={ handleDialogClick }
        onKeyDown={ handleDialogClick }
        onMouseMove={ handleMouseMoveOverWindow }
        onMouseDown={ handleDragStart }
        ref={ windowElement }
      >
        <div className={ styles.OrbitWindow_toolbar }
          onMouseDown={ handleDragStart }
          ref={ toolbarElement }
        >
          <span className={ styles.OrbitWindow_title }>
            { title }
          </span>
          <span className={ styles.OrbitWindow_space }
            onDoubleClick={ toggleWindowSize }
          ></span>
          <span className={ styles.OrbitWindow_toolbar_buttons }>
            <button className={ styles.OrbitWindow_toolbar_button }
              onClick={ handleMinimizeClick }
            >
              <IconMinimize></IconMinimize>
            </button>

            {
              isMaximize ? (
                <button className={ styles.OrbitWindow_toolbar_button }
                  onClick={ handleNormalizeClick }
                >
                  <IconNormalize></IconNormalize>
                </button>
              ) : (
                <button className={ styles.OrbitWindow_toolbar_button }
                  onClick={ handleMaximizeClick }
                >
                  <IconMaximize></IconMaximize>
                </button>
              )
            }

            <button className={ styles.OrbitWindow_toolbar_button }
              onClick={ handleClose }
            >
              <IconClose></IconClose>
            </button>
          </span>
        </div>

        <div className={ props.noContentPadding ? styles.OrbitWindow_contentNoPadding : styles.OrbitWindow_content }>
          { props.children }
        </div>

        <div className={ styles.OrbitWindow_footer }>
          <span className={ styles.OrbitWindow_footer_space }></span>
          <span className={ styles.OrbitWindow_footer_buttons }>
            { cancelButton &&
              <Button type="default"
                className={ styles.OrbitWindow_footer_cancel_button  }
                onClick={ handleCancel }
              >{ cancelText }</Button>
            }

            { submitButton &&
              <Button type="primary"
                className={ styles.OrbitWindow_footer_submit_button  }
                onClick={ handleSubmit }
              >{ submitText }</Button>
            }
          </span>
        </div>
      </div>
    )
  }
}

export default Dialog

export {curZIndex}
