export const CHECK = 'check'
export const CROSS = 'cross'
export const PLUS = 'plus'

export const COPY = 'copy'
export const MOVE = 'move'
export const NOT_ALLOWED = 'not-allowed'

const GlobalCursorTypes = [
  CHECK,
  CROSS,
  PLUS,
  COPY,
  MOVE,
  NOT_ALLOWED
];

class CursorService {
  setGlobalCursorTo(type) {
    this.resetGlabalCursor()
    document.body.classList.add(`global-cursor-${type}`)
  }

  resetGlabalCursor() {
    GlobalCursorTypes.forEach(type => {
      document.body.classList.remove(`global-cursor-${type}`)
    })
  }
}

const cursorService = new CursorService()

export default cursorService
