// The events will emitted in GraphRenderer

export const LINE_ADAPTER_MOVING = 'the line adapter moving '

export const LINE_START_END_MOVING = 'the line of start/end control moving'

export const CREATING_NOZZLE_LINE = 'creating nozzle line'

export const ACTIVE_OBJECT_CHANGE = 'activeObject:change'
export const CANCEL_ACTIVE_OBJECT = 'cancelActiveObject:change'
export const OBJECT_DBCLICK = 'Object mousedblclick'
export const PATH_LINE_CONTROL_CLICK = 'Path line control on click'
export const PATH_LINE_NOZZLE_POINT_CONTROL_CLICK = 'Path line nozzle point ctrl on click'

