import OE from 'oe'
import ODM from 'odm'

export function openRACfgD (fRA, actions) {
  let rACfgD = ODM.getDNoLoadNoExtd(fRA.cfg);
  if (!rACfgD) {
    switch (fRA.dtp) {
      case "SPREADSHEET":
      {
        const rACfgRD = ODM.getRDNoLoadByCode('SYS.RA.CFG.GRID');
        rACfgD = OE.createDSByRD(rACfgRD, '', '', true, {ds:[], d2ds:[]});
        rACfgD.cff = fRA.id;
        rACfgD.cft = "ra";
      }
        break;
      default:
      {
        const rACfgRD = ODM.getRDNoLoadByCode('SYS.RA.CFG');
        rACfgD = OE.createDSByRD(rACfgRD, '', '', true, {ds:[], d2ds:[]}, false);
        rACfgD.cff = fRA.id;
        rACfgD.cft = "ra";
      }
        break;
    }
  } else {
    if (!rACfgD.cff) {
      rACfgD.cff = fRA.id;
      rACfgD.cft = "ra";
    }
  }
  actions.addWindow({
    type: 'ViewForm',
    props: {
      title: 'ViewForm',
      visible: true,
      vFObject: rACfgD,
      onSubmit: () => {
        actions.updateODataTreeAction()
      }
    }
  });
}
