import React from 'react'
import styles from './root-dataset.module.less'
import OE from 'oe'
import { Button } from 'antd';
import InputCon from '../../../common/input/input';
import classNames from 'classnames';
import { ReactComponent as VersionIcon } from '../../../../assets/icons/version.svg'
import { svgStrToImg } from '../../../../utils/utils';


class RootDatasetConfig extends React.Component {
  constructor(props) {
    super(props);
    this.rdObject = props.rdObject
    let formVal = {
      cod: '',
      dsc: '',
    }
    if (!props.isCreate) {
      const dsc = (this.rdObject && OE.getDescription(this.rdObject)) || ''
      formVal = {
        cod: this.rdObject.cod,
        dsc: dsc,
      }
    }

    this.state = {
      formVal
    }

  }

  handleFormValueChange(key, value) {
    const formVal = this.state.formVal
    formVal[key] = value
    this.setState({
      formVal: {...formVal}
    })
  }

  handleCancel() {
    this.props.onCancel()
  }

  handleSubmit() {
    this.props.onSubmit(this.state.formVal)

    return false
  }


  renderTopActions() {
    if (this.props.isCreate) {
      return ''
    }

    return (
      <div className={styles.version}>
        <VersionIcon></VersionIcon>
        <div>{this.rdObject.ver}</div>
      </div>
    );
  }

  renderTopRow() {
    const topRow = (
      <div className={styles.TopRow} >
          <span className={styles.iconAndTitle}>
            {svgStrToImg(OE.getSVGIconCodeForRD(this.rdObject, false))}
            &nbsp;{OE.getDescription(this.rdObject)}&nbsp;[{this.rdObject.cod} - {this.rdObject.id}]
          </span>
        {this.renderTopActions()}
      </div>);

    return topRow
  }

  renderForm() {
    const formVal = this.state.formVal
    return (
      <form className="horizontal-form" onSubmit={this.handleSubmit.bind(this)}>
        <div className="form-item">
          <div className="form-item-label w-100">Description:</div>
          <div className="form-item-control">
            <InputCon
              value={formVal.dsc}
              handleChange={val => this.handleFormValueChange('dsc', val)}></InputCon>
          </div>
        </div>
        <div className="form-item">
          <div className="form-item-label w-100">Code:</div>
          <div className="form-item-control">
            <InputCon
              value={formVal.cod}
              handleChange={val => this.handleFormValueChange('cod', val)}></InputCon>
          </div>
        </div>
        <div className="buttons-container"
             className={classNames({
               'buttons-container': true,
               [styles.buttonsContainer]: true
             })}
        >
          <Button type="default" onClick={ this.handleCancel.bind(this) } htmlType="button">Cancel</Button>
          <Button type="primary" htmlType="submit" className="ml-10">Submit</Button>
        </div>
      </form>
    )
  }

  render() {
    return (
      <React.Fragment>
        {!this.props.isCreate && this.rdObject && this.renderTopRow()}
        {this.renderForm()}
      </React.Fragment>
    )
  }
}

export default RootDatasetConfig
