import React from 'react'
import styles from './top-pane.module.less'
import { ReactComponent as IconMenu } from '../../../assets/icons/ui-icons/orbit-menu.svg'
import { ReactComponent as OrbitLogo } from '../../../assets/logo/plant-orbit.svg'
import { ReactComponent as IconWifi } from '../../../assets/icons/ui-icons/orbit-wifi.svg'
import { ReactComponent as IconSetting } from '../../../assets/icons/ui-icons/orbit-setting.svg'

import ODM from 'odm'
import { SearchOutlined } from '@ant-design/icons';
import { bindActionCreators } from 'redux';
import * as WindowManagementActions from '../../../stores/window-management/actions';
import { connect } from 'react-redux';
import AuthDialog from '../../../bussiness/auth/auth-dialog';
import { Link } from 'react-router-dom';
import { AutoComplete } from 'antd';
import classNames from 'classnames'
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';

const { Option } = AutoComplete;

console.log(styles)

class TopNav extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      options: []
    }

    this.search$ = new Subject()
    this.destroy$ = new Subject()
    this.searchText = ''
  }

  componentDidMount() {
    this.search$.pipe(
      takeUntil(this.destroy$),
      debounceTime(500)
    ).subscribe((searchText) => {
      // do search here
      this.setState({
        options: ['aa', 'bb']
      })
    })
  }

  componentWillUnmount() {
    this.destroy$.next()
    this.destroy$.complete()
  }

  handleOnlineClick () {
    this.props.toggleRootOnline()
    ODM.isOffline = !this.props.rootStates.online;
    if (!ODM.isOffline) {
        ODM.initWebsocket();
        ODM.loadProjectList();
        ODM.loadLibraryList();
        ODM.loadDimensions();
    }
  }

  onSearch = (e) => {
    this.searchText = e
    this.search$.next(e)
  }

  onSearchKeyDown = (e) => {
    if (e.key === 'Enter') {
      const searchText = e.target.value
      this.openSearchWindow(searchText)
    }
  }

  onSelect = (e) => {
    this.openSearchWindow(e)
  }

  openSearchWindow(searchText) {
    console.log(searchText)
    this.props.actions.addWindow({
      type: 'SearchDialog',
      props: {
        title: 'Search Window',
        searchText,
        visible: true,
      }
    })
  }

  render () {
    return <div className={ styles.topNav }>
      <Link className={ styles.topNav_logo } to="/"
        style={{ width: `${this.props.rootStates.sidebarWidth}px` }}
      >
        <OrbitLogo></OrbitLogo>
      </Link>
      <div className={ `${styles.topNav_menu_switch} ${this.props.showLeftPane ? styles.topNav_menu_switch_active : ''}` }
        onClick={ this.props.toggleShowLeftPane }
        onKeyDown={ this.props.toggleShowLeftPane }
      >
        <IconMenu></IconMenu>
      </div>
      <div className={ `${styles.topNav_network_switch} ${this.props.rootStates.online ? styles.topNav_network_switch_online : ''}` }
        onClick={ this.handleOnlineClick.bind(this) }
        onKeyDown={ this.handleOnlineClick.bind(this) }
      >
        <IconWifi></IconWifi>
      </div>
      <Link to="/settings">
        <div className={ styles.topNav_settings_button }>
          <IconSetting></IconSetting>
        </div>
      </Link>

      <div className={ styles.topNav_sep }></div>
      <div className={ styles.topNav_tools }>
        <div className={classNames({
          [styles.search]: true

        })}>
          <AutoComplete
            style={{ width: 240 }}
            className="home-search-complete"
            onSelect={this.onSelect}
            onSearch={this.onSearch}
            onKeyDown={this.onSearchKeyDown}
            placeholder="Search"
          >
            {this.state.options.map((op, index) => (
              <Option key={index} value={op}>
                {op}
              </Option>
            ))}
          </AutoComplete>
          <SearchOutlined className={styles.search_icon} onClick={() => this.openSearchWindow(this.searchText)} />
        </div>
        <a className={ styles.topNav_tools_link }
        >Language</a>
        |
        <a className={ styles.topNav_tools_link }
        >Avatar</a>
        |
        <AuthDialog className="ml-5" />
      </div>
    </div>
  }
}

const mapDispatchToProps = dispatch => ({
  actions: Object.assign(
    {},
    bindActionCreators(WindowManagementActions, dispatch)
  )
});

export default connect(
  null,
  mapDispatchToProps
)(TopNav)
