import React from 'react';
import { cloneDeep } from 'lodash';
import { Tabs} from "antd";

import InlineDragDesign from '../inline-drag-design/inline-drag-design';
const TabPane = Tabs.TabPane

class TabDragDesign extends React.PureComponent {

  constructor(props) {
    super(props);

    const {view} = props

    this.state = {
      tabs: view,
      activeTabIndex: '0',
      isAddModalShow: false,
      activeView: view[0]
    };

    this.inlineDesignRef = React.createRef()
  }

  componentDidMount() {
    this.tabChange('0')
  }

  /**
   * when table design dialog submit, dialog will get the submit value from this function.
   * there are muti-forms, so will return a array of formData
   * @returns formData[]
   */
  getFormData() {
    return this.state.tabs
  }

  perSetState(newState) {
    this.setState(newState)
  }

  tabChange(index) {
    const preActiveIndex = this.state.activeTabIndex
    if (this.inlineDesignRef.current) {
      const preTabFormData = this.inlineDesignRef.current.getFormData()
      this.state.tabs[preActiveIndex] = preTabFormData
    }
    index = parseInt(index, 10) // index is string, need a number
    const activeTab = this.state.tabs[index]
    this.setState({
      activeView: activeTab,
      activeTabIndex: index,
    })
  }

  onFormDataChange = (value) => {
//    this.state.tabs[this.state.activeTabIndex].formData = value
    const newTabs = cloneDeep(this.state.tabs)
    newTabs[this.state.activeTabIndex] = value
    this.setState({
      tabs: newTabs,
      activeView: value
    })
  }

  renderDragSetting(view) {
    return (
      <InlineDragDesign view={view}
                        rDataset={this.props.rDataset}
                        ref={this.inlineDesignRef}
                        onFormDataChange={this.onFormDataChange.bind(this)}></InlineDragDesign>
    )
  }

  render() {
    const tabs = this.state.tabs.map((item, index) => {
      return (
        <TabPane tab={item.name} key={index}>
          {index === this.state.activeTabIndex ? this.renderDragSetting(item) : ''}
        </TabPane>
      )
    })

    return (
        <Tabs
          defaultActiveKey="0"
          onChange={this.tabChange.bind(this)}
        >
          {tabs}
        </Tabs>
    )
  }
}

export default TabDragDesign

