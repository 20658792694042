import React, {createRef, memo, useMemo, useState} from 'react'
import { connect } from 'react-redux';
import Dialog from "../../shared/dialog/dialog";
import {
  ODMHelper, FlowEditor, FlowSheetService, getViewTableByVFObj, ViewForm,
  DActions, FSCellTypes
} from "orbit-components";
import {bindActionCreators} from "redux";
import * as WindowManagementActions from '../../../stores/window-management/actions';
import ODM from 'odm'

const NewFlowSheetDialog = memo((props) => {
  const {dataset, title, windowId, actions} = props
  const [odmNode, setOdmNode] = useState()
  const flowRef = createRef()
  const fsService = useMemo(() => {
    return new FlowSheetService()
  }, [])

  const menus = useMemo(() => {
    return fsService.getMenus(21681)
  }, [fsService])

  const fsData = useMemo(() => {
    return {...fsService.getSheetData(dataset), fsDataset: dataset, template: fsService.getConfigData(dataset)}
  }, [fsService])

  const handleClose = () => {
    actions.removeWindow(windowId)
  }

  const handleMinimize = () => {
    actions.setWindowVisible(windowId, false)
  }


  const onDatasetAction = (action) => {
    const {type, payload} = action
    console.log(type, payload)

    switch (type) {
      case DActions.Selected: {
        const id = payload.id
        setOdmNode(ODM.getDNoLoad(id))
        break
      }
      case DActions.Update: {
        const {category, ...options} = payload
        if (category === FSCellTypes.Line) {
          fsService.saveLineToDataset(options)
        }
        if (category === FSCellTypes.Node) {
          fsService.saveNodeToDataset(options)
        }

        break
      }
      case DActions.Delete: {
        fsService.remove(payload, props)
        break
      }

      default:
        break;
    }
  }

  const rightPanelView = useMemo(() => {
    if (odmNode) {
      const vForm = getViewTableByVFObj(odmNode, 'DEFAULT')
      return (
        <div>
          <ViewForm view={vForm}
            formBridge={{
              onRefresh: () => {
                setOdmNode(ODM.getDNoLoad(odmNode.id))
              },
              onSubmit: (changes) => {
                ODMHelper.vfChangesToOdm(changes, odmNode)
                const d = ODM.getDNoLoad(odmNode.id)
                const rd = d && ODM.getRDNoLoadNoExtd(d.rid)
                if (!rd) {
                  return
                }
                if (fsService.isLineD(rd)) {
                  const line = fsService.getFSLineByDid(odmNode.id)
                  flowRef.current?.editorBridge.toUpdateLine(line)
                } else {
                  const node = fsService.getFSNodeByDid(odmNode.id)
                  flowRef.current?.editorBridge.toUpdateNode(node)
                }

              }
            }} noSetting={true}>
          </ViewForm>
        </div>
      )
    }

    return null
  }, [flowRef, fsService, odmNode])

  return (
    <Dialog
      title={ title }
      windowId={windowId}
      onClose={ handleClose }
      onMinimize={ handleMinimize }
      defaultWidth={1000}
      defaultHeight={600}
    >
      <FlowEditor
        menus={menus}
        rightPanel={rightPanelView}
        defaultValue={fsData}
        onDatasetAction={onDatasetAction}
      />
    </Dialog>
  )
})

const mapDispatchToProps = dispatch => ({
  actions: Object.assign(
    {},
    bindActionCreators(WindowManagementActions, dispatch)
  )
})

export default connect(
  null,
  mapDispatchToProps
)(NewFlowSheetDialog)
