import React from 'react';
import OE from 'oe'
import { Input, Table } from 'antd';
import { bindActionCreators } from 'redux';
import * as WindowManagementActions from '../../../stores/window-management/actions';
import { connect } from 'react-redux';
import styles from './odm-report-dialog.module.less'
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import ODM from 'odm'
import { resolveTableData, wrapperHighlighter } from './units';

class AntAttributeTable extends React.Component {
  constructor(props) {
    super(props);
    // <thead><tr><th>Id</th><th>DS Id</th><th>RA Code</th><th>RD Code</th><th>Act</th><th>Rev</th><th>Timestamp</th></tr></thead>
    this.columns = [
      {
        title: 'Id',
        render: (item) => <a
          onClick={ () => { this.handleOpenViewJsonDialog(item) } }
          onKeyDown={ () => { this.handleOpenViewJsonDialog(item) } }
        >{wrapperHighlighter(item.id.toString())}</a>,
      },
      {
        title: 'DS Id',
        render: (d) => {
          return wrapperHighlighter(d.did ? d.did.toString() : '', this.state.searchText)
        }
      },
      {
        title: 'RA Code',
        render: (att) => {
          const rAtt = att && ODM.getRAttNoLoadNoExtd(att.rid);

          return wrapperHighlighter(rAtt.cod || '...', this.state.searchText)
        }
      },
      {
        title: 'RD Code',
        render: (att) => {
          const rAtt = att && ODM.getRAttNoLoadNoExtd(att.rid);
          const rD = rAtt && ODM.getRDNoLoadNoExtd(rAtt.rd);

          return wrapperHighlighter(rD.cod || '...', this.state.searchText)
        }
      },
      {
        title: 'Act',
        dataIndex: 'act',
        render: (act) => {
          return act ? 'True' : 'False'
        },
      },
      {
        title: 'Rev',
        dataIndex: 'ver',
      },
      {
        title: 'Timestamp',
        dataIndex: 'ts',
      },
    ]
    const atts = this.props.attributes;
    this.data = resolveTableData('a', atts)

    this.state = {
      data: this.data,
      searchText: ''
    }

    this.onSearch$ = new Subject()
  }

  componentDidMount() {
    this.searchSub = this.onSearch$.pipe(
      debounceTime(300)
    ).subscribe((text) => {
      const showingData = this.data.filter(att => {
        const rAtt = att && ODM.getRAttNoLoadNoExtd(att.rid);
        const rD = rAtt && ODM.getRDNoLoadNoExtd(rAtt.rd);
        const dText = `${att.id}_${att.did}_${rAtt.cod}_${rD.cod}`
        return dText.toLowerCase().includes(text.toLowerCase())
      })
      this.setState({
        data: showingData,
        searchText: text
      })
    })
  }

  componentWillUnmount() {
    if (this.searchSub) {
      this.searchSub.unsubscribe()
    }
  }

  handleOpenViewJsonDialog(viewObject) {
    this.props.actions.addWindow({
      type: 'ViewJson',
      props: {
        title: 'JSON Code',
        visible: true,
        viewObject: viewObject
      }
    });
  }

  onSearch = (e) => {
    this.onSearch$.next(e.target.value)
  }

  render () {
    const title = (<span>Attributes [Count: {this.data.length}]</span>)

    const search = (
      <div className={styles.searchContainer}>
        <Input placeholder="Search" onChange={this.onSearch} allowClear />
      </div>
    )

    const tableHeader = (
      <div className={styles.tableHeader}>
        {title}
        {search}
      </div>
    )

    return <Table
      className="report-table"
      columns={this.columns}
      dataSource={this.state.data}
      pagination={{position: ['bottomRight']}}
      bordered
      title={() => tableHeader}
    />
  }
}

const mapDispatchToProps = dispatch => ({
  actions: Object.assign(
    {},
    bindActionCreators(WindowManagementActions, dispatch)
  )
})

export default connect(
  null,
  mapDispatchToProps
)(AntAttributeTable)
