const testJson = {"apply":{"op":"equal","args":[{"apply":{"op":"supsub","base":"P","args":[{"apply":{"op":"sub","args":"0,i"}},{"apply":{"op":"sup","args":"LV"}}]}},{"apply":{"op":"supsub","base":"e","args":[{"apply":{"op":"sup","args":{"apply":{"op":"leftright","left":"(","right":")","args":{"apply":{"op":"plus","args":[{"apply":{"op":"supsub","base":"A","args":[{"apply":{"op":"sub","args":["i"]}}]}},{"apply":{"op":"plus","args":[{"apply":{"op":"genfrac","args":[{"apply":{"op":"denom","args":{"apply":{"op":"plus","args":[{"apply":{"op":"supsub","base":"C","args":[{"apply":{"op":"sub","args":["i"]}}]}},"T"]}}}},{"apply":{"op":"numer","args":{"apply":{"op":"supsub","base":"B","args":[{"apply":{"op":"sub","args":["i"]}}]}}}}]}},{"apply":{"op":"plus","args":[{"apply":{"op":"multiply","args":[{"apply":{"op":"supsub","base":"D","args":[{"apply":{"op":"sub","args":["i"]}}]}},"T"]}},{"apply":{"op":"plus","args":[{"apply":{"op":"multiply","args":[{"apply":{"op":"supsub","base":"E","args":[{"apply":{"op":"sub","args":["i"]}}]}},{"apply":{"op":"supsub","base":"T","args":[{"apply":{"op":"sup","args":["2"]}}]}}]}},{"apply":{"op":"multiply","args":[{"apply":{"op":"supsub","base":"F","args":[{"apply":{"op":"sub","args":["i"]}}]}},{"apply":{"op":"group","args":[{"apply":{"type":"op","mode":"math","limits":false,"parentIsSupSub":false,"symbol":false,"name":"\\ln","op":"ln"}},{"apply":{"op":"leftright","left":"(","right":")","args":"T"}}]}}]}}]}}]}}]}}]}}}}}}]}}]}}

const OPERATORS = {
  plus: '+',
  equal: '=',
  multiply: ' \\cdot ',
  minus: '-'
}

const LITERAL_CONSTANTS = [
  'e',
  'pi'
]


export function jsonToLatex(json, variablesMap) {
  if (!isNaN(json)) {
    return json
  } else if (LITERAL_CONSTANTS.includes(typeof json === 'string' && LITERAL_CONSTANTS.includes(json.toLowerCase))) {
    return json
  } else if (isValidApply(json)) {
    const {op, args, arg } = json.apply
    switch (op) {
      case 'plus': {
        if (args && args.length) {
          const strArgs = args.map(arg => jsonToLatex(arg, variablesMap))
          return strArgs.reduce((prevStr, strArg) => (prevStr ? `${prevStr}${OPERATORS[op]}${strArg}` : strArg), '')
        }
        return 'INVALIDPLUS'
      }

      case 'multiply':
      case 'equal': {
        const [left, right] = args
        const leftStr = jsonToLatex(left, variablesMap)
        const rightStr = jsonToLatex(right, variablesMap)

        return `${leftStr}${OPERATORS[op]}${rightStr}`
      }
      case 'minus': {
        const [arg1, arg2] = args
        const arg1Str = jsonToLatex(arg1, variablesMap)
        const arg2Str = arg2 && jsonToLatex(arg2, variablesMap)
        return arg2 ? `${arg1Str}${OPERATORS[op]}${arg2Str}` : `${OPERATORS[op]}${arg1Str}`
      }
      case 'supsub': {
        const base = json.apply.base
        const sub = findSub(args)
        const sup = findSup(args)
        let supStr = ''
        if (sup) {
          supStr = isValidApply(sup) ? jsonToLatex(sup, variablesMap) : sup
          supStr = `^{${supStr}}`
        }
        let subStr = ''
        if (sub) {
          subStr = isValidApply(sub) ? jsonToLatex(sub, variablesMap) : sub
          subStr = subStr ? `_{${subStr}}` : ''
        }

        if (!supStr) {
          return `{${base}_${subStr.match(/^_\{(.+)\}$/)[1]}}`
        }

        return `${base}${supStr}${subStr}`
      }
      case 'sub':
      case 'sup': {
        return isValidApply(args) ? jsonToLatex(args, variablesMap) : args
      }
      case 'leftright': {
        const val = args || ''
        const valStr = isValidApply(val) ? jsonToLatex(val, variablesMap) : val

        return `\\left(${valStr}\\right)`
      }
      case 'genfrac': {
        const denom = findDenom(args).apply.args
        const numer = findNumer(args).apply.args

        let denomStr = isValidApply(denom) ? jsonToLatex(denom, variablesMap) : denom
        let numerStr = isValidApply(numer) ? jsonToLatex(numer, variablesMap) : numer
        if (denomStr.startsWith('{')) {
          denomStr = removeBrace(denomStr)
        }
        if (numerStr.startsWith('{')) {
          numerStr = removeBrace(numerStr)
        }


        return `\\frac{${numerStr}}{${denomStr}}`
      }
      case 'frac': {
        const [arg1, arg2] = args
        const arg1Str = jsonToLatex(arg1, variablesMap)
        const arg2Str = jsonToLatex(arg2, variablesMap)
        return `\\frac{${arg1Str}}{${arg2Str}}`
      }
      case 'group': {
        return args.map(item => {
          return isValidApply(item) ? jsonToLatex(item, variablesMap) : item
        }).join('')
      }
      case 'ln': {
        const lnOf = (arg && jsonToLatex(arg, variablesMap)) || 'LN\_OF_{UNDEF}' 
        return `\\ln\\left(${lnOf}\\right)`
      }
      case 'power': {
        const base = (json.apply.base && jsonToLatex(json.apply.base, variablesMap)) || 'BASE_{UNDEF}'
        const powerOf = (arg && jsonToLatex(arg, variablesMap)) || 'POWER\_OF_{UNDEF}' 
        return `{${base}}^{\\left(${powerOf}\\right)}`
      }
      case 'exp': {
        const powerOf = (arg && jsonToLatex(arg, variablesMap)) || 'EXPOF_{UNDEF}' 
        return `{e}^{\\left(${powerOf}\\right)}`
      }
      default: {
        console.log(op, args)
        return `INVALIDJSON(${JSON.stringify(json)})`
      }
    }
  } else if(isValidVariable(json, variablesMap)) {
    return varNameToLatex(json, variablesMap)
  }

  return `INVALIDJSON(${JSON.stringify(json)})`
}

function varNameToLatex(name, variablesMap) {
  const filteredVM = variablesMap.filter(el => el.name === name)
  return (filteredVM && filteredVM.length === 1 && filteredVM[0].formula) || 'undef_var'
}

function isValidVariable(name, variablesMap) {
  const filteredVM = variablesMap.filter(el => el.name === name)
  return (filteredVM && filteredVM.length === 1)
}

function isValidApply(obj) {
  return typeof obj === 'object' && typeof obj.apply === 'object'
}

function isValidJson(obj, variablesMap) {
  return isValidApply(obj) || isValidVariable(obj, variablesMap)
}


function findSup(args) {
  return args.find(item => item.apply.op === 'sup')
}

function findSub(args) {
  return args.find(item => item.apply.op === 'sub')
}

function findDenom(args) {
  return args.find(item => item.apply.op === 'denom')
}

function findNumer(args) {
  return args.find(item => item.apply.op === 'numer')
}

function removeBrace(text) {
  if (text.startsWith('{') && text.endsWith('}')) {
    return text.match(/^\{(.+)\}$/)[1]
  }

  return text
}
