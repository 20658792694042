import { combineReducers } from 'redux'
import rootProjects from './root-projects'
import projects from './projects'
import rootLibraries from './root-libraries'
import libraries from './libraries'
import rootStates from './root-states'
import windowState from '../window-management/reducer'
import odataTree from '../odata-tree/odata-tree-reducer'
import mutiContentWindow from '../muti-content-window/reducer';

const rootReducer = combineReducers({
  rootProjects,
  projects,
  rootLibraries,
  libraries,
  windowState,
  rootStates,
  odataTree,
  mutiContentWindow,
})

export default rootReducer
