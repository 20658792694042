import React, {
  useState
} from 'react';

import './App.css';
import './styles/index.less'
import './styles/themes/index.less'
import './styles/orbit-ui.less'
import TopPane from './components/shared/top-pane/top-pane'
import LeftPane from './containers/left-pane'
import BottomBar from './containers/bottom-pane'
import ObjectBrowserDialog from './components/window-management/object-browser-dialog/object-browser-dialog'
import ODMReportDialog from './components/window-management/odm-report-dialog/odm-report-dialog'
import ViewJsonDialog from './components/window-management/view-json-dialog/view-json-dialog'
import ViewFormDialog from './components/window-management/view-form-dialog/view-form-dialog'
import CallbackContentDialog from './components/window-management/callback-content-dialog/callback-content-dialog'
import ViewFormDesignDialog from './components/window-management/view-form-design-dialog/view-form-design-dialog';
import UserFormDialog from './components/window-management/user-form-dialog/user-form-dialog'

import ODM from 'odm'
import ViewFormTableDesignDialog from './components/window-management/view-form-table-design-dialog';
import AuthDialog from './bussiness/auth/auth-dialog';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import Home from './bussiness/home/home';
import Settings from './bussiness/settings/settings';
import SearchDialog from './components/window-management/search-dialog/search-dialog';
import DeleteDialog from './components/window-management/delete-dialog/delete-dialog';
import RenameViewDialog from "./components/window-management/view-dialog/rename-dialog";
import NewViewDialog from "./components/window-management/view-dialog/new-view-dialog";
import AttributeConfigDialog from './components/window-management/attribute-config-dialog/attribute-config-dialog';
import RDConfigDialog from './components/window-management/root-dataset-dialog/rd-config-dialog';
import RelationConfigDialog from './components/window-management/relation-config-dialog/relation-config-dialog';
import SetIconDialog from './components/window-management/set-icon-dialog/set-icon-dialog';
import FlowSheetDialog from './components/window-management/flow-sheet-dialog';
import NewFlowSheetDialog from './components/window-management/new-flow-sheet-dialog';
import FlowSheetConfigDialog from './components/window-management/flow-sheet-config-dialog';
import EquationEditorDialog from './components/window-management/equation-editor-dialog';
import AddChildDialog from './components/window-management/add-child-dialog';

const WINDOWS_MAP = {
  'ObjectBrowser': ObjectBrowserDialog,
  'ViewJson': ViewJsonDialog,
  'ODMReport': ODMReportDialog,
  'ViewForm': ViewFormDialog,
  'CallbackContent': CallbackContentDialog,
  'ViewFormDesign': ViewFormDesignDialog,
  'ViewFormTableDesign': ViewFormTableDesignDialog,
  'AuthDialog': AuthDialog,
  'SearchDialog': SearchDialog,
  'RenameViewDialog': RenameViewDialog,
  'NewViewDialog': NewViewDialog,
  'DeleteDialog': DeleteDialog,
  'AttributeConfigDialog': AttributeConfigDialog,
  'RDConfigDialog': RDConfigDialog,
  'RelationConfigDialog': RelationConfigDialog,
  'SetIconDialog': SetIconDialog,
  'UserFormDialog': UserFormDialog,
  'FlowSheetDialog': FlowSheetDialog,
  'FlowSheetConfigDialog': FlowSheetConfigDialog,
  'EquationEditorDialog': EquationEditorDialog,
  'AddChildDialog': AddChildDialog,
  'NewFlowSheetDialog': NewFlowSheetDialog,
};


function App({windowState, actions, rootStates}) {
  const { windows } = windowState
  console.log(rootStates);
  window.addEventListener('popstate', function () {
      window.history.go(1);
  });

  (function refreshForever(){
      const curRootProjectNumIds = ODM.getRootProjectList();
      const curProjectNumIds = ODM.getProjectList();
      const curRootLibraryNumIds = ODM.getRootLibraryList();
      const curLibraryNumIds = ODM.getLibraryList();
      const curProducts = curProjectNumIds.map(numId => {
        return (ODM.getDNoLoadNoExtd(numId) || {id: numId});
      });
      actions.refreshProjects(curProducts);

      const curRootProjects = curRootProjectNumIds.map(numId => {
        return (ODM.getRDNoLoadNoExtd(numId) || {id: numId});
      });
      actions.refreshRootProjects(curRootProjects);

      const curRootLibraries = curRootLibraryNumIds.map(numId => {
        return (ODM.getRDNoLoadNoExtd(numId) || {id: numId});
      });
      actions.refreshRootLibraries(curRootLibraries);

      const curLibraries = curLibraryNumIds.map(numId => {
        return (ODM.getDNoLoadNoExtd(numId) || {id: numId});
      });
      actions.refreshLibraries(curLibraries);
      // OE.refreshNavPanel(); already rewrite
    setTimeout(refreshForever, 5000);
  })();

  const [ showLeftPane, setShowLeftPane ] = useState(true);

  const toggleShowLeftPane = (newVal) => {
    setShowLeftPane(!showLeftPane);
  };

  return (
    <Router>

    <div className="App">
        <TopPane
          showLeftPane={ showLeftPane }
          rootStates={ rootStates }
          toggleShowLeftPane= { toggleShowLeftPane }
          toggleRootOnline={ actions.toggleRootOnline }
        />
      <div className="app-body-container">
        <LeftPane className="app-side-menu"
          showLeftPane={ showLeftPane }
          setSidebarWidth={ actions.setSidebarWidth }
        />
        <div id="oe-mui-center-c" className="app-content-wrapper">
          <Switch>
            <Route path="/settings">
              <Settings />
            </Route>
            <Route path="/">
              <Home />
            </Route>
          </Switch>
        </div>
      </div>
      <BottomBar />
      <div id="dz_footer_objrepdiv" title="Object Report"></div>
      <div id="window-container">
        {windows.map(window => {
          const DynamicDialog = WINDOWS_MAP[window.type];
          return (
            <DynamicDialog
              { ...window.props }
              key={ window.windowId }
              windowId={ window.windowId }
            />
          );
        })}
      </div>
      <div  id="oe-attexec-div"  className="oe-jsexec-div" ><iframe id="oe-jsexec-iframe" title="Hidden System Iframe" frameBorder="0"></iframe></div>

    </div>
    </Router>
  );
}

export default App;
