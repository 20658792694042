import { fabric } from 'fabric';

export const idGenerator = () => {
  const ts = new Date().getTime().toString(32)
  const randomStr = Math.round(Math.random() * 10000).toString(32)

  return `${ts}${randomStr}`
}

export const orbitNSGet = (canvas, elemName) => {
  const orbitNS = canvas.orbit || {}

  return orbitNS[elemName]
}

export const orbitNSSet = (canvas, elemName, value) => {
  const orbitNS = canvas.orbit || {}

  orbitNS[elemName] = value

  canvas.orbit = orbitNS
}

export const isMouseIn = (object, e) => {
  const minTop = object.top
  const minLeft = object.left
  const maxTop = minTop + object.height
  const maxLeft = minLeft + object.width
  const {x, y} = e.pointer

  return minTop <= y && y <= maxTop && minLeft <= x && x <= maxLeft
}

export const saveJSONToFile = (json, fileName) => {
    const a = document.createElement('a')
    const file = new Blob([JSON.stringify(json)], {type: 'text/plain'})
    a.href = URL.createObjectURL(file)
    a.download = fileName
    a.click()
}

export function pointRotation (srcPoint, centerPoint, angle) {
  const cos = Math.cos
  const sin = Math.sin
  const x = ( srcPoint.x - centerPoint.x ) * cos(angle) - (srcPoint.y - centerPoint.y) * sin(angle) + centerPoint.x
  const y = ( srcPoint.x - centerPoint.x ) * sin(angle) + (srcPoint.y - centerPoint.y) * cos(angle) + centerPoint.y

  return { x, y }
}

export const CONTROLS = ['tl', 'tr', 'br', 'bl', 'ml', 'mt', 'mr', 'mb', 'mtr']

export function polylinePositionHandler(dim, finalMatrix, fabricObject) {
  // when I new a Control, I don't know why the position handler will be used by others control
  if (!Array.isArray(fabricObject.points) || this.pointIndex > fabricObject.points.length-1) {
    const point = fabric.util.transformPoint({
      x: this.x * dim.x + this.offsetX,
      y: this.y * dim.y + this.offsetY }, finalMatrix)

    return point
  }
  const x = (fabricObject.points[this.pointIndex].x - fabricObject.pathOffset.x)
  const y = (fabricObject.points[this.pointIndex].y - fabricObject.pathOffset.y)

  return fabric.util.transformPoint(
    { x, y },
    fabric.util.multiplyTransformMatrices(
      fabricObject.canvas.viewportTransform,
      fabricObject.calcTransformMatrix()
    )
  )
}

export function PathControlPositionHandler(dim, finalMatrix, fabricObject) {
  const x = (fabricObject.points[this.pointIndex].x - fabricObject.pathOffset.x)
  const y = (fabricObject.points[this.pointIndex].y - fabricObject.pathOffset.y)

  return fabric.util.transformPoint(
    { x, y },
    fabric.util.multiplyTransformMatrices(
      fabricObject.canvas.viewportTransform,
      fabricObject.calcTransformMatrix()
    )
  )
}

export function controlActionHandler(anchorIndex, fn) {
  return function(eventData, transform, x, y) {
    const fabricObject = transform.target
    const anchorPoint = fabricObject.points[anchorIndex]
    const absolutePoint = fabric.util.transformPoint({
        x: (anchorPoint.x - fabricObject.pathOffset.x),
        y: (anchorPoint.y - fabricObject.pathOffset.y),
      }, fabricObject.calcTransformMatrix()
    )
    const actionPerformed = fn(eventData, transform, x, y)
    // const newDim = fabricObject._setPositionDimensions({})
    const polylineBaseSize = fabricObject._getNonTransformedDimensions()
    const newX = (anchorPoint.x - fabricObject.pathOffset.x) / polylineBaseSize.x
    const newY = (anchorPoint.y - fabricObject.pathOffset.y) / polylineBaseSize.y
    fabricObject.setPositionByOrigin(absolutePoint, newX + 0.5, newY + 0.5)

    return actionPerformed
  }
}

export function isFlowNode(object) {
  return object.node && object.flowNozzles
}

export function isFlowLine(object) {
  return !!object.line
}

export function isFlowNozzle(object) {
  return object.category === 'nozzle'
}

export function stdShapeCodeGenerator() {
  const ts = new Date().getTime().toString(32)
  const randomStr = Math.round(Math.random() * 100).toString(32)

  return `FS.STDSHAPE.${ (ts + randomStr).toUpperCase() }`
}

export function lineCodeGenerator() {
  const ts = new Date().getTime().toString(32)
  const randomStr = Math.round(Math.random() * 100).toString(32)

  return `FS.LINE.${ (ts + randomStr).toUpperCase() }`
}

export function tableCodeGenerator() {
  const ts = new Date().getTime().toString(32)
  const randomStr = Math.round(Math.random() * 100).toString(32)

  return `FS.TABLE.${ (ts + randomStr).toUpperCase() }`
}

export function checkLineLine(line1, line2) {
  const [start, end] = line1
  const [start2, end2] = line2
  const {x: x1, y: y1} = start
  const {x: x2, y: y2} = end
  const {x: x3, y: y3} = start2
  const {x: x4, y: y4} = end2
  const t1 = ((x4-x3)*(y1-y3) - (y4-y3)*(x1-x3)) / ((y4-y3)*(x2-x1) - (x4-x3)*(y2-y1))
  const t2 = ((x2-x1)*(y1-y3) - (y2-y1)*(x1-x3)) / ((y4-y3)*(x2-x1) - (x4-x3)*(y2-y1))

  if (t1 >= 0 && t1 <= 1 && t2 >= 0 && t2 <= 1) {

    return {
      x: x1 + t1 * (x2 - x1),
      y: y1 + t1 * (y2 - y1)
    }
  } else {

    return false
  }
}

export function isSamePoint(p1, p2) {
  return p1.x === p2.x && p1.y === p2.y
}

