import {
  ADD_WINDOW,
  REMOVE_WINDOW,
  SET_WINDOW_VISIBLE,
  SET_WINDOW_TITLE, SET_TOP_WINDOW,
} from './types';

const initialState = {
  activeWindowId: '',
  windows: []
}
let windowId = 1

export default function windowManagement (state = initialState, action) {
  switch (action.type) {
    case ADD_WINDOW: {
      const windows = state.windows
      const newWindows = [
        ...windows,
        Object.assign(
          {},
          action.window,
          {windowId: `orbit_window_${windowId++}`}
        )
      ]

      return {...state, windows: newWindows}
    }


    case REMOVE_WINDOW: {
      const windows = state.windows.filter(item => item.windowId !== action.windowId)

      return {...state, windows}
    }

    case SET_WINDOW_VISIBLE: {
      const index = state.windows.findIndex(item => item.windowId === action.windowId)
      state.windows[index].props.visible = action.value
      return {...state, windows: [...state.windows], activeWindowId: action.windowId}
    }

    case SET_WINDOW_TITLE: {
      const index1 = state.windows.findIndex(item => item.windowId === action.windowId)
      state.windows[index1].props.title = action.value
      return {...state,windows: [...state.windows]}
    }

    case SET_TOP_WINDOW: {
      return {...state, activeWindowId: action.windowId}
    }

    default:
      return state
  }
}
