import React from 'react'
import {
  cloneDeep,
  startCase
} from 'lodash'
import styles from './cell-design.module.less'

import {
  PROPERTIES_CATEGORIES,
  formattedLabel,
  groupedProperties,
  getInputComponentViaProperty,
  getOptionsByKey, genHash,
} from '../../utils';
import Input from '../../../common/input/input'
import { getSupportedUnits } from '../utils';
import {
  CELL_ATTRIBUTES_CAN_ADJUST_LIST,
  CELL_STYLES_CAN_ADJUST_LIST,
  COLUMN_STYLES_CAN_ADJUST_LIST,
} from '../../../../core/constant';

class CellDesign extends React.PureComponent {

  constructor (props) {
    super(props);

    this.state = {
      sourceView: props.view,
      view: cloneDeep(props.view),
      activeRowIndex: props.activeRowIndex,
      activeCellIndex: props.activeCellIndex,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { view, activeRowIndex, activeCellIndex } = nextProps
    const changes = { activeRowIndex, activeCellIndex }
    if (view !== prevState.sourceView) {
      // formData changed
      changes.view = cloneDeep(view)
      changes.sourceView = view
    }

    return changes
  }

  setCellProperties (key, value) {
    const activeCell = this.state.view.rows[this.state.activeRowIndex].cells[this.state.activeCellIndex]
    activeCell['styles'][key] = value
    this.setState({
      view: { ...this.state.view }
    }, () => {
      if (key === 'colspan' || key === 'rowspan') {
        // to record user settings, source `colspan`, `rowspan` maybe auto settings
        const settingKey = key === 'colspan' ? 'settingColspan' : 'settingRowspan'
        activeCell.styles[settingKey] = value
        this.automaticallyComputeColspanOfLastCell(key)
      }
      this.props.handleStateChange({
        view: { ...this.state.view }
      })
    })
  }

  setCellContent (content) {
    this.state.view.rows[this.state.activeRowIndex].cells[this.state.activeCellIndex]['content'] = content
    this.preStateChange({
      view: { ...this.state.view }
    })
  }


  automaticallyComputeColspanOfLastCell = (key) => {
    const nCells = new Array(this.state.view.rows.length).fill(0)

    this.state.view.rows.forEach((row, rowIndex) => {
      row.cells.forEach(cell => {
        // use user settings, not the auto generated value
        const rowspan = parseInt(cell.styles.settingRowspan, 10) || 1
        const colspan = parseInt(cell.styles.settingColspan, 10) || 1
        nCells[rowIndex] += colspan

        if (rowspan > 1) {
          for (let i = 1; i < rowspan; i++) {
            if (rowIndex + i < nCells.length) {
              nCells[rowIndex + i] += colspan
            }
          }
        }
      })
    })

    const maxCellNumber = Math.max(...nCells)

    this.state.view.rows.forEach((row, index) => {
      const curColspan = nCells[index]

      if (maxCellNumber - curColspan >= 0) {
        const lastCellOfRow = row.cells[row.cells.length - 1]
        // use user settings, not the auto generated value
        const colspanOfLastCellOfRow = parseInt(lastCellOfRow.styles.settingColspan, 10) || 1
        this.state.view.rows[index].cells[row.cells.length - 1].styles['autoColspan'] = colspanOfLastCellOfRow + maxCellNumber - curColspan + ''
      }
    })

    // makeup columns
    const delta = maxCellNumber - this.state.view.columns.length
    if (delta > 0) {
      new Array(delta).fill(1).forEach(() => {
        this.state.view.columns.push({
          id: `column-${genHash()}`,
          content: '',
          styles: COLUMN_STYLES_CAN_ADJUST_LIST.reduce((obj, key) => {
            obj[key] = ''
            return obj
          }, {})
        })
      })
    } else if (delta < 0) {
      this.state.view.columns.splice(delta, Math.abs(delta))
    }

    this.preStateChange({
      view: {...this.state.view}
    })
  }

  preStateChange(newState) {
    this.setState(newState, () => {
      this.props.handleStateChange(newState)
    })
  }

  render () {
    const activeCell = this.state.view.rows[this.state.activeRowIndex]?.cells[this.state.activeCellIndex]

    if (!activeCell) {
      console.warn('no table content found')
      return ''
    }

    let cellDesign = (
      <div className={styles.viewForm}>

        {
          activeCell
            ? (
              <div className={styles.formCategoryWrapper}>
                <div className={styles.formSubHeader}>Content</div>
                <div className={styles.formRow}>
                  <label className={ styles.formLabel }>{formattedLabel('Content')}</label>
                  <Input
                    key={`content-${this.state.activeRowIndex}-${this.state.activeCellIndex}`}
                    className={ styles.formControl }
                    value={activeCell['content']}
                    handleChange={ payload => this.setCellContent.bind(this)(payload) }
                  />
                </div>
              </div>
            ) : ''
        }

        {
          activeCell
            ? PROPERTIES_CATEGORIES.map(category => (
                (
                  <div className={styles.formCategoryWrapper}
                       key={category}
                  >
                    <div className={styles.formSubHeader}>{startCase(category)}</div>
                    {
                      groupedProperties(category)
                        .filter(item => CELL_STYLES_CAN_ADJUST_LIST.concat(CELL_ATTRIBUTES_CAN_ADJUST_LIST).includes(item))
                        .map(key => {
                          const InputComponent = getInputComponentViaProperty(key)
                          return (
                            <div className={styles.formRow}
                                 key={`${key}-${this.state.activeRowIndex}-${this.state.activeCellIndex}`}
                            >
                              <label className={ styles.formLabel }>{formattedLabel(key)}</label>
                              <InputComponent
                                className={ styles.formControl }
                                value={activeCell.styles[key] || ''}
                                styles={activeCell.styles}
                                handleChange={ payload => this.setCellProperties.bind(this)(key, payload) }
                                supportedUnits={ getSupportedUnits(key) }
                                options={ getOptionsByKey(key) }
                              />
                            </div>
                          )
                        })
                    }
                  </div>
                )
            ))
            : ''
        }
      </div>
    );

    return cellDesign;
  }
}

export default CellDesign;
