import React from 'react';
import { Button, Form, Input } from 'antd';
import styles from './login.module.less';

class LoginForm extends React.Component {
  constructor(props) {
    super(props);
  }

  onFinish = (val) => {
    console.log(val)
  }

  onFinishFailed = () => {

  }

  render() {
    const layout = {
      labelCol: { span: 5 },
      wrapperCol: { span: 24 },
    };

    const loginForm = (
      <Form
        {...layout}
        layout={'vertical'}
        name="loginForm"
        initialValues={{ remember: true }}
        onFinish={this.onFinish}
        onFinishFailed={this.onFinishFailed}
      >
        <Form.Item
          label="Email"
          name="email"
          rules={[
            { required: true, message: 'Please input your email!' },
            { type: 'email', message: 'Email is not a valid email!' }
            ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Password"
          name="password"
          rules={[{ required: true, message: 'Please input your password!' }]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item wrapperCol= {{offset: 0, span: 24} } className={styles.loginButtonWrapper}>
          <Button type="primary" htmlType="submit" className={styles.loginButton}>
            Login
          </Button>
        </Form.Item>
      </Form>
    )

    return loginForm
  }
}

export default LoginForm
