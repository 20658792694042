import * as types from './types'

export const addWindow = newWindow => {
  return {
    type: types.ADD_WINDOW,
    window: newWindow
  }
}

export const removeWindow = removedWindowId => {
  return {
    type: types.REMOVE_WINDOW,
    windowId: removedWindowId
  }
}

export const setWindowVisible = (windowId, value) => {
  return {
    type: types.SET_WINDOW_VISIBLE,
    windowId: windowId,
    value
  }
}

export const setWindowTitle = (windowId, value) => {
  return {
    type: types.SET_WINDOW_TITLE,
    windowId: windowId,
    value
  }
}

export const setTopWindow = (windowId) => {
  return {
    type: types.SET_TOP_WINDOW,
    windowId: windowId,
  }
}
