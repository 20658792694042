import {
  REFRESH_PROJECTS
} from '../constants/projects-types'

const initialState = []

export default function projects (state = initialState, action) {
  switch (action.type) {
    case REFRESH_PROJECTS:
      return [
        ...action.projects
      ]

    default:
      return state
  }
}
