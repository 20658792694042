import React from 'react'
import { Input, Select } from 'antd';
import ABase from './a-base';

class ADimvarInput extends ABase {
  constructor(props) {
    super(props);
    this.blurTimestamp = Date.now()

    this.dim = this.odm.getDimensionById(this.rAtt.ddim || 1);
    this.units = this.dim.units.map(item => ({label: item.cod, value: item.id}))
    const value = this.state.value || {}
    this.attVal = value.val || undefined;
    this.attUId = value.uid || ''

    this.state = {
      value: value.val,
      uid: value.uid
    }
  }

  saveChange() {
    this.onAttChange('', (att) => {
      const curValue = {'val': this.attVal, 'uid': this.attUId}
      att.data = curValue;
      this.odm.updateAtt(att);
    })
  }

  onUnitChange = (value) => {
    const oldUnitId = this.attUId
    this.attUId = value
    if(this.changeUnitMode) {
      const oldUnit = this.attUId && this.dim.units.filter(unit => unit.id === oldUnitId);
      const newUnit = value && this.dim.units.filter(unit => unit.id === value);
      if((this.attVal || this.attVal === 0) && oldUnit && oldUnit.length && newUnit && newUnit.length) {
        const oUPreOffset = oldUnit[0].os1;
        const oUPostOffset = oldUnit[0].os2;
        const oUFactor = oldUnit[0].fac;
        const nUPreOffset = newUnit[0].os1;
        const nUPostOffset = newUnit[0].os2;
        const nUFactor = newUnit[0].fac;
        this.attVal = ((this.attVal-oUPostOffset) / oUFactor - oUPostOffset + nUPreOffset) * nUFactor + nUPostOffset;
        this.setState({
          uid: value,
          value: this.attVal
        })
      } else {
        this.setState({
          uid: value
        })
      }
    } else {
      this.changeUnitMode = true;
      this.setState({
        uid: value
      })
    }
    this.saveChange()
  }

  onValChange = (e) => {
    const value = e.target.value
    this.attVal = this.oe.castToTypeSingle(value, 'FLOAT') || undefined
    this.saveChange()
    this.setState({
      value: value
    })
  }

  onFocus = (e) => {
//    this.hasFocused = true
  }

  onBlur = (e) => {
    this.blurTimestamp = Date.now()
  }

  onSelectFocus = (e) => {
    const curTS = Date.now()
    if ((curTS - this.blurTimestamp) > 1500) {
      this.changeUnitMode = true
    } else {
      this.changeUnitMode = false
    }
  }

  render() {
    const selectAfter = (
      <Select value={this.state.uid} options={this.units}
              dropdownClassName="auto-menu-width"
              virtual={false}
              onChange={this.onUnitChange} onFocus={this.onSelectFocus} />
    );
      const dimUnit = !this.props.editMode && this.odm.getUnitForDimensionById(this.dim, this.state.uid);
      const unitCod = (dimUnit && dimUnit.cod) || '-';
      return this.props.editMode ? <Input type="number"
        addonAfter={selectAfter} value={this.state.value} onChange={this.onValChange} onFocus={this.onFocus}  onBlur={this.onBlur}/> :
        <div>{this.state.value || "-"} {this.state.value && unitCod}</div>
  }
}

export default ADimvarInput
