import React, { createRef, memo, useEffect, useState } from 'react';
import { Col, InputNumber, Row } from 'antd';

export const RowSetting = memo(({value, onChange}) => {
  const [show, setShow] = useState(false)
  const ref = createRef()

  useEffect(() => {
    if (show) {
      setTimeout(() => {
        ref.current && ref.current.focus()
      })
    }
  }, [show])

  const onBlur = (e) => {
    if (onChange) {
      onChange(e)
    }
    setShow(false)
  }

  const onClick = (e) => {
    e.nativeEvent.stopImmediatePropagation()
  }

  return (
    <Row align="middle" onClick={onClick}>
      <Col onClick={() => setShow(true)} onKeyDown={null}>Set rows</Col>
      {show && <Col style={{marginLeft: 10}}>
        <InputNumber min={1} value={value}
                     ref={ref}
                     onBlur={onBlur}/>
      </Col>}
    </Row>
  )
})
