import React, { createContext, useCallback, useMemo, useReducer, useState } from 'react';
import ReactDOM from 'react-dom'
import isHotkey from 'is-hotkey'
import { Editable, withReact, Slate } from 'slate-react';
import {
  createEditor,
} from 'slate';
import { withHistory } from 'slate-history'
import SlateToolbar from './toolbar'
import { toggleMark } from './toolbar/mark-button'
import SlateElement from './element'
import SlateLeaf from './leaf'
import withTables from './plugins/table'
import { useMention, withMentions } from './plugins/mention';
import SlateEditorReducer, { initSlateEditorState } from './slate-editor-reducer';

const HOTKEYS = {
  'mod+b': 'bold',
  'mod+i': 'italic',
  'mod+u': 'underline',
  'mod+`': 'code',
}

const initialValue = [
  {
    type: 'paragraph',
    children: [
      { text: '' },
    ],
  },

]

export const SlateEditorContext = createContext({})

export const Portal = ({ children }) => {
  return ReactDOM.createPortal(children, document.body)
}

const plugins = [
  withReact,
  withHistory,
  withTables,
  withMentions,
]

const SlateEditor = (props) => {
  const [state, dispatch] = useReducer(SlateEditorReducer, initSlateEditorState)
  let defaultVal = initialValue
  if (props.value) {
    const propsVal = JSON.parse(props.value)
    defaultVal = Array.isArray(propsVal) ? propsVal : initialValue
  }
  const [value, setValue] = useState(defaultVal)

  const renderElement = useCallback(props => <SlateElement {...props} />, [])
  const renderLeaf = useCallback(props => <SlateLeaf {...props} />, [])
  const editor = useMemo(() => {
    let oEditor = createEditor()
    plugins.forEach(plugin => {
      oEditor = plugin(oEditor)
    })

    return oEditor
  }, [])

  const mention = useMention(editor)


  const onKeyDown = useCallback(
    event => {
      mention.onKeyDown(event)
      for (const hotkey in HOTKEYS) {
        if (isHotkey(hotkey, event)) {
          event.preventDefault()
          const mark = HOTKEYS[hotkey]
          toggleMark(editor, mark)
        }
      }
    },
    [mention]
  )

  return (
    <SlateEditorContext.Provider  value={{state, dispatch}}>
      <Slate editor={editor} value={value} onChange={
        (value) => {
          mention.onChange(value)
          setValue(value)
          if (props.onChange) {
            props.onChange(value)
          }
        }
      }>
        {(!props.readOnly) && <SlateToolbar />}
        <Editable
          className="slate-editor-body"
          renderElement={renderElement}
          renderLeaf={renderLeaf}
          spellCheck
          readOnly={props.readOnly || false}
          onKeyDown={onKeyDown}
        />
        {mention.ui}
      </Slate>
    </SlateEditorContext.Provider>
  )
}

export default SlateEditor
