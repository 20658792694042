import React, { createRef, memo, useCallback, useEffect, useState, useRef, useMemo } from 'react';
import './style.less'
import KaTeX from 'katex';
import '../../../node_modules/katex/dist/katex.css'
import PlainTextEditor from '../plain-single-line-editor'

import { ReactComponent as Formula1 } from './assets/formula-icon-1.svg'
import { ReactComponent as Formula2 } from './assets/formula-icon-2.svg'
import { ReactComponent as Formula3 } from './assets/formula-icon-3.svg'
import { ReactComponent as Formula4 } from './assets/formula-icon-4.svg'
import { ReactComponent as Formula5 } from './assets/formula-icon-5.svg'
import { ReactComponent as Formula6 } from './assets/formula-icon-6.svg'
import { ReactComponent as Formula7 } from './assets/formula-icon-7.svg'
import { ReactComponent as Formula8 } from './assets/formula-icon-8.svg'
import { ReactComponent as Formula9 } from './assets/formula-icon-9.svg'
import { ReactComponent as Formula10 } from './assets/formula-icon-10.svg'
import { ReactComponent as Formula11 } from './assets/formula-icon-11.svg'
import { ReactComponent as Formula12 } from './assets/formula-icon-12.svg'
import { ReactComponent as Formula13 } from './assets/formula-icon-13.svg'
import { ReactComponent as Formula14 } from './assets/formula-icon-14.svg'
import { latexToJson } from './latexToJson';
import { jsonToLatex } from './jsonToLatex';

export const SELECTED_TEXT = 'SELECTED-TEXT'
export const PLACEHOLDER = 'PLACEHOLDER'

const symbols = [
  {text: '+', value: '+'},
  {text: '-', value: '-'},
  {text: '=', value: '='},
  {text: '\\cdot', value: '\\cdot'},
  {text: '\\left(\\right)', value: `\\left(${SELECTED_TEXT}\\right)`, icon: <Formula14/>},
  {text: '\\pi', value: `\\pi`},
  {text: 'e', value: `e`},
  {text: '\\frac{x}{y}', value: `\\frac{${SELECTED_TEXT}}{${PLACEHOLDER}}`, icon: <Formula1/>},
  {text: '\\frac{x}{y}', value: `\\frac{${PLACEHOLDER}}{${SELECTED_TEXT}}`, icon: <Formula2/>},
  {text: 'x^n', value: `${SELECTED_TEXT}^{${PLACEHOLDER}}`, icon: <Formula3/>},
  {text: 'x^n', value: `${PLACEHOLDER}^{${SELECTED_TEXT}}`, icon: <Formula4/>},
  {text: '\\sqrt{x}', value: `\\sqrt{${SELECTED_TEXT}}`, icon: <Formula5/>},
  {text: '\\sqrt[x]{y}', value: `\\sqrt[${PLACEHOLDER}]{${SELECTED_TEXT}}`, icon: <Formula6/>},
  {text: '\\log_{10}{y}', value: `\\log_{10}{${SELECTED_TEXT}}`, icon: <Formula7/>},
  {text: '\\log_{e}{y}', value: `\\log_{e}{${SELECTED_TEXT}}`, icon: <Formula8/>},
  {text: '\\log_{x}{y}', value: `\\log_{${PLACEHOLDER}}{${SELECTED_TEXT}}`},
  {text: '\\sum', value: `\\sum\\limits_{i=1}^{N} {${SELECTED_TEXT}}`, icon: <Formula9/>},
  {text: '\\frac{\\partial }{\\partial {x}}', value: `\\frac{\\partial {${SELECTED_TEXT}}}{\\partial {x}}`, icon: <Formula10/>},
  {text: '\\frac{\\partial^2 }{\\partial {}^2}', value: `\\frac{\\partial^2 {${SELECTED_TEXT}}}{\\partial^2 {x}}`, icon: <Formula11/>},
  {text: '\\frac{\\partial^2 }{\\partial {x} \\partial {y}}', value: `\\frac{\\partial^2 {${SELECTED_TEXT}}}{\\partial {x} \\partial {y}}`, icon: <Formula12/>},
  {text: '\\frac{\\mathrm{d} }{\\mathrm{d} t}', value: `\\frac{\\mathrm{d} {${SELECTED_TEXT}}}{\\mathrm{d} t}`, icon: <Formula13/>},
]

// Variables Definitions
const VAR_PREFIX = '\\#'
const VAR_SUFFIX = '\\#'

export const variables = [
  {text: 'x', value: `${VAR_PREFIX}x${VAR_SUFFIX}`},
  {text: 'y', value: `${VAR_PREFIX}y${VAR_SUFFIX}`},
]

const  FormulaEditor = memo(function FormulaEditor({onChange, formulaDataset, odm, oe}) {
  const preview = createRef()
  const editor = useRef();
  const eqVariables = getVariablesFromDataset(formulaDataset)
  const jsonCodeAtt = formulaDataset && oe.getAttForD(formulaDataset, 'EQ_JSON_CODE', undefined)
  const initJsonCodeVal = jsonCodeAtt && oe.getAttValue(jsonCodeAtt)
  const initJsonObj = initJsonCodeVal && JSON.parse(initJsonCodeVal)
  const [katexCode, setKatexCode] = useState((initJsonObj && jsonToLatex(initJsonObj, getVariablesMap(eqVariables))) || "")

  useEffect(() => {
    // katex.render(value, preview)
  }, [])

  function generateHtml(latex) {
    return KaTeX.renderToString(latex, { throwOnError: false });
  }

  function getVariablesFromDataset(eqDataset) {
    const varRD = odm && odm.getRDNoLoadByCode('EQC.PML.VAR')
    const eqVarDs = varRD && oe.getSubDsWithRD(eqDataset, varRD)

    if (!Array.isArray(eqVarDs)) {
      return null
    }

    let returnObj = {}
    eqVarDs.forEach((eqVarD) => {
      const code = eqVarD.cod
      const nameAtt = oe.getAttForD(eqVarD, 'NAME', undefined)
      const name = nameAtt && oe.getAttValue(nameAtt)
      const subAtt = oe.getAttForD(eqVarD, 'SUB', undefined)
      const subScript = subAtt && oe.getAttValue(subAtt)
      const superAtt = oe.getAttForD(eqVarD, 'SUP', undefined)
      const superScript = superAtt && oe.getAttValue(superAtt)
      const portAtt = oe.getAttForD(eqVarD, 'PORT', undefined)
      const portD = portAtt && odm.getDNoLoadNoExtd(oe.getAttValue(portAtt))
      const portTexAtt = portD && oe.getAttForD(portD, 'TEX', undefined)
      const portTex = portTexAtt && oe.getAttValue(portTexAtt)
      const portData = portTex && {tex: portTex}
      const compIndexAtt = oe.getAttForD(eqVarD, 'COMP_INDEXED', undefined)
      const compIndexed = compIndexAtt && oe.getAttValue(compIndexAtt)
      const phaseAtt = oe.getAttForD(eqVarD, 'PHASE', undefined)
      const phase = phaseAtt && oe.getAttValue(phaseAtt)
      returnObj[code] = {code, name, subScript, superScript, port: portData, compIndexed, phase}
    })
    return returnObj
  }

  const onTextChange = useCallback((newVal) => {
    setKatexCode(newVal)
    const newJsonCode = latexToJson(newVal, getVariablesMap(eqVariables))
    console.log(newJsonCode)
    
    if (onChange) {
      onChange(newJsonCode)
    }
  })

  const onSymbolClick = useCallback((symbol) => {
    if (typeof symbol === 'string') {
      editor.current.insertTextForFormulaEditor(symbol)
    } else {
      if (symbol && symbol.code) {
        editor.current.insertTextForFormulaEditor(symbol.code)
      } else {
        console.log("Variable cannot be inserted into formula")
      }
    }
  }, [katexCode])

  const symbolButtons = symbols.map(item => (
    <SymbolButton text={item.text} icon={item.icon} onClick={() => {onSymbolClick(item.value)}}/>
  ))

  const prueValue = katexCode.replace(/<span[^>]*>([^<]*)<\/span>/g, (match, text) => {
    return text
  })

  const variableElems = useMemo(() => {
    const varRD = odm && odm.getRDNoLoadByCode('EQC.PML.VAR')
    const eqVarDs = varRD && oe.getSubDsWithRD(formulaDataset, varRD)

    if (!Array.isArray(eqVarDs)) {
      return null
    }

    return Object.keys(eqVariables).map((key) => {

      const eqVarObj = eqVariables[key]
      const katex = getKatexCodeFromVarObj(eqVarObj);

      return <SymbolButton text={katex} value={katex} onClick={() => onSymbolClick(katex)}/>
    })
  }, [variables])

  return (
    <div className="formula-editor">
      <div className="formula-header">
        <div className="operator-container">
          {symbolButtons}
        </div>
        <div className="variables-container">
          {variableElems}
        </div>
      </div>


      <div className="editor">
        <PlainTextEditor
          ref={editor}
          html={katexCode}
          onChange={onTextChange}
          enableEnter={false}
        ></PlainTextEditor>
      </div>

      <div className="preview-container">
        <h4>Preview</h4>
        <div className="preview" ref={preview} dangerouslySetInnerHTML={{__html: generateHtml(prueValue)}}></div>
      </div>
    </div>
  )
})

const SymbolButton = memo(function SymbolButton({text, onClick, icon}) {
  const buttonInner = icon
    ? (<span className="symbol-button-inner">{icon}</span>)
    : (<div dangerouslySetInnerHTML={{__html:  KaTeX.renderToString(text, { throwOnError: false })}}></div>)

  return (
    <button className="symbol" onClick={() => onClick(text)}>{buttonInner}</button>
  )
})

function getKatexCodeFromVarObj(eqVarObj) {
  return eqVarObj.name ? ('{' + eqVarObj.name + '}' + ((eqVarObj.superScript || eqVarObj.phase) ? ("^{"+((eqVarObj.superScript ? ("," + eqVarObj.superScript) : "") + (eqVarObj.phase ? ("," + eqVarObj.phase) : "")).substr(1)+"}") : "") + ((eqVarObj.subScript || (eqVarObj.port && eqVarObj.port.tex) || eqVarObj.compIndexed) ? ("_{"+((eqVarObj.subScript ? ("," + eqVarObj.subScript) : "")+((eqVarObj.port && eqVarObj.port.tex) ? ("," + eqVarObj.port.tex) : "")+(eqVarObj.compIndexed ? ",i" : "")).substr(1)+"}")  : "")) : eqVarObj.code
}

function getVariablesMap(eqVariables) {
  if (eqVariables) {
    const varKeys = Object.keys(eqVariables);

    return varKeys.map(key => {
      return {
        name: key,
        formula: getKatexCodeFromVarObj(eqVariables[key]),
      }
    })
  }

  return [];
}

export default FormulaEditor
