import React from 'react'
import { Select } from 'antd';
import ABase from './a-base';

class ASelect extends ABase {
  constructor(props) {
    super(props);
    this.setOptions('SYS.ATT.H5.OPT', 'H5.value', 'H5.label')
  }

  static getDerivedStateFromProps(props, state) {
    if (props.editMode !== state.editMode) {
      return {
        editMode: props.editMode
      };
    }

    return {}
  }

  onChange = (value) => {
    this.onAttChange(value)
  }

  render() {
    const attTextValue = this.oe.castToType(this.state.value, "TEXT", false)
    if (this.state.editMode) {
      return <Select
        options={this.options}
        value={attTextValue} style={{width: '100%'}} onChange={this.onChange}/>
    } else {
      let text = ''
      if (attTextValue || attTextValue === '') {
        const target = this.options.find(item => item.value === attTextValue)
        text = target ? target.label : attTextValue
      }

      return <div>{text}</div>
    }
  }
}

export default ASelect
