import * as React from 'react';

export function getEventComposedPath (evt) {
  let path
  // chrome, opera, safari, firefox
  path = evt.path || (evt.composedPath && evt.composedPath())

  // other: edge
  if (path == undefined && evt.target) {
    path = []
    let target = evt.target
    path.push(target)

    while (target && target.parentNode) {
      target = target.parentNode
      path.push(target)
    }
  }

  return path
}


export const { isValidElement } = React;


export function replaceElement(
    element,
    replacement,
    props,
) {
  if (!isValidElement(element)) return replacement;

  return React.cloneElement(
      element,
      typeof props === 'function' ? props(element.props || {}) : props,
  );
}

export function cloneElement(element, props) {
  return replaceElement(element, element, props);
}

export function rebuildTreeNodeKey(treeData, parentKey = '') {
  if (Array.isArray(treeData)) {
    return treeData.map((node, index) => {
      const nodeKey = parentKey ? `${parentKey}-${index}`: index.toString()
      const newNode = {...node, key: nodeKey}
      if (newNode.children) {
        newNode.children = rebuildTreeNodeKey(node.children, nodeKey)
      }

      return newNode
    })
  }
  const nodeKey = parentKey ? `${parentKey}-0`: '0'

  return {...treeData, key: nodeKey}
}

export function svgStrToImg(svgStr) {
  const imageContent = encodeURIComponent(svgStr)
  const imageSrc = `data:image/svg+xml;utf8,${imageContent}`
  return (<img src={imageSrc} alt="icon"/>)
}

function fallbackCopyTextToClipboard(text) {
  const textArea = document.createElement('textarea')
  textArea.value = text

  // Avoid scrolling to bottom
  textArea.style.top = '0'
  textArea.style.left = '0'
  textArea.style.position = 'fixed'

  document.body.appendChild(textArea)
  textArea.focus()
  textArea.select()

  try {
    const successful = document.execCommand('copy')
    const msg = successful ? 'successful' : 'unsuccessful'
    console.log('Fallback: Copying text command was ' + msg)
  } catch (err) {
    console.error('Fallback: Oops, unable to copy', err)
  }

  document.body.removeChild(textArea);
}

export function copyTextToClipboard(text) {
  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(text)
    return
  }
  navigator.clipboard.writeText(text).then(function() {
  }, function(err) {
    console.error('Async: Could not copy text: ', err)
  })
}

