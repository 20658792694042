import React, { createRef } from 'react';
import Dialog from '../../shared/dialog/dialog';
import { bindActionCreators } from 'redux';
import * as WindowManagementActions from '../../../stores/window-management/actions';
import { connect } from 'react-redux';
import ViewForm from '../../shared/view-form/view-form';
import { Button } from 'antd';
import FlowSheetService from '../../../services/flow-sheet.service';
import { OBJECT_DBCLICK } from '../../../libs/flow-editor/core/events';
import {getEquipIconByTreeNode, getEquipSvgByTreeNode, getEquipTitle} from "../../../core/utils";
import {svgStrToImg} from "../../../utils/utils";
import OE from 'oe'
import ODM from 'odm'


class FlowSheetDialog extends React.Component {
  editor = null
  editorRef = createRef()
  constructor(props) {
    super(props)
    this.flowSheetService = new FlowSheetService()
    const fsObjects = this.flowSheetService.getSheetData(props.dataset)
    const fscfgData = this.flowSheetService.getConfigData(props.dataset)
    this.state = {
      activeObj: null,
      fsObjects: fsObjects,
      fscfgData: fscfgData,
      menu: this.getMenus()
    }
  }

  getMenus() {
    const menuFolders = []
    // todo: get flow sheet from OMD my d2dId or dId
    const d2d = ODM.getD2DRelNoLoad(21681)
    const dataset = d2d && ODM.getDNoLoad(d2d.tid)
    const folders = OE.getFSEditorMenuFolders('EPC.FS_OBJ_PANE_CFG.DEFAULT', (dataset && dataset.cod) || 'EPC.FS_OBJ_PANE_CFG0') || []
    folders.map(folderItem => {
      const children = folderItem.children
      folderItem.children = children.map(item => {
        const iconSvgStr = getEquipIconByTreeNode(item)
        const equipData = getEquipSvgByTreeNode(item)
        const title = getEquipTitle(item)
        if (!(iconSvgStr && equipData)) {
          console.error(`menu data[${item.odmId}] is not valid`)
          return
        }
        const {svgStr, flowNozzles} = equipData
        const d2dRel = item.nodeType === 'd2d' && ODM.getD2DRelNoLoadNoExtd(item.odmId)
        const d = item.nodeType === 'd' && ODM.getDNoLoadNoExtd(item.odmId)
        return {icon: svgStrToImg(iconSvgStr), svg: svgStr, category: 'svg', flowNozzles, id: (d2dRel && d2dRel.tid) || (d && d.id), title: title }
      }).filter(item => !!item)

      return folderItem
    }).forEach(folder => {
      menuFolders.push(folder)
    })

    return menuFolders
  }

  handleClose () {
    this.props.actions.removeWindow(this.props.windowId)
  }

  handleSubmit(value) {
    this.props.onSubmit(this.props.odmObject, value)
    this.props.actions.removeWindow(this.props.windowId)
  }

  handleMinimize () {
    this.props.actions.setWindowVisible(this.props.windowId, false)
  }

  editorInit = (editor) => {
    this.editor = editor
    this.flowSheetService.setEditor(editor)
  }

  onObjectChange = (change) => {
    const { opt, target, type} = change
    const targetObj = target.toJSON()

    switch (opt) {
      case 'modified': {
        if (type === 'node') {
          this.flowSheetService.updateNode(target)
        } else if (type === 'line') {
          this.flowSheetService.updateLine(targetObj)
        } else if (type === 'tableImage') {
          this.flowSheetService.updateTable(target)
        } else if (type === 'text') {
          this.flowSheetService.updateFSTextDataset(targetObj)
        }

        if (this.state?.activeObj?.id === targetObj.id) {
          targetObj.$cmpKey = new Date().getTime()
          setTimeout(() => {
            this.setState({
              activeObj: targetObj
            })
          })
        }
        break
      }
      case 'create': {
        if (type === 'node') {
          this.flowSheetService.createNode(target)
        }
        break
      }
      case 'remove': {
        this.flowSheetService.removeNode(targetObj, this.props.dataset)
        this.setState({
          activeObj: null
        })
        break
      }
    }
  }

  onSaveEmitted = (changes) => {
    console.log(changes)
    this.flowSheetService.odm.pushSubToParentODM()

    return Promise.resolve()
  }

  onObjSelected = (obj) => {
    this.setState({
      activeObj: obj
    })
  }

  onDeselected = (obj) => {
    // console.log(obj)
    // if (this.state.activeObj && this.state.activeObj.id === obj.id) {
    //   this.setState({
    //     activeObj: null
    //   })
    // }
  }

  onConfig = () => {
    const dataset = this.props.dataset
    const provider = this.flowSheetService.createChildProvider()
    const vfODM = provider.odm
    const vfOE = provider.oe
    this.props.actions.addWindow({
      type: 'FlowSheetConfigDialog',
      props: {
        title: 'Flow Sheet Config Dialog',
        visible: true,
        dataset,
        vfODM,
        vfOE
      }
    });
  }

  submitChange = (vfODM, fsDId) => {
    const fsDataset = vfODM.getDNoLoadNoExtd(fsDId)
    const fsRD = fsDataset && vfODM.getRDNoLoadNoExtd(fsDataset.rid)
    if (this.editorRef.current && fsRD) {
      vfODM.pushSubToParentODM()
      switch (fsRD.cod) {
        case "EPC.TOOLS.FS.LINE":
        case "EPC.TOOLS.FS.STREAM":
        case "EPC.TOOLS.FS.SIGNAL":
        case "EPC.TOOLS.FS.BASE_CONNECT_LINE":
          this.editorRef.current.updateLineFromDataset(fsDataset)
          break
        case "EPC.TOOLS.FS.TABLE":
          const data = this.flowSheetService.getFsTableByDataset(fsDataset)
          this.editorRef.current.updateTableFromDataset(data)
          break
        case "EPC.TOOLS.FS.TEXT":
          const textData = this.flowSheetService.getTextByD(fsDataset)
          this.editorRef.current.runActions('updateTextFromDataset', textData)
          break
        default:
          this.editorRef.current.updateNodeFromDataset(fsDataset)
          break
      }
    }
  }

  discardChange = (vfODM, fsDId) => {
      this.forceUpdate()
  }

  onTableDatasetChange = (tableData) => {
    this.editorRef.current.redrawTable(tableData)
  }

  onEvents = (eventName, data) => {
    if (eventName === OBJECT_DBCLICK && data.type === 'imageTable') {
      // on table-image double click
      const provider = this.flowSheetService.createChildProvider()
      const vfODM = provider.odm
      const vfOE = provider.oe
      const dataset = vfODM.getDNoLoad(data.titleBlockId)
      this.props.actions.addWindow({
        type: 'ViewForm',
        props: {
          visible: true,
          odm: vfODM,
          oe: vfOE,
          vFObject: dataset,
          onSubmit: () => this.onTableDatasetChange(data)
        }
      });
    }
  }

  onDatasetAction = (action) => {
    console.log(action)
    const fsDataset = this.props.dataset
    // create
    if (action.type === 0) {
      const { id } = action.payload.data
      const fsEqpD = this.flowSheetService.createSVGNodeDataset(fsDataset, id)
      const node = this.flowSheetService.oe.getEqFSData(fsEqpD, undefined, 'FS_P&ID')

      const {node: nodeAttrs, ...other} = this.flowSheetService.odmNodeToFlowSheetNode(node)

      return {...nodeAttrs, ...other, svg: nodeAttrs.svgNode}
    }
  }

  render () {
    const activeObj = this.state.activeObj
    let dataset = null
    const provider = this.flowSheetService.createChildProvider()
    const vfODM = provider.odm
    const vfOE = provider.oe
    if (activeObj && activeObj.did !== undefined) {
      dataset = this.flowSheetService.odm.getDNoLoad(activeObj.did)
    }
    const activeNodeSetting = dataset && (
      <>
        <ViewForm vFObject={dataset} odm={vfODM} oe={vfOE} key={activeObj.$cmpKey ? activeObj.$cmpKey : dataset.id}></ViewForm>
        <div className="btn-container mt-20">
          <Button type="default" htmlType="button" onClick={() => {this.discardChange(vfODM)}}>
            Cancel
          </Button>
          <Button type="primary" htmlType="submit" className="ml-20" onClick={() => {this.submitChange(vfODM, activeObj.did)}}>
            Apply
          </Button>
        </div>
      </>
    )

    return (
      <Dialog
        title={ this.props.title }
        windowId={this.props.windowId}
        visible={ this.props.visible }
        onClose={ this.handleClose.bind(this) }
        onMinimize={ this.handleMinimize.bind(this) }
        defaultWidth={1000}
        defaultHeight={600}
      >
        {/*<FlowEditor onInit={this.editorInit}*/}
        {/*            ref={this.editorRef}*/}
        {/*            value={this.state.fsObjects}*/}
        {/*            fsTemplate={this.state.fscfgData}*/}
        {/*            fsDataset={this.props.dataset}*/}
        {/*            fsODM = {this.flowSheetService.odm}*/}
        {/*            fsOE = {this.flowSheetService.oe}*/}
        {/*            fsService = {this.flowSheetService}*/}
        {/*            settingForm={activeNodeSetting}*/}
        {/*            onChange={(change) => this.onObjectChange(change) }*/}
        {/*            onSave={this.onSaveEmitted}*/}
        {/*            onSelect={this.onObjSelected}*/}
        {/*            onDeselected={this.onDeselected}*/}
        {/*            onConfig={this.onConfig}*/}
        {/*            onEvents={this.onEvents}*/}
        {/*></FlowEditor>*/}
      </Dialog>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  actions: Object.assign(
    {},
    bindActionCreators(WindowManagementActions, dispatch)
  )
})

export default connect(
  null,
  mapDispatchToProps
)(FlowSheetDialog)
