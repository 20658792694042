import KaTeX from 'katex'
import {
  findIndex,
  compact,
  isArray,
  isObject,
} from 'lodash'

const UNKNOWN = 'unknown'

const TOKEN_TYPES = {
  SUP_SUB: 'supsub',
  OP: 'op',
  ORD_GROUP: 'ordgroup',
  ATOM: 'atom',
  MATH_ORD: 'mathord',
  TEXT_ORD: 'textord',
  LEFT_RIGHT: 'leftright',
  GEN_FRAC: 'genfrac',
  SUP: 'sup',
  SUB: 'sub',
  POWER: 'power',
  EXP: 'exp',
  FRAC: 'frac',
  NUMER: 'numer',
  DENOM: 'denom',
  GROUP: 'group',
  LN: 'ln',
}

const OPERATION_TYPES = {
  PLUS: 'plus',
  MINUS: 'minus',
  MULTIPLY: 'multiply',
  DIVIDE: 'divide',
}

const OPERATION_TYPES_ARRAY = [
  OPERATION_TYPES.PLUS,
  OPERATION_TYPES.MINUS,
  OPERATION_TYPES.MULTIPLY,
  OPERATION_TYPES.DIVIDE,
];

const OPERATOR_EQUAL = '='
const OPERATOR_PLUS = '+'
const OPERATOR_MINUS = '-'
const OPERATOR_MULTIPLY = '\\cdot'
const OPERATOR_DIVIDE = '\\div'
const OPERATOR_LN = '\\ln'

const OPERATORS_FIRST = [
  OPERATOR_PLUS,
  OPERATOR_MINUS,
]

const OPERATORS_SECOND = [
  OPERATOR_MULTIPLY,
  OPERATOR_DIVIDE,
]

const EXP_BASE = 'e'

export function latexToJson(latex, variablesMap) {
  let tokens
  let formattedLatex = latex
  variablesMap.forEach(v => {
    formattedLatex = formattedLatex.replaceAll(
      v.formula,
      v.name.replaceAll('_', '\\_')
    )
  })

  try {
    tokens = KaTeX.__parse(formattedLatex)
  } catch (e) {
    console.log(e)
    return null
  }

  return mathematics(simpleParser(tokens))
}

function simpleParser(tokens, requireEqual = false) {
  if (!tokens || tokens.length === 0) {
    return null
  }

  const indexOfEqual = findIndex(tokens, t => t.type === TOKEN_TYPES.ATOM && t.text === OPERATOR_EQUAL)
  if (indexOfEqual > 0) {
    return splitTokens(tokens, indexOfEqual, 'equal')
  }

  const indexOfFirstOperator = findIndex(tokens, t => t.type === TOKEN_TYPES.ATOM && OPERATORS_FIRST.indexOf(t.text) >= 0)
  if (indexOfFirstOperator >= 0) {
    let opType
    switch (tokens[indexOfFirstOperator].text) {
      case OPERATOR_PLUS:
        opType = 'plus'
        break;
      case OPERATOR_MINUS:
        opType = 'minus'
        break;
      default:
        opType = UNKNOWN
        break;
    }

    return splitTokens(tokens, indexOfFirstOperator, opType)
  }

  const indexOfSecondOperator = findIndex(tokens, t => t.type === TOKEN_TYPES.ATOM && OPERATORS_SECOND.indexOf(t.text) >= 0)
  if (indexOfSecondOperator >= 0) {
    let opType
    switch (tokens[indexOfSecondOperator].text) {
      case OPERATOR_MULTIPLY:
        opType = 'multiply'
        break;
      case OPERATOR_DIVIDE:
        opType = 'divide'
        break;
      default:
        opType = UNKNOWN
        break;
    }
    return splitTokens(tokens, indexOfSecondOperator, opType)
  }

  const formattedTokens = combineConsecutiveText(tokens)
  if (formattedTokens.length > 1) {
      return {
        apply: {
          op: TOKEN_TYPES.GROUP,
          args: formattedTokens.map(tokenParser),
        }
      }
  } else {
    return tokenParser(formattedTokens[0])
  }
}

function splitTokens(tokens, index, opType) {
  const head = tokens.splice(0, index)
  const tail = tokens.splice(1, tokens.length)
  let args
  if (head.length === 0) {
    args = [simpleParser(tail)]
  } else if (tail.length === 0) {
    args = [simpleParser(head)]
  } else {
    args = [
      simpleParser(head),
      simpleParser(tail),
    ]
  }

  return {
    apply: {
      op: opType,
      args,
    }
  }
}

function tokenParser(token, opType) {
  if (!token) {
    return
  }

  if ([
    TOKEN_TYPES.SUB, TOKEN_TYPES.SUP, TOKEN_TYPES.DENOM, TOKEN_TYPES.NUMER
  ].indexOf(opType) >= 0) {
    if (token.type === TOKEN_TYPES.ORD_GROUP) {
      return {
        apply: {
          op: opType || UNKNOWN,
          args: simpleParser(token.body)
        }
      }
    } else if (
      token.type === TOKEN_TYPES.TEXT_ORD ||
      token.type === TOKEN_TYPES.MATH_ORD ||
      token.type === TOKEN_TYPES.ATOM
    ) {
      return {
        apply: {
          op: opType || UNKNOWN,
          args: [token.text]
        }
      }
    }
  } else if (token.type === TOKEN_TYPES.SUP_SUB) {
    return {
      apply: {
        op: token.type,
        base: tokenParser(token.base),
        args: compact([
          tokenParser(token.sub, TOKEN_TYPES.sub),
          tokenParser(token.sup, TOKEN_TYPES.SUP),
        ])
      }
    }
  } else if (token.type === TOKEN_TYPES.ORD_GROUP) {
    const formattedBody = combineConsecutiveText(token.body)
    if (formattedBody.length > 1) {
      return {
        apply: {
          op: opType || token.type,
          args: simpleParser(formattedBody.body)
        }
      }
    } else {
      return tokenParser(formattedBody[0])
    }
  } else if (token.type === TOKEN_TYPES.LEFT_RIGHT) {
    return {
      apply: {
        op: token.type,
        left: token.left,
        right: token.right,
        args: simpleParser(token.body)
      }
    }
  } else if (token.type === TOKEN_TYPES.GEN_FRAC) {
    return {
      apply: {
        op: TOKEN_TYPES.FRAC,
        args: compact([
          tokenParser(token.numer, TOKEN_TYPES.NUMER),
          tokenParser(token.denom, TOKEN_TYPES.DENOM),
        ])
      }
    }  
  } else if (token.type === 'op') {
    return {
      apply: {
        ...token,
        op: token.name.replace(/\\/g , ''),
      }
    }
  } else if (
    token.type === TOKEN_TYPES.TEXT_ORD ||
    token.type === TOKEN_TYPES.MATH_ORD ||
    token.type === TOKEN_TYPES.ATOM
  ) {
    return token.text
  }

  return token
}

function combineConsecutiveText(tokens) {
  return tokens && tokens.reduce((result, token, i) => {
    const prev = result[result.length - 1]
    if (
      isText(token) &&
      isText(prev)
    ) {
      result[result.length - 1] = {
        ...prev,
        text: prev.text + token.text.replaceAll('\\', '')
      }
    } else if (
      token.type === TOKEN_TYPES.SUP_SUB &&
      isText(prev) &&
      isText(token.base)
    ) {
      result[result.length - 1] = {
        ...token,
        base: prev.text + token.base.text
      }
    } else {
      result.push(token)
    }

    return result
  }, []) || []
}

function isText(token) {
  return token &&
    (token.type === TOKEN_TYPES.TEXT_ORD ||
      token.type === TOKEN_TYPES.MATH_ORD ||
      token.type === TOKEN_TYPES.ATOM)
}

function mathematics(parsedData) {
  // flatten continuous same operation
  if (
    parsedData &&
    parsedData.apply &&
    parsedData.apply.op &&
    OPERATION_TYPES_ARRAY.indexOf(parsedData.apply.op) >= 0
  ) {
    parsedData.apply.args = getFlattenSameOperationArgs(parsedData.apply.args, parsedData.apply.op)
  }

  // mathematics
  if (
    parsedData &&
    parsedData.apply &&
    isArray(parsedData.apply.args)
  ) {
    parsedData.apply.args.forEach(op => {
      detectOperationArgs(op, parsedData)

      if (
        op.apply &&
        op.apply.args
      ) {
        mathematics(op)
      }
    })
    parsedData.apply.args = parsedData.apply.args.filter(item => !!item)
    if (parsedData.apply.args.length === 0) {
      delete parsedData.apply.args
    }
  } else if (
    parsedData &&
    parsedData.apply &&
    isObject(parsedData.apply.args)
  ) {
    detectOperationArgs(parsedData.apply.args, parsedData)
    if (
      parsedData.apply.args.apply &&
      parsedData.apply.args.apply.args
    ) {
      mathematics(parsedData.apply.args)
    }
  }

  return parsedData;
}

// mathematics ops
function detectOperationArgs(curOp, parentOp) {
  if (!curOp || !curOp.apply) {
    return
  }

  if (parentOp.apply.op === TOKEN_TYPES.SUP_SUB) {
    if (curOp.apply.op === TOKEN_TYPES.SUP) {
      if (
        isObject(curOp.apply.args) &&
        !isArray(curOp.apply.args) &&
        curOp.apply.args.apply.op === TOKEN_TYPES.LEFT_RIGHT
      ) {
        curOp.apply.args = curOp.apply.args.apply.args
      }

      if (
        parentOp.apply.base === EXP_BASE &&
        !parentOp.apply.sub &&
        !parentOp.apply.args.some(item => item.apply.op === TOKEN_TYPES.SUB)
      ) {
        const curOpIndex = parentOp.apply.args.indexOf(curOp)
        parentOp.apply.op = TOKEN_TYPES.EXP
        parentOp.apply.arg = curOp.apply.args
        parentOp.apply.args[curOpIndex] = null
        delete parentOp.apply.base
      } else {
        const curOpIndex = parentOp.apply.args.indexOf(curOp)
        parentOp.apply.op = TOKEN_TYPES.POWER
        parentOp.apply.arg = curOp.apply.args
        parentOp.apply.args[curOpIndex] = null
      }
    }

    if (curOp.apply.op === TOKEN_TYPES.SUB) {
      const curOpIndex = parentOp.apply.args.indexOf(curOp)
      parentOp.apply.sub = curOp.apply.args
      parentOp.apply.args[curOpIndex] = null
    }
  }

  if (parentOp.apply.op === TOKEN_TYPES.FRAC) {
    const curOpIndex = parentOp.apply.args.indexOf(curOp)
    parentOp.apply.args[curOpIndex] = curOp.apply.args
  }

  if (parentOp.apply.op === TOKEN_TYPES.GROUP) {
    if (curOp.apply.op === TOKEN_TYPES.LN) {
      const curOpIndex = parentOp.apply.args.indexOf(curOp)
      parentOp.apply.op = TOKEN_TYPES.LN
      parentOp.apply.base = EXP_BASE
      parentOp.apply.args[curOpIndex] = null
    }
  }

  if (parentOp.apply.op === TOKEN_TYPES.LN) {
    if (
      isObject(curOp.apply.args) &&
      !isArray(curOp.apply.args) &&
      curOp.apply.op === TOKEN_TYPES.LEFT_RIGHT
    ) {
      parentOp.apply.arg = curOp.apply.args.apply.args
    } else {
      parentOp.apply.arg = curOp.apply.args
    }
    const curOpIndex = parentOp.apply.args.indexOf(curOp)
    parentOp.apply.args[curOpIndex] = null
  }
}


// flatten continuous same operation
function getFlattenSameOperationArgs(ops, operationType) {
  return ops.reduce((result, op) => {
    if (
      !op.apply ||
      op.apply.op !== operationType
    ) {
      result.push(op)
    } else {
      result = result.concat(
        getFlattenSameOperationArgs(
          op.apply.args,
          operationType
        )
      )
    }

    return result
  }, [])
}
