import React from 'react'
import Editor from '@monaco-editor/react';
import ABase from './a-base';

class AMonacoEditor extends ABase {
  constructor(props) {
    super(props);
  }

  static getDerivedStateFromProps(props, state) {
    if (props.editMode !== state.editMode) {
      return {
        editMode: props.editMode
      };
    }

    return {}
  }

  onChange = (value, event) => {
    this.onAttChange(value)
  }

  render() {
    if (this.state.editMode) {
      return (
        <Editor
          height="100px"
          defaultLanguage="javascript"
          defaultValue={this.state.value}
          onChange={this.onChange}
        />
      )
    } else {
      return (
        <pre>{this.state.value}</pre>
      )
    }
  }
}

export default AMonacoEditor
