import React from 'react'
import styles from './view-form-dialog.module.less'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as WindowManagementActions from '../../../stores/window-management/actions'

import Dialog from '../../shared/dialog/dialog'
import { getViewTableByVFObj, ODMHelper, ViewForm } from 'orbit-components';
import classNames from 'classnames';
import { svgStrToImg } from '../../../utils/utils';
import {loadVersionThread, saveTableDesign} from '../../../services/view-form.service';
import ODM from 'odm'

// onSubmit
class ViewFormDialog extends React.Component {
  componentDidMount () {
      this.props.actions.setWindowTitle(this.props.windowId, this.dialogTitle);
  }

  constructor (props) {
    super(props);
    const {vFObject, vFCode} = this.props
    const  viewCode = vFCode || 'DEFAULT'
    const view = getViewTableByVFObj(vFObject, viewCode)

    this.state = {
      view,
      viewCode
    }
  }

  createVFTitle() {
    const view = this.state.view
    if (!view) {
      return <div className={styles.viewFormTitle}>Temporary Dialog Title</div>
    }
    return (
      <div className={styles.viewFormTitle}>
        <div className={styles.iconContainer}>{svgStrToImg(view.icon)}</div>
        <div className={classNames('ml-5', styles.title)} title={view.title}>{view.title}</div>
      </div>
    )
  }

  handleClose () {
    this.props.actions.removeWindow(this.props.windowId);
  }

  handleMinimize () {
    this.props.actions.setWindowVisible(this.props.windowId, false);
  }

  onSubmit = (changes) => {
    ODMHelper.vfChangesToOdm(changes, this.props.vFObject)
    if (this.props.onSubmit) this.props.onSubmit()
    ODM.saveAllDataToBrowser()
  }

  onRefresh = () => {
    const view = getViewTableByVFObj(this.props.vFObject, this.state.viewCode)
    this.setState({
      view
    })
  }

  saveTableDesign = (view, vCode) => {
    saveTableDesign(view, vCode, this.props.vFObject)

    setTimeout(() => {
      ODM.saveAllDataToBrowser()
      this.onRefresh()
    }, 200)
  }

  onTableDesign = (viewCode, key) => {
    const view = getViewTableByVFObj(this.props.vFObject, this.state.viewCode)

    this.props.actions.addWindow({
      type: 'ViewFormTableDesign',
      props: {
        view,
        title: '',
        updateViewForm: (view) => {
          this.saveTableDesign(view, viewCode)
        }
      }
    })
  }

  onViewDesign = (viewCode, key) => {
    const view = getViewTableByVFObj(this.props.vFObject, viewCode)
    let rDataset = ODMHelper.getRDByOdmObj(this.props.vFObject)
    let vFDesignProps = {
      title: 'Temporary Dialog Title',
      view: view,
      updateTableDesign: (view) => {
        this.saveTableDesign(view, viewCode)
      },
      ownerRDId: rDataset.id,
      rDataset: rDataset,
      vfCode: viewCode || 'DEFAULT',
    }

    this.props.actions.addWindow({
      type: 'ViewFormDesign',
      props: vFDesignProps
    });
  }

  changeView = (viewCode) => {
    const view = getViewTableByVFObj(this.props.vFObject, viewCode)
    this.setState({
      title: 'Design the view table',
      view,
      viewCode
    })
  }

  getVersions = () => {
    const rd = ODMHelper.getRDByOdmObj(this.props.vFObject)

    return loadVersionThread(rd)
  }

  createBridge = () => {

    return {
      onSubmit: this.onSubmit,
      onRefresh: this.onRefresh,
      onTableDesign: this.onTableDesign,
      onViewDesign: this.onViewDesign,
      onChangeView: this.changeView,
      onCreate: this.onCreate,
      onRemove: this.onDelete,
      getVersions: this.getVersions
    }
  }

  onCreate = (view, cell) => {
    const {parentD, rd2rd} = cell.tempView
    // ODMHelper.createSubD(parentD, rd2rd)
    let vFDesignProps = {
      parentD,
      rd2rd,
      onSubmit: this.afterCreated,
      vfCode: cell.tempObj.code
    }
    this.props.actions.addWindow({
      type: 'AddChildDialog',
      props: vFDesignProps,
    });
  }

  afterCreated = () => {
    this.onRefresh()
  }

  onDelete = (view) => {
    ODMHelper.deleteD2D(view.vfObj)
    this.onRefresh()
  }

  render () {

    return (
      <Dialog
        title={ this.createVFTitle() }
        visible={ this.props.visible }
        onClose={ this.handleClose.bind(this) }
        onOpen={ (this.props.onOpen && this.props.onOpen.bind(this))}
        onMinimize={ this.handleMinimize.bind(this) }
        noContentPadding={ true }
        windowId={this.props.windowId}
      >
        {this.state.view && <ViewForm view={this.state.view} formBridge={this.createBridge()}/>}
        {!this.state.view && <div>There is no view found.</div>}
      </Dialog>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  actions: Object.assign(
    {},
    bindActionCreators(WindowManagementActions, dispatch)
  )
})

export default connect(
  null,
  mapDispatchToProps
)(ViewFormDialog)
