import React from 'react'
import {Dropdown, Menu} from "antd";
import ODM from 'odm'
import OE from 'oe'
import { bindActionCreators } from 'redux';
import * as WindowManagementActions from '../../../stores/window-management/actions';
import { connect } from 'react-redux';
import { getViewFormTypeTitle } from '../../window-management/utils';
import * as OdataTreeActions from '../../../stores/odata-tree/odata-tree-actions';
class RvwMenu extends React.Component {
  constructor(props) {
      super(props);
  }

  onClickMenuItem(treeNodeData, type, e) {

    const { odmType } = treeNodeData;
    const rd2rdRelation = (odmType === "rd2rd") && ODM.getRD2RDRelNoLoadNoExtd(treeNodeData.odmId);
    const rootDataset = (rd2rdRelation && ODM.getRDNoLoadNoExtd(rd2rdRelation.tid)) || ODM.getRDNoLoadNoExtd(treeNodeData.odmId);
    switch (type) {
      case "open":
        {
          const { formCode } = treeNodeData;
          this.props.actions.addWindow({
            type: 'ViewForm',
            props: {
              title: 'ViewForm',
              visible: true,
              vFObject: rd2rdRelation || rootDataset,
              dataset: undefined, // todo get the default object
              vFCode: formCode
            }
          });
        }
        break;
      case "refresh":
        this.props.actions.updateODataTreeAction()
        break;
      case "rename":
        {
          const { formCode } = treeNodeData
          const vForm = rootDataset.gui[formCode]
          const formDescription = OE.getDescription(vForm)
          this.props.actions.addWindow({
            type: 'RenameViewDialog',
            props: {
              title: 'Rename',
              visible: true,
              odmObject: vForm,
              code: formCode,
              description: formDescription,
              vFRDId: rootDataset.id,
              onSubmit: this.onRename
            }
          });
        }
        break;
      case "delete":
        {
          const { formCode } = treeNodeData
          const subTitle = getViewFormTypeTitle(rootDataset)
          const title = `Delete View-Form ${formCode} - ${subTitle}`
          const description = OE.getDescription(rootDataset.gui[formCode])

          const content = <div>Do you want to proceed with deleting view-form <span className="primary-color">{formCode} - [{description}]</span> . Please confirm!</div>
          this.props.actions.addWindow({
            type: 'DeleteDialog',
            props: {
              title: title,
              content: content,
              visible: true,
              vFObject: rootDataset,
              onSubmit: this.onDelete
            }
          });
        }
        break;
    }
  }

  onDelete = (vFObject) => {
    const { treeData } = this.props
    const { formCode } = treeData;
    const rootDataset = ODM.getRDNoLoadNoExtd(treeData.odmId);
    OE.deleteRDV(rootDataset, formCode)
    this.props.actions.updateODataTreeAction()
  }

  onRename = (vFObject, value) => {
    const rootDataset = ODM.getRDNoLoad(value.vFRDId);
    if(value.code) {
      let isChange = false;
      const viewForm = rootDataset.gui[value.oldVFCode];
      if (value.code !== value.oldVFCode) {
        rootDataset.gui[value.code] = viewForm;
        delete rootDataset.gui[value.oldVFCode];
        isChange = true;
      }
      if (OE.getDescription(viewForm) !== value.description) {
        OE.setDescription(viewForm, value.description);
        isChange = true;
      }
      if(isChange) {
        ODM.updateRD(rootDataset, value.vFRDId);
      }
      this.props.actions.updateODataTreeAction()
    }
  }

  render() {
      const { treeData, onVisibleChange } = this.props
      const { formCode } = treeData;
      const rd2rdRelation = ODM.getRD2RDRelNoLoadNoExtd(treeData.odmId);
      const rootDataset = rd2rdRelation && rd2rdRelation.tid && ODM.getRDNoLoadNoExtd(rd2rdRelation.tid);
      const rvwMenu = (
          <Menu forceSubMenuRender={false}>
              <Menu.Item key="open" onClick ={(e) => {e.domEvent.nativeEvent.noTreeSelect = true; this.onClickMenuItem(treeData,'open', e);}} >
                  Open
              </Menu.Item>
              <Menu.Item key="refresh" onClick ={(e) => {e.domEvent.nativeEvent.noTreeSelect = true; this.onClickMenuItem(treeData,'refresh', e);}} >
                  Refresh
              </Menu.Item>
              {rootDataset && rootDataset.gui[formCode] &&
                <Menu.Item key="rename" onClick ={(e) => {e.domEvent.nativeEvent.noTreeSelect = true; this.onClickMenuItem(treeData,'rename', e);}} >
                  Rename
                </Menu.Item>
              }
              {rootDataset && rootDataset.gui[formCode] &&
                <Menu.Item key="delete" onClick ={(e) => {e.domEvent.nativeEvent.noTreeSelect = true; this.onClickMenuItem(treeData,'delete', e);}} >
                    Delete
                </Menu.Item>
              }
          </Menu>
      );
      return (<Dropdown overlay={rvwMenu} onVisibleChange={onVisibleChange} >
          <a className="ant-dropdown-link">
              {treeData.title}
          </a>
      </Dropdown>);
  }
}

const mapDispatchToProps = dispatch => ({
  actions: Object.assign(
    {},
    bindActionCreators(WindowManagementActions, dispatch),
    bindActionCreators(OdataTreeActions, dispatch),
  )
});

export default connect(
  null,
  mapDispatchToProps
)(RvwMenu)
