import React from 'react'
import {Dropdown, Menu} from "antd";
class DMenu extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { treeData, onVisibleChange, onClickMenuItem } = this.props
        const dMenu = (
            <Menu forceSubMenuRender={false}>
                <Menu.Item key="open" onClick ={(e) => {e.domEvent.nativeEvent.noTreeSelect = true; onClickMenuItem(treeData,'open', e);}} >
                    Open
                </Menu.Item>
                <Menu.Item key="refresh" onClick ={(e) => {e.domEvent.nativeEvent.noTreeSelect = true; onClickMenuItem(treeData,'refresh', e);}} >
                    Refresh
                </Menu.Item>
                <Menu.Item key="rename" onClick ={(e) => {e.domEvent.nativeEvent.noTreeSelect = true; onClickMenuItem(treeData,'rename', e);}} >
                    Rename
                </Menu.Item>
                <Menu.Item key="d-delete" onClick ={(e) => {e.domEvent.nativeEvent.noTreeSelect = true; onClickMenuItem(treeData,'d-delete', e);}} >
                    Delete
                </Menu.Item>
                <Menu.Item key="d-json" onClick ={(e) => {e.domEvent.nativeEvent.noTreeSelect = true; onClickMenuItem(treeData,'d-json', e);}} >
                    View JSON
                </Menu.Item>
            </Menu>
        );
        return (<Dropdown overlay={dMenu} onVisibleChange={onVisibleChange} >
            <a className="ant-dropdown-link">
                {treeData.title}
            </a>
        </Dropdown>);
    }
}

export default DMenu
