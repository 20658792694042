import React from 'react'
import { InboxOutlined } from '@ant-design/icons';
import Dragger from 'antd/es/upload/Dragger';
import ABase from './a-base';

class AAntdFileDnd extends ABase {
  constructor(props) {
    super(props);
  }

  static getDerivedStateFromProps(props, state) {
    if (props.editMode !== state.editMode) {
      return {
        editMode: props.editMode
      };
    }

    return {}
  }

  onUpload = (event) => {
    console.log(event)
  }

  renderValue() {
    let files = this.state.value
    const contents = files.map((item, index) => {
      // Todo Jul15 2021: Added the if-statement to get rid of an exception.  Long-term fix is required. 
      if(item && item.file) {
        const [temp, file] = item
        if (!file) {
          return temp
        }
        let liContent = ''
        if (file.type && file.type.startsWith('image')) {
          liContent = (<img src={file.src} alt="" />)
        } else {
          liContent = file.name
        }

        return (
          <li key={index}>
            {temp}-{liContent}
          </li>
        )
      }
    })

    return (
      <ul>
        {contents}
      </ul>
    )
  }

  render() {
    const props = {
      name: 'file',
      multiple: true,
      customRequest: this.onUpload
    }

    let files = this.state.value
    let fileList = ''
    if (files && files.length > 0) {
      fileList = this.renderValue()
    }

    return (
      <React.Fragment>
        {fileList}
        {this.state.editMode && <Dragger {...props}>
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">Click or drag file to this area to upload</p>
          <p className="ant-upload-hint">
            Support for a single or bulk upload. Strictly prohibit from uploading company data or other
            band files
          </p>
        </Dragger>}
      </React.Fragment>
    )
  }
}

export default AAntdFileDnd
