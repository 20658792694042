import { useSlate } from 'slate-react';
import React, { useState } from 'react';
import { Editor, Element as SlateElement, Transforms } from 'slate';
import classNames from 'classnames';
import TableBuilder from '../components/table-builder/table-builder';

const NODE_TYPES = ['table', 'table-row']

const PopupActionButton = ({ format, icon }) => {
  const editor = useSlate()
  const [isOpen, setIsOpen] = useState(false)
  let popupContent = ''

  const onTableSelect = (val) => {
    setIsOpen(false)
    // do set a table to editor
    if (format === 'table') {
      const table = {
        type: 'table',
        children: new Array(val.row).fill(0).map(() => {
          return {
            type: 'table-row',
            children: new Array(val.col).fill(0).map(() => {
              return {
                type: 'table-cell',
                children: [{ text: 'aa' }]
              }
            })
          }
        }),
      }
      Transforms.insertNodes(editor, table)
    }
  }

  if (format === 'table') {
    popupContent = (<TableBuilder onSelect={onTableSelect} />)
  }
  return (
    <button
      className={classNames('icon-btn', 'popup-action')}
      onClick={event => {
        event.preventDefault()
        setIsOpen(!isOpen)
      }}
    >
      <i className={classNames('iconfont', icon)}></i>
      {isOpen && (<div className={classNames('popup-container')}>{popupContent}</div>)}
    </button>
  )
}

export default PopupActionButton
