import React from 'react'
import ABase from './a-base'
import { treeNodeIcon } from '../../odata-tree/tree-node-icon'
import { ReactComponent as DefaultTreeIcon } from '../../../../assets/icons/tree-default.svg'
import styles from './editors.module.less'
import classNames from 'classnames'
import DropEditor from './drop-editor';
import { Select } from 'antd';


class RdEditor extends ABase {
  constructor(props) {
    super(props);
    if (props.options && props.options.length) {
      // Lyon: Add the RDs to a drop down menu for the input field.  Display should be as in the ojbect
      this.dropDownRDs = props.options.map((rdId) => this.odm.getRDNoLoadNoExtd(rdId))
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (props.editMode !== state.editMode) {
      return {
        editMode: props.editMode
      };
    }

    return {}
  }

  doCheck(node) {
    const nodeData = node.dataRef
    if (nodeData.nodeType === 'rd' || nodeData.nodeType === 'rd2rd') {
      return true
    }

    return false
  }

  onDrop(e) {
    const { dragNode } = e
    if (this.doCheck(dragNode)) {
      const nodeData = dragNode.dataRef
      let value;
      if (nodeData.nodeType === 'rd2rd') {
        const rd2rd = this.odm.getRD2RDRelNoLoadNoExtd(nodeData.odmId)
        value = rd2rd && rd2rd.tid
      } else {
        value = nodeData && nodeData.odmId
      }
      if (value) {
        this.onAttChange(value)
      }
    }
  }

  onRemove() {
    this.onAttChange(undefined)
  }

  onSelectRD(rdId) {
    this.onAttChange(rdId)
  }

  renderRd() {
    const rd = (this.state.value || this.state.value === 0) && this.odm.getRDNoLoadNoExtd(this.state.value)
    if (!rd) {
      return ''
    }

    const icon = this.oe.getSVGIconCodeForRD(rd)
    const iconElem = treeNodeIcon(icon) || <DefaultTreeIcon style={{verticalAlign: '-.125em', height:'1.1em', width:'1.1em', color: '#7f0000'}}/>

    return <div className={classNames(styles.editorContent)}>
      {iconElem}{rd.cod}
    </div>

  }


  renderEditor() {
    if (Array.isArray(this.dropDownRDs)) {
      const options = this.dropDownRDs.map(item => {
        const icon = this.oe.getSVGIconCodeForRD(item)
        const iconElem = treeNodeIcon(icon) || <DefaultTreeIcon style={{verticalAlign: '-.125em', height:'1.1em', width:'1.1em', color: '#7f0000'}}/>

        return <Select.Option value={item.id}><div className={classNames(styles.editorContent)}>{iconElem}{item.cod}</div></Select.Option>
      })

      return <Select style={{ width: '100%' }} onChange={(val) => this.onSelectRD(val)}>{options}</Select>
    }

    return <DropEditor
      onDrop={(e) => this.onDrop(e)}
      doCheck={(node) => this.doCheck(node)}
      onRemove={() => this.onRemove()}
      isEmpty={!(this.state.value || this.state.value === 0)}
    >
      {this.renderRd()}
    </DropEditor>
  }

  render() {

    return this.state.editMode ? this.renderEditor() : this.renderRd()
  }
}

export default RdEditor
