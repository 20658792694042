import React from 'react'
import styles from './odm-report-dialog.module.less'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as WindowManagementActions from '../../../stores/window-management/actions'
import ODM from 'odm'

import Dialog from '../../shared/dialog/dialog'
import AntDatasetTable from './dataset-table';
import AntRootDatasetTable from './root-dataset-table';
import AntRootAttributeTable from './root-attribute-table';
import AntAttributeTable from './attribute-table';
import AntRelationTable from './relation-table';
import { Tabs } from 'antd';
import { getDialogTitle } from '../utils';
import { AreaChartOutlined } from '@ant-design/icons';

class ODMReportDialog extends React.Component {
  constructor (props) {
    super(props)
    let dialogTitle
    switch(this.props.objectState) {
      case "loaded":
        dialogTitle = "Loaded Objects";
        break
      case "new":
        dialogTitle = "New Objects";
        break
      case "modified":
        dialogTitle = "Modified Objects";
        break
      case "nosave":
        dialogTitle = "No-Save Objects";
        break
      case "deleted":
        dialogTitle = "Deleted Objects";
        break
      case "local":
        dialogTitle = "Local Objects";
        break
    };
    this.title = getDialogTitle(
      <AreaChartOutlined />,
      dialogTitle
    )
    this.props.actions.setWindowTitle(this.props.windowId, this.title);
  }

  handleClose () {
    this.props.actions.removeWindow(this.props.windowId)
  }

  handleMinimize () {
    this.props.actions.setWindowVisible(this.props.windowId, false)
  }

  render () {
    let rDs, ds, rAtts, atts, rd2rdRels, rd2dRels, d2dRels;


    switch(this.props.objectState) {
        case "loaded":
            ds = ODM.getLoadedDS();
            rDs = ODM.getLoadedRDS();
            atts = ODM.getLoadedAtts();
            rAtts = ODM.getLoadedRAtts();
            rd2rdRels = ODM.getLoadedRD2RDRels();
            rd2dRels = ODM.getLoadedRD2DRels();
            d2dRels = ODM.getLoadedD2DRels();
            break
        case "new":
            ds = ODM.getNewDS();
            rDs = ODM.getNewRDS();
            atts = ODM.getNewAtts();
            rAtts = ODM.getNewRAtts();
            rd2rdRels = ODM.getNewRD2RDRels();
            rd2dRels = ODM.getNewRD2DRels();
            d2dRels = ODM.getNewD2DRels();
            break
        case "modified":
            ds = ODM.getModifiedDS();
            rDs = ODM.getModifiedRDS();
            atts = ODM.getModifiedAtts();
            rAtts = ODM.getModifiedRAtts();
            rd2rdRels = ODM.getModifiedRD2RDRels();
            rd2dRels = ODM.getModifiedRD2DRels();
            d2dRels = ODM.getModifiedD2DRels();
            break
        case "nosave":
            ds = ODM.getNoSaveDS();
            rDs = ODM.getNoSaveRDS();
            atts = {};
            rAtts = {};
            rd2rdRels = {};
            rd2dRels = {};
            d2dRels = {};
            break
        case "deleted":
            ds = ODM.getDeletedDS();
            rDs = ODM.getDeletedRDS();
            atts = ODM.getDeletedAtts();
            rAtts = ODM.getDeletedRAtts();
            rd2rdRels = ODM.getDeletedRD2RDRels();
            rd2dRels = ODM.getDeletedRD2DRels();
            d2dRels = ODM.getDeletedD2DRels();
            break
        case "local":
            ds = ODM.getLocalDS();
            rDs = undefined;
            atts = ODM.getLocalAtts();
            rAtts = undefined;
            rd2rdRels = ODM.getLocalRD2RDRels();
            rd2dRels = ODM.getLocalRD2DRels();
            d2dRels = ODM.getLocalD2DRels();
            break
    };



    return (
      <Dialog
        title={ this.props.title }
        windowId={this.props.windowId}
        visible={ this.props.visible }
        onClose={ this.handleClose.bind(this) }
        onOpen={ this.props.onOpen.bind(this) }
        onMinimize={ this.handleMinimize.bind(this) }
      >
        <Tabs defaultActiveKey="1">
          <Tabs.TabPane tab="Root Datasets" key="1">
            <AntRootDatasetTable rootDatasets={rDs}></AntRootDatasetTable>
          </Tabs.TabPane>
          <Tabs.TabPane tab="Datasets" key="2">
            <AntDatasetTable datasets={ds} />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Root Attributes" key="3">
            <AntRootAttributeTable rootAttributes={rAtts}/>
          </Tabs.TabPane>
          <Tabs.TabPane tab="Attributes" key="4">
            <AntAttributeTable attributes={atts} />
          </Tabs.TabPane>
          <Tabs.TabPane tab="RD2RD" key="5">
            <AntRelationTable relations={rd2rdRels} type='RD2RD' />
          </Tabs.TabPane>
          <Tabs.TabPane tab="RD2D" key="6">
            <AntRelationTable relations={rd2dRels} type='RD2D' />
          </Tabs.TabPane>
          <Tabs.TabPane tab="D2D" key="7">
            <AntRelationTable relations={d2dRels} type='D2D' />
          </Tabs.TabPane>
        </Tabs>
      </Dialog>
    )
  }
}

const mapDispatchToProps = dispatch => ({
  actions: Object.assign(
    {},
    bindActionCreators(WindowManagementActions, dispatch)
  )
})

export default connect(
  null,
  mapDispatchToProps
)(ODMReportDialog)

