import React, { memo, useState } from 'react';
import { Dropdown, Menu, Modal, Select } from 'antd';
import { DownOutlined, SettingOutlined } from '@ant-design/icons';
import { ColumnEditorsMap } from './index';
import ODM from 'odm'

export const ColumnSettingDropdown = memo(({onSubmitEditor, column, onFilter}) => {
  const [showModal, setShowModal] = useState(false)
  const rA = (column.colCfg.deepRAId || column.colCfg.deepRAId === 0) && ODM.getRAttNoLoadNoExtd(column.colCfg.deepRAId)
  const rAType = rA && rA.dtp.toLowerCase().endsWith("_ar") && rA.dtp.substring(0, rA.dtp.length - 3)
  const editorCfgD = (column.colCfg.edtCfgDId || column.colCfg.edtCfgDId) && ODM.getDNoLoadNoExtd(column.colCfg.edtCfgDId)
  const editorCfgRD = editorCfgD && ODM.getRDNoLoadNoExtd(editorCfgD.rid)
  const edtRDCode = editorCfgRD && editorCfgRD.cod && editorCfgRD.cod.startsWith("SYS.AECFG.SPDSHT.COL.EDTCFG.") && editorCfgRD.cod.substr(28)
  const filteredEditorMapEntry = rAType && ColumnEditorsMap[rAType] && ColumnEditorsMap[rAType].filter(entry => entry.rDCode === edtRDCode)
  const initColumnEditor = filteredEditorMapEntry && filteredEditorMapEntry.length === 1 && filteredEditorMapEntry[0].key
  const [columnEditor, setColumnEditor] = useState(initColumnEditor)

 const onEditorTypeClick = () => {
    setShowModal(true)
  }

  const handleOk = () => {
    setShowModal(false)
    onSubmitEditor(columnEditor)
  }

  const handleCancel = () => {
    setShowModal(false)
  }

  const onSelectEditor = (key) => {
    setColumnEditor(key)
  }

  const onSort = (e) => {
    const sortProps = column.getHeaderProps(column.getSortByToggleProps())
    console.log(sortProps)
    // sortProps.onClick(e)
  }

  const columnHiddenProps = column.getToggleHiddenProps()
  const activeSortStyle = {color: '#1890ff'}

  const menu = (
    <Menu>
      <Menu.Item onClick={() => columnHiddenProps.onChange({target: {checked: false}})}>Hide</Menu.Item>
      <Menu.Item onClick={onFilter}>Filter</Menu.Item>
      <Menu.Item><div {...column.getHeaderProps(column.getSortByToggleProps())}>Toggle Sort {column.isSorted ? column.isSortedDesc ? '(desc)' : '(asc)' : ''}</div></Menu.Item>
      <Menu.SubMenu title="Rendering">
        <Menu.Item onClick={onEditorTypeClick}>Set editor type</Menu.Item>
        <Menu.Item>Delete editor configuration</Menu.Item>
        <Menu.Item>Open editor configuration</Menu.Item>
      </Menu.SubMenu>
    </Menu>
  )

  return (
    <>
      <Dropdown overlay={menu}>
        <div className="ant-dropdown-link"><SettingOutlined /></div>
      </Dropdown>

      <Modal title="Set column editor type" visible={showModal} onOk={handleOk} onCancel={handleCancel}>
        <Select value={columnEditor} onChange={onSelectEditor} style={{width: '100%'}}>
          {rAType && ColumnEditorsMap[rAType] && ColumnEditorsMap[rAType].map((item, index) => {
            return (
              <Select.Option value={item.key} key={index}>{item.text}</Select.Option>
            )
          })}
        </Select>
      </Modal>
    </>
  )
})
