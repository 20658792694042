import React from 'react'
import ODM from 'odm'
import OE from 'oe'
import {bindActionCreators} from "redux";
import * as WindowManagementActions from "../../../../stores/window-management/actions";
import {connect} from "react-redux";
import ODrag from "../../../shared/o-drag/o-drag";
import CreateConfigDataset from './create-config-dataset';
import { Select } from 'antd';
import styles from './inline-drag-design.module.less'
import { CloseOutlined } from '@ant-design/icons';

class CellDragDesign extends React.PureComponent {
  constructor(props) {
    super(props)
  }

  openDataset(d) {
    let vfObject = ODM.getDNoLoadNoExtd(d.id);
    this.props.actions.addWindow({
      type: 'ViewForm',
      props: {
        title: 'ViewForm',
        visible: true,
        vFObject: vfObject
      }
    });
  }

  onDrop(e) {
    const { dragNode } = e
    if(dragNode && this.doCheck(dragNode)) {
      this.props.onDrop(dragNode, this.props.cell)
    }
  }

  onCreatedCfgD(cfgD) {
    this.props.onSetCfgDId(cfgD.id, this.props.cell)
  }

  doCheck(node) {
    const nodeData = node.dataRef
    const rDataset = this.props.rDataset
    if (nodeData.nodeType === 'ra' || nodeData.nodeType === 'rd2rd') {
      const rd = ODM.getRDNoLoadNoExtd(rDataset.id)
      if (nodeData.nodeType === 'ra') {
        const rAtts = OE.getRAtts(rd, [])
        return rAtts.includes(nodeData.odmId)
      } else {
        const rd2rd = ODM.getRD2RDRelNoLoad(nodeData.odmId)
        const sourceRD = rd2rd && Number.isInteger(rd2rd.sid) && ODM.getRDNoLoadNoExtd(rd2rd.sid)
        if(sourceRD && OE.isExtensionOfByRD(rDataset, sourceRD)) {
          return true
        }
      }
    }

    if (nodeData.nodeType === 'rvw') {
      const rd2rd = ODM.getRD2RDRelNoLoad(nodeData.odmId)
      const targetRD = rd2rd && Number.isInteger(rd2rd.tid) && ODM.getRDNoLoadNoExtd(rd2rd.tid)
      const sourceRD = rd2rd && Number.isInteger(rd2rd.sid) && ODM.getRDNoLoadNoExtd(rd2rd.sid)
      if((targetRD && OE.isExtensionOfByRD(rDataset, targetRD)) || (sourceRD && OE.isExtensionOfByRD(rDataset, sourceRD))) {
        return true
      }
      return false
    }

    return false
  }

  onSetVFCode(formCode) {
    this.props.onSetVFCode(formCode, this.props.cell)
  }

  renderAttr() {
    const cell = this.props.cell

    const cellCfg = cell.cellCfg
    const tempObj = cell.tempObj
    const raid = tempObj.id
    const rAtt = ODM.getRAttNoLoadNoExtd(raid);
    const cfgD = ODM.getDNoLoadNoExtd(cellCfg.d?.id);
    const cfgRD = cfgD && ODM.getRDNoLoadNoExtd(cfgD.rid);
    const dsc = (cfgRD && OE.getDescription(cfgRD)) || ''
    const label = (rAtt && OE.getDescription(rAtt)) || "...";

    const isCreate = !(cfgD && cfgD.id)
    let dscTail
    if (isCreate) {
      dscTail = <CreateConfigDataset rADTP={rAtt.dtp} onCreated={(d) => this.onCreatedCfgD(d)}>[Create New]</CreateConfigDataset>
    } else {
      dscTail = <a onClick={() => this.openDataset(cfgD)} onKeyDown={() => this.openDataset(cfgD)}>[{cfgD && cfgD.id}]</a>
    }

    return (
      <div className={styles.attributeContent}>
        <p>{label}</p>
        <p>
          {dsc}
          {dscTail}
        </p>
        {!isCreate && <div className={styles.close} title="Remove Dataset"
             onClick={() => this.onRemove()} onKeyDown={() => this.onRemove()}><CloseOutlined/></div>}
      </div>
    )
  }

  renderDataset(mode) {
    const cell = this.props.cell

    const tempObj = cell.tempObj
    const cfgRD2RDRel = mode === 'rd2rd' && ODM.getRD2RDRelNoLoadNoExtd(tempObj.id)
    const subRD = (cfgRD2RDRel && ODM.getRDNoLoadNoExtd(cfgRD2RDRel.tid)) || ODM.getRDNoLoadNoExtd(this.props.rDataset.id)
    if (!subRD) {
      return (
        <React.Fragment>
          <p style={{wordBreak: 'break-word'}}>...</p>
        </React.Fragment>
      )
    }
    const label = OE.getDescription(subRD) || "...";
    const curVFs = OE.getVFs(subRD);
    const viewFormOptions = Object.keys(curVFs).map((vfCode, index) => {
      const vForm = curVFs[vfCode]
      const dsc = OE.getDescription(vForm) || 'undefined'

      return (
        <Select.Option key={index} value={vfCode}>{dsc}[{vfCode}]</Select.Option>
      )
    })

    const cellTitle = (cfgRD2RDRel && <p style={{wordBreak: 'break-word'}}>{label}[{subRD.cod}]</p>) || ""

    return (
      <React.Fragment>
        {cellTitle}
        <Select
          onChange={e => this.onSetVFCode(e)}
          style={{width: '200px'}}
          value={tempObj.code || 'DEFAULT'}
        >{viewFormOptions}</Select>
      </React.Fragment>
    )
  }

  model() {
    const tempObj = this.props.cell.tempObj
    if (!tempObj) {
      return null
    }
    if (tempObj.type === 'ra' && tempObj.id) {
      return 'a'
    }
    if (tempObj.type === 'vf' && tempObj.id) {
      return 'rd2rd'
    }

    if (tempObj.type === 'vf' && !tempObj.id) {
      return 'vf'
    }

    return null
  }

  onRemove() {
    this.props.onRemoveCfgDId(this.props.cell)
  }

  render() {
    let children
    const mode = this.model()
    switch (mode) {
      case 'a':
        children = this.renderAttr()
        break
      case 'vf':
      case 'rd2rd':
        children = this.renderDataset(mode)
        break
      default:
        children = ''
        break
    }

    return (
      <ODrag
        onDrop={(e) => this.onDrop(e)}
        doCheck={(node) => this.doCheck(node)}
      >
        {children}
      </ODrag>
    )

  }
}

const mapDispatchToProps = dispatch => ({
  actions: Object.assign(
    {},
    bindActionCreators(WindowManagementActions, dispatch)
  )
});

export default connect(
  null,
  mapDispatchToProps
)(CellDragDesign)
