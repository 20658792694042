import React from 'react'
import {Dropdown, Menu} from "antd";
import { bindActionCreators } from 'redux';
import * as WindowManagementActions from '../../../stores/window-management/actions';
import { connect } from 'react-redux';
import ODM from 'odm'
import * as OdataTreeActions from "../../../stores/odata-tree/odata-tree-actions";

class RasMenu extends React.Component {
  constructor(props) {
      super(props);
  }

  onClickMenuItem(treeNodeData, type, e) {
  }

  render() {
    const { treeData, onVisibleChange, onClickMenuItem } = this.props
    const rasMenu = (
      <Menu forceSubMenuRender={false}>
        <Menu.Item onClick ={(e) => {e.domEvent.nativeEvent.noTreeSelect = true; onClickMenuItem(treeData,'refresh', e);}} >
          Refresh
        </Menu.Item>
        <Menu.Item onClick ={(e) => {e.domEvent.nativeEvent.noTreeSelect = true; onClickMenuItem(treeData,'new', e);}} >
          New
        </Menu.Item>
      </Menu>
    );
    return (<Dropdown overlay={rasMenu} onVisibleChange={onVisibleChange} >
      <a className="ant-dropdown-link">
        {treeData.title}
      </a>
    </Dropdown>);
  }
}

const mapDispatchToProps = dispatch => ({
  actions: Object.assign(
    {},
    bindActionCreators(WindowManagementActions, dispatch),
    bindActionCreators(OdataTreeActions, dispatch),
  )
});

export default connect(
  null,
  mapDispatchToProps
)(RasMenu)
