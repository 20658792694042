import React from 'react';
import { bindActionCreators } from 'redux';
import * as WindowManagementActions from '../../stores/window-management/actions';
import { connect } from 'react-redux'

class Home extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {


    return (
      <div style={{display: 'flex', flexDirection: 'column', width: '100%', height: '500px', overflow: 'hidden'}}>
{/*
        <FlowEditor></FlowEditor>
*/}
      </div>
    )
  }
}

const mapDispatchToProps = dispatch => ({
  actions: Object.assign(
    {},
    bindActionCreators(WindowManagementActions, dispatch)
  )
});

export default connect(
  null,
  mapDispatchToProps
)(Home)
