(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("$"), require("ODM"), require("OE"));
	else if(typeof define === 'function' && define.amd)
		define(["$", "ODM", "OE"], factory);
	else {
		var a = typeof exports === 'object' ? factory(require("$"), require("ODM"), require("OE")) : factory(root["$"], root["ODM"], root["OE"]);
		for(var i in a) (typeof exports === 'object' ? exports : root)[i] = a[i];
	}
})(self, function(__WEBPACK_EXTERNAL_MODULE__92319__, __WEBPACK_EXTERNAL_MODULE__71092__, __WEBPACK_EXTERNAL_MODULE__29283__) {
return 