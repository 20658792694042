export const ADD_EDITOR_KEYDOWN = '[slate editor] add slate editor keydown event handler'
export function addEditorKeyDownAction(action) {
  return {
    type: ADD_EDITOR_KEYDOWN,
    payload: action
  }
}

export const REMOVE_EDITOR_KEYDOWN = '[slate editor] remove slate editor keydown event handler'
export function removeEditorKeyDownAction(action) {
  return {
    type: REMOVE_EDITOR_KEYDOWN,
    payload: action
  }
}

export const ADD_EDITOR_ONCHANGE = '[slate editor] add slate editor onchange event handler'
export function addEditorOnChangeAction(action) {
  return {
    type: ADD_EDITOR_ONCHANGE,
    payload: action
  }
}

export const REMOVE_EDITOR_ONCHANGE = '[slate editor] remove slate editor onchange event handler'
export function removeEditorOnChangeAction(action) {
  return {
    type: REMOVE_EDITOR_ONCHANGE,
    payload: action
  }
}

export const initSlateEditorState = {
  onKeyDownHandlers: [],
  onChanges: []
}

const SlateEditorReducer = (state = initSlateEditorState, action) => {
  switch (action.type) {
    case ADD_EDITOR_KEYDOWN: {
      return {...state, onKeyDownHandlers: [...state.onKeyDownHandlers, action.payload]}
    }
    case REMOVE_EDITOR_KEYDOWN: {
      return {...state, onKeyDownHandlers: state.onKeyDownHandlers.filter(item => item !== action.payload)}
    }
    case ADD_EDITOR_ONCHANGE: {
      return {...state, onChanges: [...state.onChanges, action.payload]}
    }
    case REMOVE_EDITOR_ONCHANGE: {
      return {...state, onChanges: state.onChanges.filter(item => item !== action.payload)}
    }
    default: return state;
  }
}

export default SlateEditorReducer
