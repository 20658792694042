import React from 'react'
import {Dropdown, Menu} from "antd";
import ODM from 'odm'
import OE from 'oe'
import { bindActionCreators } from 'redux';
import * as WindowManagementActions from '../../../stores/window-management/actions';
import { connect } from 'react-redux';
import * as OdataTreeActions from "../../../stores/odata-tree/odata-tree-actions";

class RAMenu extends React.Component {
    constructor(props) {
        super(props);
    }

  render() {
    const { treeData, onVisibleChange, onClickMenuItem } = this.props
    const rAMenu = (
      <Menu forceSubMenuRender={false}>
        <Menu.Item key="refresh" onClick ={(e) => {e.domEvent.nativeEvent.noTreeSelect = true; onClickMenuItem(treeData,'refresh', e);}} >
          Refresh
        </Menu.Item>
        <Menu.Item key="configure" onClick ={(e) => {e.domEvent.nativeEvent.noTreeSelect = true; onClickMenuItem(treeData,'configure', e);}} >
          Configure
        </Menu.Item>
        <Menu.Item key="delete" onClick ={(e) => {e.domEvent.nativeEvent.noTreeSelect = true; onClickMenuItem(treeData,'delete', e);}} >
          Delete
        </Menu.Item>
        <Menu.Item key="json" onClick ={(e) => {e.domEvent.nativeEvent.noTreeSelect = true; onClickMenuItem(treeData,'json', e);}} >
          View JSON
        </Menu.Item>
      </Menu>
    );
    return (<Dropdown overlay={rAMenu} onVisibleChange={onVisibleChange} >
      <a className="ant-dropdown-link">
        {treeData.title}
      </a>
    </Dropdown>);
  }
}

const mapDispatchToProps = dispatch => ({
  actions: Object.assign(
    {},
    bindActionCreators(WindowManagementActions, dispatch),
    bindActionCreators(OdataTreeActions, dispatch),
  )
});

export default connect(
  null,
  mapDispatchToProps
)(RAMenu)
