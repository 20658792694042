import * as React from 'react';
import {TreeNode} from 'rc-tree';
const DRAG_SIDE_RANGE = 0.25;
const DRAG_MIN_GAP = 2;

export function arrDel(list, value) {
    const clone = list.slice();
    const index = clone.indexOf(value);
    if (index >= 0) {
        clone.splice(index, 1);
    }
    return clone;
}

export function arrAdd(list, value) {
    const clone = list.slice();
    if (clone.indexOf(value) === -1) {
        clone.push(value);
    }
    return clone;
}

export function posToArr(pos) {
    return pos.split('-');
}

export function getPosition(level, index) {
    return `${level}-${index}`;
}

export function isTreeNode(node) {
    return node && node.type && node.type.isTreeNode;
}

export function getDragChildrenKeys(dragNodeKey, keyEntities) {
    // not contains self
    // self for left or right drag
    const dragChildrenKeys = [];

    const entity = keyEntities[dragNodeKey];
    function dig(list = []) {
        list.forEach(({ key, children }) => {
            dragChildrenKeys.push(key);
            dig(children);
        });
    }

    dig(entity.children);

    return dragChildrenKeys;
}

export function isLastChild (treeNodeEntity) {
    if (treeNodeEntity.parent) {
        const posArr = posToArr(treeNodeEntity.pos);
        return Number(posArr[posArr.length - 1]) === treeNodeEntity.parent.children.length - 1;
    }
    return false;
}

export function isFirstChild (treeNodeEntity) {
    const posArr = posToArr(treeNodeEntity.pos);
    return Number(posArr[posArr.length - 1]) === 0;
}

// Only used when drag, not affect SSR.
// Only used when drag, not affect SSR.
export function calcDropPosition(event, treeNode) {
    const { clientY } = event;
    const { top, bottom, height } = treeNode.selectHandle.getBoundingClientRect();
    const des = Math.max(height * DRAG_SIDE_RANGE, DRAG_MIN_GAP);

    if (clientY <= top + des) {
        return -1;
    }
    if (clientY >= bottom - des) {
        return 1;
    }

    return 0;
}
/**
 * Return selectedKeys according with multiple prop
 * @param selectedKeys
 * @param props
 * @returns [string]
 */
export function calcSelectedKeys(selectedKeys, props) {
    if (!selectedKeys) return undefined;

    const { multiple } = props;
    if (multiple) {
        return selectedKeys.slice();
    }

    if (selectedKeys.length) {
        return [selectedKeys[0]];
    }
    return selectedKeys;
}

const internalProcessProps = (props) => props;
export function convertDataToTree(
    treeData,
    processor,
) {
    if (!treeData) return [];

    const { processProps = internalProcessProps } = processor || {};
    const list = Array.isArray(treeData) ? treeData : [treeData];
    return list.map(
        ({ children, ...props }) => {
            const childrenNodes = convertDataToTree(children, processor);

            return <TreeNode {...processProps(props)}>{childrenNodes}</TreeNode>;
        },
    );
}

/**
 * Parse `checkedKeys` to { checkedKeys, halfCheckedKeys } style
 */
export function parseCheckedKeys(keys) {
    if (!keys) {
        return null;
    }

    // Convert keys to object format
    let keyProps;
    if (Array.isArray(keys)) {
        // [Legacy] Follow the api doc
        keyProps = {
            checkedKeys: keys,
            halfCheckedKeys: undefined,
        };
    } else if (typeof keys === 'object') {
        keyProps = {
            checkedKeys: keys.checked || undefined,
            halfCheckedKeys: keys.halfChecked || undefined,
        };
    } else {
        return null;
    }

    return keyProps;
}

/**
 * If user use `autoExpandParent` we should get the list of parent node
 * @param keyList
 * @param keyEntities
 */
export function conductExpandParent(keyList, keyEntities) {
    const expandedKeys = new Set();

    function conductUp(key) {
        if (expandedKeys.has(key)) return;

        const entity = keyEntities[key];
        if (!entity) return;

        expandedKeys.add(key);

        const { parent, node } = entity;

        if (node.disabled) return;

        if (parent) {
            conductUp(parent.key);
        }
    }

    (keyList || []).forEach(key => {
        conductUp(key);
    });

    return [...expandedKeys];
}

/**
 * Returns only the data- and aria- key/value pairs
 */
export function getDataAndAria(props) {
    const omitProps = {};
    Object.keys(props).forEach(key => {
        if (key.startsWith('data-') || key.startsWith('aria-')) {
            omitProps[key] = props[key];
        }
    });

    return omitProps;
}

export function convertNodePropsToEventData(props) {
    const {
        data,
        expanded,
        selected,
        checked,
        loaded,
        loading,
        halfChecked,
        dragOver,
        dragOverGapTop,
        dragOverGapBottom,
        pos,
        active,
    } = props;

    const eventData = {
        ...data,
        expanded,
        selected,
        checked,
        loaded,
        loading,
        halfChecked,
        dragOver,
        dragOverGapTop,
        dragOverGapBottom,
        pos,
        active,
    };

    if (!('props' in eventData)) {
        Object.defineProperty(eventData, 'props', {
            get() {
                console.warn(
                    false,
                    'Second param return from event is node data instead of TreeNode instance. Please read value directly instead of reading from `props`.',
                );
                return props;
            },
        });
    }

    return eventData;
}

export function getTreeNodeProps(
    key,
    {
        expandedKeys,
        selectedKeys,
        loadedKeys,
        loadingKeys,
        checkedKeys,
        halfCheckedKeys,
        dragOverNodeKey,
        dropPosition,
        keyEntities,
    },
) {
    const entity = keyEntities[key];

    const treeNodeProps = {
        eventKey: key,
        expanded: expandedKeys.indexOf(key) !== -1,
        selected: selectedKeys.indexOf(key) !== -1,
        loaded: loadedKeys.indexOf(key) !== -1,
        loading: loadingKeys.indexOf(key) !== -1,
        checked: checkedKeys.indexOf(key) !== -1,
        halfChecked: halfCheckedKeys.indexOf(key) !== -1,
        pos: String(entity ? entity.pos : ''),

        // [Legacy] Drag props
        // Since the interaction of drag is changed, the semantic of the props are
        // not accuracy, I think it should be finally removed
        dragOver: dragOverNodeKey === key && dropPosition === 0,
        dragOverGapTop: dragOverNodeKey === key && dropPosition === -1,
        dragOverGapBottom: dragOverNodeKey === key && dropPosition === 1,
    };

    return treeNodeProps;
}

export function getDragNodesKeys(dragNodeKey, keyEntities) {
    const dragNodesKeys = [dragNodeKey];

    const entity = keyEntities[dragNodeKey];
    function dig(list = []) {
        list.forEach(({ key, children }) => {
            dragNodesKeys.push(key);
            dig(children);
        });
    }

    dig(entity.children);

    return dragNodesKeys;
}
