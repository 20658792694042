import React from 'react'
import {Dropdown, Menu} from "antd";
import ODM from 'odm';
import { bindActionCreators } from 'redux';
import * as WindowManagementActions from '../../../stores/window-management/actions';
import { connect } from 'react-redux';
import * as OdataTreeActions from "../../../stores/odata-tree/odata-tree-actions";

class RDSubsMenu extends React.Component {
    constructor(props) {
        super(props);
    }

  onClickMenuItem(treeNodeData, type, e) {

    const rootDataset = ODM.getRDNoLoadNoExtd(treeNodeData.odmId);
    switch (type) {
      case 'refresh': {
        this.props.actions.updateODataTreeAction()
        break
      }
      case "new": {
        this.props.actions.addWindow({
          type: 'RDConfigDialog',
          props: {
            title: 'ViewForm',
            visible: true,
            rdObject: rootDataset,
            action: 'new',
            relType: 'rdsub'
          }
        });
      }
    }
  }

    render() {
        const { treeData, onVisibleChange } = this.props
        const rdsubsMenu = (
            <Menu forceSubMenuRender={false}>
                <Menu.Item onClick ={(e) => {e.domEvent.nativeEvent.noTreeSelect = true; this.onClickMenuItem(treeData,'refresh', e);}} >
                    Refresh
                </Menu.Item>
                <Menu.Item onClick ={(e) => {e.domEvent.nativeEvent.noTreeSelect = true; this.onClickMenuItem(treeData,'new', e);}} >
                    New
                </Menu.Item>
            </Menu>
        );
        return (<Dropdown overlay={rdsubsMenu} onVisibleChange={onVisibleChange} >
            <a className="ant-dropdown-link">
                {treeData.title}
            </a>
        </Dropdown>);
    }
}

const mapDispatchToProps = dispatch => ({
  actions: Object.assign(
    {},
    bindActionCreators(WindowManagementActions, dispatch),
    bindActionCreators(OdataTreeActions, dispatch),
  )
});

export default connect(
  null,
  mapDispatchToProps
)(RDSubsMenu)

