import React from 'react';
import Dialog from '../../shared/dialog/dialog';
import { bindActionCreators } from 'redux';
import * as WindowManagementActions from '../../../stores/window-management/actions';
import { connect } from 'react-redux';
import FormulaEditor from '../../../libs/formula-editor';


class EquationEditorDialog extends React.Component {
  editor = null
  constructor(props) {
    super(props)

  }

  handleClose () {
    this.props.actions.removeWindow(this.props.windowId)
  }

  handleSubmit() {
    if (this.eqJsonCode) {
      const jsonCodeAtt = this.props.oe.getOrCreateAttForDS(this.props.dataset, 'EQ_JSON_CODE', undefined)
      const jsonCodeVal = jsonCodeAtt && this.props.oe.getAttValue(jsonCodeAtt)
      if (jsonCodeVal !== this.eqJsonCode) {
        this.props.oe.setAttValue(jsonCodeAtt, this.eqJsonCode)
        this.props.odm.updateAtt(jsonCodeAtt)
      }
    }
    this.handleClose()
  }

  handleCancel() {
    this.handleClose()
  }

  handleMinimize () {
    this.props.actions.setWindowVisible(this.props.windowId, false)
  }

  onChange(value) {
    this.eqJsonCode = value && JSON.stringify(value)
    console.log(this.eqJsonCode)
  }

  render () {

    return (
      <Dialog
        title={ this.props.title }
        submitButton={true}
        cancelButton={true}
        windowId={this.props.windowId}
        visible={ this.props.visible }
        onCancel={ this.handleCancel.bind(this) }
        onClose={this.handleCancel.bind(this)}
        onSubmit={this.handleSubmit.bind(this)}
        onMinimize={ this.handleMinimize.bind(this) }
        defaultWidth={1000}
        defaultHeight={600}
      >
        <FormulaEditor onChange={(val) => this.onChange(val)} odm={this.props.odm} oe={this.props.oe} formulaDataset={this.props.dataset}/>
      </Dialog>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  actions: Object.assign(
    {},
    bindActionCreators(WindowManagementActions, dispatch)
  )
})

export default connect(
  null,
  mapDispatchToProps
)(EquationEditorDialog)
