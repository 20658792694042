import OE from 'oe'
import ODM from 'odm'
import { inToPxByDPI, mmToPxByDPI, pxToIn, UnitConversion } from '../core/utils';
import { lineCodeGenerator, stdShapeCodeGenerator, tableCodeGenerator } from '../libs/flow-editor/utils';
import { cloneDeep, isNumber } from 'lodash';
import { Provider } from 'react-redux';
import ViewForm from '../components/shared/view-form/view-form';
import ReactDOM from 'react-dom';
import React from 'react'
import reducer from '../stores/reducers';
import thunk from 'redux-thunk';
import { applyMiddleware, createStore } from 'redux';
const store = createStore(reducer, applyMiddleware(thunk))

const XAttrs = ['width', 'xpos']
const YAttrs = ['height', 'ypos']

export default class FlowSheetService{
  constructor() {
    this.odm = ODM.createSubODM()
    this.oe = OE.createSubOE(this.odm)
    const uc = new UnitConversion()
    this.dpi = uc.conversion_getDPI()
  }

  setEditor(editor) {
      this.editor = editor
  }

  getSheetData(dataset) {
    const fSDataset = dataset
    const eqRD = this.odm.getRDNoLoadByCode('EPC.FS.EQP')
    const stdShapeRD = this.odm.getRDNoLoadByCode('EPC.TOOLS.FS.STDSHAPE')
    const streamRD = this.odm.getRDNoLoadByCode('EPC.TOOLS.FS.BASE_CONNECT_LINE')
    const lineRD = this.odm.getRDNoLoadByCode('EPC.TOOLS.FS.LINE')
    const tableRD = this.odm.getRDNoLoadByCode('EPC.TOOLS.FS.TABLE')
    const textRD = this.odm.getRDNoLoadByCode('EPC.TOOLS.FS.TEXT')
    const fSSubDs = this.oe.getSubDs(fSDataset)
    const fsObjects = {nodes:[], lines:[], tables:[], texts: []}
    fSSubDs.forEach(fsSubD => {
      if (fsSubD.act) {
        if(OE.isInstanceOfByRD(fsSubD, streamRD)) {
          fsObjects.lines.push(this.oe.getLineFSData(fsSubD))
        } else if (this.oe.isInstanceOfByRD(fsSubD, textRD)) {
          fsObjects.texts.push(this.oe.getTextFSData(fsSubD))
        } else if (this.oe.isInstanceOfByRD(fsSubD, lineRD)) {
          fsObjects.lines.push(this.oe.getPolylineFSData(fsSubD))
        } else if (this.oe.isInstanceOfByRD(fsSubD, tableRD)) {
          fsObjects.tables.push(this.oe.getTableFSData(fsSubD))
        } else if (this.oe.isInstanceOfByRD(fsSubD, eqRD)) {
          fsObjects.nodes.push(this.oe.getEqFSData(fsSubD, undefined, 'FS_P&ID'))
        } else if (this.oe.isInstanceOfByRD(fsSubD, stdShapeRD)) {
          fsObjects.nodes.push(this.oe.getStdShapeFSData(fsSubD))
        }
      }
    })
    fsObjects.nodes = fsObjects.nodes.map((node) => {
      return this.odmNodeToFlowSheetNode(node)
    })

    fsObjects.lines = fsObjects.lines.map(line => {
      return this.odmLineToFlowSheetLine(line)
    })

    fsObjects.tables = fsObjects.tables.map(table => {
      return this.odmTableToFlowSheetTable(table)
    })

    fsObjects.texts = fsObjects.texts.map(text => {
      return this.odmTextToFlowSheetText(text)
    })

    return fsObjects
  }

  isALineDId(id) {
    const d = this.odm.getDNoLoadNoExtd(id)
    const streamRD = this.odm.getRDNoLoadByCode('EPC.TOOLS.FS.BASE_CONNECT_LINE')

    return d && OE.isInstanceOfByRD(d, streamRD)
  }

  /**
   * For update the dataset
   * @param flowNode
   */
  updateNode(flowNode) {
    const {node, flowNozzles, did} = flowNode.toJSON()
    const nodeD = this.odm.getDNoLoadNoExtd(did)

    // const {width, height, left, top} = node
    // const xPosAtt = nodeD && this.oe.getOrCreateAttForDS(nodeD, 'X', undefined)
    // const xPosRAtt = this.odm.getRAttNoLoadNoExtd(xPosAtt.rid)
    // const dim = this.odm.getDimensionById(xPosRAtt.ddim || 1)
    // const uid = dim.units.map(item => ({label: item.cod, value: item.id})).filter(item => item.label === "in")[0].value
    // xPosAtt.data = {val:((left+width/2)/this.dpi[0]), uid: uid}
    // this.odm.updateAtt(xPosAtt)
    // const yPosAtt = nodeD && this.oe.getOrCreateAttForDS(nodeD, 'Y', undefined)
    // yPosAtt.data = {val:((top+height/2)/this.dpi[1]), uid: uid}
    // this.odm.updateAtt(yPosAtt)
    // const widthPosAtt = nodeD && this.oe.getOrCreateAttForDS(nodeD, 'WIDTH', undefined)
    // widthPosAtt.data = {val:(width/this.dpi[0]), uid: uid}
    // this.odm.updateAtt(widthPosAtt)
    // const heightPosAtt = nodeD && this.oe.getOrCreateAttForDS(nodeD, 'HEIGHT', undefined)
    // heightPosAtt.data = {val:(height/this.dpi[1]), uid: uid}
    // this.odm.updateAtt(heightPosAtt)
    this.updateFSNodePositionAndShape(nodeD, node)
  }

  getTextByD(d) {
    return this.odmTextToFlowSheetText(this.oe.getTextFSData(d))
  }

  odmTextToFlowSheetText(text) {
    XAttrs.forEach((key) => {
      text[key] = this.odmXUnitToPx(text[key])
    })
    YAttrs.forEach((key) => {
      text[key] = this.odmYUnitToPx(text[key])
    })

    const {width, height, xpos, ypos, did, value} = text

    return {
      type: 'text',
      id: did,
      did,
      width,
      height,
      left: xpos,
      top: ypos,
      value: value
    }
  }

  /**
   * When user drag a Flow Object to Editor, But Maybe it's useless, because It has created a dataset to ODM.
   * @param flowNode
   */
  createNode(flowNode) {
    const {node, did} = flowNode.toJSON()
    let { width, height, left, top, scaleX, scaleY } = node
    width = width * scaleX
    height = height * scaleY
    // const svgNode = this.flowSvgToOrbitSvg(node)
    const nodeD = this.odm.getDNoLoadNoExtd(did)
    const xPosAtt = nodeD && this.oe.getOrCreateAttForDS(nodeD, 'X', undefined)
    const xPosRAtt = this.odm.getRAttNoLoadNoExtd(xPosAtt.rid)
    const dim = this.odm.getDimensionById(xPosRAtt.ddim || 1)
    const uid = dim.units.map(item => ({label: item.cod, value: item.id})).filter(item => item.label === "in")[0].value
    xPosAtt.data = {val:((left+width/2)/this.dpi[0]), uid: uid}
    this.odm.updateAtt(xPosAtt)
    const yPosAtt = nodeD && this.oe.getOrCreateAttForDS(nodeD, 'Y', undefined)
    yPosAtt.data = {val:((top+height/2)/this.dpi[1]), uid: uid}
    this.odm.updateAtt(yPosAtt)
    const widthPosAtt = nodeD && this.oe.getOrCreateAttForDS(nodeD, 'WIDTH', undefined)
    widthPosAtt.data = {val:(width/this.dpi[0]), uid: uid}
    this.odm.updateAtt(widthPosAtt)
    const heightPosAtt = nodeD && this.oe.getOrCreateAttForDS(nodeD, 'HEIGHT', undefined)
    heightPosAtt.data = {val:(height/this.dpi[1]), uid: uid}
    this.odm.updateAtt(heightPosAtt)
  }

  /**
   * When users delete a node from the editor
   * @param flowNode
   */
  removeNode(flowNode, fsDataset) {
    const {did} = flowNode;
    const nodeD = this.odm.getDNoLoadNoExtd(did);
    if (nodeD) {
      this.odm.deleteD(nodeD, did);
      if (fsDataset) {
        const fsDatasetCP = this.odm.getDNoLoad(fsDataset.id)
        let needsFSUpdate = false
        const fsSubRelIds = fsDatasetCP && fsDatasetCP.srl.map(d2dRelKey => (this.odm.getD2DRelNoLoadNoExtd(d2dRelKey)))
          .filter(d2dRel => {
            if (d2dRel && (d2dRel.id || d2dRel.id === 0)) {
              if(d2dRel.tid === (nodeD && nodeD.id)) {
                this.odm.deleteD2DRel(d2dRel, d2dRel.id)
                needsFSUpdate = true
                return false
              }
              return true
            }
            return false
          })
          .map(d2dRel => d2dRel.id)
        if (needsFSUpdate) {
          fsDatasetCP.srl = fsSubRelIds
          this.odm.updateDNoSave(fsDatasetCP, fsDatasetCP.id)
        }
      }
    }
  }

  /**
   * To save the flow line data to dataset
   * @param flowLine
   */
  updateLine(flowLine) {
    const {line, startAdapter, endAdapter, did: lineDid} = flowLine
    const fsStreamD = this.odm.getDNoLoad(lineDid)
    if (startAdapter) {
      const inNozAtt = fsStreamD && this.oe.getOrCreateAttForDS(fsStreamD, 'IN_NOZ', undefined)
      const inNozDId = inNozAtt && this.oe.getAttValue(inNozAtt)
      if (inNozDId !== (startAdapter && startAdapter.tid) ) {
        this.oe.setAttValue(inNozAtt, startAdapter && startAdapter.tid)
        this.odm.updateAtt(inNozAtt)
      }
    }

    if (endAdapter) {
      const outNozAtt = fsStreamD && this.oe.getOrCreateAttForDS(fsStreamD, 'OUT_NOZ', undefined)
      const outNozDId = outNozAtt && this.oe.getAttValue(outNozAtt)
      if (outNozDId !== (endAdapter && endAdapter.tid)) {
        this.oe.setAttValue(outNozAtt, endAdapter && endAdapter.tid)
        this.odm.updateAtt(outNozAtt)
      }
    }

    let points = [...line.points]
    const lineRD = this.odm.getRDNoLoadByCode('EPC.TOOLS.FS.LINE')
    if (!this.oe.isInstanceOfByRD(fsStreamD, lineRD)) {
      if (startAdapter && startAdapter.tid) {
        points.shift()
      }
      if (endAdapter && endAdapter.tid) {
        points.pop()
      }
    }
    this.updateLineDataset(fsStreamD, points)
    // const xRouteAtt = fsStreamD && this.oe.getOrCreateAttForDS(fsStreamD, 'ROUTE_X', undefined)
    // const yRouteAtt = fsStreamD && this.oe.getOrCreateAttForDS(fsStreamD, 'ROUTE_Y', undefined)
    // const routeRAtt = this.odm.getRAttNoLoadNoExtd(xRouteAtt.rid);
    // const routeDim = this.odm.getDimensionById(routeRAtt.ddim);
    // const inchUnit = routeDim.units.filter(unit => unit.cod && unit.cod.toLowerCase() === 'in')[0];
    // const xUnit = routeDim.units.filter(unit => unit.id === xRouteAtt.data.uid)[0] || inchUnit;
    // const yUnit = routeDim.units.filter(unit => unit.id === yRouteAtt.data.uid)[0] || inchUnit;
    // const newXRouteVal = points.map((el) => el.x).map(val => this.oe.unit1ToUnit2(pxToIn(val, this.dpi[0]), inchUnit, xUnit))
    // const newYRouteVal = points.map((el) => el.y).map(val => this.oe.unit1ToUnit2(pxToIn(val, this.dpi[1]), inchUnit, yUnit))
    // const oldXRouteVal = xRouteAtt && this.oe.getAttValue(xRouteAtt)
    // xRouteAtt.uid = xUnit.id
    // xRouteAtt.data.uid = xUnit.id
    // this.oe.setAttValue(xRouteAtt, newXRouteVal)
    // this.odm.updateAtt(xRouteAtt)
    // const oldYRouteVal = yRouteAtt && this.oe.getAttValue(yRouteAtt)
    // yRouteAtt.uid = yUnit.id
    // yRouteAtt.data.uid = yUnit.id
    // this.oe.setAttValue(yRouteAtt, newYRouteVal)
    // this.odm.updateAtt(yRouteAtt)
  }

  /**
   * update the table dataset here.
   * @param table
   */
  updateTable(table) {
    // todo: when the table be modified in flow sheet, this function will be call, and we should change the table dataset.

  }

  /**
   * get the config dataset by flow sheet dataset
   * @param dataset // the flow sheet dataset
   *
   * @return {
   *   template: {
   *     lines: [{points: Point[], options: {}}],
   *     nodes: [Lyon - please complete this section],
   *     tables: [@TODO]
   *   },
   *   paperFormat: string, // eg: 'A3'
   *   dpi: number,
   *   ppi: number
   * }
   */
  getConfigData(fsDataset, isTemplate = false) {

    const templateAtt = this.oe.getAttForD(fsDataset, 'TEMPLATE', undefined)
    const templateD = templateAtt && this.odm.getDNoLoad(this.oe.getAttValue(templateAtt))
    const configData = templateD ? this.getConfigData(templateD, true) : {template:{lines:[], nodes:[], tables:[]}}
    if (isTemplate) {
      const fsData = this.getSheetData(fsDataset)
      configData.template.lines = configData.template.lines.concat(fsData.lines)
      configData.template.nodes = configData.template.nodes.concat(fsData.nodes)
      configData.template.tables = configData.template.tables.concat(fsData.tables)
    }
    const paperFormatAtt = this.oe.getAttForD(fsDataset, 'PAPER_FORMAT', undefined)
    if (paperFormatAtt) {
      configData.paperFormat = this.oe.getAttValue(paperFormatAtt)
    }
    const dpiAtt = this.oe.getAttForD(fsDataset, 'DPI', undefined)
    if (dpiAtt) {
      configData.dpi = this.oe.getAttValue(dpiAtt)
    }
    const ppiAtt = this.oe.getAttForD(fsDataset, 'PPI', undefined)
    if (ppiAtt) {
      configData.ppi = this.oe.getAttValue(ppiAtt)
    }
    return configData;
  }

  /**
   * Users remove a line from the editor
   * @param flowLine
   */
  removeLine(flowLine) {

  }

  createChildProvider() {
    const cOdm = this.odm.createSubODM()
    const cOe = OE.createSubOE(cOdm)

    return {odm: cOdm, oe: cOe}
  }

  _odmUnitToPx(strVal = '', dpiVal) {
    const reg = /^(\d+|\d+.\d+) ?([a-z]+)$/i
    const matches = strVal.match(reg)
    let value = 0
    let unit = 'in'
    if (matches) {
      value = Number(matches[1]) || 0
      unit = matches[2]
    }

    switch (unit) {
      case 'mm': {
        return mmToPxByDPI(value, dpiVal)
      }
      case 'cm': {
        return mmToPxByDPI(value * 10, dpiVal)
      }
      case 'm': {
        return mmToPxByDPI(value * 1000, dpiVal)
      }
      case 'in': {
        return inToPxByDPI(value, dpiVal)
      }
      default: {
        return value
      }
    }
  }

  odmXUnitToPx(strVal = '') {
    return this._odmUnitToPx(strVal, this.dpi[0])
  }

  odmYUnitToPx(strVal = '') {
    return this._odmUnitToPx(strVal, this.dpi[1])
  }

  odmPolylineToFlowSheetPolyline(polyline) {

      const {linePoints, id} = polyline
      let points = linePoints
      if (linePoints.length === 0) {
        // todo: current newOline.points is empty array. the blow code need be removed when the bug be fixed.
        console.log('use test line points')
        points = [
          { x: 596, y: 816 },
          { x: 596, y: 1762 },
          { x: 1054, y: 1762 },
          { x: 1054, y: 816 },
          { x: 596, y: 816 },
        ]
      }

      return {
        type: 'solid-line',
        line: {
          stroke: '#000000',
          strokeWidth: 2,
          points
        },
        did: id,
        id
      }
  }

  odmLineToFlowSheetLine(line) {

    const {inNozDId, linePoints, outNozDId, id, category = 'stream', rType} = line

    const points = linePoints.map(point => {

      const rePoint = {
        x: this.odmXUnitToPx(`${point.x} in`),
        y: this.odmYUnitToPx(`${point.y} in`),
      }
      if (isNumber(point.connectLineDId)) {
        rePoint.adapter = {
          tid: point.connectLineDId
        }
      }

      return rePoint
    })

    const type = rType === 'EPC.TOOLS.FS.SIGNAL' ? 'dash-line' : 'solid-line'

    return {
      type,
      startAdapter: {tid: inNozDId, type: 'LineNozzleAdapter'},
      endAdapter: {tid: outNozDId, type: 'LineNozzleAdapter'},
      line: {
        stroke: '#000000',
        strokeWidth: 2,
        points: points
      },
      category,
      did: id,
      id
    }
  }

  odmTableToFlowSheetTable(table) {
    const {did, height, width, xpos, ypos, vfDataset} = table

    return {
      did,
      height: this.odmYUnitToPx(height),
      width: this.odmXUnitToPx(width),
      left: this.odmXUnitToPx(xpos),
      top: this.odmYUnitToPx(ypos),
      titleBlockId: vfDataset.id
    }
  }

  odmNodeToFlowSheetNode(node) {

    XAttrs.forEach((key) => {
      node[key] = this.odmXUnitToPx(node[key])
    })
    YAttrs.forEach((key) => {
      node[key] = this.odmYUnitToPx(node[key])
    })

    const {width, height, xpos, ypos, did, actuators, angle} = node
    const nodeData = {
      width,
      height,
      left: xpos - (width / 2),
      top: ypos - (height / 2),
      angle
    }
    let type = ''
    if (node.nodeType) {
      type = node.nodeType
      if (type === 'circle') {
        nodeData.radius = width / 2
      }
      if (type === 'ellipse') {
        nodeData.rx = width / 2
        nodeData.ry = height / 2
      }
    } else if (node.svgNode) {
      nodeData.svgNode = this.retrieveSvg(node)
      type = 'svg-node'
    }

    const returnObj = {
      id: did,
      nodeType: type,
      node: nodeData,
      did: did,
    }

    if (actuators) {
      returnObj.actuators = actuators.map(act => {
        const instNozzles =  (act.instNozzles || []).map(item => {
          const {xPos, yPos, did} = item

          return {...item, x: xPos, y: yPos, id: did}
        })

        return {
          ...act,
          instNozzles
        }
      })
    }

    if (node.flowNozzles) {
      returnObj.flowNozzles = node.flowNozzles.map(nozzle => {
        const {xPos, yPos, did} = nozzle

        return {...nozzle, x: xPos, y: yPos, id: did}
      })
    }
    if (node.instNozzles) {
      returnObj.instNozzles = node.instNozzles.map(nozzle => {
        const {xPos, yPos, did} = nozzle

        return {...nozzle, x: xPos, y: yPos, id: did}
      })
    }

    return returnObj
  }

  createStreamDataset(fsDataset) {
    const streamRD = this.odm.getRDNoLoadByCode('EPC.TOOLS.FS.STREAM')
    const streamD = this.oe.createDSByRD(streamRD, lineCodeGenerator(), cloneDeep(streamRD.dsc))
    const fsid = fsDataset && fsDataset.id
    const fsRD = this.odm.getRDNoLoadByCode("EPC.TOOLS.FS")
    const fsRDSubRels = this.oe.getRDSubRels(fsRD,{})
    const fsRDEQSubRels = Object.keys(fsRDSubRels).map(rd2rdRelKey => (this.odm.getRD2RDRelNoLoadNoExtd(rd2rdRelKey)))
      .filter(rd2rdRel => rd2rdRel && rd2rdRel.tid && ((rdSub) => rdSub && rdSub.cod === 'EPC.FS.EQP')(this.odm.getRDNoLoadNoExtd(rd2rdRel.tid)))
    const fsRDEQSubRel = fsRDEQSubRels && fsRDEQSubRels.length && fsRDEQSubRels[0]
    const newStreamRel = this.oe.createDSubRelForRDSubRel(fsid, streamD.id, fsRDEQSubRel)
    return streamD
  }

  createSignalLineDataset(fsDataset) {
    const signalRD = this.odm.getRDNoLoadByCode('EPC.TOOLS.FS.SIGNAL')
    const signalLineD = this.oe.createDSByRD(signalRD, lineCodeGenerator(), cloneDeep(signalRD.dsc))
    const fsid = fsDataset && fsDataset.id
    const fsRD = this.odm.getRDNoLoadByCode("EPC.TOOLS.FS")
    const fsRDSubRels = this.oe.getRDSubRels(fsRD,{})
    const fsRDEQSubRels = Object.keys(fsRDSubRels).map(rd2rdRelKey => (this.odm.getRD2RDRelNoLoadNoExtd(rd2rdRelKey)))
      .filter(rd2rdRel => rd2rdRel && rd2rdRel.tid && ((rdSub) => rdSub && rdSub.cod === 'EPC.FS.EQP')(this.odm.getRDNoLoadNoExtd(rd2rdRel.tid)))
    const fsRDEQSubRel = fsRDEQSubRels && fsRDEQSubRels.length && fsRDEQSubRels[0]
    const newStreamRel = this.oe.createDSubRelForRDSubRel(fsid, signalLineD.id, fsRDEQSubRel)
    return signalLineD
  }

  createLineDataset(fsDataset, points = []) {
    const streamRD = this.odm.getRDNoLoadByCode('EPC.TOOLS.FS.LINE')
    let streamD = this.oe.createDSByRD(streamRD, lineCodeGenerator(), cloneDeep(streamRD.dsc))
    const fsid = fsDataset && fsDataset.id
    const fsRD = this.odm.getRDNoLoadByCode("EPC.TOOLS.FS")
    const fsRDSubRels = this.oe.getRDSubRels(fsRD,{})
    const fsRDEQSubRels = Object.keys(fsRDSubRels).map(rd2rdRelKey => (this.odm.getRD2RDRelNoLoadNoExtd(rd2rdRelKey)))
      .filter(rd2rdRel => rd2rdRel && rd2rdRel.tid && ((rdSub) => rdSub && rdSub.cod === 'EPC.FS.EQP')(this.odm.getRDNoLoadNoExtd(rd2rdRel.tid)))
    const fsRDEQSubRel = fsRDEQSubRels && fsRDEQSubRels.length && fsRDEQSubRels[0]
    const newStreamRel = this.oe.createDSubRelForRDSubRel(fsid, streamD.id, fsRDEQSubRel)
    streamD = this.odm.getDNoLoad(streamD.id)
    if (points && points.length) {
      this.updateLineDataset(streamD, points)
    }
    return streamD
  }

  updateLineDataset(fsLineD, points) {
    const xRouteAtt = fsLineD && this.oe.getOrCreateAttForDS(fsLineD, 'ROUTE_X', undefined)
    const yRouteAtt = fsLineD && this.oe.getOrCreateAttForDS(fsLineD, 'ROUTE_Y', undefined)
    const connectedLineAtt = fsLineD && this.oe.getOrCreateAttForDS(fsLineD, 'CONNECTED_LINE', undefined)
    const routeRAtt = this.odm.getRAttNoLoadNoExtd(xRouteAtt.rid);
    const routeDim = this.odm.getDimensionById(routeRAtt.ddim);
    const inchUnit = routeDim.units.filter(unit => unit.cod && unit.cod.toLowerCase() === 'in')[0];
    const xUnit = routeDim.units.filter(unit => unit.id === xRouteAtt.data.uid)[0] || inchUnit;
    const yUnit = routeDim.units.filter(unit => unit.id === yRouteAtt.data.uid)[0] || inchUnit;
    const newXRouteVal = points.map((el) => el.x).map(val => this.oe.unit1ToUnit2(pxToIn(val, this.dpi[0]), inchUnit, xUnit))
    const newYRouteVal = points.map((el) => el.y).map(val => this.oe.unit1ToUnit2(pxToIn(val, this.dpi[1]), inchUnit, yUnit))
    xRouteAtt.uid = xUnit.id
    xRouteAtt.data.uid = xUnit.id
    this.oe.setAttValue(xRouteAtt, newXRouteVal)
    this.odm.updateAtt(xRouteAtt)
    yRouteAtt.uid = yUnit.id
    yRouteAtt.data.uid = yUnit.id
    this.oe.setAttValue(yRouteAtt, newYRouteVal)
    this.odm.updateAtt(yRouteAtt)

    if (connectedLineAtt) {
      const newConnectedLineVal = points.map(point => point.nozzle ? JSON.stringify(point.nozzle) : '')
      this.oe.setAttValue(connectedLineAtt, newConnectedLineVal)
      this.odm.updateAtt(connectedLineAtt)
    }
  }

  getOdmLineByLineD(fsD) {
    const odmData = this.oe.getPolylineFSData(fsD)

    return this.odmPolylineToFlowSheetPolyline(odmData)
  }

  getOdmNodeByNodeD(fsD) {
    const odmData = this.oe.getEqFSData(fsD, undefined, 'FS_P&ID')
    const odmNode = this.odmNodeToFlowSheetNode(odmData)

    return {node: odmNode, nozzles: odmData.flowNozzles}
  }

  createStrandShapeDataset(fsDataset, nodeType) {
    const stdShapeRD = this.odm.getRDNoLoadByCode("EPC.TOOLS.FS.STDSHAPE")
    const newODMNode = fsDataset && this.oe.createDSByRD(stdShapeRD, stdShapeCodeGenerator(), cloneDeep(stdShapeRD.dsc))
    if (newODMNode) {
      const shapeAtt = this.oe.getOrCreateAttForDS(newODMNode, 'SHAPE', undefined)
      this.oe.setAttValue(shapeAtt, nodeType)
    }

    return newODMNode
  }

  createTextDataset(fsDataset) {
    const textRd = this.odm.getRDNoLoadByCode("EPC.TOOLS.FS.TEXT")
    const textD = fsDataset && this.oe.createDSByRD(textRd, stdShapeCodeGenerator(), cloneDeep(textRd.dsc))

    const fsid = fsDataset && fsDataset.id
    const fsRD = this.odm.getRDNoLoadByCode("EPC.TOOLS.FS")
    const fsRDSubRels = this.oe.getRDSubRels(fsRD,{})
    const fsRDEQSubRels = Object.keys(fsRDSubRels).map(rd2rdRelKey => (this.odm.getRD2RDRelNoLoadNoExtd(rd2rdRelKey)))
      .filter(rd2rdRel => rd2rdRel && rd2rdRel.tid && ((rdSub) => rdSub && rdSub.cod === 'EPC.FS.EQP')(this.odm.getRDNoLoadNoExtd(rd2rdRel.tid)))
    const fsRDEQSubRel = fsRDEQSubRels && fsRDEQSubRels.length && fsRDEQSubRels[0]
    const newTextRel = this.oe.createDSubRelForRDSubRel(fsid, textD.id, fsRDEQSubRel)
    return textD
  }

  updateFSTextDataset(textData) {
    const {id, node, value} = textData
    const nodeD = this.odm.getDNoLoadNoExtd(id)
    this.updateFSNodePositionAndShape(nodeD, node)
    const textValAtt = nodeD && this.oe.getOrCreateAttForDS(nodeD, 'TEXT_VAL', undefined)
    textValAtt.data = {val: value}
    this.odm.updateAtt(textValAtt)
  }

  updateFSNodePositionAndShape(nodeD, node) {
    const {width, height, left, top, angle} = node
    const xPosAtt = nodeD && this.oe.getOrCreateAttForDS(nodeD, 'X', undefined)
    const xPosRAtt = this.odm.getRAttNoLoadNoExtd(xPosAtt.rid)
    const dim = this.odm.getDimensionById(xPosRAtt.ddim || 1)
    const uid = dim.units.map(item => ({label: item.cod, value: item.id})).filter(item => item.label === "in")[0].value
    xPosAtt.data = {val:((left+width/2)/this.dpi[0]), uid: uid}
    this.odm.updateAtt(xPosAtt)
    const yPosAtt = nodeD && this.oe.getOrCreateAttForDS(nodeD, 'Y', undefined)
    yPosAtt.data = {val:((top+height/2)/this.dpi[1]), uid: uid}
    this.odm.updateAtt(yPosAtt)
    const widthPosAtt = nodeD && this.oe.getOrCreateAttForDS(nodeD, 'WIDTH', undefined)
    widthPosAtt.data = {val:(width/this.dpi[0]), uid: uid}
    this.odm.updateAtt(widthPosAtt)
    const heightPosAtt = nodeD && this.oe.getOrCreateAttForDS(nodeD, 'HEIGHT', undefined)
    heightPosAtt.data = {val:(height/this.dpi[1]), uid: uid}
    this.odm.updateAtt(heightPosAtt)

    const angleAtt = nodeD && this.oe.getOrCreateAttForDS(nodeD, 'ANGLE', undefined)
    if (angleAtt) {
      // standard shape don't have angle attribute
      angleAtt.data = {val: angle}
      this.odm.updateAtt(angleAtt)
    }
  }

  createDataTableDataset(fsDataset) {
    const tableShapeRD = this.odm.getRDNoLoadByCode("EPC.TOOLS.FS.TABLE")
    const newODMNode = fsDataset && this.oe.createDSByRD(tableShapeRD, tableCodeGenerator(), cloneDeep(tableShapeRD.dsc))

    return newODMNode
  }

  getTitleBlockByTableD(tableD) {

  }

  getFsTableByDataset(dataset) {
    const table = this.oe.getTableFSData(dataset)

    return this.odmTableToFlowSheetTable(table)
  }

  createSVGNodeDataset(fsDataset, did) {
    const nodeTemplate = did && this.odm.getDNoLoadNoExtd(Number(did))
    const newODMNode = nodeTemplate && this.oe.createDByDefD(nodeTemplate)

    return newODMNode
  }

  getFSNodeByDid(did) {
    const d = this.odm.getDNoLoad(did)
    const fsNode= this.oe.getEqFSData(d, undefined, 'FS_P&ID')

    return this.odmNodeToFlowSheetNode(fsNode)
  }

  updateODMNode(fsDataset, newODMNode) {
    const fsRD = this.odm.getRDNoLoadByCode("EPC.TOOLS.FS")
    const fsRDSubRels = this.oe.getRDSubRels(fsRD,{})
    const fsid = fsDataset.id
    const fsDatasetCP = this.odm.getDNoLoad(fsDataset.id)
    const fsSubRels = fsDatasetCP && fsDatasetCP.srl.map(d2dRelKey => (this.odm.getD2DRelNoLoadNoExtd(d2dRelKey)))
      .filter(d2dRel => {
        return d2dRel && d2dRel.tid === (newODMNode && newODMNode.id)
        })
    if (fsSubRels && fsSubRels.length === 0) {
      const fsRDEQSubRels = Object.keys(fsRDSubRels).map(rd2rdRelKey => (this.odm.getRD2RDRelNoLoadNoExtd(rd2rdRelKey)))
        .filter(rd2rdRel => rd2rdRel && rd2rdRel.tid && ((rdSub) => rdSub && rdSub.cod === 'EPC.FS.EQP')(this.odm.getRDNoLoadNoExtd(rd2rdRel.tid)))
      const fsRDEQSubRel = fsRDEQSubRels && fsRDEQSubRels.length && fsRDEQSubRels[0]
      const newNodeRel = this.oe.createDSubRelForRDSubRel(fsid, newODMNode.id, fsRDEQSubRel)
    }
  }

  retrieveSvg(oNode) {
    const {svgNode, width, height} = oNode
    const {atts, children} = svgNode

    const svgAttrs = this.resolveSvgAttrs(atts)
    const childrenStr = children.map(node => {
      const {type, atts, children} = node
      let exts = {}
      if (type === 'path' && !atts.fill) {
        exts.fill = 'none'
        exts.stroke = '#000000'
      }
      const attStr = atts ? this.resolveSvgAttrs(atts) : ''
      if (children) {
        return `<${type} ${attStr} ${this.resolveSvgAttrs(exts)}>${children}</${type}>`
      }

      return `<${type} ${attStr} ${this.resolveSvgAttrs(exts)}/>`
    }).join('')

    return `
      <svg ${svgAttrs} width="${width}" height="${height}" xmlns="http://www.w3.org/2000/svg">
        ${childrenStr}
      </svg>
    `
  }

  resolveSvgAttrs(attrObj) {
    return Array.from(Object.keys(attrObj)).map(key => `${key}="${attrObj[key]}"`).join(' ')
  }

  buildSvgPathStr(path) {
    const d = path.map(item => {
      const [op, ...args] = item

      return `${op} ${args.join(' ')}`
    }).join(' ')

    return {
      type: 'path',
      atts: {
        d,
        fill: 'none',
        stroke: '#000000'
      }
    }
  }

  renderViewForm(did) {
    return new Promise((resolve) => {
      const dataset = this.odm.getDNoLoad(did)
      const elem = (
        <Provider store={store}>
          <ViewForm vFObject={dataset} noTop={true} readonly={true}></ViewForm>
        </Provider>
      )
      const div = document.createElement('div')

      ReactDOM.render(elem, div )

      setTimeout(() => {
        const xhtml = div.innerHTML
          .replace(/(<col [^\<\>]*>)|<col>/g, (item) => {
            return item + '</col>'
          })
          .replace(/<table/g, '<table border="1" ')
          .replace(/<img[^<>]*/g, (item) => {
            return item + '/'
          })
        resolve(xhtml)
      }, 1000)
    })
  }
}
