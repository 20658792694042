import React from 'react'
import styles from './odata-tree.module.less'
import 'antd/dist/antd.css';
import OE from 'oe'
import ODM from 'odm'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as WindowManagementActions from '../../../stores/window-management/actions'
import * as OdataTreeActions from '../../../stores/odata-tree/odata-tree-actions'

import { ReactComponent as DefaultTreeIcon } from '../../../assets/icons/tree-default.svg'
import { ReactComponent as ExtendsIcon } from '../../../assets/icons/extends.svg'
import { ReactComponent as SubsetsIcon } from '../../../assets/icons/subsets.svg'
import { ReactComponent as AttributesIcon } from '../../../assets/icons/attributes.svg'
import { ReactComponent as AttributeIcon } from '../../../assets/icons/attribute.svg'
import { ReactComponent as ViewsIcon } from '../../../assets/icons/views.svg'
import { ReactComponent as ViewIcon } from '../../../assets/icons/view.svg'
import { ReactComponent as ReportsIcon } from '../../../assets/icons/reports.svg'
import { ReactComponent as SandboxIcon } from '../../../assets/icons/sandbox.svg'
import {curZIndex} from '../dialog/dialog'

import { Subject} from "rxjs";
import { TreeNode } from 'rc-tree';
import RdMenu from "./rd-menu";
import Rd2RdMenu from "./rd2rd-menu";
import DMenu from "./d-menu";
import D2DMenu, { isFlowSheet } from './d2d-menu';
import XndsMenu from "./xnds-menu";
import RasMenu from "./ras-menu";
import VwsMenu from "./vws-menu";
import RvwMenu from "./rvw-menu";
import RtestsMenu from "./rtests-menu";
import RDSubsMenu from "./rdsubs-menu";
import AsMenu from "./as-menu";
import RAMenu from "./ra-menu";
import AMenu from "./a-menu";
import RvwsMenu from "./rvws-menu";
import VWMenu from "./vw-menu";
import RrepsMenu from "./rreps-menu";
import RepsMenu from "./reps-menu";
import DSubsMenu from "./dsubs-menu";

import checkAllowDrop from "./node-drop-check";
import { COPY, MOVE, NOT_ALLOWED } from "../../../utils/cursor-service";
import {convertNodePropsToEventData} from "../o-tree/tree-util";
import cursorService from "../../../utils/cursor-service";
import {takeUntil, throttleTime} from "rxjs/operators";
import OAntDTree from '../o-tree/o-antd-tree';
import { treeNodeIcon } from "./tree-node-icon";
import { getD2DRDIcon, getDatasetRDIcon } from '../view-form/utils';
import EquationEditorDialog from '../../window-management/equation-editor-dialog';
import {openRACfgD} from "./dialog-helper";


class ODataTree extends React.PureComponent {
  constructor (props) {
    super(props);
    this.state = {
      odmObject: ODataTree.getNodeData,
      treeData: [ODataTree.getNodeData(props.odmObject.typ, props.odmObject.id, '0')],
      dialogZIndex: curZIndex + 10,
      autoExpandParent: true,
      loadedKeys: [],
    };
    this.dragOverSubject$ = new Subject()
    this.destroy$ = new Subject()
    this.isMac = this.isMacOs()
    this.syncLoading = null
    this.id = this.createObjectId()
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const newOdmObject = nextProps.odmObject
    if (newOdmObject !== prevState.odmObject) {
      // odmObject updated, Update the tree
      const treeData = ODataTree.getNodeData(newOdmObject.typ, newOdmObject.id, '0');
      return {
        odmObject: newOdmObject,
        treeData: [treeData],
        loadedKeys: [], // need reload some async data-node.
      }
    }
  }

  componentDidMount() {
    this.dragOverSubject$.pipe(
        takeUntil(this.destroy$),
        throttleTime(100)
    ).subscribe(({event, node, dragNode}) => {
      const isAllow = checkAllowDrop(dragNode.dataRef, node.dataRef, this.state.treeData);
      const isCtrlDown = event.ctrlKey;
      if (isAllow) {
        if (isCtrlDown) {
          cursorService.setGlobalCursorTo(COPY);
          event.dataTransfer.dropEffect = 'link';
        } else {
          cursorService.setGlobalCursorTo(MOVE)
          event.dataTransfer.dropEffect = 'move';
        }
      } else {
        cursorService.setGlobalCursorTo(NOT_ALLOWED);
        event.dataTransfer.dropEffect = 'none';
      }

    })
  }

  componentWillUnmount() {
    this.destroy$.next()
    this.destroy$.complete()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.odataTree.dragging && (!this.props.odataTree.dragging)) {
      this.setState({hideMenu: false});
    }
  }

  isMacOs() {
    return /Macintosh/.test(navigator.appVersion)
  }

  /**
   * all Orbit object need id
   * @returns {string}
   */
  createObjectId() {
    const ts = new Date().getTime().toString(32)
    const randomStr = Math.round(Math.random() * 10000).toString(32)

    return `${ts}${randomStr}`
  }

  openUserForm (libCode, formCode, windowTitle, ojsParams) {
    this.props.actions.addWindow({
      type: 'UserFormDialog',
      props: {
        title: windowTitle,
        visible: true,
        libCode: libCode,
        formCode: formCode,
        ojsParams: ojsParams,
        onSubmit: () => {
          this.props.actions.updateODataTreeAction()
        }
      }
    });
  }

  handleOpenCallbackContentDialog = (dialogTitle) => {
    this.props.actions.addWindow({
      type: 'CallbackContent',
      props: {
        title: dialogTitle,
        visible: true
      }
    });
  };


  onClickMenuItem = (onClickTreeNodeData, actionCode, event) => {
    const treeNodeData = this.retrieveNodeByKey(onClickTreeNodeData.key);

    switch (treeNodeData.nodeType) {
      case "rd2rd":
      case "rd":
        let rdId = treeNodeData.odmId;
        let curRD2RD;
        if (treeNodeData.nodeType === "rd2rd") {
          curRD2RD = ODM.getRD2RDRelNoLoadNoExtd(treeNodeData.odmId);
          rdId = curRD2RD.tid;
        }
        switch (actionCode.toLowerCase()) {
          case "open":
            let vfObject = curRD2RD || ODM.getRDNoLoadNoExtd(rdId);
            this.props.actions.addWindow({
              type: 'ViewForm',
              props: {
                title: 'ViewForm',
                visible: true,
                vFObject: vfObject
              }
            });
            break;
          case "refresh":
            // treeNodeData.children = undefined;
            // this.setState({treeData: [...this.state.treeData]});
            // const newLoadedKeys = this.state.loadedKeys.filter(item=>!item.startsWith(onClickTreeNodeData.key));
            // this.setState({loadedKeys: newLoadedKeys});
            // this.oTree.tree.onNodeLoad(treeNodeData.treeNodeRef);
            this.props.actions.updateODataTreeAction()
            break;
          case "rd-configure":
            let rdConfigObj = ODM.getRDNoLoadNoExtd(rdId)
            OE.openRDCfgD(rdConfigObj, this.props.actions)
            break;
          case "rd-delete":
            const deletedRD  = ODM.getRDNoLoad(rdId)
            ODM.deleteRD(deletedRD, deletedRD.id)
            if (curRD2RD) {
              const deleteRD2RD = ODM.getRD2RDRelNoLoad(curRD2RD.id)
              ODM.deleteRD2RDRel(deleteRD2RD, deleteRD2RD.id)
            }
            this.props.actions.updateODataTreeAction()
            break;
          case "rd-json":
            let viewObject = ODM.getRDNoLoadNoExtd(rdId);
            this.props.actions.addWindow({
              type: 'ViewJson',
              props: {
                title: 'JSON Code',
                visible: true,
                viewObject: viewObject
              }
            });
            break;
          case "rel-configure":
            let rdRelConfigObj = curRD2RD || ODM.getRD2RDRelNoLoadNoExtd(rdId);
            OE.openRD2RDRelCfgD(rdRelConfigObj, this.props.actions)
            break;
          case "rel-delete":
            const deleteRD2RD1 = ODM.getRD2RDRelNoLoad(curRD2RD.id);
            ODM.deleteRD2RDRel(deleteRD2RD1, deleteRD2RD1.id);
            this.props.actions.updateODataTreeAction()
            break;
          case "rel-json":
            const relJsonObj = curRD2RD;
            this.props.actions.addWindow({
              type: 'ViewJson',
              props: {
                title: 'JSON Code',
                visible: true,
                viewObject: relJsonObj
              }
            });
            break;
          case 'set-icon':
            let rdObject = ODM.getRDNoLoadNoExtd(rdId);
            this.props.actions.addWindow({
              type: 'SetIconDialog',
              props: {
                title: 'Set Icon',
                visible: true,
                rdObject: rdObject
              }
            });
            break;
        }
        break;
      case "d2d":
      case "d":
        let curD2D = null
        let dId = treeNodeData.odmId;
        if (treeNodeData.nodeType === "d2d") {
          curD2D = ODM.getD2DRelNoLoad(treeNodeData.odmId);
          dId = curD2D.tid;
        }
        switch (actionCode) {
          case "open":
            const dataset = curD2D ? ODM.getDNoLoad(curD2D.tid) : ODM.getDNoLoad(treeNodeData.odmId)
            // todo: need confirm how to distinguish the node is Flow Sheet or not.
            if (dataset && isFlowSheet(dataset)) {
              this.props.actions.addWindow({
                type: 'FlowSheetDialog',
                props: {
                  title: 'FlowSheet',
                  visible: true,
                  dataset
                }
              })
            } else {
              let vfObject = curD2D || ODM.getDNoLoad(treeNodeData.odmId);
              this.props.actions.addWindow({
                type: 'ViewForm',
                props: {
                  title: 'ViewForm',
                  visible: true,
                  vFObject: vfObject
                }
              });
            }

            break;
          case 'openEquationEditor': {
            const dataset = curD2D ? ODM.getDNoLoad(curD2D.tid) : ODM.getDNoLoad(treeNodeData.odmId)
            this.props.actions.addWindow({
              type: 'EquationEditorDialog',
              props: {
                title: 'Equation Editor Dialog',
                visible: true,
                dataset,
                odm: ODM,
                oe: OE
              }
            });
            break;
          }
          case 'configure': {
            const dataset = curD2D ? ODM.getDNoLoad(curD2D.tid) : ODM.getDNoLoad(treeNodeData.odmId)
            this.props.actions.addWindow({
              type: 'FlowSheetConfigDialog',
              props: {
                title: 'Flow Sheet Config Dialog',
                visible: true,
                dataset
              }
            });
            break
          }
          case "refresh":
            this.props.actions.updateODataTreeAction()
            break;
          case "rename":
            this.openUserForm('SYS_FORMS', 'INPUT_FORM4', 'Rename Dataset', {odmObj: ODM.getDNoLoad(dId)})
            break;
          case "d-delete":
            const deletedD  = ODM.getDNoLoad(dId)
            ODM.deleteD(deletedD, deletedD.id)
            if (curD2D) {
              ODM.deleteD2DRel(curD2D, curD2D.id)
            }
            this.props.actions.updateODataTreeAction()
            break;
          case "d-json":
            {
              let viewObject = ODM.getDNoLoadNoExtd(dId);
              this.props.actions.addWindow({
                type: 'ViewJson',
                props: {
                  title: 'JSON Code',
                  visible: true,
                  viewObject: viewObject
                }
              });
            }
            break;
          case "rel-delete":
            if (curD2D) {
              ODM.deleteD2DRel(curD2D, curD2D.id)
              this.props.actions.updateODataTreeAction()
            }
            break;
          case "rel-json":
            if (curD2D) {
              this.props.actions.addWindow({
                type: 'ViewJson',
                props: {
                  title: 'JSON Code',
                  visible: true,
                  viewObject: curD2D
                }
              });
            }
            break;
        }
        break;
      case "rdsubs":
        // see rdsubs-menu.js
        break;
      case "xnds":
        // see xnds-menu.js
        break;
      case "dsubs":
        switch (actionCode) {
          case "refresh":
            this.props.actions.updateODataTreeAction()
            break;
          case "new...":
            break;
        }
        break;
      case "ras":
        {
          const rdObject = ODM.getRDNoLoadNoExtd(treeNodeData.odmId);
          switch (actionCode) {
            case 'refresh': {
              this.props.actions.updateODataTreeAction()
              break
            }
            case 'new': {
              this.openUserForm('SYS_FORMS', 'INPUT_FORM5', 'New Root-Attribute', {odmObj: rdObject, mode: "new"})
              break
            }
          }
        }
        break;
      case "as":
        switch (actionCode) {
          case "refresh":
            this.props.actions.updateODataTreeAction()
            break;
          case "new...":
            break;
        }
        break;
      case "ra":
        {
          const raId = treeNodeData.odmId;
          const ra = ODM.getRAttNoLoadNoExtd(raId);
          switch (actionCode.toLowerCase()) {
            case 'refresh': {
              this.props.actions.updateODataTreeAction()
              }
              break
            case 'configure': {
                if (ra && ra.dtp && ra.dtp === "SPREADSHEET") {
                  this.openUserForm('SYS_FORMS', 'INPUT_FORM5', 'Configure Grid-Root-Attribute', {odmObj: ra})
                } else {
                  // debugger
                  // OE.openRACfgD(ra, this.props.actions)
                  openRACfgD(ra, this.props.actions)
                }
              }
              break
            case 'delete': {
              }
              break
            case 'json': {
              this.props.actions.addWindow({
                type: 'ViewJson',
                props: {
                  title: 'JSON Code',
                  visible: true,
                  viewObject: ra
                }
              });
              }
              break
          }
        }
        break;
      case "a":
        switch (actionCode) {
          case "refresh":
            this.props.actions.updateODataTreeAction()
            break;
          case "rename":
            break;
          case "delete":
            break;
          case "json":
            break;
        }
        break;
      case "rvws":
        switch (actionCode) {
          case "refresh":
            this.props.actions.updateODataTreeAction()
            break;
          case "new":
            break;
        }
        break;
      case "vws":
        switch (actionCode) {
          case "refresh":
            this.props.actions.updateODataTreeAction()
            break;
        }
        break;
      case "rvw":
        // moved to rvm-menu component
        break;
      case "vw":
        switch (actionCode) {
          case "open":
            break;
          case "refresh":
            this.props.actions.updateODataTreeAction()
            break;
        }
        break;
      case "rreps":
        break;
      case "reps":
        break;
      case "rtests":
        break;
    }
  };

  retrieveNodeByKey = (key, treeNode = this.state.treeData) => {
    if (key && key === treeNode.key) {
      return treeNode;
    } else {
        let children = treeNode.children || (Array.isArray(treeNode) && treeNode);
        children = children && children.filter(child => key.startsWith(child.key+'-') || key === child.key);
        return (children && children.length === 1) ? this.retrieveNodeByKey(key, children[0]) : undefined;
    }
  }

  static getNodeData(nodeType, odmObjOrId, key) {
    let rD2RDRel, d2DRel
    switch (nodeType && nodeType.toLowerCase()) {
      case 'rd2rd':
          rD2RDRel = odmObjOrId.typ ? odmObjOrId : ODM.getRD2RDRelNoLoadNoExtd(odmObjOrId)
      case 'rd': {
          const rD = rD2RDRel ? ODM.getRDNoLoadNoExtd(rD2RDRel.tid) : (odmObjOrId.typ ? odmObjOrId : ODM.getRDNoLoadNoExtd(odmObjOrId))
          return {
              key: key,
              title: rD ? OE.getDescription(rD) + " [" + rD.cod + "]" : (rD2RDRel && ("REL [ID:" +rD2RDRel.id + "]") || "...") ,
              odmId: rD2RDRel ? rD2RDRel.id : rD.id,
              nodeType: nodeType
          };
          break
      }
      case 'ra': {
        const curRA = odmObjOrId.typ ? odmObjOrId : ODM.getRAttNoLoadNoExtd(odmObjOrId)
        let isSubAttribute = false
        const curLnkRA = curRA.lnk && ODM.getRAttNoLoadNoExtd(curRA.lnk)
        return {
            key: key,
            odmId: curRA.id,
            nodeType: 'ra',
            title: OE.getDescription(curRA || curLnkRA) || curRA.cod,
            isLeaf: true
        }
      }
      case 'd2d':
          d2DRel = odmObjOrId.typ ? odmObjOrId : ODM.getD2DRelNoLoadNoExtd(odmObjOrId)
      case 'd': {
          const d = d2DRel ? ODM.getDNoLoadNoExtd(d2DRel.tid) : (odmObjOrId.typ ? odmObjOrId : ODM.getDNoLoadNoExtd(odmObjOrId))
          const rD = d && ODM.getRDNoLoadNoExtd(d.rid)
          return {
              key: key,
              title: d ? (OE.getDescription(d) || OE.getDescription(rD)) + " [" + d.cod + "]" : (d2DRel && ("REL [ID:" +d2DRel.id + "]") || "...") ,
              odmId: d2DRel ? d2DRel.id : d.id,
              nodeType: nodeType
          }
          break
      }
      case 'a': {
        const att = odmObjOrId
        const rAtt = ODM.getRAttNoLoadNoExtd(odmObjOrId.rid)
        let isSubAttribute = false
        const lnkRAtt = rAtt.lnk && ODM.getRAttNoLoadNoExtd(rAtt.lnk)
        return {
            key: key,
            odmId: att.id,
            attTyp: att.dtp,
            nodeType: 'a',
            title: OE.getDescription(rAtt || lnkRAtt) || rAtt.cod,
            isLeaf: true
        }
      }
      default: {
        return {
            key: key,
            nodeType: nodeType,
            title: 'Rendering of nodeType ' + nodeType + 'not supported',
            isLeaf: true
        }
      }
    }
  }

  loadTreeNodeChildren(treeNode) {
    let returnValue;
    let curRD, curD, curRD2RDRel;

    treeNode.props.dataRef.treeNodeRef = treeNode;
    const parentNode = this.retrieveNodeByKey(treeNode.props.dataRef.key);

    //const parentNode = treeNode && treeNode.props.dataRef.nodeType && this.getNodeData(treeNode.props.dataRef.nodeType, treeNode.props.dataRef.odmId, treeNode.props.dataRef.key)
    if (parentNode) {
      let rD2RDRel, d2DRel, rD2DRel
      switch (parentNode.nodeType.toLowerCase()) {
        case 'rd2rd':
          rD2RDRel = ODM.getRD2RDRelNoLoadNoExtd(parentNode.odmId)
        case 'rd': {
          return this.loadRDById(rD2RDRel ? rD2RDRel.tid : parentNode.odmId).then((value) => {
            if (value === "LOADED") {
              const rD = rD2RDRel ? ODM.getRDNoLoadNoExtd(rD2RDRel.tid) : ODM.getRDNoLoadNoExtd(parentNode.odmId)
              parentNode.children = [
                  {key: parentNode.key + '-0', odmId: rD.id, nodeType: 'xnds', title: 'Extensions'},
                  {key: parentNode.key + '-1', odmId: rD.id, nodeType: 'rdsubs', title: 'Subsets'},
                  {key: parentNode.key + '-2', odmId: rD.id, nodeType: 'ras', title: 'Attributes'},
                  {key: parentNode.key + '-3', odmId: rD.id, nodeType: 'rvws', title: 'Views'},
                  {key: parentNode.key + '-4', odmId: rD.id, nodeType: 'rreps', title: 'Reports', isLeaf: true},
                  {key: parentNode.key + '-5', odmId: rD.id, nodeType: 'rtests', title: 'Sandbox', isLeaf: true}
              ];
              const xndRels = OE.getRecursiveXndRels(rD, {});
              if(!(xndRels && Object.keys(xndRels).length)) {
                  parentNode.children[0].isLeaf = true;
              }
              const subRels = OE.getRDSubRels(rD, {});
              if(!(subRels && Object.keys(subRels).length)) {
                  parentNode.children[1].isLeaf = true;
              }
              const rAtts = OE.getRAtts(rD, []);
              if (!rAtts.length) {
                  parentNode.children[2].isLeaf = true;
              }
              const vFs = OE.getVFs(rD);
              if(!(vFs && Object.keys(vFs).length)) {
                parentNode.children[3].isLeaf = true;
              } else {
                parentNode.children[3].children = Object.keys(vFs).map((vFCode, index) => {
                  let nodeText = OE.getDescription(vFs[vFCode]) || vFCode
                  return {key: parentNode.key + '-3-' + index, odmId: rD2RDRel ? rD2RDRel.id : rD.id, odmType: rD2RDRel ? "rd2rd" : "rd", formCode: vFCode, nodeType: 'rvw', title: nodeText, isLeaf: true}})
              }
            } else {
              if (value === "OFFLINE") {
                parentNode.isLeaf = true;
                console.log("Can't load Root-Dataset with ID " + (rD2RDRel ? rD2RDRel.tid : parentNode.odmId) + " in offline mode");
              }
            }
          })
          break
        }
        case 'xnds': {
          const rD = (parentNode.odmId || parentNode.odmId === 0) && ODM.getRDNoLoadNoExtd(parentNode.odmId)
          if (!rD || (rD.lvl || 0) < 2000) {
            console.log("Xnds cannot be displayed for Root-Datasets with ID " + (rD.id) + " due to insufficient loading level");
          } else {
            const childRels = OE.getRelsForObjectWithType(rD, 's', 'xnd') || []
            const unloadedRDIds = childRels
              .filter(item => item.tid && !ODM.getRDNoLoadNoExtd(item.tid))
              .map(item => item.tid)

            return this.loadRD2RDList(unloadedRDIds).then(() => {
              parentNode.children = childRels.map((rel, index) => ODataTree.getNodeData('rd2rd', rel.id, parentNode.key + '-' + index))
              return Promise.resolve()
            })
          }
          break
        }
        case 'rdsubs': {
          const rD = (parentNode.odmId || parentNode.odmId === 0) && ODM.getRDNoLoadNoExtd(parentNode.odmId)
          if (!rD || (rD.lvl || 0) < 2000) {
            console.log("RDSubs cannot be displayed for Root-Datasets with ID " + (rD.id) + " due to insufficient loading level")
          } else {
            const tmp = OE.getRDSubRels(rD, {})
            const childRels = Object.keys(tmp).map((relId) => tmp[relId])
            const unloadedRDIds = childRels
              .filter(item => item.tid && !ODM.getRDNoLoadNoExtd(item.tid))
              .map(item => item.tid)

            return this.loadRD2RDList(unloadedRDIds).then(() => {
              parentNode.children = childRels.map((rel, index) => ODataTree.getNodeData('rd2rd', rel.id, parentNode.key + '-' + index))
              return Promise.resolve()
            })
          }
          break
        }
        case 'ras': {
          const rD = (parentNode.odmId || parentNode.odmId === 0) && ODM.getRDNoLoadNoExtd(parentNode.odmId)
          if (!rD || (rD.lvl || 0) < 2000) {
            console.log("Root-Attributes cannot be displayed for Root-Datasets with ID " + (rD.id) + " due to insufficient loading level")
          } else {
            return Promise.resolve().then(() => {
              const rAtts = OE.getRAtts(rD, []).map(id => ODM.getRAttNoLoadNoExtd(id));
              parentNode.children = rAtts.map((rAtt, index) => ODataTree.getNodeData('ra', rAtt, parentNode.key + '-' + index))
              return Promise.resolve()
            })
          }
          break
        }
        case 'd2d':
          d2DRel = ODM.getD2DRelNoLoad(parentNode.odmId);
        case 'rd2d':
          if (!d2DRel)
            rD2DRel = ODM.getRD2DRelNoLoad(parentNode.odmId);
        case 'd':
          const dId = (d2DRel || rD2DRel) ? (d2DRel || rD2DRel).tid : parentNode.odmId
          return this.loadDById(dId).then((value) => {
            if (value === "LOADED") {
              const d = ODM.getDNoLoadNoExtd(dId)
              parentNode.children = [
                {key: parentNode.key + '-0', odmId: d.id, nodeType: 'dsubs', title: 'Subsets'},
                {key: parentNode.key + '-1', odmId: d.id, nodeType: 'as', title: 'Attributes'},
                {key: parentNode.key + '-2', odmId: d.id, nodeType: 'vws', title: 'Views'}
              ];
              const subRels = OE.getDSubRels(d);
              if(!(subRels && Object.keys(subRels).length)) {
                  parentNode.children[0].isLeaf = true;
              }
              const atts = OE.getAttIds(d);
              const attCount = Object.keys(atts).reduce((acc, curVal) => acc + atts[curVal].length, 0)
              if (!attCount) {
                  parentNode.children[1].isLeaf = true;
              }
              const rD = ODM.getRDNoLoadNoExtd(d.rid)
              const vFs = OE.getVFs(rD);
              if(!(vFs && Object.keys(vFs).length)) {
                  parentNode.children[2].isLeaf = true;
              } else {
                parentNode.children[2].children = Object.keys(vFs).map((vFCode, index) => {
                  let nodeText = OE.getDescription(vFs[vFCode]) || vFCode
                  return {key: parentNode.key + '-2-' + index, odmId: d2DRel ? d2DRel.id : d.id, odmType: d2DRel ? "d2d" : "d", formCode: vFCode, nodeType: 'vw', title: nodeText, isLeaf: true}})
              }
            } else {
              if (value === "OFFLINE") {
                parentNode.isLeaf = true;
                console.log("Can't load Dataset with ID " + dId + " in offline mode")
              }
            }
          })
        case 'dsubs': {
          const d = (parentNode.odmId || parentNode.odmId === 0) && ODM.getDNoLoadNoExtd(parentNode.odmId)
          if (!d || (d.lvl || 0) < 2000) {
            console.log("DSubs cannot be displayed for Datasets with ID " + (d.id) + " due to insufficient loading level");
          } else {
            const subRels = OE.getDSubRels(d);
            const childRels = Object.keys(subRels).map((relId) => subRels[relId])
            const unloadedDIds = childRels
              .filter(item => item.tid && !ODM.getDNoLoadNoExtd(item.tid))
              .map(item => item.tid)

            return this.loadD2DList(unloadedDIds).then(() => {
              parentNode.children = childRels.map((rel, index) => ODataTree.getNodeData('d2d', rel.id, parentNode.key + '-' + index))
              return Promise.resolve()
            })
          }
          break
        }
        case 'as': {
          const d = (parentNode.odmId || parentNode.odmId === 0) && ODM.getDNoLoadNoExtd(parentNode.odmId)
          if (!d || (d.lvl || 0) < 2000) {
            console.log("Attributes cannot be displayed for Datasets with ID " + (d.id) + " due to insufficient loading level")
          } else {
            return Promise.resolve().then(() => {
              const attIds = OE.getAttIds(d)
              const atts = Object.keys(attIds).reduce((acc, curVal) => acc.concat(attIds[curVal].map(id => ODM.getAttNoLoadNoExtd(curVal, id))), [])
              parentNode.children = atts.map((att, index) => ODataTree.getNodeData('a', att, parentNode.key + '-' + index))
              return Promise.resolve()
            })
          }
          break
        }
      }
    }
  }

  onLoadData = treeNode => {
    // Todo 4/4/2021: differentiate between online and offline mode
    if (this.syncLoading) {
      this.syncLoading = this.syncLoading.then(() => {
        return this.loadTreeNodeChildren(treeNode)
      })
    } else {
      this.syncLoading = this.loadTreeNodeChildren(treeNode)
    }

    return this.syncLoading
  };

  loadRD2RDList(ids) {
    if (ODM.isOffline || !ids || !ids.length) {
      return new Promise((resolve, reject) => {
        resolve()
      })
    } else {
      const loadTimeOut = 3000
      const reqData = [{ids: ids, type: 'rd', lvl: 1000}]
      const loadPromise = new Promise((resolve, reject) => {
        ODM.loadAndCallback(reqData, () => {
          resolve("LOAD")
        });
      })
      const timeOutPromise = new Promise((resolve, reject) => {
        let wait = setTimeout(() => {
          clearTimeout(wait)
          resolve("TIMEOUT")
        }, loadTimeOut)
      })
      return Promise.race([
        loadPromise,
        timeOutPromise
      ])
    }
  }

  loadRDById(id) {
    const rD = ODM.getRDNoLoadNoExtd(id);

    if ((rD && rD.lvl && rD.lvl > 2999 )) {
      return new Promise((resolve, reject) => {
        resolve("LOADED")
      })
    } else {
      if(ODM.isOffline) {
        return new Promise((resolve, reject) => {
          resolve("OFFLINE")
        })
      }
      const loadTimeOut = 3000
      const reqData = [{ids: [id], type: 'rd', lvl: 3000}]
      const loadPromise = new Promise((resolve, reject) => {
        ODM.loadAndCallback(reqData, () => {
          resolve("LOADED")
        });
      })
      const timeOutPromise = new Promise((resolve, reject) => {
        let wait = setTimeout(() => {
          clearTimeout(wait)
          resolve("TIMEOUT")
        }, loadTimeOut)
      })
      return Promise.race([
        loadPromise,
        timeOutPromise
      ])
    }
  }

  loadD2DList(ids) {
    if (ODM.isOffline || !ids || !ids.length) {
      return new Promise((resolve, reject) => {
        resolve()
      })
    } else {
      const loadTimeOut = 3000
      const reqData = [{ids: ids, type: 'd', lvl: 1000}]
      const loadPromise = new Promise((resolve, reject) => {
        ODM.loadAndCallback(reqData, () => {
          resolve("LOAD")
        });
      })
      const timeOutPromise = new Promise((resolve, reject) => {
        let wait = setTimeout(() => {
          clearTimeout(wait)
          resolve("TIMEOUT")
        }, loadTimeOut)
      })
      return Promise.race([
        loadPromise,
        timeOutPromise
      ])
    }
  }

  loadDById(id) {
    const d = ODM.getDNoLoadNoExtd(id);

    if ((d && d.lvl && d.lvl > 2999 )) {
      return new Promise((resolve, reject) => {
        resolve("LOADED")
      })
    } else {
      if(ODM.isOffline) {
        return new Promise((resolve, reject) => {
          resolve("OFFLINE")
        })
      }
      const loadTimeOut = 3000
      const reqData = [{ids: [id], type: 'd', lvl: 3000}]
      const loadPromise = new Promise((resolve, reject) => {
        ODM.loadAndCallback(reqData, () => {
          resolve("LOADED")
        });
      })
      const timeOutPromise = new Promise((resolve, reject) => {
        let wait = setTimeout(() => {
          clearTimeout(wait)
          resolve("TIMEOUT")
        }, loadTimeOut)
      })
      return Promise.race([
        loadPromise,
        timeOutPromise
      ])
    }
  }

  onSelect = (selectedKeys, event) => {
    let formCode;
    if (!event.nativeEvent.noTreeSelect && event.selected === true){
      let treeNode = event.node;
      switch (treeNode.props.dataRef.nodeType) {
        case 'rvw':
          formCode = treeNode.props.dataRef.formCode;
        case 'rd':
          formCode ? ODM.openRD(treeNode.props.dataRef.odmId, true, formCode) : ODM.openRD(treeNode.props.dataRef.odmId, true);
          break;
        case 'rd2rd':
          ODM.openRD2RDRel(treeNode.props.dataRef.odmId, true);
          break;
        case 'vw':
          formCode = treeNode.props.dataRef.formCode;
        case 'd':
          formCode ? ODM.openD(treeNode.props.dataRef.odmId, true, formCode) : ODM.openD(treeNode.props.dataRef.odmId, true);
          break;
        case 'd2d':
          let curD2DRel = ODM.getD2DRelNoLoadNoExtd(treeNode.props.dataRef.odmId, true);
          ODM.openD(curD2DRel.tid, true);
          break;
        case 'rd2d':
          let curRD2DRel = ODM.getD2DRelNoLoadNoExtd(treeNode.props.dataRef.odmId, true);
          ODM.openD(curRD2DRel.tid, true);
          break;
        case 'ra':
          let curRA = ODM.getRAttNoLoadNoExtd(treeNode.props.dataRef.odmId, true);
          OE.loadRAttForm(curRA.id, "DEFAULT");
          break;
      }
    }
  };

  renderTreeNodes = (data, parent) => {
    return data.map(item => {
      let treeData = item;
      let treeIcon;
      let treeTitle = treeData.title;
      let fixDropDownZIndex = () => {
        document.querySelectorAll("div.ant-dropdown")
            .forEach(e => (e.style['z-index'] = curZIndex+10000));
        document.querySelectorAll("div.ant-dropdown-menu-submenu")
            .forEach(e => (e.style['z-index'] = curZIndex+10000));
      };
      switch (item.nodeType) {
        case "rd":
          const rd = ODM.getRDNoLoad(item.odmId)
          const icon = OE.getSVGIconCodeForRD(rd)
          treeIcon = treeNodeIcon(icon) || <DefaultTreeIcon style={{verticalAlign: '-.125em', height:'1.1em', width:'1.1em', color: '#7f0000'}}/>
          treeTitle = <RdMenu
              onVisibleChange={fixDropDownZIndex}
              onClickMenuItem={this.onClickMenuItem}
              treeData={treeData} />
          break;
        case "rd2rd":
          const rd2rd = ODM.getRD2RDRelNoLoad(item.odmId)
          const sRd = ODM.getRDNoLoad(rd2rd.tid)
          const sRdIcon = sRd && OE.getSVGIconCodeForRD(sRd)
          treeIcon = (sRdIcon && treeNodeIcon(sRdIcon)) || <DefaultTreeIcon style={{verticalAlign: '-.125em', height:'1.1em', width:'1.1em', color: '#7f0000'}}/>;
          treeTitle = <Rd2RdMenu
              onVisibleChange={fixDropDownZIndex}
              onClickMenuItem={this.onClickMenuItem}
              treeData={treeData}/>
          break;
        case "d": {
          const rdIcon = getDatasetRDIcon(item.odmId)
          treeIcon = (rdIcon && treeNodeIcon(rdIcon)) || (treeData && treeData.icon) ||
            <DefaultTreeIcon style={{verticalAlign: '-.125em', height: '1.1em', width: '1.1em', color: '#7f0000'}}/>;
          treeTitle = <DMenu
            onVisibleChange={fixDropDownZIndex}
            onClickMenuItem={this.onClickMenuItem}
            treeData={treeData}/>
          break;
        }
        case "d2d": {
          const rdIcon = getD2DRDIcon(item.odmId)
          treeIcon = (rdIcon && treeNodeIcon(rdIcon)) || (treeData && treeData.icon) ||
            <DefaultTreeIcon style={{verticalAlign: '-.125em', height: '1.1em', width: '1.1em', color: '#7f0000'}}/>;
          treeTitle = <D2DMenu
            onVisibleChange={fixDropDownZIndex}
            onClickMenuItem={this.onClickMenuItem}
            treeData={treeData}/>;
          break;
        }
        case "dsubs":
          treeIcon = <SubsetsIcon style={{verticalAlign: '-.125em', height:'1.1em', width:'1.1em', color: '#007f00'}}/>;
          treeTitle = <DSubsMenu
              onVisibleChange={fixDropDownZIndex}
              onClickMenuItem={this.onClickMenuItem}
              treeData={treeData}
              loadRD2RDList={this.loadRD2RDList.bind(this)}/>;
          break;
        case "rdsubs":
          treeIcon = <SubsetsIcon style={{verticalAlign: '-.125em', height:'1.1em', width:'1.1em', color: '#7f0000'}}/>;
          treeTitle = <RDSubsMenu
              onVisibleChange={fixDropDownZIndex}
              onClickMenuItem={this.onClickMenuItem}
              treeData={treeData} />;
          break;
        case "xnds":
          treeIcon = <ExtendsIcon style={{verticalAlign: '-.125em', height:'1.1em', width:'1.1em', color: '#7f0000'}}/>;
          treeTitle = <XndsMenu
              onVisibleChange={fixDropDownZIndex}
              onClickMenuItem={this.onClickMenuItem}
              treeData={treeData} />;
          break;
        case "ras":
          treeIcon = <AttributesIcon style={{verticalAlign: '-.125em', height:'1.1em', width:'1.1em', color: '#7f0000'}}/>;
          treeTitle = <RasMenu
              onVisibleChange={fixDropDownZIndex}
              onClickMenuItem={this.onClickMenuItem}
              treeData={treeData} />;
          break;
        case "as":
          treeIcon = <AttributesIcon style={{verticalAlign: '-.125em', height:'1.1em', width:'1.1em', color: '#007f00'}}/>;
          treeTitle = <AsMenu
              onVisibleChange={fixDropDownZIndex}
              onClickMenuItem={this.onClickMenuItem}
              treeData={treeData} />;
          break;
        case "ra":
          treeIcon = <AttributeIcon style={{verticalAlign: '-.125em', height:'1.1em', width:'1.1em', color: '#7f0000'}}/>;
          treeTitle = <RAMenu
              onVisibleChange={fixDropDownZIndex}
              onClickMenuItem={this.onClickMenuItem}
              treeData={treeData} />;
          break;
        case "a":
          treeIcon = <AttributeIcon style={{verticalAlign: '-.125em', height:'1.1em', width:'1.1em', color: '#007f00'}}/>;
          treeTitle = <AMenu
              onVisibleChange={fixDropDownZIndex}
              onClickMenuItem={this.onClickMenuItem}
              treeData={treeData} />;
          break;
        case "rvws":
          treeIcon = <ViewsIcon style={{verticalAlign: '-.125em', height:'1.1em', width:'1.1em', color: '#7f0000'}}/>;
          treeTitle = <RvwsMenu
              onVisibleChange={fixDropDownZIndex}
              onClickMenuItem={this.onClickMenuItem}
              treeData={treeData} />;
          break;
        case "vws":
            treeIcon = <ViewsIcon style={{verticalAlign: '-.125em', height:'1.1em', width:'1.1em', color: '#007f00'}}/>;
          treeTitle = <VwsMenu
              onVisibleChange={fixDropDownZIndex}
              onClickMenuItem={this.onClickMenuItem}
              treeData={treeData} />;
            break;
        case "rvw":
          treeIcon = <ViewIcon style={{verticalAlign: '-.125em', height:'1.1em', width:'1.1em', color: '#7f0000'}}/>;
          treeTitle = <RvwMenu
              onVisibleChange={fixDropDownZIndex}
              onClickMenuItem={this.onClickMenuItem}
              treeData={treeData} />;
          break;
        case "vw":
          treeIcon = <ViewIcon style={{verticalAlign: '-.125em', height:'1.1em', width:'1.1em', color: '#007f00'}}/>;
          treeTitle = <VWMenu
              onVisibleChange={fixDropDownZIndex}
              onClickMenuItem={this.onClickMenuItem}
              treeData={treeData} />;
          break;
        case "rreps":
          treeIcon = <ReportsIcon style={{verticalAlign: '-.125em', height:'1.1em', width:'1.1em', color: '#7f0000'}}/>;
          treeTitle = <RrepsMenu
              onVisibleChange={fixDropDownZIndex}
              onClickMenuItem={this.onClickMenuItem}
              treeData={treeData} />;
          break;
        case "reps":
          treeIcon = <ReportsIcon style={{verticalAlign: '-.125em', height:'1.1em', width:'1.1em', color: '#007f00'}}/>;
          treeTitle = <RepsMenu
              onVisibleChange={fixDropDownZIndex}
              onClickMenuItem={this.onClickMenuItem}
              treeData={treeData} />;
          break;
        case "rtests":
          treeIcon = <SandboxIcon style={{verticalAlign: '-.125em', height:'1.1em', width:'1.1em', color: '#7f0000'}}/>;
          treeTitle = <RtestsMenu
              onVisibleChange={fixDropDownZIndex}
              onClickMenuItem={this.onClickMenuItem}
              treeData={treeData} />;
          break;
      }
      const returnNode = <TreeNode
          title={(this.state.hideMenu || (treeData && treeData.hideMenu)) ? treeData.title : treeTitle}
          key={item.key}
          dataRef={item}
          icon={treeIcon}
          showIcon={true}
          isLeaf={item.isLeaf}
          className={styles.odmtreenode_nodrop}
          disabled={item.disabled}
          parent={parent}
          >
            {this.state.loadedKeys.includes(item.key) && treeData && treeData.children && this.renderTreeNodes(treeData.children, item)}
        </TreeNode>;
      return (
        returnNode
      );
    });
  };

  changeCursor = (event, dragNodeData, dropNodeData) => {
    const isAllow = checkAllowDrop(dragNodeData, dropNodeData, this.state.treeData);
    const isCtrlDown = event.ctrlKey;

    let dropEffect = 'none';
    if (isAllow) {
      if (isCtrlDown) {
        if (this.isMac) {
          // mac os when ctrl pressed, dropEffect need be link.
          dropEffect = 'link';
        } else {
          dropEffect = 'copy';
        }
      } else {
        dropEffect = 'move';
      }
    }
    event.dataTransfer.dropEffect = dropEffect;
  }

  onDragOver = ({event, node, dragNode}) => {
    const dragNodeData = convertNodePropsToEventData(dragNode.props)
    // mac use cursor to control the mouse cursor.
    // windows use dropEffect to control the cursor.
    if (this.isMac) {
      this.dragOverSubject$.next({event, node, dragNode: dragNodeData})
    } else {
      this.changeCursor(event, dragNodeData.dataRef, node.dataRef);
    }
  };

  getPureTreeData(data) {
    return data.map(item => {
      const { children, key, nodeType, odmId, title} = item
      let pureChildren = children
      if (children && children.length > 0) {
        pureChildren = this.getPureTreeData(children)
      }

      return {children: pureChildren, key, nodeType, odmId, title}
    })
  }

  onDragStart = ({ event, node }) => {
    this.setState({hideMenu: true});
    event.dataTransfer.effectAllowed = 'all'
    event.oTreeSourceId = this.id
    event.oTreeSourceTreeData = [...this.state.treeData]
    event.dataTransfer.setData('oTreeSourceId', this.id)
    const srcData = this.getPureTreeData(this.state.treeData)
    event.dataTransfer.setData('oTreeSourceData', JSON.stringify(srcData))
    this.props.actions.dragStartAction(node);
  };

  onDragEnter = ({event, node, dragNode}) => {}

  onDrop = info => {
    if (!info.event.dataTransfer) {
      return;
    }
    const oTreeSourceId = info.event.dataTransfer.getData('oTreeSourceId')
    const srcDataStr = info.event.dataTransfer.getData('oTreeSourceData')
    const srcTreeData = srcDataStr && JSON.parse(srcDataStr) || []
    const data = [...this.state.treeData];
    // cross tree onDrop will emit twice.  Avoid any actions for the second onDrop call, for which onDragStart is not called.
    if (oTreeSourceId) {

    cursorService.resetGlabalCursor()
    this.props.actions.nodeDragEndAction()
    this.setState({hideMenu: false});
    const dropKey = info.node.props.eventKey;
    const dragKey = info.dragNode.props.eventKey;
    const dropPos = info.node.props.pos.split('-');
    const dropPosition = info.dropPosition - Number(dropPos[dropPos.length - 1]);
    const loop = (data, key, callback) => {
      data.forEach((item, index, arr) => {
        if (item.key === key) {
          return callback(item, index, arr);
        }
        if (item.children) {
          return loop(item.children, key, callback);
        }
      });
    };
    // Find dragObject
    let dragObj;
    loop(srcTreeData, dragKey, (item, index, arr) => {
      dragObj = item;
    });

    // cross tree drag. the event emit from.
    // cross tree onDrop will emit twice.
    // 1, from dist tree and the event is completely
    // 2, form source tree the event is not completely, but you can get is by '$srcEvent'
    const isCrossTree = (this.id !== oTreeSourceId)
    const isCtrlDown = info.event.ctrlKey

    // findDropNode
    let dropNode;
    loop(data, dropKey, (item) => {
      dropNode = item
    })
    const isAllowDrop = checkAllowDrop(dragObj, dropNode, this.state.treeData)
    if (!isAllowDrop) {
      console.warn('not allow drop')
      return
    }

    if (dragObj &&dropNode) {
      switch (dragObj.nodeType && dragObj.nodeType.toLowerCase()) {
        case "rd":
        case "rd2rd":
          {
            const dragRd2rdRel = (dragObj.nodeType.toLowerCase() === "rd2rd") ? ODM.getRD2RDRelNoLoad(dragObj.odmId) : undefined
            const dragRDId = dragRd2rdRel ? dragRd2rdRel.tid : dragObj.odmId
            switch (dropNode.nodeType && dropNode.nodeType.toLowerCase()) {
              case "rdsubs":
              case "xnds":
                {
                  const reqData = [{ids: [dragRDId, dropNode.odmId], type: 'rd', lvl: 3000}]
                  if (dragRd2rdRel && dragRd2rdRel.sid != dropNode.odmId) {
                      reqData[0].ids.push(dragRd2rdRel.sid)
                  }
                  reqData[0].ids = reqData[0].ids.filter(odmId => (odmId >= 0))
                  ODM.getOrLoadData(reqData).then(() => {
                    let dragRD = ODM.getRDNoLoad(dragRDId)
                    let dropRD = ODM.getRDNoLoad(dropNode.odmId)
                    if (dragRD && dropRD) {
                      if (isCtrlDown || !dragRd2rdRel) {
                        OE.createRel(dropRD.id, dragRD.id, "rd2rd", dropNode.nodeType.toLowerCase().substring(0, dropNode.nodeType.length-1), false, function(isSuccess, newRel) {
                          if (isSuccess === true) {
                            dragRD = ODM.getRDNoLoad(dragRDId)
                            dropRD = ODM.getRDNoLoad(dropNode.odmId)
                          }
                        });
                      } else if (dragRd2rdRel && dragRd2rdRel.sid !== dropRD.id) {
                        const oldSourceRD = ODM.getRDNoLoad(dragRd2rdRel.sid)
                        dragRd2rdRel.sid = dropRD.id
                        ODM.updateRD2RDRel(dragRd2rdRel, dragRd2rdRel.id)
                        if(oldSourceRD.srl.indexOf(dragRd2rdRel.id) !== -1) {
                          oldSourceRD.srl.splice(oldSourceRD.srl.indexOf(dragRd2rdRel.id), 1)
                          ODM.updateRDNoSave(oldSourceRD, oldSourceRD.id)
                        }
                        if(dropRD.srl.indexOf(dragRd2rdRel.id) === -1) {
                          dropRD.srl.push(dragRd2rdRel.id)
                          ODM.updateRDNoSave(dropRD, dropRD.id)
                        }
                        if(dragRD.trl.indexOf(dragRd2rdRel.id) === -1) {
                          dragRD.trl.push(dragRd2rdRel.id);
                          ODM.updateRDNoSave(dragRD, dragRD.id);
                        }
                      }
                    }
                  });
                }
                break;
            }
          }
          break;
        case "d":
        case "d2d":
          {
            const distD = ODM.getDNoLoadNoExtd(dropNode.odmId)
            const sourceD2DRel = ODM.getD2DRelNoLoadNoExtd(dragObj.odmId)
            const sourceD = ODM.getDNoLoadNoExtd(sourceD2DRel.tid)
            if (isCtrlDown) {
              const distRD = ODM.getRDNoLoadNoExtd(distD.rid)
              const distRDSubRels = OE.getRDSubRels(distRD,{})
              const sourceRD = ODM.getRDNoLoadNoExtd(sourceD.rid)
              const rDSubRelArray = Object.keys(distRDSubRels).map(key => distRDSubRels[key] && distRDSubRels[key].tid && [ODM.getRDNoLoadNoExtd(distRDSubRels[key].tid), distRDSubRels[key]]).filter(item => item && item[0] && OE.isExtensionOfByRD(sourceRD, item[0]))
              if (rDSubRelArray && rDSubRelArray.length && rDSubRelArray.length === 1) {
                const newDSubRel = OE.createDSubRelForRDSubRel(distD.id, sourceD.id, rDSubRelArray[0][1], false)
              }
            }
          }
          break;
      }
    }
      this.props.actions.updateODataTreeAction()
    }
  }

  onDragEnd = ({dragNode}) => {
    cursorService.resetGlabalCursor()
    this.props.actions.nodeDragEndAction()
  }

  getTreeState = () => {
    return  this.oTree.state
  }

  onLoad = (loadedKeys, {event, node}) => {
    this.setState({
      loadedKeys
    })
  }

  render () {
    return <OAntDTree
        ref={(tree) => this.oTree = tree}
        className="odata-tree-container"
        onLoad={this.onLoad}
        loadedKeys={this.state.loadedKeys}
        showIcon={true}
        loadData={this.onLoadData}
        defaultExpandedKeys={[]}
        autoExpandParent={this.state.autoExpandParent}
        draggable
        selectable
        blockNode
        onDragOver={this.onDragOver}
        onDragStart={this.onDragStart}
        onDragEnter={this.onDragEnter}
        onDragEnd={this.onDragEnd}
        onDrop={this.onDrop}
    >
      {this.renderTreeNodes(this.state.treeData)}
    </OAntDTree>
 }
};

const mapStateToProps = state => ({
  windows: state.windowState.windows,
  odataTree: state.odataTree,
  mutiContentWindow: state.mutiContentWindow
})

const mapDispatchToProps = dispatch => ({
  actions: Object.assign(
    {},
    bindActionCreators(WindowManagementActions, dispatch),
    bindActionCreators(OdataTreeActions, dispatch),
  )
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ODataTree)
