import ODM from 'odm'
import {ODMHelper} from "orbit-components";
import {message} from "antd";
import {cloneDeep} from "lodash";

export function loadVersionThread (rd) {
  const vtid = rd.vtid
  const versionThread = ODM.rDVTs[vtid];
  if(!versionThread) {
    return new Promise((resolve, reject) => {
      const timer = setTimeout(() => {
        reject()
      }, 10 * 1000)
      ODM.callCallbackForVersionThreadId(vtid, () => {
        clearTimeout(timer)
        if (ODM.rDVTs[vtid]) {
          return resolve(ODM.rDVTs[vtid])
        }
      })
    })
  }

  return Promise.resolve(versionThread)
}

export function saveTableDesign(view, vCode, vFObject) {
  const newGuiData = {}
  const viewFormArray = Array.isArray(view) ? view : [view] // convert to same format regardless of whether it is a tab or inline type form
  newGuiData.codes = viewFormArray.map(item => item.code)
  newGuiData.descs = viewFormArray.map(item => item.name)
  newGuiData.layout = {table:[], cols: [], rows: [], cells: []}
  newGuiData.gcod = []
  newGuiData.cfgdid = []
  newGuiData.rd2rdrelid = []
  newGuiData.raid = []
  let tabCount = 0
  viewFormArray.forEach((table) => {
    const formData = table

    newGuiData.gcod.push([])
    newGuiData.cfgdid.push([])
    newGuiData.rd2rdrelid.push([])
    newGuiData.raid.push([])

    newGuiData.layout.table.push({})
    newGuiData.layout.cols.push([])
    newGuiData.layout.rows.push([])
    newGuiData.layout.cells.push([])
    Object.keys(formData.styles).forEach((propName) => {
      if(formData.styles[propName]) {
        newGuiData.layout.table[tabCount][propName] = formData.styles[propName]
      }
    })
    let rowCount = 0
    formData.rows.forEach((rowData) => {
      newGuiData.gcod[tabCount].push([])
      newGuiData.cfgdid[tabCount].push([])
      newGuiData.rd2rdrelid[tabCount].push([])
      newGuiData.raid[tabCount].push([])
      newGuiData.layout.rows[tabCount].push({})
      Object.keys(rowData.styles).forEach((propName) => {
        if(rowData.styles[propName]) {
          newGuiData.layout.rows[tabCount][rowCount][propName] = rowData.styles[propName]
        }
      })
      newGuiData.layout.cells[tabCount].push([])
      let cellCount = 0
      rowData.cells.forEach((cellData) => {
        const raid = cellData.tempObj?.type === 'ra' ? cellData.tempObj.id : null
        const rd2rdid = cellData.tempObj?.type === 'vf' && cellData.tempObj.id ? cellData.tempObj.id : null
        const cfgdid = cellData.cellCfg?.d?.id || null

        newGuiData.gcod[tabCount][rowCount].push(cellData.tempObj?.hasOwnProperty('code') ? cellData.tempObj.code : null);
        newGuiData.cfgdid[tabCount][rowCount].push(cfgdid);
        newGuiData.rd2rdrelid[tabCount][rowCount].push(rd2rdid);
        newGuiData.raid[tabCount][rowCount].push(raid);

        newGuiData.layout.cells[tabCount][rowCount].push({})
        // we can't save the calculated value, the autoColspan is high priority.
        // In the design process we will automatically calculate the colspan attribute of the last cell.
        // If cell A is the last cell in a row, when insert a cell to right of current cell. the cell A is not the last on now.
        // so If there is a autoColspan attribute, the autoColspan will never be recalculated.
        delete cellData.autoColspan
        Object.keys(cellData.styles).forEach((propName) => {
          if(cellData.styles[propName]) {
            if (propName.toLowerCase() !== 'colspan' || cellCount < (rowData.cells.length-1) || (!isNaN(cellData.styles[propName]) && Number(cellData.styles[propName]) > 1)) {
              newGuiData.layout.cells[tabCount][rowCount][cellCount][propName] = cellData.styles[propName]
            }
          }
        })
        cellCount++
      })
      rowCount++
    })
    let colCount = 0
    formData.columns.forEach((colData) => {
      newGuiData.layout.cols[tabCount].push({})
      Object.keys(colData.styles || {}).forEach((propName) => {
        if(colData.styles[propName]) {
          newGuiData.layout.cols[tabCount][colCount][propName] = colData.styles[propName]
        }
      })
      colCount++
    })
    tabCount++
  })

  let rDataset = ODMHelper.getRDByOdmObj(vFObject)
  let guiObject = rDataset.gui[vCode || 'DEFAULT'];
  if (!guiObject) {
    return message.warn(`Root Dataset ${rDataset.cod} don't have View, Please create a view first.`)
  }
  guiObject.data = cloneDeep(newGuiData);
  ODM.updateRD(rDataset, rDataset.id);
}
