import { useSlate } from 'slate-react';
import React from 'react';
import { Editor, Element as SlateElement, Transforms } from 'slate';
import classNames from 'classnames';

const LIST_TYPES = ['numbered-list', 'bulleted-list']

const isBlockActive = (editor, format) => {
  const [match] = Editor.nodes(editor, {
    match: n =>
      !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === format,
  })

  return !!match
}

const toggleBlock = (editor, format) => {
  const isActive = isBlockActive(editor, format)
  const isList = LIST_TYPES.includes(format)

  Transforms.unwrapNodes(editor, {
    match: n =>
      LIST_TYPES.includes(
        !Editor.isEditor(n) && SlateElement.isElement(n) && n.type
      ),
    split: true,
  })
  const newProperties = {
    type: isActive ? 'paragraph' : isList ? 'list-item' : format,
  }
  Transforms.setNodes(editor, newProperties)

  if (!isActive && isList) {
    const block = { type: format, children: [] }
    Transforms.wrapNodes(editor, block)
  }
}

const BlockButton = ({ format, icon }) => {
  const editor = useSlate()
  return (
    <button
      className={classNames('icon-btn', {'icon-btn-active': isBlockActive(editor, format)})}
      onMouseDown={event => {
        event.preventDefault()
        toggleBlock(editor, format)
      }}
    >
      <i className={classNames('iconfont', icon)}></i>
    </button>
  )
}

export default BlockButton
