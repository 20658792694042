import React from 'react';
import { cloneDeep } from 'lodash';
import { Dropdown, Menu } from 'antd';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import styles from './cell-design.module.less'

import {
  CELL_ATTRIBUTES_CAN_ADJUST_LIST,
  CELL_STYLES_CAN_ADJUST_LIST,
  COLUMN_STYLES_CAN_ADJUST_LIST,
} from '../../../../core/constant';
import {
  genHash,
  getCellColspan,
  getCellRowspan,
  getCellStyles,
  getColumnStyles,
  getRowStyles,
  getTableStyles,
} from '../../utils';

const getCellMergedDraggingStyles = (cell, provided) => {
  const userStyles = getCellStyles(cell)
  return Object.assign(
    {},
    provided.draggableProps.style,
    userStyles
  )
}

class CellDesignTable extends React.PureComponent {
  constructor (props) {
    super(props);

    this.state = {
      sourceView: props.view,
      view: cloneDeep(props.view), // for edit
      activeRowIndex: props.activeRowIndex,
      activeCellIndex: props.activeCellIndex
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { view, activeRowIndex, activeCellIndex } = nextProps
    const changes = { activeRowIndex, activeCellIndex }
    if (view !== prevState.sourceView) {
      // formData changed
      changes.view = cloneDeep(view)
      changes.sourceView = view
    }

    return changes
  }

  onDragContextStart(result) {
    const { source } = result;
    let rowIndex = this.state.view.rows.findIndex(item => item.id ===  source.droppableId);
    this.perSetState({
      activeRowIndex: rowIndex,
      activeCellIndex: source.index
    });
  }

  onDragContextEnd(result) {
    const { source, destination } = result;

    // dropped outside the list
    if (!destination) {
      return;
    }
    const {droppableId: sId, index: sIndex } = source
    const {droppableId: dId, index: dIndex } = destination
    const rows = this.state.view.rows
    const sRow = rows.find(item => item.id === sId)
    const dRow = rows.find(item => item.id === dId)
    const sCell = sRow.cells[sIndex]
    const dCell = dRow.cells[dIndex]
    const temp = dCell
    dRow.cells[dIndex] = sCell
    sRow.cells[sIndex] = temp

    this.perSetState({
      view: { ...this.state.view },
      activeRowIndex: rows.findIndex(item => item.id === dId),
      activeCellIndex: destination.index
    });
  }

  dropdownClickHandler ({ key }) {
    switch(key) {
      case 'insertLeft':
        this.insertNewCell(this.state.activeRowIndex, this.state.activeCellIndex, true)
        break
      case 'insertRight':
        this.insertNewCell(this.state.activeRowIndex, this.state.activeCellIndex, false)
        break
      case 'delete':
        this.deleteCell(this.state.activeRowIndex, this.state.activeCellIndex)
        break
      default:
    }
  }

  insertNewCell = (rowIndex, colIndex, isLeft) => {
    const refRow = this.state.view.rows[rowIndex]
    if (!refRow) return
    const newCell = {
      id: `cell-${rowIndex}-${colIndex}-${genHash()}`,
      styles: CELL_STYLES_CAN_ADJUST_LIST
        .concat(CELL_ATTRIBUTES_CAN_ADJUST_LIST)
        .reduce((obj, key) => {
          obj[key] = ''
          return obj
        }, {})
    }

    if (isLeft) {
      refRow.cells.splice(colIndex, 0, newCell)
    } else {
      delete refRow.cells[colIndex].styles.autoColspan
      refRow.cells.splice(colIndex + 1, 0, newCell)
    }

    this.setState({
      view: this.state.view,
      activeCellIndex: isLeft ? this.state.activeCellIndex + 1 : this.state.activeCellIndex
    }, () => {
      this.automaticallyComputeColspanOfLastCell()
    })
  }

  deleteCell = (rowIndex, colIndex) => {
    const refRow = this.state.view.rows[rowIndex]
    if (!refRow) return
    refRow.cells.splice(colIndex, 1)
    this.state.view.rows = this.state.view.rows
      .filter(item => item.cells.length > 0)

    this.setState({
      view: this.state.view,
    }, () => {
      this.automaticallyComputeColspanOfLastCell()
    })
  }

  automaticallyComputeColspanOfLastCell = () => {
    const rows = this.state.view.rows
    const nCells = new Array(rows.length).fill(0)

    rows.forEach((row, rowIndex) => {
      row.cells.forEach(cell => {
        const rowspan = parseInt(cell.styles.rowspan, 10) || 1
        const colspan = parseInt(cell.styles.colspan, 10) || 1
        nCells[rowIndex] += colspan

        if (rowspan > 1) {
          for (let i = 1; i < rowspan; i++) {
            if (rowIndex + i < nCells.length) {
              nCells[rowIndex + i] += colspan
            }
          }
        }
      })
    })

    const maxCellNumber = Math.max(...nCells)

    rows.forEach((row, index) => {
      const curColspan = nCells[index]

      if (maxCellNumber - curColspan) {
        const lastCellOfRow = row.cells[row.cells.length - 1]
        const colspanOfLastCellOfRow = parseInt(lastCellOfRow['styles'].colspan, 10) || 1
        rows[index].cells[row.cells.length - 1]['styles']['autoColspan'] = colspanOfLastCellOfRow + maxCellNumber - curColspan + ''
      }
    })

    // makeup columns
    const delta = maxCellNumber - this.state.view.columns.length
    if (delta > 0) {
      new Array(delta).fill(1).forEach(() => {
        this.state.view.columns.push({
          id: `column-${genHash()}`,
          styles: COLUMN_STYLES_CAN_ADJUST_LIST.reduce((obj, key) => {
            obj[key] = ''
            return obj
          }, {})
        })
      })
    } else if (delta < 0) {
      this.state.view.columns.splice(delta, Math.abs(delta))
    }
    const { view, activeRowIndex, activeCellIndex } = this.state
    this.perSetState({ view, activeRowIndex, activeCellIndex })
  }

  dropdownShowHandler (visible, rowIndex, cellIndex) {
    if (visible) {
      this.perSetState({
        activeRowIndex: rowIndex,
        activeCellIndex: cellIndex
      })
    }
  }

  perSetState(newState) {
    this.setState(newState)
    this.props.handleStateChange(newState)
  }

  onCellClick(row, col) {
    this.perSetState({
      activeRowIndex: row,
      activeCellIndex: col
    })
  }

  render() {
    const view = this.state.view
    const rowCount = view.rows.length
    const row1 = view.rows[0]
    const onlyOneCell = (rowCount === 1 && row1 && row1.cells.length === 1)
    const deleteOpt = (!onlyOneCell) && (
      <React.Fragment>
        <Menu.Divider />
        <Menu.Item key="delete">Delete Cell</Menu.Item>
      </React.Fragment>
    )
    const cellContextMenu = (
      <Menu onClick={ this.dropdownClickHandler.bind(this) }>
        <Menu.Item key="insertLeft">
          Insert Cell Left
        </Menu.Item>
        <Menu.Item key="insertRight">
          Insert Cell Right
        </Menu.Item>
        {deleteOpt}
      </Menu>
    )
    const rowDesignTable = (
      <DragDropContext
        onDragStart={this.onDragContextStart.bind(this)}
        onDragEnd={this.onDragContextEnd.bind(this)}
      >
        <table className={ styles.ViewFormTableView }
               style={ getTableStyles(this.state.view) }
        >
          <colgroup>
            {
              this.state.view.columns.map(col => (
                <col
                  key={ col.id }
                  style={ getColumnStyles(col) }
                />
              ))
            }
          </colgroup>
          <tbody>
          {
            this.state.view.rows.map((row, rowIndex) => (
              <Droppable key={row.id} droppableId={row.id} direction="horizontal" type="cell">
                {(provided, snapshot) => (
                  <tr
                    key={ row.id }
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    style={ getRowStyles(row) }
                  >
                    {
                      row.cells.map((cell, cellIndex) => (
                        <Draggable key={cell.id} draggableId={cell.id} index={cellIndex}>
                          {(provided, snapshot) => (
                            <Dropdown
                              overlay={ cellContextMenu }
                              trigger={['contextMenu']}
                              overlayClassName={styles.CellContextOverlay}
                              onVisibleChange={ visible => { this.dropdownShowHandler.bind(this)(visible, rowIndex, cellIndex) } }
                            >
                              <td
                                key={ cell.id }
                                className={ `${rowIndex === this.state.activeRowIndex && cellIndex === this.state.activeCellIndex ? styles.ActiveCell : ''}` }
                                onClick={ () => this.onCellClick(rowIndex, cellIndex) }
                                onKeyDown={ () => this.onCellClick(rowIndex, cellIndex) }
                                role="presentation"
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                style={ getCellMergedDraggingStyles(cell, provided) }
                                colSpan={ getCellColspan(cell) }
                                rowSpan={ getCellRowspan(cell) }
                              >{ cell.content || `cell-${rowIndex}-${cellIndex}` }</td>
                            </Dropdown>
                          )}
                        </Draggable>
                      ))
                    }
                  </tr>
                )}
              </Droppable>
            ))
          }
          </tbody>
        </table>
      </DragDropContext>
    )

    return rowDesignTable
  }
}

export default CellDesignTable;
