import React from 'react'
import ABase from './a-base';
import { InboxOutlined } from '@ant-design/icons';
import Dragger from 'antd/es/upload/Dragger';
import styles from './editors.module.less'
import { svgStrToImg } from '../../../../utils/utils';

class ASvgDndEditor extends ABase {
  constructor(props) {
    super(props);
  }

  static getDerivedStateFromProps(props, state) {
    if (props.editMode !== state.editMode) {
      return {
        editMode: props.editMode
      };
    }

    return {}
  }

  onChange = (value) => {
    this.onAttChange(value)
  }

  onUpload = (e) => {
    const file = e.file
    const reader = new FileReader()
    reader.onload = (res) => {
      const content = res.target.result.substring(res.target.result.toLowerCase().indexOf("<svg")).replace(/<!--.*?-->/g, "")
      this.onChange(content)
    }
    reader.readAsText(file)
  }

  renderPreview() {
    const value = this.state.value
    if(!value) {
      return
    }
    const image = svgStrToImg(value)

    return (
      <div className={styles.svgPreview}>
        {image}
      </div>
    )
  }

  render() {
    const props = {
      name: 'file',
      multiple: false,
      customRequest: this.onUpload,
      accept: 'image/svg+xml',
      showUploadList: false,
    }
    const preview = this.renderPreview()

    if (this.state.editMode) {
      return (
        <div className={styles.svgDND_editor}>
        <Dragger {...props}>
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">Click or drag SVG file to this area to upload</p>
        </Dragger>
          {preview}
        </div>
      )
    } else {
      return (
        <div>
          {preview}
        </div>
      )
    }
  }
}

export default ASvgDndEditor
