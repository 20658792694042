import React from 'react'
import {Dropdown, Menu} from "antd";
class RrepsMenu extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { treeData, onVisibleChange, onClickMenuItem } = this.props
        const rrepsMenu = (
            <Menu forceSubMenuRender={false}>
                <Menu.Item key="open" onClick ={(e) => {e.domEvent.nativeEvent.noTreeSelect = true; onClickMenuItem(treeData,'open', e);}} >
                    Coming soon...
                </Menu.Item>
            </Menu>
        );
        return (<Dropdown overlay={rrepsMenu} onVisibleChange={onVisibleChange} >
            <a className="ant-dropdown-link">
                {treeData.title}
            </a>
        </Dropdown>);
    }
}

export default RrepsMenu;
