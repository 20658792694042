import * as oDataTreeTypes from './odata-tree-types';

const initialState = {
  dragging: false,
  dragNode: null,
  treeVersion: 0,
}

export default function odataTree (state = initialState, action) {
  switch (action.type) {
    case oDataTreeTypes.DRAG_NODE_START: {
      return {
        ...state,
        dragging: true,
        dragNode: action.payload,
      }
    }

    case oDataTreeTypes.DRAG_NODE_END: {
      return {
        ...state,
        dragging: false,
        dragNode: null
      }
    }

    case oDataTreeTypes.UPDATE_ODATA_TREE: {
      const version = state.treeVersion

      return {
        treeVersion: version + 1
      }
    }

    default:
      return state
  }
}
