import React from 'react'
import ABase from './a-base'
import { treeNodeIcon } from '../../odata-tree/tree-node-icon'
import { ReactComponent as DefaultTreeIcon } from '../../../../assets/icons/tree-default.svg'
import styles from './editors.module.less'
import classNames from 'classnames'
import DropEditor from './drop-editor';
import { Select } from 'antd';


class DEditor extends ABase {
  constructor(props) {
    super(props);
    if (props.options && props.options.length) {
      this.dropDownDs = props.options.map((dId) => this.odm.getDNoLoadNoExtd(dId))
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (props.editMode !== state.editMode) {
      return {
        editMode: props.editMode
      };
    }

    return {}
  }

  doCheck(node) {
    const nodeData = node.dataRef
    if (nodeData.nodeType === 'd' || nodeData.nodeType === 'rd2d' || nodeData.nodeType === 'd2d') {
      return true
    }

    return false
  }

  onDrop(e) {
    const { dragNode } = e
    if (this.doCheck(dragNode)) {
      const nodeData = dragNode.dataRef
      let value;
      if (nodeData.nodeType === 'rd2d') {
        const rd2d = this.odm.getRD2DRelNoLoadNoExtd(nodeData.odmId)
        value = rd2d && rd2d.tid
      } else if (nodeData.nodeType === 'd2d') {
        const d2d = this.odm.getD2DRelNoLoadNoExtd(nodeData.odmId)
        value = d2d && d2d.tid
      } else {
        value = nodeData && nodeData.odmId
      }
      if (value) {
        this.onAttChange(value)
      }
    }
  }

  onRemove() {
    this.onAttChange(undefined)
  }

  /**
   *
   * @param data instance of root-dataset or null
   */
  onChange = (data) => {

  }

  onSelectD(dId) {
    this.onAttChange(dId)
  }


  renderD() {
    const d = (this.state.value || this.state.value === 0) && this.odm.getDNoLoadNoExtd(this.state.value)
    if (!d) {
      return ''
    }

    const icon = this.oe.getSVGIconCodeForRD(d)
    const iconElem = treeNodeIcon(icon) || <DefaultTreeIcon style={{verticalAlign: '-.125em', height:'1.1em', width:'1.1em', color: '#7f0000'}}/>

    return <div className={classNames(styles.editorContent)}>
      {iconElem}{d.cod}
    </div>

  }


  renderEditor() {
    if (Array.isArray(this.dropDownDs)) {
      const options = this.dropDownDs.map(item => {
        const dropDownRD = this.odm.getRDNoLoadNoExtd(item.rid)
        const icon = this.oe.getSVGIconCodeForRD(dropDownRD)
        const iconElem = treeNodeIcon(icon) || <DefaultTreeIcon style={{verticalAlign: '-.125em', height:'1.1em', width:'1.1em', color: '#7f0000'}}/>

        return <Select.Option value={item.id}><div className={classNames(styles.editorContent)}>{iconElem}{item.cod}</div></Select.Option>
      })

      return <Select style={{ width: '100%' }} onChange={(val) => this.onSelectD(val)}>{options}</Select>
    }
    return <DropEditor
      onDrop={(e) => this.onDrop(e)}
      doCheck={(node) => this.doCheck(node)}
      onRemove={() => this.onRemove()}
      isEmpty={!(this.state.value || this.state.value === 0)}
    >
      {this.renderD()}
    </DropEditor>
  }

  render() {

    return this.state.editMode ? this.renderEditor() : this.renderD()
  }
}

export default DEditor
