import React from 'react';
import { Dropdown, Menu } from "antd";
const { SubMenu } = Menu;

class Rd2RdMenu extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { treeData, onVisibleChange, onClickMenuItem } = this.props
        const rd2rdMenu = (
            <Menu forceSubMenuRender={false}>
                <Menu.Item key="open" onClick ={(e) => {e.domEvent.nativeEvent.noTreeSelect = true; onClickMenuItem(treeData,'open', e);}} >
                    Open
                </Menu.Item>
                <Menu.Item key="refresh" onClick ={(e) => {e.domEvent.nativeEvent.noTreeSelect = true; onClickMenuItem(treeData,'refresh', e);}} >
                    Refresh
                </Menu.Item>
                <SubMenu
                    key="rd2rd-rd"
                    title="Root-Dataset"
                >
                    <Menu.Item key="rd-configure" onClick ={(e) => {e.domEvent.nativeEvent.noTreeSelect = true; onClickMenuItem(treeData,'rd-configure', e);}} >
                        Configure
                    </Menu.Item>
                    <Menu.Item key="rd-delete" onClick ={(e) => {e.domEvent.nativeEvent.noTreeSelect = true; onClickMenuItem(treeData,'rd-delete', e);}} >
                        Delete
                    </Menu.Item>
                    <Menu.Item key="rd-json" onClick ={(e) => {e.domEvent.nativeEvent.noTreeSelect = true; onClickMenuItem(treeData,'rd-json', e);}} >
                        View JSON
                    </Menu.Item>
                    <Menu.Item key="rd-set-icon" onClick={(e) => {e.domEvent.nativeEvent.noTreeSelect = true; onClickMenuItem(treeData,'set-icon', e);}}>
                      Set Icon
                    </Menu.Item>
                </SubMenu>
                <SubMenu
                    key="rd2rd-rel"
                    title="Relation"
                >
                    <Menu.Item key="rel-configure" onClick ={(e) => {e.domEvent.nativeEvent.noTreeSelect = true; onClickMenuItem(treeData,'rel-configure', e);}} >
                        Configure
                    </Menu.Item>
                    <Menu.Item key="rel-delete" onClick ={(e) => {e.domEvent.nativeEvent.noTreeSelect = true; onClickMenuItem(treeData,'rel-delete', e);}} >
                        Delete
                    </Menu.Item>
                    <Menu.Item key="rel-json" onClick ={(e) => {e.domEvent.nativeEvent.noTreeSelect = true; onClickMenuItem(treeData,'rel-json', e);}} >
                        View JSON
                    </Menu.Item>
                </SubMenu>
            </Menu>
        );

        return (<Dropdown overlay={rd2rdMenu} onVisibleChange={onVisibleChange}>
            <a className="ant-dropdown-link">
                {treeData.title}
            </a>
        </Dropdown>);
    }
}

export default Rd2RdMenu
