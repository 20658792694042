import React from 'react'
import OE from 'oe'
import ODM from 'odm'

class ABase extends React.Component {
  constructor(props) {
    super(props);
    this.odm = props.odm || ODM
    this.oe = props.oe || OE
    const cellCfg = this.props.cellCfg;
    const vFData = this.props.vFData;
    const rAtt = cellCfg.raid && this.odm.getRAttNoLoadNoExtd(cellCfg.raid);
    // todo: rAtt maybe undefined, why? in ADimvarInput
    if (!rAtt) {
      this.state = { value: '' }
      return
    }
    const deepRAtt = this.oe.getDeepRAtt(rAtt)
    const deepRD = deepRAtt && deepRAtt.rd && this.odm.getRDNoLoadNoExtd(deepRAtt.rd)
    this.deepRDDefaultD = deepRD && this.oe.getDefaultD(deepRD)
    // const rd2rdRel = (cellCfg.rd2rdRel ||(cellCfg.d2dRel && cellCfg.d2dRel.rrl && this.odm.getRD2RDRelNoLoadNoExtd(cellCfg.d2dRel.rrl)))
    let d
    let params = {}
    switch(vFData.vFObjTyp) {
      case 'd2d':
      case 'd':
        d = vFData.dataset
        break
      case 'rd':
        d = vFData.rDDefaultD
        break
      case 'rd2rd':
        d = vFData.rd2rdRelDefaultD
        break
    }
    const att = deepRAtt && ((d &&this.oe.getAttForD(d, deepRAtt.cod, undefined)) || (vFData.rd2rdRelDefaultD && this.oe.getAttForD(vFData.rd2rdRelDefaultD, deepRAtt.cod, undefined)) || (vFData.rDDefaultD &&this.oe.getAttForD(vFData.rDDefaultD, deepRAtt.cod, undefined)))
    let value = this.oe.getAttValue(att)
    if (deepRAtt.loc) {
      let isArray
      let dataType
      if (deepRAtt.dtp.toLowerCase().endsWith('_ar')) {
        isArray = true
        dataType = deepRAtt.dtp.substring(0, deepRAtt.dtp.length-3).toLowerCase()
      } else {
        dataType = deepRAtt.dtp.toLowerCase()
      }
      const castVal = this.oe.castToType(value, dataType, isArray)
      try {
         this.oe.callFunction(deepRAtt, 'get-value', {ojsD:d, ojsVal: castVal, ojsODM: this.odm, ojsOE: this.oe}).then(value => {
          this.setState({
            value: value
          })
        })
        //value = jscFunc && jscFunc(ODM, OE, d, att, deepRAtt, vFData.rDataset, castVal, undefined)
      } catch (e) {
       console.error(e.message)
      }
    }

    this.d = d
    this.deepRAtt = deepRAtt
    this.rAtt = rAtt

    this.state = {
      value: value,
      editMode: props.editMode || false
    }
  }

  setOptions(code, optVal, optLabel) {
    const cellCfg = this.props.cellCfg;
    const cfgD = this.odm.getDNoLoadNoExtd(cellCfg.cfgdid);
    const cfgRD = cfgD && this.odm.getRDNoLoadNoExtd(cfgD.rid);
    const tmp = this.oe.getSubRDAndRD2RDWithCode(cfgRD, code);  // change to "SYS.ATT.H5.INP.RAD" for the radio-type input, and change to "SYS.ATT.H5.INP.CBX" for checkbox-type input.
    const optSubD2DRels = this.oe.getDSubRelsForRDSubRel(cfgD, tmp.rD2RD);
    this.options = optSubD2DRels.map(optSubD2DRel => {
      const optD = this.odm.getDNoLoadNoExtd(optSubD2DRel.tid);
      const valueAtt = this.oe.getAttForD(optD, optVal, undefined);
      const labelAtt = this.oe.getAttForD(optD, optLabel, undefined);
      return {label: this.oe.getAttValue(labelAtt), value: this.oe.getAttValue(valueAtt)};
    });
  }

  onAttChange = (value, updateCb) => {
    const cellCfg = this.props.cellCfg
    const deepRAtt = this.deepRAtt
    const vFData = this.props.vFData;
    const d = this.d  || (vFData.vFObjTyp === 'rd2rd' && this.oe.getOrCreateDefaultD(vFData.rd2rdRel)) || (vFData.vFObjTyp === 'rd' && this.oe.getOrCreateDefaultD(vFData.rDataset))

    if(!d) {
      // RJZ comment: Will need to add proper logging later
      console.log("error occured setting attribute")
      return;
    }
    let isArray, dataType;
    if (deepRAtt.dtp.toLowerCase().endsWith('_ar')) {
      isArray = true
      dataType = deepRAtt.dtp.substring(0, deepRAtt.dtp.length-3).toLowerCase()
    } else {
      dataType = deepRAtt.dtp.toLowerCase()
    }
    const castVal = this.oe.castToType(value, dataType, isArray)
    if (deepRAtt.loc) {
        this.oe.setLocalAttValueForD(d, deepRAtt, castVal)
        this.setState({
          value: value
        })
    } else {
      let att = this.oe.getAttForD(d, deepRAtt.cod, undefined)
      if (!att || (att.did !== d.id)) {
        att = this.oe.createAtt(d, deepRAtt)
      }
      att.data.val = castVal
      this.odm.updateAtt(att)
      this.setState({
        value: value
      })
      if (updateCb) {
        return updateCb(att)
      }
    }
  }
}

export default ABase
