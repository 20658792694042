import Tree from 'rc-tree';
import {
    arrAdd,
    arrDel,
    calcDropPosition,
    convertNodePropsToEventData, getDragNodesKeys,
    posToArr
} from "./tree-util";

const crossDraggedEvent = new Event('crossDragged')
class OTree extends Tree {
    constructor(props) {
        super(props)

        this.onNodeDragStart = (event, node) => {
            const { expandedKeys, keyEntities } = this.state;
            const { onDragStart } = this.props;
            const { eventKey } = node.props;

            this.dragNode = node;
            // for cross drag
            window.$$Node = {...node}
            // end for cross drag
            this.dragStartMousePosition = {
                x: event.clientX,
                y: event.clientY,
            };

            const newExpandedKeys = arrDel(expandedKeys, eventKey);
            this.setState({
                dragging: true,
                dragNodesKeys: getDragNodesKeys(eventKey, keyEntities),
            });

            this.setExpandedKeys(newExpandedKeys);

            window.addEventListener('crossDragged', this.onCrossDragged)

            if (onDragStart) {
                onDragStart({ event, node: convertNodePropsToEventData(node.props) });
            }
        };

        this.onCrossDragged = (event) => {
            this.onNodeDrop(event, event.$data)
        }

        this.onNodeDragEnter = (event, node) => {
            const { expandedKeys, keyEntities } = this.state;
            const { onDragEnter } = this.props;
            const { pos, eventKey } = node.props;
            let dragNodesKeys
            // cross tree drag
            if (!this.dragNode && window.$$Node) {
                this.dragNode = window.$$Node
                this.$crossTreeDrag = true
                dragNodesKeys = [this.dragNode.props.dataRef.key]
                this.setState({
                    dragging: true,
                    dragNodesKeys: dragNodesKeys
                })
            } else {
                dragNodesKeys = this.state.dragNodesKeys
            }
            // cross tree drag end

            if (!this.dragNode || dragNodesKeys.indexOf(eventKey) !== -1) return;

            const dropPosition = calcDropPosition(event, node);

            // Skip if drag node is self
            if (this.dragNode.props.eventKey === eventKey && dropPosition === 0) {
                this.setState({
                    dragOverNodeKey: '',
                    dropPosition: null,
                });
                return;
            }

            // Ref: https://github.com/react-component/tree/issues/132
            // Add timeout to let onDragLevel fire before onDragEnter,
            // so that we can clean drag props for onDragLeave node.
            // Macro task for this:
            // https://html.spec.whatwg.org/multipage/webappapis.html#clean-up-after-running-script
            setTimeout(() => {
                // Update drag over node
                this.setState({
                    dragOverNodeKey: eventKey,
                    dropPosition,
                });

                // Side effect for delay drag
                if (!this.delayedDragEnterLogic) {
                    this.delayedDragEnterLogic = {};
                }
                Object.keys(this.delayedDragEnterLogic).forEach(key => {
                    clearTimeout(this.delayedDragEnterLogic[key]);
                });
                this.delayedDragEnterLogic[pos] = window.setTimeout(() => {
                    if (!this.state.dragging) return;

                    let newExpandedKeys = [...expandedKeys];
                    const entity = keyEntities[eventKey];

                    if (entity && (entity.children || []).length) {
                        newExpandedKeys = arrAdd(expandedKeys, eventKey);
                    }

                    if (!('expandedKeys' in this.props)) {
                        this.setExpandedKeys(newExpandedKeys);
                    }

                    if (onDragEnter) {
                        onDragEnter({
                            event,
                            node: convertNodePropsToEventData(node.props),
                            expandedKeys: newExpandedKeys,
                            dragNode: this.dragNode
                        });
                    }
                }, 400);
            }, 0);
        };

        this.onNodeDragOver = (event, node) => {
            const { dragNodesKeys } = this.state;
            const { onDragOver } = this.props;
            const { eventKey } = node.props;


            if ((!this.dragNode) || dragNodesKeys.indexOf(eventKey) !== -1) {
                event.dataTransfer.dropEffect = 'none';
                return;
            }

            // Update drag position
            if (eventKey === this.state.dragOverNodeKey) {
                const dropPosition = calcDropPosition(event, node);

                if (dropPosition !== this.state.dropPosition) {
                    this.setState({
                        dropPosition,
                    });
                }
            }

            if (onDragOver) {
                onDragOver({
                    event,
                    node: convertNodePropsToEventData(node.props),
                    dragNode: this.dragNode
                });
            }
        }

        // if onNodeDragEnd is called, onWindowDragEnd won't be called since stopPropagation() is called
        this.onNodeDragEnd = (event, node, outsideTree = false) => {
            const { onDragEnd } = this.props;
            this.setState({
                dragOverNodeKey: '',
            });
            this.cleanDragState();

            if (onDragEnd) {
                onDragEnd({
                    event,
                    node: convertNodePropsToEventData(node.props),
                    dragNode: this.dragNode,
                });
            }

            this.dragNode = null;
        };

        this.onNodeDrop = (event, node, outsideTree = false) => {
            const { dragNodesKeys = [], dropPosition } = this.state;
            const { onDrop } = this.props;
            const { eventKey, pos } = node.props;

            this.setState({
                dragOverNodeKey: '',
            });
            this.cleanDragState();

            if (dragNodesKeys.indexOf(eventKey) !== -1) {
                console.warn(false, "Can not drop to dragNode(include it's children node)");
                return;
            }

            const posArr = posToArr(pos);

            const dropResult = {
                event,
                node: convertNodePropsToEventData(node.props),
                dragNode: this.dragNode ? convertNodePropsToEventData(this.dragNode.props) : null,
                dragNodesKeys: dragNodesKeys.slice(),
                dropPosition: dropPosition + Number(posArr[posArr.length - 1]),
                dropToGap: false,
            };

            if (dropPosition !== 0) {
                dropResult.dropToGap = true;
            }

            if (onDrop) {
                onDrop(dropResult);
            }

            if (this.$crossTreeDrag) {
                crossDraggedEvent.$data = node
                crossDraggedEvent.$srcEvent = event
                window.dispatchEvent(crossDraggedEvent)
                delete window.$$Node
                this.$crossTreeDrag = undefined
            }
            this.dragNode = null;
            window.removeEventListener('crossDragged', this.onCrossDragged)
        };
    }
}

export default OTree
