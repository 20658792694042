import React, { useState, useMemo, useEffect, useImperativeHandle } from 'react'
import { createEditor, Node, Editor, Transforms } from 'slate'
import { Slate, Editable, withReact, ReactEditor } from 'slate-react'
import { withHistory } from 'slate-history'

import { SELECTED_TEXT, PLACEHOLDER } from '../formula-editor/index'

const KEY_CODES = {
  ENTER: 13,
}

const serialize = nodes => {
  return nodes.map(n => Node.string(n)).join('\n')
}

const deserialize = htmlString => {
  return htmlString.split('\n')
    .map(line => {
      return {
        type: 'paragraph',
        children: [
          { text: line },
        ],
      }
    })
}

const PlainTextEditor = React.forwardRef(({
  onChange,
  html,
  enableEnter = true,
}, ref) => {
  const [value, setValue] = useState(initialValue)
  const editor = useMemo(() => withHistory(withReact(createEditor())), [])

  useImperativeHandle(ref, () => ({
    insertTextForFormulaEditor: (text) => {
      const selectedText = serialize(editor.getFragment())
      // wrapped around selected text
      text = text.replace(SELECTED_TEXT, `${selectedText || ''}`)
      const textArr = text.split(PLACEHOLDER);
      editor.insertText(textArr.join(''))
      ReactEditor.focus(editor)
      Transforms.move(editor, {
        distance: textArr.length === 2 ? textArr[1].length : 0,
        unit: 'character',
        reverse: true,
      })
    }
  }))

  const handleChange = (newVal) => {
    setValue(newVal)
    onChange(serialize(newVal))
  }

  const handleKeyDown = (event) => {
    const keyCode = event.keyCode
    switch(keyCode) {
      case KEY_CODES.ENTER:
        if (!enableEnter) {
          event.preventDefault()
          return false
        } else {
          return true
        }
      default:
        return true
    }
  }

  useEffect(() => {
    if (html && typeof html === 'string') {
      setValue(deserialize(html))
    }
  }, [html])

  return (
    <Slate editor={editor} value={value} onChange={value => handleChange(value)}>
      <Editable
        placeholder="Please enter your latex..."
        onKeyDown={event => handleKeyDown(event)}
      />
    </Slate>
  )
})

const initialValue = [
  {
    type: 'paragraph',
    children: [
      { text: 'Please enter your latex...' },
    ],
  },
]

export default PlainTextEditor
