import * as types from './types';
import { Subject } from 'rxjs';


const initialState = {
  windowMap: new Map()
}

export default function mutiContentWindow (state = initialState, action) {
  switch (action.type) {
    case types.ADD_MUTI_CONTENT_WINDOW: {
      const newMap = new Map(state.windowMap)
      if (newMap.get(action.payload)) {
        return console.warn(action.payload, 'The id is in the map.')
      }

      newMap.set(action.payload, createDataPipe())

      return {
        ...state,
        windowMap: newMap
      }
    }

    default: {
      return state
    }
  }
}

function createDataPipe() {
  return new Subject()
}
