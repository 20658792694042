import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as WindowManagementActions from '../../../stores/window-management/actions'

import Dialog from '../../shared/dialog/dialog'
import { Button, Form, Input, Radio } from 'antd';
import { getDialogTitle } from '../utils';
import { EyeOutlined } from '@ant-design/icons';

class NewViewDialog extends React.Component {
  constructor (props) {
    super(props)
    const {odmObject, newType} = props;
    if(odmObject) {
      this.intialValues = {code: this.props.code, description: this.props.description}
      switch(newType) {
        case "vf":
          this.dialogTitle = `New View Form [ID: ${odmObject.id}]`;
          this.isVF = true;
          break;
        case "d":
          this.dialogTitle = `New Dataset [ID: ${odmObject.id}]`;
          break;
        case "rd":
          this.dialogTitle = `New Root Dataset [ID: ${odmObject.id}]`;
          break;
        case "ra":
          this.dialogTitle = `New Root Attribute [ID: ${odmObject.id}]`;
          break;
        case "a":
          this.dialogTitle = `New Attribute [ID: ${odmObject.id}]`;
          break;
        case "rd2rd":
          this.dialogTitle = `New RD2RD Relation [ID: ${odmObject.id}]`;
          break;
        case "d2d":
          this.dialogTitle = `New D2D Relation [ID: ${odmObject.id}]`;
          break;
        case "rd2d":
          this.dialogTitle = `New RD2D Relation [ID: ${odmObject.id}]`;
          break;
      }
      const dialogTitle = getDialogTitle(
        <EyeOutlined />,
        this.dialogTitle
      )
      this.props.actions.setWindowTitle(this.props.windowId, dialogTitle);
    }
  }

  handleClose () {
    this.props.actions.removeWindow(this.props.windowId)
  }

  handleSubmit(value) {
    this.props.onSubmit(this.props.odmObject, value)
    this.props.actions.removeWindow(this.props.windowId)
  }

  handleMinimize () {
    this.props.actions.setWindowVisible(this.props.windowId, false)
  }

  render () {
    const {odmObject} = this.props;
    if (!odmObject) {
      return ''
    }
    const layout = {
      labelCol: { span: 10 },
      wrapperCol: { span: 24 },
    }

    return (
      <Dialog
        title={ this.props.title }
        visible={ this.props.visible }
        onClose={ this.handleClose.bind(this) }
        onOpen={ (this.props.onOpen && this.props.onOpen.bind(this))}
        onMinimize={ this.handleMinimize.bind(this) }
        windowId={this.props.windowId}
      >
        <Form
          {...layout}
          layout={'vertical'}
          name="newForm"
          initialValues={{code: '', description: ''}}
          onFinish={(value) => this.handleSubmit(value)}
          onFinishFailed={this.onFinishFailed}
        >
          <Form.Item
            label="Code"
            name="code"
            rules={[
              { required: true, message: 'Please input the code!' },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Description"
            name="description"
            rules={[{ required: true, message: 'Please input your description!' }]}
          >
            <Input />
          </Form.Item>
          { this.isVF &&
            <Form.Item
              label="Form Type"
              name="formType"
            >
              <Radio.Group value="INLINE">
                <Radio value="INLINE">
                  Inline Form
                </Radio>
                <Radio value="TAB">
                  Form With Tabs
                </Radio>
              </Radio.Group>
            </Form.Item>
          }

          <div className="btn-container">
            <Button type="default" htmlType="button" onClick={() => this.handleClose()}>
              Cancel
            </Button>
            <Button type="primary" htmlType="submit" className="ml-20">
              Save
            </Button>
          </div>
        </Form>
      </Dialog>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  actions: Object.assign(
    {},
    bindActionCreators(WindowManagementActions, dispatch)
  )
})

export default connect(
  null,
  mapDispatchToProps
)(NewViewDialog)
