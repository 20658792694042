import React from 'react';
import { cloneDeep } from 'lodash';
import styles from './col-design.module.less';
import {
  getCellColspan,
  getCellRowspan,
  getCellStyles, getColumnStyles,
  getRowStyles,
  getTableStyles,
} from '../../utils';

class ColDesignTable extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      sourceView: props.view,
      view: cloneDeep(props.view), // for edit
      editingColumnIndex: props.editingColumnIndex
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { view, editingColumnIndex } = nextProps
    const changes = { editingColumnIndex }
    if (view !== prevState.sourceView) {
      // formData changed
      changes.view = cloneDeep(view)
      changes.sourceView = view
    }

    return changes
  }

  render() {
    const tableView = (
      <table className={ styles.ViewFormTableView }
             style={ getTableStyles(this.state.view) }
      >
        <colgroup>
          {
            this.state.view.columns.map(col => (
              <col
                key={ col.id }
                style={ getColumnStyles(col) }
              />
            ))
          }
        </colgroup>
        <tbody>
        {
          this.state.view.rows.map((row, rowIndex) => (
            <tr
              key={ row.id }
              style={ getRowStyles(row) }
            >
              {
                row.cells.map((cell, cellIndex) => (
                  <td
                    key={ cell.id }
                    style={ getCellStyles(cell) }
                    colSpan={ getCellColspan(cell) }
                    rowSpan={ getCellRowspan(cell) }
                  >{ cell.content || `cell-${rowIndex}-${cellIndex}` }</td>
                ))
              }
            </tr>
          ))
        }
        </tbody>
      </table>
    )

    return tableView;
  }
}

export default ColDesignTable
