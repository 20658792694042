import React, { createRef } from 'react';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux';
import * as WindowManagementActions from '../../../stores/window-management/actions'
import ODM from 'odm'
import OE from 'oe'

import Dialog from '../../shared/dialog/dialog'
import { getViewTableByVFObj, ODMHelper, ViewForm } from 'orbit-components';

class UserFormDialog extends React.Component {
  constructor (props) {
    super(props)
    const library = props.libCode && ODM.getLibraryByCode(props.libCode)
    const formDataset = library && props.formCode && OE.getSubDWithCode(library, props.formCode)
    const rdIdAtt = formDataset && OE.getAttForD(formDataset, "INPUT_RD", undefined)
    const dIdAtt = formDataset && OE.getAttForD(formDataset, "INPUT_D", undefined)
    const initCodeAtt = formDataset && OE.getAttForD(formDataset, "INIT_CODE", undefined)
    const submitCodeAtt = formDataset && OE.getAttForD(formDataset, "SUBMIT_CODE", undefined)

    const rdIdAttValue = OE.getAttValue(rdIdAtt)
    const dIdAttValue = OE.getAttValue(dIdAtt)
    this.initSync = true
    this.initCode = OE.getAttValue(initCodeAtt) || ""
    this.submitCode = OE.getAttValue(submitCodeAtt) || ""

    this.dataset = dIdAttValue && ODM.getDNoLoad(dIdAttValue)
    this.rootDataset = (this.dataset && ODM.getRDNoLoad(this.dataset.rid)) || (rdIdAttValue && ODM.getRDNoLoad(rdIdAttValue))
    if (!this.dataset)
      this.dataset = this.rootDataset && OE.createDSByRD(this.rootDataset, this.rootDataset.cod, this.rootDataset.dsc, true)
    this.initParams = this.initCode && this.initFunction()
    this.formRef = createRef()
    this.state = {
      view: getViewTableByVFObj(this.dataset, 'DEFAULT')
    }
  }

  handleClose () {
    this.props.actions.removeWindow(this.props.windowId)
  }

  initFunction() {
    const jSCFunction = this.dataset && this.rootDataset && this.initCode && Function(
      `
        'use strict';

        return function(ojsODM, ojsOE, ojsRD, ojsD, ojsParams) {
           let ojsReturn;
           ${this.initCode}
           return ojsReturn;
        }
      `
    )();
    try {
       return this.initSync ? jSCFunction(ODM, OE, this.rootDataset, this.dataset, this.props.ojsParams) : new Promise( (resolve,reject) => {resolve(jSCFunction(ODM, OE, this.rootDataset, this.dataset, this.props.ojsParams))});
    } catch (error) {
        // todo: log error
    }

  }

  handleSubmit() {
    this.formRef.current?.submitChanges()
    const jSCFunction = this.dataset && this.rootDataset && this.submitCode && Function(
      `
        'use strict';

        return function(ojsODM, ojsOE, ojsRD, ojsD, ojsParams) {
           ${this.submitCode}
        }
      `
    )();
    try {
      jSCFunction && jSCFunction(ODM, OE, this.rootDataset, this.dataset, this.props.ojsParams)
    } catch (error) {
        // todo: log error
    }

    if (this.props.onSubmit) this.props.onSubmit()
    ODM.saveAllDataToBrowser()
    this.handleClose()
  }

  handleMinimize () {
    this.props.actions.setWindowVisible(this.props.windowId, false)
  }

  createBridge = () => {
    return {
      onSubmit: (changes) => {
        ODMHelper.vfChangesToOdm(changes, this.dataset)
      }
    }
  }

  render () {
    const {vFObject, vFCode, dataset, rootDataset} = this.props;

    return (
      <Dialog
        title={ this.props.title }
        windowId={this.props.windowId}
        visible={ this.props.visible }
        submitButton={ true }
        cancelButton={ true }
        submitText={'Confirm'}
        onClose={ this.handleClose.bind(this) }
        onCancel={ this.handleClose.bind(this) }
        onSubmit={ this.handleSubmit.bind(this) }
        onMinimize={ this.handleMinimize.bind(this) }
      >
        {/*{ this.dataset ? <ViewForm*/}
        {/*  vFObject={this.dataset}*/}
        {/*  vFCode={'DEFAULT'}*/}
        {/*  dataset={this.dataset}*/}
        {/*  rootDataset={this.rootDataset}*/}
        {/*  svgIcon={''}*/}
        {/*  editMode={true}*/}
        {/*  noTop={true}*/}
        {/*  enableClipboard={false}*/}
        {/*  displayObjectSize={false}*/}
        {/*  displayDataTypes={false}*/}
        {/*  onAfterSubmit={ () => { console.log('after submit') } }*/}
        {/*/> : "Dataset and Root-Dataset for Dialog Undefined" }*/}
        <ViewForm view={this.state.view}
                  formBridge={this.createBridge()}
                  isEdit={true}
                  noHeader={true}
                  notShowCellEdit={true}
                  ref={this.formRef}/>

      </Dialog>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  actions: Object.assign(
    {},
    bindActionCreators(WindowManagementActions, dispatch)
  )
})

export default connect(
  null,
  mapDispatchToProps
)(UserFormDialog)
