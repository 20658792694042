import React from 'react'
import styles from './input-color-picker.module.less'
import { SketchPicker } from 'react-color';
import {
  getEventComposedPath
} from '../../../utils/utils'
import Icon from "@ant-design/icons";

class Component extends React.Component {

  render() {
    return <SketchPicker />;
  }
}

class InputColorPicker extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      value: '',
      pickerOpened: false
    }

    this.handleDocClick = e => {
      const path = getEventComposedPath(e)
      if (path.indexOf(this.refs.container) < 0) {
        this.hidePicker()
      }
    }
  }

  componentDidMount () {
    this.setState({ value: this.props.value })
    document.body.addEventListener('click', this.handleDocClick)
  }

  componentWillUnmount () {
    document.body.removeEventListener('click', this.handleDocClick)
  }

  handleValueChange (value) {
    const {r, g, b, a} = value
    this.setState({ value: `rgba(${r},${g},${b},${a})` }, () => {
      this.props.handleChange(`rgba(${r},${g},${b},${a})`)
    })
  }

  showPicker () {
    this.setState({ pickerOpened: true })
  }

  hidePicker () {
    this.setState({ pickerOpened: false })
  }

  togglePicker () {
    if (this.state.pickerOpened) {
      this.hidePicker()
    } else {
      this.showPicker()
    }
  }

  clear () {
    this.handleValueChange('')
    this.hidePicker()
  }

  render () {
    return (
      <div className={ styles.ColorInput_container }
        ref="container"
      >
        <div className={ styles.ColorInput_input }>
          <div className={ styles.ColorInput_button }
            onClick={ this.togglePicker.bind(this) }
            onKeyDown={ this.togglePicker.bind(this) }
          >
            <div className={ `${styles.ColorInput_buttonInner} ${this.state.value === '' ? styles.ColorInput_buttonInnerEmpty : ''}` }
              style={{ backgroundColor: this.state.value === '' ? '' : this.state.value }}
            ></div>
          </div>
        </div>
        <div className={ `${styles.ColorInput_picker} ${this.state.pickerOpened ? styles.ColorInput_openedPicker : ''}` }>
          <SketchPicker
            color={ this.state.value }
            onChange={ payload => this.handleValueChange.bind(this)(payload.rgb) }
          />
        </div>
        <button
          className={ styles.ColorInput_clearInput }
          onClick={() => this.clear()}
        >
          <Icon type="close-circle" theme={ 'filled' } />
        </button>
      </div>
    )
  }
}

export default InputColorPicker
