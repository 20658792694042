import {
  REFRESH_LIBRARIES
} from '../constants/libraries-types'

const initialState = []

export default function libraries (state = initialState, action) {
  switch (action.type) {
    case REFRESH_LIBRARIES:
      return [
        ...action.libraries
      ]

    default:
      return state
  }
}
