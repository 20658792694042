import React from 'react';
import OE from 'oe'
import { Input, Table } from 'antd';
import { bindActionCreators } from 'redux';
import * as WindowManagementActions from '../../../stores/window-management/actions';
import { connect } from 'react-redux';
import styles from './odm-report-dialog.module.less'
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import ODM from 'odm'
import { resolveTableData, wrapperHighlighter } from './units';

class AntRelationTable extends React.Component {
  constructor(props) {
    super(props);

    // <thead><tr><th>Id</th><th>Type</th><th>Source Code</th><th>Target Code</th><th>Target Fetch</th>   <th>Act</th><th>Rev</th><th>Timestamp</th></tr></thead>
    this.columns = [
      {
        title: 'Id',
        render: (item) => <a
          onClick={ () => { this.handleOpenViewJsonDialog(item) } }
          onKeyDown={ () => { this.handleOpenViewJsonDialog(item) } }
        >{wrapperHighlighter(item.id.toString(), this.state.searchText)}</a>,
      },
      {
        title: 'Type',
        render: (d) => {
          return wrapperHighlighter(d.typ || '', this.state.searchText)
        }
      },
      {
        title: 'Source Code',
        render: (rel) => {
          let sObj;
          switch (rel.typ && rel.typ.toLowerCase()) {
            case 'rd2rd':
              sObj = ODM.getRDNoLoadNoExtd(rel.sid);
              break;
            case 'rd2d':
              sObj = ODM.getRDNoLoadNoExtd(rel.sid);
              break;
            case 'd2d':
              sObj = ODM.getDNoLoadNoExtd(rel.sid);
              break;
          }
          return (
            <a
              onClick={ () => { this.handleOpenDataset(sObj) } }
              onKeyDown={ () => { this.handleOpenDataset(sObj) } }
            >{wrapperHighlighter((sObj && sObj.cod) || '', this.state.searchText)}</a>
          )
        },
      },
      {
        title: 'Target Code',
        render: (rel) => {
          let tObj;
          switch (rel.typ && rel.typ.toLowerCase()) {
            case 'rd2rd':
              tObj = ODM.getRDNoLoadNoExtd(rel.tid);
              break;
            case 'rd2d':
              tObj = ODM.getDNoLoadNoExtd(rel.tid);
              break;
            case 'd2d':
              tObj = ODM.getDNoLoadNoExtd(rel.tid);
              break;
          }
          return (
            <a
              onClick={ () => { this.handleOpenDataset(tObj) } }
              onKeyDown={ () => { this.handleOpenDataset(tObj) } }
            >{wrapperHighlighter((tObj && tObj.cod) || '', this.state.searchText)}</a>
          )
        },
      },
      {
        title: 'Target Fetch',
        render: (ref) => ref.tft || '...',
      },
      {
        title: 'Act',
        dataIndex: 'act',
        render: (act) => {
          return act ? 'True' : 'False'
        },
      },
      {
        title: 'Rev',
        dataIndex: 'ver',
      },
      {
        title: 'Timestamp',
        dataIndex: 'ts',
      },
    ]
    this.data = []
    const rels = this.props.relations;
    this.data = resolveTableData(this.props.type.toLowerCase(), rels)

    this.state = {
      data: this.data,
      searchText: ''
    }

    this.onSearch$ = new Subject()
  }

  componentDidMount() {
    this.searchSub = this.onSearch$.pipe(
      debounceTime(300)
    ).subscribe((text) => {
      const showingData = this.data.filter(d => {
        let sObj,tObj
        switch (d.typ.toLowerCase()) {
          case 'rd2rd':
            sObj = ODM.getRDNoLoadNoExtd(d.sid)
            tObj = ODM.getRDNoLoadNoExtd(d.tid);
            break;
          case 'rd2d':
            sObj = ODM.getRDNoLoadNoExtd(d.sid)
            tObj = ODM.getDNoLoadNoExtd(d.tid);
            break;
          case 'd2d':
            sObj = ODM.getDNoLoadNoExtd(d.sid)
            tObj = ODM.getDNoLoadNoExtd(d.tid);
        }
        const dText = `${d.id}_${sObj ? sObj.cod:''}_${tObj? tObj.cod:''}`

        return dText.toLowerCase().includes(text.toLowerCase())
      })
      this.setState({
        data: showingData,
        searchText: text
      })
    })
  }

  componentWillUnmount() {
    if (this.searchSub) {
      this.searchSub.unsubscribe()
    }
  }

  handleOpenViewJsonDialog(viewObject) {
    this.props.actions.addWindow({
      type: 'ViewJson',
      props: {
        title: 'JSON Code',
        visible: true,
        viewObject: viewObject
      }
    });
  }

  handleOpenDataset(viewObject) {
    this.props.actions.addWindow({
      type: 'ViewForm',
      props: {
        title: 'ViewForm',
        visible: true,
        vFObject: viewObject
      }
    });
  }

  onSearch = (e) => {
    this.onSearch$.next(e.target.value)
  }

  render () {
    const title = (<span>{this.props.type} [Count: {this.data.length}]</span>)

    const search = (
      <div className={styles.searchContainer}>
        <Input placeholder="Search" onChange={this.onSearch} allowClear />
      </div>
    )

    const tableHeader = (
      <div className={styles.tableHeader}>
        {title}
        {search}
      </div>
    )

    return <Table
      className="report-table"
      columns={this.columns}
      dataSource={this.state.data}
      pagination={{position: ['bottomRight']}}
      bordered
      title={() => tableHeader}
    />
  }
}

const mapDispatchToProps = dispatch => ({
  actions: Object.assign(
    {},
    bindActionCreators(WindowManagementActions, dispatch)
  )
})

export default connect(
  null,
  mapDispatchToProps
)(AntRelationTable)
