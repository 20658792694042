import React from 'react'
import ABase from './a-base'
import styles from './editors.module.less'
import classNames from 'classnames'
import DropEditor from './drop-editor';
import { ReactComponent as AttributeIcon } from '../../../../assets/icons/attribute.svg';


class AttrEditor extends ABase {
  constructor(props) {
    super(props);
    this.state = {...this.state, a: null}
  }

  static getDerivedStateFromProps(props, state) {
    if (props.editMode !== state.editMode) {
      return {
        editMode: props.editMode
      };
    }

    return {}
  }

  doCheck(node) {
    const nodeData = node.dataRef
    if (nodeData.nodeType === 'a') {
      return true
    }

    return false
  }

  onDrop(e) {
    const { dragNode } = e
    const nodeData = dragNode.dataRef
    if (nodeData.nodeType === 'a') {
      // todo: Hi Rudy, this function return undefined. please check it.
      const a = this.odm.getAttNoLoad(nodeData.attType, nodeData.odmId)
      this.setState({a})
      this.onChange(a)
    }

  }

  onRemove() {
    this.setState({a: null})
    this.onChange(null)
  }

  /**
   *
   * @param data instance of attribute or null
   */
  onChange = (data) => {

  }

  renderAttr() {
    const a = this.state.a
    if (!a) {
      return ''
    }

    const iconElem = <AttributeIcon style={{verticalAlign: '-.125em', height:'1.1em', width:'1.1em', color: '#7f0000'}}/>;
    const dsc = this.oe.getDescription(a)

    return <div className={classNames(styles.editorContent)}>
      {iconElem}{dsc}[{a.cod}]
    </div>

  }


  renderEditor() {
    return <DropEditor
      onDrop={(e) => this.onDrop(e)}
      doCheck={(node) => this.doCheck(node)}
      onRemove={() => this.onRemove()}
      isEmpty={!this.state.a}
    >
      {this.renderAttr()}
    </DropEditor>
  }

  render() {

    return this.state.editMode ? this.renderEditor() : this.renderAttr()
  }
}

export default AttrEditor
