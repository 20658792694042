import React from 'react';
import { cloneDeep, startCase } from 'lodash';
import styles from './row-design.module.less';
import {
  formattedLabel,
  getInputComponentViaProperty,
  getOptionsByKey, groupedPropertiesAndFilter,
} from '../../utils';
import { getSupportedUnits } from '../utils';
import { ROW_STYLES_CAN_ADJUST_LIST } from '../../../../core/constant';

class RowDesign extends React.PureComponent {
  constructor (props) {
    super(props);

    this.state = {
      sourceView: props.view,
      view: cloneDeep(props.view),
      activeRowIndex: props.activeRowIndex,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { view, activeRowIndex, activeCellIndex } = nextProps
    const changes = { activeRowIndex, activeCellIndex }
    if (view !== prevState.sourceView) {
      // formData changed
      changes.view = cloneDeep(view)
      changes.sourceView = view
    }

    return changes
  }

  setRowProperties (key, value) {
    this.state.view.rows[this.state.activeRowIndex].styles[key] = value
    this.setState({
      view: { ...this.state.view }
    }, () => {
      this.props.handleStateChange({
        view: this.state.view,
        activeRowIndex: this.state.activeRowIndex,
      })
    })
  }

  render() {
    const activeRow = this.state.view.rows[this.state.activeRowIndex]
    console.log(activeRow)

    const items = groupedPropertiesAndFilter(ROW_STYLES_CAN_ADJUST_LIST).map(item => {
      const {category, keys} = item

      return (
        <div className={styles.formCategoryWrapper}
             key={category}
        >
          <div className={styles.formSubHeader}>{startCase(category)}</div>
          {
            keys.map(key => {
              const InputComponent = getInputComponentViaProperty(key)

              return (
                <div className={styles.formRow}
                     key={`${key}-${this.state.activeRowIndex}`}
                >
                  <label className={ styles.formLabel }>{formattedLabel(key)}</label>
                  <InputComponent
                    className={ styles.formControl }
                    value={activeRow?.styles[key] || ''}
                    handleChange={ payload => this.setRowProperties.bind(this)(key, payload) }
                    supportedUnits={ getSupportedUnits(key) }
                    options={ getOptionsByKey(key) }
                  />
                </div>
              )
            })
          }
        </div>
      )
    })

    return (
      <div className={styles.viewForm}>
        {activeRow && items}
      </div>
    )
  }
}

export default RowDesign;
