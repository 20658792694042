import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as WindowManagementActions from '../../../stores/window-management/actions'

import Dialog from '../../shared/dialog/dialog'
import { getDialogTitle, getViewFormTypeTitle } from '../utils';
import ViewForm from '../../shared/view-form/view-form';
import { Button } from 'antd';
import ODM from 'odm'
import OE from 'oe'
import { SwapOutlined } from '@ant-design/icons';

class RelationConfigDialog extends React.Component {
  constructor (props) {
    super(props)
    const {relObject} = props;
    this.relCfgD = ODM.getDNoLoadNoExtd(relObject.cfg)
    if (!this.relCfgD) {
      switch (relObject && relObject.rtp) {
        case "rdsub":
          const rdsubRD = ODM.getRDNoLoadByCode('SYS.REL.RDSUB')
          this.relCfgD = OE.createDSByRD(rdsubRD, '', '', true, {ds:[], d2ds:[]}, false)
          relObject.cfg = this.relCfgD.id
          ODM.updateRD2RDRel(relObject);
          this.isCreateNewCfgD = true
          this.isSaveRel = true
          break
        case "xnd":
          const xndRD = ODM.getRDNoLoadByCode('SYS.REL.XND')
          this.relCfgD = OE.createDSByRD(xndRD, '', '', true, {ds:[], d2ds:[]}, false)
          break
      }
    }

    let dialogTitle;
    dialogTitle = 'Configuration - ' + getViewFormTypeTitle(relObject);
    dialogTitle = getDialogTitle(
      <SwapOutlined />,
      dialogTitle
    )
    this.props.actions.setWindowTitle(this.props.windowId, dialogTitle);
  }

  handleClose () {
    this.props.actions.removeWindow(this.props.windowId)
  }

  handleSubmit(value) {

    this.props.actions.removeWindow(this.props.windowId)
  }

  handleMinimize () {
    this.props.actions.setWindowVisible(this.props.windowId, false)
  }

  saveRelation = () => {
    //const rootDataset = ODM. getRDNoLoadByCode('SYS.REL.XND')

    //const dataset = OE.createDSByRD (rootDataset, '', '', true, {ds:[], d2ds:[]}, false);
    //console.log(dataset)
  }

  render () {

    return (
      <Dialog
        title={ this.props.title }
        windowId={this.props.windowId}
        visible={ this.props.visible }
        onClose={ this.handleClose.bind(this) }
        noContentPadding={ true }
        onOpen={ (this.props.onOpen && this.props.onOpen.bind(this))}
        onMinimize={ this.handleMinimize.bind(this) }
      >
        <ViewForm vFObject={this.relCfgD} noSetting={true} editMode={true}></ViewForm>
        <div className="buttons-container mt-20 pl-20">
          <Button type="default" htmlType="button" onClick={this.handleClose.bind(this)}>Cancel</Button>
          <Button type="primary" htmlType="button" className="ml-10" onClick={this.saveRelation}>Submit</Button>
        </div>

      </Dialog>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  actions: Object.assign(
    {},
    bindActionCreators(WindowManagementActions, dispatch)
  )
})

export default connect(
  null,
  mapDispatchToProps
)(RelationConfigDialog)
