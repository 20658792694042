import React from 'react';
import { cloneDeep } from 'lodash';
import ViewFormPreview from '../../../shared/view-form/view-form-preview';

class TableDesignTable extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      sourceView: props.view,
      view: cloneDeep(props.view),
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { view, activeRowIndex, activeCellIndex } = nextProps
    const changes = { activeRowIndex, activeCellIndex }
    if (view !== prevState.sourceView) {
      // formData changed
      changes.view = cloneDeep(view)
      changes.sourceView = view
    }

    return changes
  }

  render() {

    return (
      <ViewFormPreview
        view={ this.state.view }
      />
    )
  }
}

export default TableDesignTable
