import React from 'react'
import styles from './select.module.less'
import { Select } from 'antd'
import classnames from 'classnames'
const { Option } = Select

class SelectCon extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      value: '',
      options: []
    }
  }

  handleValueChange (e) {
    const newVal = e === undefined ? '' : e
    this.setState({ value: newVal }, () => {
      this.props.handleChange(this.state.value)
    })
  }

  componentDidMount () {
    this.setState({
      value: this.props.value,
      options: this.props.options ? this.props.options : []
    })
  }

  render () {
    return (
      <Select
        className={ classnames(styles.Select, this.props.className) }
        dropdownStyle={{ zIndex: 1000000000 }}
        value={this.state.value}
        allowClear
        onChange={ e => this.handleValueChange.bind(this)(e) }
      >
        {
          this.state.options.map(option => (
            <Option
              key={option}
              text={option}
          >{ option }</Option>
          ))
        }
      </Select>
    )
  }
}

export default SelectCon
