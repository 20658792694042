import React from 'react'
import styles from './callback-content-dialog.module.less'
import OE from 'oe'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as WindowManagementActions from '../../../stores/window-management/actions'

import Dialog from '../../shared/dialog/dialog'

class CallbackContentDialog extends React.Component {

  contentDivId;

  constructor (props) {
    super(props);
    this.contentDivId = OE.getNextHTMLId();
  }

  handleClose () {
    this.props.actions.removeWindow(this.props.windowId);
  }

  handleMinimize () {
    this.props.actions.setWindowVisible(this.props.windowId, false);
  }

  handleSetTitle (newTitle) {
    this.props.actions.setWindowTitle(this.props.windowId, newTitle);
  }

  render () {
    let curOnOpen = () => {
        this.props.onOpenCallback && this.props.onOpenCallback(this.contentDivId, this.props.windowId);
    };

    let curDialog = (
      <Dialog
        title={ this.props.title }
        visible={ this.props.visible }
        onClose={ this.handleClose.bind(this) }
        onOpen={ curOnOpen.bind(this) }
        onMinimize={ this.handleMinimize.bind(this) }
        windowId={ this.props.windowId }
      >
        <div id={ this.contentDivId }></div>
      </Dialog>
    );

    return curDialog;
  }
}

const mapStateToProps = state => ({
});

const mapDispatchToProps = dispatch => ({
  actions: Object.assign(
    {},
    bindActionCreators(WindowManagementActions, dispatch)
  )
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CallbackContentDialog)
