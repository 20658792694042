import React, { useEffect } from 'react';
import ODM from 'odm'
import OE from 'oe'
import { useDispatch } from 'react-redux'
import { REFRESH_PROJECTS } from '../../stores/constants/projects-types';
import { REFRESH_ROOT_PROJECTS } from '../../stores/constants/root-projects-types';
import { REFRESH_ROOT_LIBRARIES } from '../../stores/constants/root-libraries-types';
import * as types from '../../stores/constants/libraries-types';
import { ADD_WINDOW } from '../../stores/window-management/types';
import SlateEditor from '../../components/shared/slate-editor/slate-editor';
import Editor from '@monaco-editor/react';
import { callJSC } from '../../core/utils';
import FormulaEditor from '../../libs/formula-editor/index'

document.fonts.ready.then(function() {
  // 字体加载完成后的逻辑
});

window.addEventListener('unhandledrejection', e => {
  throw e.reason
})

window.addEventListener('error', args => {
  console.log(args)
}, true)

function loadALLDataFromFile (e, dispatch) {
  ODM.loadDataFromFile(e);
  // get list for leftPane, then dispatch to store
  ODM.isOffline = true;
  setInterval(() => {
    const curRootProjectNumIds = ODM.getRootProjectList();
    const curProjectNumIds = ODM.getProjectList();
    const curRootLibraryNumIds = ODM.getRootLibraryList();
    const curLibraryNumIds = ODM.getLibraryList();

    const curProjects = curProjectNumIds.map(numId => {
      return ODM.getDNoLoadNoExtd(numId);
    });
    dispatch({type: REFRESH_PROJECTS, projects: curProjects})

    const curRootProjects = curRootProjectNumIds.map(numId => {
      return ODM.getRDNoLoadNoExtd(numId);
    });
    dispatch({type: REFRESH_ROOT_PROJECTS, rootProjects: curRootProjects})

    const curRootLibraries = curRootLibraryNumIds.map(numId => {
      return (ODM.getRDNoLoadNoExtd(numId) || {id: numId});
    });
    dispatch({ type: REFRESH_ROOT_LIBRARIES, rootLibraries: curRootLibraries })

    const curLibraries = curLibraryNumIds.map(numId => {
      return (ODM.getDNoLoadNoExtd(numId) || {id: numId});
    });
    dispatch({ type: types.REFRESH_LIBRARIES, libraries: curLibraries })
  }, 3000);
}

const openObjectTree = (oEObject, dispatch) => {
  let contentDivId = OE.getNextHTMLId();
  dispatch({
    type: ADD_WINDOW,
    window: {
      type: 'ObjectBrowser',
      props: {
        title: 'Object Browser - ' + OE.getDescription(oEObject) + ' ['+oEObject.cod+']',
        visible: true,
        contentId: contentDivId,
        odmObject: oEObject,
        onOpen: function() {
          if(oEObject && oEObject.typ && oEObject.typ.toLowerCase() === "rd") {
            OE.openRDBrowser1(contentDivId, oEObject.id);
          } else if(oEObject && oEObject.typ && oEObject.typ.toLowerCase() === "d") {
            OE.openDBrowser1(contentDivId, oEObject.id);
          }
        }
      }
    }
  })
};

const testLoadVersionThread = () => {
  ODM.loadRDThreadsFromServer([16961],5000, undefined, undefined);
};

const openViewForm = (dispatch) => {
  let contentDivId = OE.getNextHTMLId();
  let vfObject = ODM.getRD2RDRelNoLoadNoExtd(9815)
  // let vfObject = ODM.getRDNoLoadNoExtd(31811);
  // let vfObject = ODM.getRDNoLoadNoExtd(16961);
  // let vfObject = ODM.getDNoLoadNoExtd(35924);
  // let vfObject = ODM.getD2DRelNoLoad(27391)

  dispatch({
    type: ADD_WINDOW,
    window: {
      type: 'ViewForm',
      props: {
        title: 'ViewForm',
        visible: true,
        vFObject: vfObject,
        contentId: contentDivId,
        onOpen: function() {
        }
      }
    }
  });
};

const openFlowSheet = (dispatch, id = -1) => {
  let dataset = ODM.getDNoLoadNoExtd(id);

  dispatch({
    type: ADD_WINDOW,
    window: {
      type: 'NewFlowSheetDialog',
      props: {
        title: 'FlowSheet',
        visible: true,
        dataset
      }
    }
  })
}

const openAttribute = (dispatch) => {
  const odmId = 12
  const raObject = ODM.getRAttNoLoadNoExtd(odmId);
  dispatch({
    type: ADD_WINDOW,
    window: {
      type: 'SetIconDialog',
      props: {
        title: 'ViewForm',
        visible: true,
        raObject: raObject,
        odmId: odmId,
        action: 'new'
      }
    }
  })
}

export default function Settings({actions}) {
  const dispatch = useDispatch();

  const getDataFromServer = () => {
    fetch('/api/').then(res => {
      if (res.status !== 200) {
        //throw new Error('Server error')
        return undefined;
      }
      const reader = res.body.getReader()
      return new ReadableStream({
        start(controller) {
          return pump();
          function pump() {
            return reader.read().then(({ done, value }) => {
              // When no more data needs to be consumed, close the stream
              if (done) {
                controller.close();
                return;
              }
              // Enqueue the next data chunk into our target stream
              controller.enqueue(value);
              return pump();
            });
          }
        }
      })
    })
      .then(stream => new Response(stream))
      .then(response => response.blob())
      .then(blob => new File([blob], 'test.json', {type: 'application/json', lastModified: new Date()}))
      .then(file => {
        if (file) {
          const e = {
            target: {
              files: [file]
            }
          }
          console.log(file)
          loadALLDataFromFile(e, dispatch)
        }
      }).catch((err) => {
        console.log(err)
      })
  }

  useEffect(() => {
    const data = localStorage.getItem('ODM_DATA')
    if (data) {
      ODM.retrieveAllDataFromBrowser(JSON.parse(data))
    } else {
      getDataFromServer()
    }
  }, [])


  return (
    <div className="setting-container">
      <p>
        <a id="load_all_data" className="oe-mui-link"
           onClick={ () => { ODM.retrieveAllDataFromServer() } }
           onKeyDown={ () => { ODM.retrieveAllDataFromServer() } }
        >Retrieve all data from Database</a>
      </p>
      <p>
        Load all data from file:
        <input type="file" onChange={ (e) => loadALLDataFromFile(e.nativeEvent, dispatch) } />
      </p>
      <p>
        <button onClick={e => openObjectTree(ODM.getRDNoLoadNoExtd(12) ,dispatch)}>
          Open System RD Browser
        </button>
      </p>
      <p>
        <button onClick={e => openViewForm(dispatch)}>
          Open View Form
        </button>
      </p>
      <p>
        <button onClick={e => openFlowSheet(dispatch)}>
          Open Flow Sheet
        </button>
      </p>
      <p>
        <button onClick={e => openFlowSheet(dispatch, 40311)}>
          Open New Flow Sheet
        </button>
      </p>
      <p>
        <button onClick={e => openFlowSheet(dispatch, 40605)}>
          Open Test Flow Sheet
        </button>
      </p>
      <p>
        <button onClick={e => testLoadVersionThread(dispatch)}>
          Load Version Thread Test
        </button>
      </p>
      <div style={{padding: '10px 20px'}}>
        {/*<SlateEditor />*/}
        {/*<FormulaEditor variableDatasets={[1,2,3]}/>*/}
      </div>
      <p>
        <div id="test_obj_tree" />
      </p>
    </div>
  )
}

