import React from 'react'
import styles from './attribute.module.less'
import OE from 'oe'
import ODM from 'odm'
import SelectCon from '../../../common/select/select';
import { ROOT_ATTRIBUTE_TYPES } from '../../../../core/constant';
import { Button, Input, Radio, Select } from 'antd';
import classNames from 'classnames';
import { ReactComponent as VersionIcon } from '../../../../assets/icons/version.svg'


class AttributeConfig extends React.Component {
  constructor(props) {
    super(props);
    // only for top row, I don't known it's right or not?
    this.raObject = props.raObject
    let formVal = {
      dtp: '',
      loc: false,
      cod: '',
      dsc: '',
    }
    if (!props.isCreate) {
      const { dtp, loc, cod, dsc, ddim} = props.raObject
      const dscText = OE.getDescription(props.raObject)
      formVal = {
        dtp,
        loc,
        cod,
        dsc: dscText,
      }
      if (ddim || ddim === 0) {
        const attDim = ODM.getDimensionById(ddim);
        formVal.dimension = attDim.cod
      }
    }

    this.state = {
      formVal
    }

  }

  handleFormValueChange(key, value) {
    const formVal = this.state.formVal
    formVal[key] = value
    console.log(key, value)
    console.log(formVal)
    this.setState({
      formVal: {...formVal}
    })
  }

  handleCancel() {
    this.props.onCancel()
  }

  handleSubmit() {
    const res = this.state.formVal
    const code = res.cod
    res.cod = this.props.prefixCode ? `${this.props.prefixCode}.${code}` : code

    this.props.onSubmit(res)

    return false
  }


  renderTopActions() {
    if (this.props.isCreate) {
      return ''
    }

    return (
      <div className={styles.version}>
        <VersionIcon></VersionIcon>
        <div>{this.raObject.ver}</div>
      </div>
    );
  }

  renderTopRow() {
    const topRow = (
      <div className={styles.TopRow} >
          <span >
            &nbsp;{OE.getDescription(this.raObject)}&nbsp;[{this.raObject.cod} - {this.raObject.id}]
          </span>
        {this.renderTopActions()}
      </div>);

    return topRow
  }

  renderDimensionView() {
    // todo: if is configure mode, we should init the val in `formVal`
    // I just use `dimension` as the attribute of formVal
    return (
      <div className="form-item">
        <div className="form-item-label w-100">Dimension:</div>
        <div className="form-item-control">
          <Select
            placeholder="Select a dimension"
            style={{width: '200px'}}
            value={this.state.formVal.dimension}
            onChange={val => this.handleFormValueChange('dimension', val)}
          >{ODM.getDimensionCodeAndDescriptionPairs().map((entry, index) =>
            <Select.Option value={entry.code} key={index}>{entry.dsc || entry.code}</Select.Option>
          )}
          </Select>
        </div>
      </div>
    )
  }


  renderForm() {
    const formVal = this.state.formVal
    return (
      <form className="horizontal-form" onSubmit={this.handleSubmit.bind(this)}>
        <div className="form-item">
          <div className="form-item-label w-100">Type:</div>
          <div className="form-item-control">
            <SelectCon value={formVal.dtp}
                       className={styles.inputWidth}
                       options={ROOT_ATTRIBUTE_TYPES}
                       handleChange={e => this.handleFormValueChange('dtp', e)}></SelectCon>
          </div>
        </div>
        {formVal.dtp === 'DIMVAR' ? this.renderDimensionView() : ''}
        <div className="form-item">
          <div className="form-item-label w-100">Local:</div>
          <div className="form-item-control">
            <Radio.Group onChange={e => this.handleFormValueChange('loc', e.target.value)} value={formVal.loc}>
              <Radio value={true}>True</Radio>
              <Radio value={false}>False</Radio>
            </Radio.Group>
          </div>
        </div>
        <div className="form-item">
          <div className="form-item-label w-100">Description:</div>
          <div className="form-item-control">
            <Input value={formVal.dsc}
                   className={styles.inputWidth}
                   onChange={(e) => this.handleFormValueChange('dsc', e.target.value)}></Input>
          </div>
        </div>
        <div className="form-item">
          <div className="form-item-label w-100">Code:</div>
          <div className="form-item-control">
            <Input value={formVal.cod}
                   addonBefore={this.props.prefixCode ? `${this.props.prefixCode}.` : ''}
                   className={styles.inputWidth}
                   onChange={(e) => this.handleFormValueChange('cod', e.target.value)}></Input>
          </div>
        </div>
        <div className="buttons-container"
             className={classNames({
               'buttons-container': true,
               [styles.buttonsContainer]: true
             })}
        >
          <Button type="default" onClick={ this.handleCancel.bind(this) } htmlType="button">Cancel</Button>
          <Button type="primary" htmlType="submit" className="ml-10">Submit</Button>
        </div>
      </form>
    )
  }

  render() {
    return (
      <React.Fragment>
        {this.raObject && this.renderTopRow()}
        {this.renderForm()}
      </React.Fragment>
    )
  }
}

export default AttributeConfig
