import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux';
import * as WindowManagementActions from '../../../stores/window-management/actions'

import Dialog from '../../shared/dialog/dialog'

class DeleteDialog extends React.Component {
  constructor (props) {
    super(props)
  }

  handleClose () {
    this.props.actions.removeWindow(this.props.windowId)
  }

  handleSubmit() {
    this.props.onSubmit(this.props.vFObject)
    this.handleClose()
  }

  handleMinimize () {
    this.props.actions.setWindowVisible(this.props.windowId, false)
  }

  render () {
    const content = this.props.content

    return (
      <Dialog
        title={ this.props.title }
        windowId={this.props.windowId}
        visible={ this.props.visible }
        submitButton={ true }
        cancelButton={ true }
        submitText={'Confirm'}
        onClose={ this.handleClose.bind(this) }
        hasBazel={ true }
        onCancel={ this.handleClose.bind(this) }
        onSubmit={ this.handleSubmit.bind(this) }
        onMinimize={ this.handleMinimize.bind(this) }
      >
        { content }
      </Dialog>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  actions: Object.assign(
    {},
    bindActionCreators(WindowManagementActions, dispatch)
  )
})

export default connect(
  null,
  mapDispatchToProps
)(DeleteDialog)
