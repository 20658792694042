import React from 'react'
import styles from './view-json-dialog.module.less'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as WindowManagementActions from '../../../stores/window-management/actions'
import ODM from 'odm'
import OE from 'oe'
import ReactJson from 'react-json-view'

import Dialog from '../../shared/dialog/dialog'
import { getDialogTitle } from '../utils';
import { CodeOutlined } from '@ant-design/icons';

class ViewJsonDialog extends React.Component {
  constructor (props) {
    super(props)
    const viewObject = props.viewObject
    let dialogTitle
    switch(viewObject.typ.toLowerCase()) {
      case "d":
        dialogTitle = "JSON Code - Dataset [ID: "+viewObject.id+"]";
        break;
      case "rd":
        dialogTitle = "JSON Code - Root Dataset [ID: "+viewObject.id+"]";
        break;
      case "ra":
        dialogTitle = "JSON Code - Root Attribute [ID: "+viewObject.id+"]";
        break;
      case "a":
        dialogTitle = "JSON Code - Attribute [ID: "+viewObject.id+"]";
        break;
      case "rd2rd":
        dialogTitle = "JSON Code - RD2RD Relation [ID: "+viewObject.id+"]";
        break;
      case "d2d":
        dialogTitle = "JSON Code - D2D Relation [ID: "+viewObject.id+"]";
        break;
      case "rd2d":
        dialogTitle = "JSON Code - RD2D Relation [ID: "+viewObject.id+"]";
        break;
    }

    dialogTitle = getDialogTitle(
      <CodeOutlined />,
      `Design ${dialogTitle}`
    )
    this.props.actions.setWindowTitle(this.props.windowId, dialogTitle);
  }

  handleClose () {
    this.props.actions.removeWindow(this.props.windowId)
  }

  handleMinimize () {
    this.props.actions.setWindowVisible(this.props.windowId, false)
  }

  onRefresh() {
    this.setState({
      refresh: true
    })
  }

  render () {
    const viewObject = (this.state && this.state.refresh) ?  ODM.getCurrentODMObject(this.props.viewObject) : this.props.viewObject;
    let curJsonCode;
    if(viewObject) {


      curJsonCode = OE.jsonFormater.prettyPrint(viewObject);
    }

    return (
      <Dialog
        title={ this.props.title }
        visible={ this.props.visible }
        onClose={ this.handleClose.bind(this) }
        onOpen={ (this.props.onOpen && this.props.onOpen.bind(this))}
        onMinimize={ this.handleMinimize.bind(this) }
        windowId={this.props.windowId}
      >
        <ReactJson
          src={viewObject}
          displayObjectSize={false}
          enableClipboard={false}
          displayDataTypes={false}
        />
        <div className={styles.refresh} title="Refresh"
             onKeyDown={() => this.onRefresh()}
             onClick={() => this.onRefresh()}><i className="iconfont icon-refresh"></i></div>

      </Dialog>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  actions: Object.assign(
    {},
    bindActionCreators(WindowManagementActions, dispatch)
  )
})

export default connect(
  null,
  mapDispatchToProps
)(ViewJsonDialog)
