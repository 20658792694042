export const ROOT_ATTRIBUTE_TYPES = [
  'TEXT', // Text
  'FLOAT', // Real number
  'INTEGER', // Integer
  'LONG', // Long
  'BOOLEAN', // Boolean
  'DIMVAR', // Dimensional variable, allowing users to switch between different units (such as for length, mass, …).  Wei developed a react element to specify the width/height of html-elements that I would like to use for this purpose.
  'DATASET', // Holds a dataset Id
  'ROOTDATASET', // Holds a root-dataset Id
  'ATTRIBUTE', // Holds an attribute Id
  'ROOTATTRIBUTE', // Holds a root-attribute Id
  'FILE', // File (i.e. BLOB)
  'TIMESTAMP', // Timestamp
  'TEXT_AR', // Text array
  'FLOAT_AR', // Array of real numbers
  'INTEGER_AR', // Array of integers
  'LONG_AR', // Array of long-type data
  'BOOLEAN_AR', // Array of booleans
  'DIMVAR_AR', // Array of dimensional variables
  'DATASET_AR', // Array of dataset ids
  'ROOTDATASET_AR', // Array of root-dataset ids
  'ATTRIBUTE_AR', // Array of attribute ids
  'ROOTATTRIBUTE_AR', // Array of root-attribute ids
  'FILE_AR', // Array of files (i.e. BLOBs)
  'TIMESTAMP_AR', // Array of timestamps
  'SPREADSHEET',
]

export const TABLE_STYLES_CAN_ADJUST_LIST = [
  'width',
  'height',
  'backgroundColor',
  'color',
  'fontSize',
  'fontFamily',
  'fontWeight',
  'textDecoration',
  'borderColor',
  'borderWidth',
  'borderStyle'
]

export const COLUMN_STYLES_CAN_ADJUST_LIST = [
  'width',
  'backgroundColor',
  'borderColor',
  'borderWidth',
  'borderStyle'
]

export const DEFAULT_COLUMN_WIDTH = 100

export const ROW_STYLES_CAN_ADJUST_LIST = [
  'height',
  'borderTopColor',
  'borderTopWidth',
  'borderTopStyle',
  'borderRightColor',
  'borderRightWidth',
  'borderRightStyle',
  'borderBottomColor',
  'borderBottomWidth',
  'borderBottomStyle',
  'borderLeftColor',
  'borderLeftWidth',
  'borderLeftStyle',
  'backgroundColor',
  'color',
  'fontSize',
  'fontFamily',
  'fontWeight',
  'textDecoration'
]

export const CELL_STYLES_CAN_ADJUST_LIST = [
  'borderTopColor',
  'borderTopWidth',
  'borderTopStyle',
  'borderRightColor',
  'borderRightWidth',
  'borderRightStyle',
  'borderBottomColor',
  'borderBottomWidth',
  'borderBottomStyle',
  'borderLeftColor',
  'borderLeftWidth',
  'borderLeftStyle',
  'paddingTop',
  'paddingBottom',
  'paddingLeft',
  'paddingRight',
  'backgroundColor',
  'color',
  'fontSize',
  'fontFamily',
  'fontWeight',
  'textDecoration',
  'textAlign'
]

export const CELL_ATTRIBUTES_CAN_ADJUST_LIST = [
  'rowspan',
  'colspan',
  'autoColspan' // for showing auto clac colspan value
]

export const CELL_DATASET_CODES = [
  { rADTP: 'TEXT', cfgDRDCode: ['SYS.ATT.H5.INP.TXT', 'SYS.ATT.H5.SEL', 'SYS.ATT.H5.RADIV', 'SYS.ATT.H5.CBDIV', 'SYS.ATT.ACE', 'SYS.ATT.SVGCODE', 'SYS.ATT.SLATE', 'SYS.ATT']}, // SYS.ATT is currently for rich-text.  Needs to be migrated to a new specific root attribute
  { rADTP: 'FLOAT', cfgDRDCode: ['SYS.ATT.H5.INP.TXT', 'SYS.ATT.H5.SEL', 'SYS.ATT.H5.RADIV', 'SYS.ATT.H5.CBDIV']},
  { rADTP: 'INTEGER', cfgDRDCode: ['SYS.ATT.H5.INP.TXT', 'SYS.ATT.H5.SEL', 'SYS.ATT.H5.RADIV', 'SYS.ATT.H5.CBDIV']},
  { rADTP: 'LONG', cfgDRDCode: ['SYS.ATT.H5.INP.TXT', 'SYS.ATT.H5.SEL', 'SYS.ATT.H5.RADIV', 'SYS.ATT.H5.CBDIV']},
  { rADTP: 'BOOLEAN', cfgDRDCode: ['SYS.ATT.H5.SEL', 'SYS.ATT.H5.RADIV', 'SYS.ATT.H5.CBDIV']},
  { rADTP: 'DIMVAR', cfgDRDCode: ['SYS.ATT.OE.DIMVAR']},
  { rADTP: 'FILE', cfgDRDCode: ['SYS.ATT.H5.INP.FLE', 'SYS.ATT', 'SYS.ATT.FILEDND']},
  { rADTP: 'ROOTDATASET', cfgDRDCode: ['SYS.AECFG.ODT.RD']},
  { rADTP: 'DATASET', cfgDRDCode: ['SYS.AECFG.ODT.D']},
  { rADTP: 'ROOTATTRIBUTE', cfgDRDCode: ['SYS.AECFG.ODT.RA']},
  { rADTP: 'ATTRIBUTE', cfgDRDCode: ['SYS.AECFG.ODT.A']},
  { rADTP: 'SPREADSHEET', cfgDRDCode: ['SYS.AECFG.SPDSHT']},
]

