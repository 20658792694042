import React from 'react'
import { Button, Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';

class AAntdUpload extends React.Component {
  constructor(props) {
    super(props);
  }

  onChange = (event) => {

  }

  render() {
    return (
      <Upload name={'file'} customRequest={this.onChange}>
        <Button icon={<UploadOutlined />}>Click to Upload</Button>
      </Upload>
    )
  }
}

export default AAntdUpload
