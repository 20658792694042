import { genHash } from '../../window-management/utils';
import {
  CELL_ATTRIBUTES_CAN_ADJUST_LIST,
  CELL_STYLES_CAN_ADJUST_LIST, COLUMN_STYLES_CAN_ADJUST_LIST, ROW_STYLES_CAN_ADJUST_LIST,
  TABLE_STYLES_CAN_ADJUST_LIST,
} from '../../../core/constant';
import { getItems } from './view-form';
import OE from 'oe'
import ODM from 'odm'
import { isNumber } from 'lodash'

export function guiDataToViewForm(guiData, index, cellCfg) {
  const { cols, table, cells, rows } = guiData.data.layout
  const activeCols = cols[index]
  const activeTable = table[index]
  const activeCells = cells[index]
  const activeRows = rows[index]

  if (!activeCells ||  activeCells.length === 0) {
    return getItems(4, 6)
  }
  let colCount = 1;
  let lastCellSpans
  ({colCount, lastCellSpans} = OE.colCountAndLastCellSpanForLayout(guiData.data.layout, index))

  const { raid, gcod, cfgdid, rd2rdrelid} = guiData.data
  const activeRaid = raid[index]
  const activeGcod = gcod[index]
  const activeCfgdid = cfgdid[index]
  const activeRd2rdrelid = rd2rdrelid[index]

  let returnValue = {
    id: `table-${genHash()}`,
    properties: TABLE_STYLES_CAN_ADJUST_LIST
      .reduce((obj, key) => {
        obj[key] = activeTable[key] || ''
        return obj
      }, {}),
    content: activeRaid.map((val1, k1) => ({
      id: `row-${genHash()}`,
      content: activeRaid[k1].map((val, k) => ({
        id: `cell-${k1}-${genHash()}`,
        content: `cell ${k1}-${k}`,
        cellCfg: {
          gcod: activeGcod[k1][k],
          raid: activeRaid[k1][k],
          cfgdid: activeCfgdid[k1][k],
          rd2rdrelid: activeRd2rdrelid[k1][k],
          ...cellCfg
        },
        properties: CELL_STYLES_CAN_ADJUST_LIST
          .concat(CELL_ATTRIBUTES_CAN_ADJUST_LIST)
          .reduce((obj, key) => {
            obj[key] = ''
            return obj
          }, {})
      })),
      properties: ROW_STYLES_CAN_ADJUST_LIST.reduce((obj, key) => {
        obj[key] = ''
        return obj
      }, {})
    })),
    columns: Array.from({ length: colCount }, (v, k) => k).map(k => ({
      id: `column-${genHash()}`,
      content: '',
      properties: COLUMN_STYLES_CAN_ADJUST_LIST.reduce((obj, key) => {
        obj[key] = ''
        return obj
      }, {})
    }))
  }

  if(activeCols && activeCols.length) {
    returnValue.properties = {...returnValue.properties, ...activeTable};
    activeCols.map((val, k) => {
      returnValue.columns[k].properties = {...returnValue.columns[k].properties, ...val};
    });

  }

  if (activeCells && activeCells.length) {
    activeCells.map((row, rIndex) => {
      returnValue.content[rIndex].properties = {...returnValue.content[rIndex].properties, ...activeRows[rIndex]};
      row.map((val1, colIndex) => {
        returnValue.content[rIndex].content[colIndex].properties = {...returnValue.content[rIndex].content[colIndex].properties, ...val1}
      })

      if (row.length) {
        returnValue.content[rIndex].content[row.length-1].properties.autoColspan = lastCellSpans[rIndex].toString();
      }
    });
  }

  return returnValue;
}

export function viewFormsDataToGuiData(newVal, guiData) {
  const newGuiData = {...guiData.data}
  const viewFormArray = Array.isArray(newVal) ? newVal : [{cod: "___", dsc: {}, formData: newVal}] // convert to same format regardless of whether it is a tab or inline type form
  newGuiData.codes = viewFormArray.map(item => item.cod)
  newGuiData.descs = viewFormArray.map(item => item.dsc)
  newGuiData.layout = {table:[], cols: [], rows: [], cells: []}
  newGuiData.gcod = []
  newGuiData.cfgdid = []
  newGuiData.rd2rdrelid = []
  newGuiData.raid = []
  let tabCount = 0
  viewFormArray.forEach((tab) => {
    const formData = tab.formData

    newGuiData.gcod.push([])
    newGuiData.cfgdid.push([])
    newGuiData.rd2rdrelid.push([])
    newGuiData.raid.push([])

    newGuiData.layout.table.push({})
    newGuiData.layout.cols.push([])
    newGuiData.layout.rows.push([])
    newGuiData.layout.cells.push([])
    Object.keys(formData.properties).forEach((propName) => {
      if(formData.properties[propName]) {
        newGuiData.layout.table[tabCount][propName] = formData.properties[propName]
      }
    })
    let rowCount = 0
    formData.content.forEach((rowData) => {
      newGuiData.gcod[tabCount].push([])
      newGuiData.cfgdid[tabCount].push([])
      newGuiData.rd2rdrelid[tabCount].push([])
      newGuiData.raid[tabCount].push([])
      newGuiData.layout.rows[tabCount].push({})
      Object.keys(rowData.properties).forEach((propName) => {
        if(rowData.properties[propName]) {
          newGuiData.layout.rows[tabCount][rowCount][propName] = rowData.properties[propName]
        }
      })
      newGuiData.layout.cells[tabCount].push([])
      let cellCount = 0
      rowData.content.forEach((cellData) => {
        if (cellData.cellCfg) {
          newGuiData.gcod[tabCount][rowCount].push(cellData.cellCfg.hasOwnProperty('gcod') ? cellData.cellCfg.gcod : null);
          newGuiData.cfgdid[tabCount][rowCount].push(cellData.cellCfg.hasOwnProperty('cfgdid') ? cellData.cellCfg.cfgdid : null);
          newGuiData.rd2rdrelid[tabCount][rowCount].push(cellData.cellCfg.hasOwnProperty('rd2rdrelid') ? cellData.cellCfg.rd2rdrelid : null);
          newGuiData.raid[tabCount][rowCount].push(cellData.cellCfg.hasOwnProperty('raid') ? cellData.cellCfg.raid : null);
        } else {
          newGuiData.gcod[tabCount][rowCount].push(null);
          newGuiData.cfgdid[tabCount][rowCount].push(null);
          newGuiData.rd2rdrelid[tabCount][rowCount].push(null);
          newGuiData.raid[tabCount][rowCount].push(null);
        }
        newGuiData.layout.cells[tabCount][rowCount].push({})
        // we can't save the calculated value, the autoColspan is high priority.
        // In the design process we will automatically calculate the colspan attribute of the last cell.
        // If cell A is the last cell in a row, when insert a cell to right of current cell. the cell A is not the last on now.
        // so If there is a autoColspan attribute, the autoColspan will never be recalculated.
        delete cellData.properties.autoColspan
        Object.keys(cellData.properties).forEach((propName) => {
          if(cellData.properties[propName]) {
            if (propName.toLowerCase() !== 'colspan' || cellCount < (rowData.content.length-1) || (!isNaN(cellData.properties[propName]) && Number(cellData.properties[propName]) > 1)) {
              newGuiData.layout.cells[tabCount][rowCount][cellCount][propName] = cellData.properties[propName]
            }
          }
        })
        cellCount++
      })
      rowCount++
    })
    let colCount = 0
    formData.columns.forEach((colData) => {
      newGuiData.layout.cols[tabCount].push({})
      Object.keys(colData.properties).forEach((propName) => {
        if(colData.properties[propName]) {
          newGuiData.layout.cols[tabCount][colCount][propName] = colData.properties[propName]
        }
      })
      colCount++
    })
    tabCount++
  })

  return newGuiData
}


export function getDatasetRDIcon(d) {
  let dataset = d
  if (isNumber(d)) {
    dataset = ODM.getDNoLoad(d)
  }
  const rd = dataset && ODM.getRDNoLoad(dataset.rid)

  return rd && OE.getSVGIconCodeForRD(rd)
}

export function getD2DRDIcon(d2d) {
  let obj = d2d
  if (isNumber(d2d)) {
    obj = ODM.getD2DRelNoLoad(d2d)
  }

  return obj && getDatasetRDIcon(obj.tid)
}
