import React from 'react'
import styles from './object-browser-dialog.module.less'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as WindowManagementActions from '../../../stores/window-management/actions'

import Dialog from '../../shared/dialog/dialog'
import ODataTree from '../../shared/odata-tree/odata-tree'
import * as OdataTreeActions from '../../../stores/odata-tree/odata-tree-actions';
import { getDialogTitle } from '../utils';
import { GlobalOutlined } from '@ant-design/icons';
import OE from 'oe'

class ObjectBrowserDialog extends React.PureComponent {
  constructor (props) {
    super(props);
    this.state = {
      treeVersion: props.odataTree.treeVersion,
      odmObject: props.odmObject
    }
    this.title = getDialogTitle(
      <GlobalOutlined />,
      `Object Browser - ${OE.getDescription(this.props.odmObject)} [${this.props.odmObject.cod}]`
    )
  }

  componentDidMount () {
    this.props.actions.setWindowTitle(this.props.windowId, this.title);
  }

  handleClose () {
    this.props.actions.removeWindow(this.props.windowId);
  }

  handleMinimize () {
    this.props.actions.setWindowVisible(this.props.windowId, false);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const odataTree = nextProps.odataTree
    if (odataTree.treeVersion !== prevState.treeVersion) {
      // update the tree
      return {
        treeVersion: odataTree.treeVersion,
        odmObject: {...prevState.odmObject}
      }
    }
  }

  render () {
    return (
      <Dialog
        title={ this.props.title }
        windowId={this.props.windowId}
        visible={ this.props.visible }
        onClose={ this.handleClose.bind(this) }
        onMinimize={ this.handleMinimize.bind(this) }
      >
        <ODataTree odmObject={this.state.odmObject} />
      </Dialog>
    );
  }
}

const mapStateToProps = state => ({
  odataTree: state.odataTree,
})

const mapDispatchToProps = dispatch => ({
  actions: Object.assign(
    {},
    bindActionCreators(WindowManagementActions, dispatch),
    bindActionCreators(OdataTreeActions, dispatch),
  )
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ObjectBrowserDialog)
