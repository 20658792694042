import React from "react";
import styles from './odata-tree.module.less'
import { svgStrToImg } from '../../../utils/utils';

export function treeNodeIcon(svgStr) {
  return (
    <div className={styles.treeNodeIcon}>
      {svgStrToImg(svgStr)}
    </div>
  )
}
