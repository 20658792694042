import { ReactComponent as PlusIcon } from '../../../../assets/icons/plus.svg'
import React from 'react'
import { Modal } from 'antd';
import ViewForm from '../view-form';
import ODM from 'odm'
import OE from 'oe'

class CreateChild extends React.Component {
  constructor(props) {
    super(props);
    this.odm = (this.props.odm || ODM).createSubODM()
    this.oe = (this.props.oe || OE).createSubOE(this.odm)
    this.state = {
      isModalShow: false
    }
  }

  onCreate() {
    if (this.props.vFObject && this.props.vFObject.typ) {
      const vFObject = this.props.vFObject
      switch (vFObject.typ.toLowerCase()) {
        case 'd2d':
          this.parentDataset = this.odm.getDNoLoad(vFObject.tid)
          break
        case 'd':
          this.parentDataset = vFObject
          break
        // case 'rd2rd':
        //   this.parentDataset = this.odm.getRDNoLoad(vFObject.tid)
        //   break
      }
      if (this.parentDataset) {
        this.newD2DRelation = this.oe.newSubDForRdsubRel(this.parentDataset, this.props.relData)
        this.parentDataset.trl.push(this.newD2DRelation.id);
        if(this.parentDataset.srl.indexOf(this.newD2DRelation.id) === -1) {
          this.parentDataset.srl.push(this.newD2DRelation.id);
          this.odm.updateDNoSave(this.parentDataset, this.parentDataset.id);
        }
        this.setState({
          isModalShow: true
        })
      }
    }
  }

  onOk = () => {
    this.setState({
      isModalShow: false
    })
    this.odm.pushSubToParentODM()
    this.props.refreshParent()
  }

  onCancel = () => {
    this.odm.discard()
    this.setState({
      isModalShow: false
    })
  }

  render() {
        return (
          <React.Fragment>
            <PlusIcon className={this.props.className} onClick={() => this.onCreate()}></PlusIcon>
            {this.state.isModalShow && this.newD2DRelation &&
            <Modal title="Create child"
                   onOk={this.onOk}
                   maskClosable={false}
                   onCancel={this.onCancel}
                   visible={this.state.isModalShow}>
              <ViewForm
                vFObject={this.newD2DRelation}
                vFCode={this.props.vFCode || 'DEFAULT'}
                displayObjectSize={false}
                enableClipboard={false}
                displayObjectSize={false}
                displayDataTypes={false}
                noActions={true}
                editMode={true}
                onAfterSubmit={ () => { console.log('after submit') } }
                odm={this.odm}
                oe={this.oe}
              />

            </Modal>
            }
          </React.Fragment>
        )
      }
}

export default CreateChild
