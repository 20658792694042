import React, { useMemo } from 'react';
import { Checkbox } from 'antd';

export const TableCheckboxGroup = React.memo(({values, options, onChange, editMode}) => {

  if (editMode) {
    return (
      <Checkbox.Group
        value={values}
        options={options}
        onChange={onChange}
      />
    )
  }

  const label = typeof values === "boolean" ?  (values ? "True" : "False") : values.map(item => {
    const target = options.find(o => o.value === item)
    return target ? target.label : ''
  }).join(',')
  return (<div>{label}</div>)
})
